import { useEffect, useState } from "react";
import recruiterLogo from "../../components/assets/LogoColor.png";
import { useSearchParams } from "react-router-dom";
import FilterCategories from "../../components/jobBoard/jobListing/Filter";
import { useDispatch } from "react-redux";
import { fetchJobListByCompany } from "../../redux/services/JobBoardService";
import { useSelector } from "react-redux";
import ApplyModal from "../../components/jobBoard/ApplyModal";
import JobTable from "../../components/jobBoard/jobListing/JobTable";
import LoadingSkeleton from "../../components/jobBoard/jobListing/LoadingSkeleton";
import { production } from "../../lib/core";

const JobListing = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState({});
  const [selectedJob, setSelectedJob] = useState(-1);

  // URL search params for searching keywords
  const [searchParams, setSearchParams] = useSearchParams({});

  // Jobs coming from the API
  const { jobs, isLoading } = useSelector((state) => {
    return {
      jobs: state?.JobBoardSlice?.jobs?.jobposts,
      isLoading: state?.JobBoardSlice?.isLoading,
    };
  });

  // Search - set query in search params
  const handleSearch = (e, category) => {
    // If the input is deleted => params existed but now value is empty then remove param
    if (searchParams.get(category) && e.target.value.trim() == "") {
      searchParams.delete(category);
      setSearchParams(searchParams);
    } else {
      // Set value as param
      searchParams.set(category, e.target.value);
      setSearchParams(searchParams, { replace: true });
    }
    getJobs();
  };

  const handlePagination = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
    getJobs();
  };

  useEffect(() => {
    getJobs();
  }, [status]);

  const getJobs = async () => {
    try {
      let name;
      // Get the company name from hostname
      console.log(production())
      if (production()) {
        name = window.location.hostname.split(".")[0];
      } else {
        name = "natera";
      }
      const query = window.location.search ?? "";
      dispatch(fetchJobListByCompany({ companyName: name, query: query }));
    } catch (error) {}
  };

  return (
    <div className="w-screen h-screen p-0 overflow-hidden flex flex-col">
      {/* Header - with only logo */}
      <div className="h-16 w-full container py-4 bg-transparent">
        <img className="h-full" src={recruiterLogo} alt="" />
      </div>
      <main className="grow w-full min-h-full h-auto overflow-auto scrollbar-candidate flex flex-col">
        <div
          className={
            jobs?.bg
              ? "min-h-[25%] w-full bg-[url(" + String(jobs?.bg) + ")] relative"
              : "h-auto w-full bg-bluegreen-800 relative"
          }
        >
          {/* Container with background cover */}
          <div
            className={`relative lg:h-full h-auto container sm:w-4/5 w-full flex lg:flex-row flex-col lg:gap-5 gap-2 items-center lg:py-14 py-5 px-4`}
          >
            {/* Search bar */}
            <div className="w-full relative h-12 bg-white z-10 rounded-lg">
              <input
                type="text"
                name="search"
                placeholder="Search jobs"
                onChange={(e) => handleSearch(e, "search")}
                className="w-full border bg-white border-gray-400 pr-4 pl-14 sm:py-3 h-full rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600 text-gray-700 placeholder:text-base placeholder:font-medium sm:text-lg text-base tracking-widest placeholder:tracking-normal"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="#00AC85"
                className="w-10 h-[calc(100%-2px)] absolute z-10 left-2 top-px p-2 rounded-l-lg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
            <div className="w-full relative h-12 bg-white z-10 rounded-lg">
              <input
                type="text"
                name="search"
                placeholder="Location"
                onChange={(e) => handleSearch(e, "location")}
                className="w-full border bg-white border-gray-400 pr-4 pl-14 sm:py-3 h-full rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600 text-gray-700 placeholder:text-base placeholder:font-medium sm:text-lg text-base tracking-widest placeholder:tracking-normal"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="#00AC85"
                className="w-10 h-[calc(100%-2px)] absolute z-10 left-2 top-px p-2 rounded-l-lg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
            </div>
          </div>
        </div>
        <FilterCategories />
        {/* Jobs list section */}
        <div className="h-auto min-h-full w-full bg-gray-300 pb-16">
          {!isLoading ? (
            <div className="container h-full relative px-5 py-10">
              <h1 className="text-xl text-black font-medium w-auto mb-7">
                Explore new jobs
              </h1>
              <div className="relative w-full h-auto p-4 bg-white rounded-lg shadow-lg divide-y divide-gray-300">
                {jobs?.data?.map(
                  (job, i) =>
                    job?.position?.length > 0 && (
                      <JobTable
                        key={i}
                        job={job}
                        setOpenModal={setOpenModal}
                        setSelectedJob={setSelectedJob}
                        status={status}
                      />
                    )
                )}
              </div>
              <div className="w-full flex justify-center text-sm mt-4 text-black font-medium gap-3">
                <button
                  disabled={jobs?.page == 1}
                  className="hover:text-blue hover:underline cursor-pointer disabled:pointer-events-none disabled:opacity-50"
                  onClick={() => handlePagination(1)}
                >
                  {"<< First"}
                </button>
                <button
                  disabled={!jobs?.hasPrevPage}
                  className="hover:text-blue hover:underline cursor-pointer disabled:pointer-events-none disabled:opacity-50"
                  onClick={() => handlePagination(jobs?.prevPage)}
                >
                  {"< Prev"}
                </button>
                {
                  <span>
                    {`Showing ${
                      ((jobs?.page ?? 1) - 1) * (jobs?.limit ?? 10) + 1
                    } -
                  ${Math.min(
                    (jobs?.page ?? 1) * (jobs?.limit ?? 10),
                    jobs?.total ?? 10
                  )} of ${jobs?.total ?? 10}`}
                  </span>
                }
                <button
                  disabled={!jobs?.hasNextPage}
                  className="hover:text-blue hover:underline cursor-pointer disabled:pointer-events-none disabled:opacity-50"
                  onClick={() => handlePagination(jobs?.nextPage)}
                >
                  {"Next >"}
                </button>
                <button
                  disabled={jobs?.page == jobs?.totalPages}
                  className="hover:text-blue hover:underline cursor-pointer disabled:pointer-events-none disabled:opacity-50"
                  onClick={() => handlePagination(jobs?.totalPages)}
                >
                  {"Last >>"}
                </button>
              </div>
            </div>
          ) : (
            <LoadingSkeleton />
          )}
        </div>
      </main>
      {openModal ? (
        <ApplyModal
          status={status}
          setOpenModal={setOpenModal}
          setStatus={setStatus}
          jobId={selectedJob}
        />
      ) : null}
    </div>
  );
};

export default JobListing;
