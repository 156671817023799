import React, { useEffect } from 'react'
import Header from '../../all/header/Header'
import { useState } from 'react'
import { useLocation, Link, useNavigate } from "react-router-dom";
import SideBar from '../../all/SideBar'
import Menu from '../../all/header/Menu'
import About from './profileComponents/About'
import Jobs from './profileComponents/Jobs'
import Hiringmanagers from './profileComponents/Hiringmanagers'
const core = require('../../../lib/core')


export default function CompanyDetails() {
  const [company, setCompany] = useState([])
  const [about, setAbout] = useState("")
  const [jobs, setJobs] = useState("hidden")
  const [hm, setHm] = useState("hidden")
  const [aboutborder, setAboutborder] = useState("2px solid #0D4CC5")
  const [jobsborder, setJobsborder] = useState("")
  const [hmborder, setHmborder] = useState("")
  const location = useLocation();
  const [data, setData] = useState([])

  const getAbout = () => {
    setAbout("")
    setJobs("hidden")
    setHm("hidden")
    setAboutborder("2px solid #85ADF9")
    setJobsborder("")
    setHmborder("")
  }
  const getJobs = () => {
    setAbout("hidden")
    setJobs("")
    setHm("hidden")
    setAboutborder("")
    setJobsborder("2px solid #85ADF9")
    setHmborder("")
  }
  const getHm = () => {
    setAbout("hidden")
    setJobs("hidden")
    setHm("")
    setAboutborder("")
    setJobsborder("")
    setHmborder("2px solid #85ADF9")
  }

  const nav = useNavigate()

  useEffect(() => {
    fetchcompany(location.pathname.split("/").at(-1))

  }, [])

  const fetchcompany = async (id) => {
    try {
      var jobapi = await core.API(
        core.API_METHODS.GET,
        core.COMPANY_API.GET_COMPANY_PROFILE  + String(id),
        1,
        {}
      );
      setCompany(jobapi?.data?.company);
      setData(jobapi.data)
    } catch (error) {
      nav("/")
    }
  };

  return (
    <div className="page recruiter" id="company-details">
      <Header category="recruiter" showOnlyLogo={false} menu={<Menu />} />
      <SideBar />
      <main className="main-content">
        <h6 className="breadcrumbs"> <Link to='/'>Home</Link> / <Link to='/company-profile'>Active companies</Link> / <Link to='.'>{company?.name}</Link></h6>
        <div className='background-color'></div>
        <div className="col-xs-12  top-section">
          <div className=''>
            {company.logo ? (<>
              <img src={company.logo} alt="" />
            </>) : (<>
              <span className='image-text'>{company?.name?.split('')[0]?.toUpperCase()}</span>
            </>)}
            {/* <img src={company?.logo} alt="" /> */}
          </div>
          <div className='text-section'>
            <div className=' no-padding button-section'>
              <span className='name'>{company?.name}</span>
            </div>

            {/* <span className='company  no-padding'>{company?.about}</span> */}
            <span className='description  no-padding'>{company?.location}</span>
          </div>

        </div>
        <div className='col-xs-12 bottom-section no-padding'>
          {/* <div className='button-section col-xs-12 no-padding'>
                    <button style={{ borderBottom: aboutborder }} 
                     onClick={() => {
                      window.history.replaceState(
                        "",
                        "",
                        window.location.pathname.split("/").at(-1)+
                          "?About"
                      );
                      getAbout()
                    }}
                    >About</button>
                    <button style={{ borderBottom: jobsborder }}
                     onClick={() => {
                      window.history.replaceState(
                        "",
                        "",
                        window.location.pathname.split("/").at(-1)+
                          "?Jobs"
                      );
                      getJobs()
                    }}
                    
                    >Jobs</button>
                   </div> */}
          <div className='col-xs-12 no-padding ' hidden={about}>
            <About props={data} />
          </div>
          <div className='col-xs-12 no-padding' hidden={jobs}>
            <Jobs props={data} />
          </div>
          <div className='col-xs-12 no-padding' hidden={hm}>
            {/* <Hiringmanagers/> */}
          </div>

        </div>
      </main>
    </div>
  )
}
