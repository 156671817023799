import React, { useRef, useState } from "react";
import Modal from "../modal/Modal";
const core = require("../../lib/core");

const ApplyModal = ({ setOpenModal, status, setStatus, jobId }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const handleDrop = (event) => {
    setStatus({ ...status, [jobId]: "UPLOADING" });
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    onFileChange(file);
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  function onFileChange(file) {
    if (
      file.size <= 4 * 1024 * 1024 &&
      file.name.split(".").pop().toLowerCase() === "pdf"
    ) {
      try {
        setFile(file);
        setStatus({ ...status, [jobId]: "UPLOADED" });
      } catch (err) {
        console.error(err);
      }
    } else {
      //   toast.error("File format or size not supported");
    }
  }
  async function upload_resume() {
    try {
      setStatus({ ...status, [jobId]: "SENDING" });
      let formData = new FormData();
      formData.append("files", file);
      formData.append("jobId", jobId);
      await fetch(core.RECRUITER_API.POST_PUBLIC_UPLOAD_RESUME, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.error) {
            return;
          }
          setStatus({ ...status, [jobId]: "SENT" });
          setTimeout(() => setOpenModal(false), 5000);
        })
        .catch((err) => {
          console.error(err);
          setStatus({ ...status, [jobId]: "ERROR_APPLYING" });
        });
    } catch (err) {
      console.error(err);
      setStatus({ ...status, [jobId]: "ERROR_APPLYING" });
    }
  }
  return (
    <Modal
      modalDismiss={() => setOpenModal(false)}
      heading={"Apply with your resume"}
      onSave={() => {
        upload_resume();
      }}
      dismissOnSave={false}
      defaultButtons={{ primaryLabel: "Submit", primaryDisabled: !file }}
    >
      <div
        className="upload-document upload-document-first-state w-full cursor-pointer flex gap-4 items-center border-dashed border hover:bg-gray-300 border-gray-700 rounded-lg p-2"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        onClick={handleFileClick}
      >
        <input
          type="file"
          id="upload"
          name="resume"
          accept=".pdf"
          ref={fileInputRef}
          hidden
          className="hide hidden"
          onChange={(e) => {
            onFileChange(e.target.files[0]);
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="63"
          height="63"
          viewBox="0 0 63 63"
          fill="none"
        >
          <g id="Upload Single File">
            <g id="Group 47170">
              <path
                id="Vector"
                d="M31.3645 61.56C48.096 61.56 61.729 47.9833 61.729 31.3082C61.6727 14.5767 48.096 1 31.3645 1C14.5767 1 1 14.5767 1 31.2518C1 47.9833 14.5767 61.56 31.3645 61.56Z"
                fill="#F1F3F9"
                stroke="#D6DCE8"
                strokeMiterlimit="10"
              />
            </g>
            <g id="Group 47171">
              <g id="Group 47159">
                <path
                  id="Vector_2"
                  d="M52.666 18.559V55.2506C52.666 57.9241 50.5456 60.0445 47.8721 60.0445H14.8681C12.1946 60.0445 10.0742 57.9241 10.0742 55.2506V7.21966C10.0742 4.54615 12.1946 2.42578 14.8681 2.42578H36.6249C37.8234 2.42578 38.9297 2.88673 39.7594 3.71644L51.3753 15.3324C52.205 16.2543 52.666 17.3605 52.666 18.559Z"
                  fill="white"
                  stroke="#D6DDE8"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_3"
                  d="M38.248 2.76172V13.6645C38.248 15.6198 39.9038 17.184 41.7853 17.184H52.3349"
                  fill="#D6DCE8"
                />
                <g id="Group 47168">
                  <path
                    id="Vector_4"
                    d="M24.3154 50.0525H16.6277C16.3408 50.0525 16.1113 49.7476 16.1113 49.3817C16.1113 49.0159 16.3408 48.7109 16.6277 48.7109H24.3154C24.6023 48.7109 24.8318 49.0159 24.8318 49.3817C24.8318 49.8086 24.6023 50.0525 24.3154 50.0525Z"
                    fill="#D5DDEA"
                  />
                  <path
                    id="Vector_5"
                    d="M34.1471 12.4861H16.858C16.456 12.4861 16.1113 12.1765 16.1113 11.8153C16.1113 11.4541 16.456 11.1445 16.858 11.1445H34.1471C34.5492 11.1445 34.8938 11.4541 34.8938 11.8153C34.8938 12.1765 34.5492 12.4861 34.1471 12.4861Z"
                    fill="#D6DCE8"
                  />
                  <path
                    id="Vector_6"
                    d="M23.4759 16.5096H16.8535C16.4539 16.5096 16.1113 16.2 16.1113 15.8388C16.1113 15.4776 16.4539 15.168 16.8535 15.168H23.4188C23.8184 15.168 24.161 15.4776 24.161 15.8388C24.161 16.2 23.8184 16.5096 23.4759 16.5096Z"
                    fill="#D6DCE8"
                  />
                  <path
                    id="Vector_7"
                    d="M46.4254 39.9922H26.2652H24.0433H17.3232C17.0522 39.9922 16.7812 40.2717 16.7812 40.663C16.7812 40.9984 16.998 41.3338 17.3232 41.3338H24.0433H26.2652H46.4254C46.6964 41.3338 46.9674 41.0543 46.9674 40.663C46.9132 40.2717 46.6964 39.9922 46.4254 39.9922Z"
                    fill="#D6DCE8"
                  />
                  <path
                    id="Vector_8"
                    d="M46.4245 35.293H38.9322H36.3805H17.3242C17.0527 35.293 16.7812 35.5725 16.7812 35.9638C16.7812 36.2992 16.9984 36.6346 17.3242 36.6346H36.3805H38.9322H46.4245C46.6959 36.6346 46.9674 36.3551 46.9674 35.9638C46.9131 35.5725 46.6959 35.293 46.4245 35.293Z"
                    fill="#D6DCE8"
                  />
                  <path
                    id="Vector_9"
                    d="M46.4737 30.5977H44.5266H42.5796H17.3221C17.0517 30.5977 16.7812 30.8772 16.7812 31.2685C16.7812 31.6039 16.9976 31.9393 17.3221 31.9393H42.5796H44.743H46.4196C46.69 31.9393 46.9604 31.6598 46.9604 31.2685C47.0145 30.9331 46.7441 30.5977 46.4737 30.5977Z"
                    fill="#D6DCE8"
                  />
                  <path
                    id="Vector_10"
                    d="M46.4312 25.9062H40.1581H38.6032H17.3174C17.0493 25.9062 16.7812 26.1858 16.7812 26.5771C16.7812 26.9125 16.9957 27.2479 17.3174 27.2479H38.6032H40.1581H46.3776C46.7529 27.2479 46.9674 26.9684 46.9674 26.5771C46.9674 26.2417 46.7529 25.9062 46.4312 25.9062Z"
                    fill="#D6DCE8"
                  />
                </g>
                <path
                  id="Vector_11"
                  d="M46.77 60.1493C53.3178 60.1493 58.6259 54.8412 58.6259 48.2934C58.6259 41.7456 53.3178 36.4375 46.77 36.4375C40.2221 36.4375 34.9141 41.7456 34.9141 48.2934C34.9141 54.8412 40.2221 60.1493 46.77 60.1493Z"
                  fill="#8D98B1"
                />
                <path
                  id="Vector_12"
                  d="M47.4928 43.5394L50.4622 46.6922C50.8116 47.0632 50.8116 47.7123 50.4622 48.0832C50.1129 48.4541 49.5015 48.4541 49.1085 48.0832L47.7548 46.6922V51.7924C47.7548 52.3488 47.3181 52.8125 46.7941 52.8125C46.2701 52.8125 45.8334 52.3488 45.8334 51.7924V46.6922L44.4797 48.0832C44.1304 48.4541 43.519 48.4541 43.126 48.0832C42.9076 47.8514 42.8203 47.6196 42.8203 47.3877C42.8203 47.1559 42.9076 46.8313 43.126 46.6922L46.0954 43.5394C46.2264 43.4003 46.5321 43.2148 46.7504 43.2148C46.9688 43.2148 47.3618 43.3076 47.4928 43.5394Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </svg>
        <div className="text-section">
          <div className="text-gray-800 text-base font-medium">
            Drag or click to browse your document
          </div>
          <div className="lower-text">
            Supported formats:
            {/* doc, docs, */}
            pdf
            {/* rtf */}
            (4 mb max)
          </div>
        </div>
      </div>
      {String(status[jobId])?.length > 0 && (
        <span
          className={`inline-block w-full my-2 ${
            status[jobId] == "ERROR_APPLYING"
              ? "text-red-900"
              : status[jobId] == "SENT" || status[jobId] == "UPLOADED"
              ? "text-green-900"
              : "text-gray-900"
          }`}
        >
          {status[jobId] == "UPLOADING"
            ? "Uploading file. Please wait."
            : status[jobId] == "UPLOADED"
            ? "File added successfully"
            : status[jobId] == "SENDING"
            ? "Sending your application. Please wait."
            : status[jobId] == "SENT"
            ? "Application sent successfully"
            : status[jobId] == "ERROR_APPLYING"
            ? "Something went wrong while applying. Please try again."
            : null}
        </span>
      )}
    </Modal>
  );
};

export default ApplyModal;
