import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/all/header/Header";
import SideBar from "../../components/all/SideBar";
import Menu from "../../components/all/header/Menu";
import { toast } from "react-toastify";
const core = require("../../lib/core");

const company_data = {
  name: "",
  logo: " ",
  founder: " ",
  size: " ",
  about: " ",
  links: " ",
};

export default function CreateCompany() {
  // const { user, roleId } = useAppContext();
  const nav = useNavigate();
  const [formcompany, setFormcompany] = useState(company_data);
  const [dpUrl, setDpUrl] = useState(
    "https://sproutsweb-assets.s3.amazonaws.com/common-assets/VerificationPage/enterprise.png"
  );

  var img_extension = "";

  useEffect(() => {}, []);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const submitFileData = async (companyId) => {
    let formData = new FormData();
    formData.append("file", file);
    const token = await core.getData(core.data.ACCESS_TOKEN);
    fetch(core.COMPANY_API.POST_UPLOAD_COMPANY_PIC, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        cid: companyId,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          toast.error(data.error)
          nav(-1, { replace: true });
        }
      });
  };

  const createNewCompanyPost = async () => {
    console.log(formcompany.name);
    const json = await core.API(
      core.API_METHODS.POST,
      core.COMPANY_API.POST_CREATE_COMPANY_PROFILE,
      1,
      {
        name: formcompany.name,
        founder: formcompany.founder,
        size: formcompany.size,
        about: formcompany.about,
        links: formcompany.links,
      }
    );
    console.log("JSON:", json);
    if (json.data) {
      // Success
      console.log(json.data);
      nav(-1, { replace: true });
      submitFileData(json?.data?.company);
    } else {
      console.log("error");
      // Error
    }
  };

  function setInput(key, value) {
    setFormcompany({ ...formcompany, [key]: value });
  }
  return (
    <div className="page recruiter-pages" id="createCompany">
      <Header category="recruiter" showOnlyLogo={false} menu={<Menu />} />
      <SideBar />
      <main className="main-content no-padding col-xs-12">
        <div className="sub-container">
          <h1 className="headingText">Company Details</h1>
          <form
            className="col-xs-12 no-padding"
            onSubmit={createNewCompanyPost}
          >
            <div className="col-xs-12 col-md-3 no-padding left-section">
              <img
                src={image ?? dpUrl}
                alt="Company Logo"
                className="img-responsive company_logo"
              />
              <input
                type="file"
                id="logo"
                name="logo"
                className="hide"
                hidden
                onChange={onFileChange}
              />
              <label htmlFor="logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.862 4.48725L19.5 7.12525M18 14.0002V18.7502C18 19.347 17.7629 19.9193 17.341 20.3412C16.919 20.7632 16.3467 21.0002 15.75 21.0002H5.25C4.65326 21.0002 4.08097 20.7632 3.65901 20.3412C3.23705 19.9193 3 19.347 3 18.7502V8.25025C3 7.65351 3.23705 7.08121 3.65901 6.65926C4.08097 6.2373 4.65326 6.00025 5.25 6.00025H10M16.862 4.48725L18.549 2.79925C18.9007 2.44757 19.3777 2.25 19.875 2.25C20.3723 2.25 20.8493 2.44757 21.201 2.79925C21.5527 3.15092 21.7502 3.6279 21.7502 4.12525C21.7502 4.62259 21.5527 5.09957 21.201 5.45125L10.582 16.0702C10.0533 16.5986 9.40137 16.987 8.685 17.2002L6 18.0002L6.8 15.3152C7.01328 14.5989 7.40163 13.9469 7.93 13.4182L16.862 4.48725V4.48725Z"
                    stroke="#0B53DD"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
                <span>Replace</span>{" "}
              </label>
            </div>
            <div className="col-xs-12 col-md-8 no-padding right-section">
              <label
                htmlFor="companyName"
                className="col-xs-12 no-padding"
                required
              >
                Company Name
              </label>
              <input
                className="col-xs-12 no-padding"
                type="text"
                name="companyName"
                required
                value={formcompany.name}
                onChange={(event) => {
                  setInput("name", event.target.value);
                }}
                placeholder="Full Company Name"
              />
              <label
                htmlFor="founder"
                className="col-xs-12 no-padding"
                required
              >
                Founder Name
              </label>
              <input
                className="col-xs-12 no-padding"
                type="text"
                name="founder"
                value={formcompany.founder}
                required
                onChange={(event) => {
                  setInput("founder", event.target.value);
                }}
                placeholder="Founder's Name(s)"
              />
              <label htmlFor="aboutCompany" className="col-xs-12 no-padding">
                About Company
              </label>
              <textarea
                className="col-xs-12"
                placeholder="About Company"
                onChange={(event) => {
                  setInput("about", event.target.value);
                }}
                value={formcompany.about}
                name="aboutCompany"
                id="aboutCompany"
                cols="3"
                rows="10"
              ></textarea>
            </div>
          </form>
          <div className="controller col-xs-12 no-padding">
            <Link to={-1}>Skip</Link>
            <button
              type="submit"
              className="right"
              onClick={createNewCompanyPost}
            >
              <span className="label">Save and Exit</span>
            </button>
            <button
              type="submit"
              className="right"
              onClick={createNewCompanyPost}
            >
              <span className="label">Save and Continue</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
