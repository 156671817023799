import { useEffect, useState } from "react";
import AssessmentPlan from "./AssessmentPlan";
import AssessmentInvites from "./AssessmentInvites";
import { useSelector } from "react-redux";
import { fetchAssessment } from "../../../../redux/services";
import { useDispatch } from "react-redux";
import TabLayout from "../../../../layouts/TabLayout";

export default function AssessmentSection({ reload, fetchJob, ...props }) {
  const dispatch = useDispatch();
  const { jobId, count } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
      count:
        state?.JobExpandSlice?.assessment?.assessments?.length +
        state?.JobExpandSlice?.assessment?.invitedCandidates?.length,
    };
  });

  const [view, setView] = useState("assessment");
  const [section, setSection] = useState(<AssessmentPlan />);
  const fetchAssessmentlist = async () => {
    if (!jobId) return;
    dispatch(fetchAssessment(jobId + "?status=active"));
  };

  useEffect(() => {
    fetchAssessmentlist();
  }, [jobId]);

  useEffect(() => {
    switch (view) {
      case "assessment": {
        setSection(
          <AssessmentPlan reload={reload} miniReload={fetchAssessmentlist} />,
        );
        break;
      }
      case "invited": {
        setSection(<AssessmentInvites />);
        break;
      }
    }
  }, [view]);

  return (
    <section className="mt-[28px]" id="assessment-section">
      {count > 0 ? (
        <div className="w-auto z-10">
          <TabLayout
            btnList={[
              { id: "assessment", name: "Assessment Planning" },
              { id: "invited", name: "Invited Candidates" },
            ]}
            state={view}
            setState={setView}
          />
          {/* <div className=" px-[2px]  py-[1.5px] inline-flex rounded-[10px] bg-bg1 relative">
            <button
              className={`border-none px-[10px] py-[6px] h-[40px]  ${
                view === "Plan" ? " rounded-[8px] bg-[#FFF] text-[#1369E9]" : ""
              }`}
              onClick={() => setView("Plan")}
            >
              Assessment Planning
            </button>
            {true && (
              <>
                <button
                  className={`border-none px-[10px] py-[6px] h-[40px]  ${
                    view === "Invited"
                      ? " rounded-[8px] bg-[#FFF] text-[#1369E9]"
                      : ""
                  }`}
                  onClick={() => setView("Invited")}
                >
                  Invited candidates
                </button>
              </>
            )}
          </div> */}
        </div>
      ) : null}
      <div className="col-xs-12 no-padding section">{section}</div>
    </section>
  );
}
