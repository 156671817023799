import React from "react";
import Sprouts from "../../jobPost/sprouts.png";
import Dash from "../../jobPost/dash.JPG";
import CrearJobimg from "../../jobPost/createjob.JPG";
const Guidelines = () => {
  return (
    <>
      <div className="bg-defaultBg  py-10 h-full md:text-justify overflow-auto">
        <div className="bg-white  max-w-5xl mx-auto rounded-lg shadow-md">
          <div className="flex items-center justify-between py-3">
            <img
              src={Sprouts}
              alt="Sprouts Logo"
              className="ml-0 md:ml-12  w-40 h-auto  "
            />
            <div className="text-white mr-2 md:mr-12 hover:bg-blue-600 hover:no-underline inline-flex items-center bg-blue-900 px-3 py-2 rounded-md">
              <a
                href="https://www.sproutsai.com/"
                className="hover:no-underline hover:text-white"
              >
                Homepage
              </a>
            </div>
          </div>
          <hr className="border-gray-400 " />
          <div className="p-8">
            <div className="flex justify-center items-center pb-6">
              <h1 className="text-3xl font-bold mb-4 text-gray-800">
                Guideliness for Career Page Integration
              </h1>
            </div>
            <p className="mb-6 text-gray-700">
              SproutsAI allows you to integrate the job boards and posts you
              create in your account with the career page you already have on
              your organization’s website. This is key to ensuring that
              candidates who apply through your career page will be brought into
              SproutsAI.
            </p>
            <p className="mb-6 text-gray-700">
              This process saves time, streamlines the management of your job
              posts, and simplifies applicant tracking by tracking data from a
              single place.
            </p>
            <div className="bg-gray-200 p-6 rounded-lg mb-6 shadow-sm ">
              <h2 className="text-lg font-semibold mb-4">In This Article</h2>
              <ul className="space-y-2 text-green-900">
                <li>
                  <a
                    href="#before-you-start"
                    className="text-green-900 hover:no-underline font-semibold text-gray-800"
                  >
                    Before you start
                  </a>
                  <ul className="pl-4 list-disc">
                    <li>
                      <a
                        href="#know-the-terms"
                        className="text-green-900 text-gray-800 hover:no-underline"
                      >
                        Know the terms
                      </a>
                    </li>
                    <li>
                      <a
                        href="#determine-your-website-builder"
                        className="text-green-900 hover:no-underline"
                      >
                        Determine your website builder
                      </a>
                    </li>
                    <li>
                      <a
                        href="#decide-how-you-want-your-careers-page-to-look"
                        className="text-green-900 hover:no-underline"
                      >
                        Decide how you want your careers page to look
                      </a>
                    </li>
                    <li>
                      <a
                        href="#assess-your-resources"
                        className="text-green-900 hover:no-underline"
                      >
                        Assess your resources
                      </a>
                    </li>
                    <li>
                      <a
                        href="#make-a-plan"
                        className="text-green-900 hover:no-underline"
                      >
                        Make a plan
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="#start-integrating"
                    className="text-green-900 hover:no-underline font-semibold"
                  >
                    Start integrating
                  </a>
                </li>
              </ul>
            </div>

            <div className="bg-white py-6 rounded-lg text-gray-700">
              <h2
                id="before-you-start"
                className="text-2xl font-semibold mb-4 text-gray-800"
              >
                Before you start
              </h2>
              <p className="mb-6">
                The careers page integration process differs in time and
                complexity for each organization. However, every organization
                starts the process by collecting the same key information.
              </p>
              <p className="mb-6">
                We created the following steps to help you gather the
                information you need to get started.
              </p>
              <h3
                id="know-the-terms"
                className="text-lg font-semibold mb-4 text-gray-800"
              >
                Know the terms
              </h3>
              <ul className="list-disc pl-6 space-y-4 marker:text-gray-800">
                <li className="text-gray-700">
                  <span className="font-bold text-gray-800">Job post:</span> A
                  page created in SproutsAI that includes a description of the
                  role you’re hiring for, its location, and an application form
                </li>
                <li className="text-gray-700">
                  <span className="font-bold text-gray-800 ">Job board:</span> A
                  page created in SproutsAI that links to all your job posts
                </li>
                <li className="text-gray-700">
                  <span className="font-bold text-gray-800">Careers page:</span>{" "}
                  The page on your organization’s website where you list open
                  roles, often called “Careers”, “Jobs”, “Opportunities” or
                  “Work with us”
                </li>
              </ul>
            </div>

            {/* <div className="bg-white py-6 rounded-lg  text-gray-700 mb-6">
                            <h3 id="determine-your-website-builder" className="text-2xl font-semibold mb-4 text-gray-800">Determine your website builder</h3>
                            <p className="mb-6">
                                A website builder, sometimes called a Content Management System or CMS, is the service you use to create and edit the pages on your website.
                            </p>
                            <p className="mb-6">
                                Common website builders include <a href="https://wordpress.com" className="text-green-900 hover:no-underline">WordPress</a>, <a href="https://squarespace.com" className="text-green-900 hover:no-underline">Squarespace</a>, and <a href="https://hubspot.com" className="text-green-900 hover:no-underline">Hubspot</a>, but your organization may use a different one. If you don't know who your website builder is, we recommend reaching out to a member of your team. This could be someone in your IT department, the person who manages your website, or another technical contact.
                            </p>
                            <p className="mb-6">
                                We created the following message template to help you collect the info you need. Copy the message below and send it to a member of your team:
                            </p>
                            <div className="bg-gray-50 px-4 py-2 rounded-lg border border-gray-200 mb-6">
                                <p className="font-mono">
                                    Hello, I’m working on connecting our website with our SproutsAI  account. What website builder do we use?
                                </p>
                            </div>
                            <p>
                                Depending on how you choose to integrate your careers page with SproutsAI, you may need to <a href="https://www.sproutsai.com/integrations" className="text-green-900 hover:no-underline">edit the code on your website</a>.
                            </p>
                        </div> */}

            <div className="bg-white pb-6 pt-3 text-gray-700 rounded-lg ">
              <h3
                id="decide-how-you-want-your-careers-page-to-look"
                className="text-2xl font-semibold mb-4 text-gray-800"
              >
                Decide how you want your careers page to look
              </h3>
              <p className="mb-6">
                Make a quick and simple change to the code on your website that
                redirects to your job board on SproutsAI
                (sproutsai.com/job-post/[your_company]).
              </p>
              <p className="mb-6">
                Control basic information on the job board and applications
                pages. “Powered by SproutsAI” appears at the bottom of each
                page..
              </p>
              <table className="table-auto w-full border-collapse  border-gray-100">
                <tbody>
                  <tr className="bg-emerald-100">
                    <td className="border p-4 font-semibold">Customizations</td>
                    <td className="border p-4">
                      <ul className="list-disc list-inside">
                        <li>Add a logo</li>
                        <li>Select a theme</li>
                        <li>Provide job details for the job post</li>
                        <li>Add custom questions to job applications</li>
                      </ul>
                    </td>
                  </tr>
                  <tr className="bg-emerald-50">
                    <td className="border p-4 font-semibold">
                      Where your job boards are hosted
                    </td>
                    <td className="border p-4">
                      SproutsAI (sproutsai.com/job-post/[your_company])
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="bg-white pt-2 rounded-lg text-gray-700 mb-6">
              <h2
                id="assess-your-resources"
                className="text-2xl font-semibold mb-4 text-gray-800"
              >
                Assess your resources
              </h2>
              <p className="mb-6">
                Consider the number of people in your organization with the
                skills, knowledge, and access to help you integrate your careers
                page. This may include people on your marketing or brand teams.
                Think about how this might impact the job board integration you
                can make.
              </p>
            </div>

            <div className="bg-white pt-4 rounded-lg text-gray-700 mb-6">
              <h2
                id="make-a-plan"
                className="text-2xl font-semibold mb-4 text-gray-800"
              >
                Make a plan
              </h2>
              <p className="mb-2">
                Make adjustments to your goals based on your assessment in the
                previous step.
              </p>
              <p className="mb-6">
                We suggest meeting with the team members who will be involved in
                the project and setting expectations for how the project will
                proceed.
              </p>
            </div>
            <div className="bg-white pt-4 rounded-lg text-gray-700 ">
              <h2
                id="start-integrating"
                className="text-2xl font-semibold mb-4 text-gray-800"
              >
                Start Integrating
              </h2>
              <p className="mb-6">
                When everything is set up, click the "Post New Job" button. You
                will be redirected to the job creation page, where you must
                provide the necessary details for the job post.
              </p>
              <div className="flex justify-center items-center m-8">
                <img src={Dash} alt="Step 1 for Job Posting" />
              </div>
              <p className="mb-6 pt-4">
                Upon clicking the "Post New Job" button, you will be taken to
                the "Create Job Post" page to enter all the job details.
              </p>
              <div className="flex justify-center items-center m-8">
                <img src={CrearJobimg} alt="Step 2 for Job Posting" />
              </div>
              <p className="mb-6 pt-4">
                When all job details are entered, choose "Publish" to finalize
                and publish the job post.
              </p>
            </div>
          </div>
          <footer className="bg-gray-200 pt-5  pb-4 rounded-md">
            <div className="container mx-auto flex items-center justify-center">
              <p className="text-gray-600 mr-1">Hire with</p>
              <img
                src={Sprouts}
                alt="Sprouts Logo"
                className="w-24 h-auto ml-4"
              />
            </div>
            <div className="text-center mt-2">
              <p className="text-sm">
                Read our{" "}
                <a
                  href="https://www.sproutsai.com/privacy"
                  className="text-blue-500 hover:no-underline "
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Guidelines;
