import React from "react";

const Badge = ({ children, type = "primary", className, onClick }) => {
  const getClassname = () => {
    switch (type) {
      case "primary":
        return "text-blue-800 bg-blue-200";
      case "secondary":
        return "text-gray-800 bg-gray-200";
      case "success":
        return "text-green-800 bg-green-200";
      case "danger":
        return "text-red-800 bg-red-200";
      case "warning":
        return "text-yellow-800 bg-yellow-200";
      default:
        return "text-gray-800 bg-gray-200";
    }
  };
  return (
    <button
      className={`${getClassname()} p-2 rounded-md flex justify-center items-center ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Badge;
