import React, { useMemo, useState } from "react";
import { Pagination, Table as TableAnt } from "antd";
import "./table.scss";

const Table = ({
  dataSource,
  columns,
  pageSize,
  current,
  onPageChange,
  totalPage,
  ...props
}) => {
  const revampedColumns = columns.map((column) => {
    if (column.sorter) {
      return {
        ...column,
        sortIcon: (order) => {
          if (order.sortOrder === "ascend") {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                className="inline-block ml-4"
              >
                <path
                  d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                  stroke="#bbbfc9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          } else if (order.sortOrder === "descend") {
            console.log("descend");
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                className="inline-block ml-4"
              >
                <path
                  d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                  stroke="#bbbfc9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          } else {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                className="inline-block ml-4"
              >
                <path
                  d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                  stroke="#bbbfc9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                  stroke="#bbbfc9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          }
        },
      };
    }
    return column;
  });
  return (
    <div id="custom-ant-table">
      <TableAnt
        // scroll={{
        //   y: "800px",
        // }}
        sticky={true}
        dataSource={dataSource}
        columns={revampedColumns}
        showSorterTooltip={false}
        rowHoverable={false}
        bordered={false}
        pagination={false}
        {...props}
      />
      <div className="flex justify-end mt-4">
        <Pagination
          pageSize={pageSize}
          current={current}
          onChange={onPageChange}
          total={totalPage}
          showSizeChanger={true}
          showQuickJumper
        />
      </div>
    </div>
  );
};

export default Table;
