import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";
const core = require("../../../lib/core");

const AverageAnswerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    className="inline-block mr-2"
  >
    <path
      d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
      fill="#FF9500"
    />
    <path
      d="M9.19531 14.2454V9.16535C9.19531 8.96535 9.25531 8.77035 9.36531 8.60535L10.7303 6.57535C10.9453 6.25035 11.4803 6.02035 11.9353 6.19035C12.4253 6.35535 12.7503 6.90535 12.6453 7.39535L12.3853 9.03035C12.3653 9.18035 12.4053 9.31535 12.4903 9.42035C12.5753 9.51535 12.7003 9.57535 12.8353 9.57535H14.8903C15.2853 9.57535 15.6253 9.73535 15.8253 10.0154C16.0153 10.2854 16.0503 10.6354 15.9253 10.9904L14.6953 14.7354C14.5403 15.3554 13.8653 15.8604 13.1953 15.8604H11.2453C10.9103 15.8604 10.4403 15.7454 10.2253 15.5304L9.58531 15.0354C9.34031 14.8504 9.19531 14.5554 9.19531 14.2454Z"
      fill="white"
    />
    <path
      d="M7.605 8.18945H7.09C6.315 8.18945 6 8.48945 6 9.22945V14.2595C6 14.9995 6.315 15.2995 7.09 15.2995H7.605C8.38 15.2995 8.695 14.9995 8.695 14.2595V9.22945C8.695 8.48945 8.38 8.18945 7.605 8.18945Z"
      fill="white"
    />
  </svg>
);
const BestAnswerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    className="inline-block mr-2"
  >
    <path
      d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
      fill="#00AC85"
    />
    <path
      d="M6.61719 10.9985L9.53562 13.917L15.3828 8.08008"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const BadAnswerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill="none"
    className="inline-block mr-2"
  >
    <path
      d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
      fill="#FF475D"
    />
    <path
      d="M12.8035 8.05055V13.1305C12.8035 13.3305 12.7435 13.5255 12.6335 13.6905L11.2685 15.7205C11.0535 16.0455 10.5185 16.2755 10.0635 16.1055C9.57354 15.9405 9.24854 15.3905 9.35354 14.9005L9.61354 13.2655C9.63354 13.1155 9.59354 12.9805 9.50854 12.8755C9.42354 12.7805 9.29854 12.7205 9.16354 12.7205H7.10854C6.71354 12.7205 6.37354 12.5605 6.17354 12.2805C5.98354 12.0105 5.94854 11.6605 6.07354 11.3055L7.30354 7.56055C7.45854 6.94055 8.13354 6.43555 8.80354 6.43555H10.7535C11.0885 6.43555 11.5585 6.55055 11.7735 6.76555L12.4135 7.26055C12.6585 7.45055 12.8035 7.74055 12.8035 8.05055Z"
      fill="white"
    />
    <path
      d="M14.3947 14.105H14.9097C15.6847 14.105 15.9997 13.805 15.9997 13.065V8.04C15.9997 7.3 15.6847 7 14.9097 7H14.3947C13.6197 7 13.3047 7.3 13.3047 8.04V13.07C13.3047 13.805 13.6197 14.105 14.3947 14.105Z"
      fill="white"
    />
  </svg>
);

function QuestionEvaluationModal({ matchCriteria, modalDismiss, jobId }) {
  const [questions, setQuestions] = useState([]);
  const [questionsnew, setQuestionsnew] = useState([]);
  const [tempQsn, setTempQsn] = useState([]);
  const [jobSkills, setJobSkills] = useState([]);
  const [level, setLevel] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");

  async function getQuestionsdata(cid, status) {
    await core
      .API(core.API_METHODS.GET, core.JOB_API.GET_JOB_QUESTIONS + jobId, 1, {})
      .then((json) => {
        if (json.data) {
          setQuestions(json.data);
        } else {
        }
      });
  }

  async function getJobSkills() {
    await core
      .API(core.API_METHODS.GET, core.JOB_API.GET_JOB_SKILLS + jobId, 1, {})
      .then((json) => {
        if (json.data) {
          setJobSkills(json.data);
          // get_question_by_skills(json.data[0]);
          setSelectedSkill(json?.data[0]);
          setLevel("EASY");
        } else {
        }
      });
  }

  const [loading, setLoading] = useState(false);

  async function get_question_by_skills(skill, level) {
    setTempQsn([]);
    setQuestionsnew([]);
    setLoading(true);
    await core
      .API(core.API_METHODS.POST, core.JOB_API.GET_QUESTIONS_BY_SKILLS, 1, {
        jobId: jobId,
        skill: skill,
        level: level,
      })
      .then((json) => {
        if (json.data) {
          setTempQsn(json?.data?.content);
          setQuestionsnew(json.data.content);
          setLoading(false);
        } else {
          setTempQsn([]);
          setQuestionsnew([]);
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    getQuestionsdata();
    getJobSkills();
  }, []);

  useEffect(() => {
    get_question_by_skills(selectedSkill, level);
  }, [level, selectedSkill]);

  const levels = [
    { name: "Easy", id: "EASY" },
    { name: "Medium", id: "MEDIUM" },
    { name: "Hard", id: "HARD" },
  ];

  const [criteria, setCriteria] = useState(0);

  const getLevel = (item) => {
    if (item?.level?.includes("HARD")) {
      return "#FF475D";
    } else if (item?.level?.includes("EASY")) {
      return "#00AC84";
    } else return "#FF9500";
  };

  const filterQusn_by_level = (level) => {
    let temp = [];
    if (level === "") {
      setQuestionsnew(tempQsn);
    } else {
      tempQsn.map((item) => {
        if (item?.level?.toLowerCase() === level?.toLowerCase()) {
          temp.push(item);
        }
      });
      setQuestionsnew(temp);
    }
  };

  useEffect(() => {
    filterQusn_by_level(level);
  }, [level]);

  return (
    <Modal
      heading={"Interview Q/A"}
      modalDismiss={modalDismiss}
      className={"md:max-w-[75%] scrollbar-hide"}
      noBtns={true}
    >
      <div className="flex flex-wrap gap-2">
        {jobSkills?.map((item, index) => (
          <button
            key={"criteria" + index}
            className={`border border-gray-300 py-2 px-4 rounded-lg text-gray-800 ${
              criteria === index ? "bg-gray-300" : "bg-white"
            }`}
            onClick={() => {
              setCriteria(index);
              setSelectedSkill(item);
            }}
          >
            {item}
          </button>
        ))}
      </div>
      <div className="flex flex-wrap gap-2.5">
        {levels?.map((lvl) => (
          <button
            key={lvl.id}
            className={` border border-gray-300 py-2 px-4 rounded-lg text-gray-800 ${
              level == lvl.id ? "bg-gray-300" : "bg-white"
            }`}
            onClick={() => {
              if (level === lvl.id) {
                setLevel("");
                setQuestionsnew(tempQsn);
              } else {
                setLevel(lvl.id);
                filterQusn_by_level(lvl.id);
              }
            }}
          >
            {lvl.name}
          </button>
        ))}
      </div>
      {questionsnew?.length > 0 ? (
        <>
          {questionsnew?.map((item, index) => (
            <>
              <div
                className="w-full border-gray-300 border-b last:border-0 flex justify-between gap-8"
                key={index}
              >
                <span className="max-w-[5%] text-gray-800 text-base not-italic font-semibold">{`${
                  index + 1
                }.`}</span>
                <div className="grow">
                  <span className="text-gray-800 text-base not-italic font-normal">
                    {item?.question}
                  </span>
                  <div className="w-full flex flex-wrap gap-2 my-2">
                    <span className="py-1 px-1.5">
                      <span
                        className="inline-block w-2 h-2 rounded-lg mr-2  "
                        style={{
                          backgroundColor: getLevel(item),
                          textTransform: "lowercase",
                        }}
                      />
                      {item?.level}
                    </span>
                    {item?.evaluation_criteria?.map((data, index) => (
                      <span
                        className="border border-gray-300 rounded-lg py-1 px-1.5"
                        key={index}
                      >
                        {data}
                      </span>
                    ))}
                    {/* {item?.topic_tag?.map((data, idx) => (
                  <span key={idx}
                    className="border border-gray-300 rounded-lg py-1 px-1.5"
                  >
                    {data}
                  </span>
                ))} */}
                  </div>
                  <div className="w-full">
                    {item?.sample_answer && (
                      <>
                        <h3 className="w-full sample-answer-tag my-3">
                          Sample answer
                        </h3>
                        <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                          <div className="w-full flex flex-row justify-between bg-green-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                            <span>
                              {BestAnswerIcon}
                              <span>Best answer</span>
                            </span>
                            <span>
                              Score
                              <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                                7-10
                              </span>
                            </span>
                          </div>
                          <div className="w-full p-4 text-sm text-gray-800">
                            <span>
                              {item?.sample_answer?.sample_answer_high}{" "}
                            </span>
                            <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                              <h4 className="w-full mb-2">
                                Score explanation :-
                              </h4>
                              {item?.sample_answer?.high_answer_reason}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        <div className="w-full flex flex-row justify-between bg-orange-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span>
                            {AverageAnswerIcon}
                            <span>Average answer</span>
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              4-6
                            </span>
                          </span>
                        </div>
                        <div className="w-full p-4 text-sm text-gray-800">
                          <span>{item?.sample_answer?.sample_answer_mid} </span>
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.mid_answer_reason}
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.sample_answer && (
                      <div className="w-full relative rounded-lg bg-gray-200 mb-5">
                        <div className="w-full flex flex-row justify-between bg-red-100 px-2 rounded-t-lg py-1.5 text-black text-base font-medium not-italic">
                          <span>
                            {BadAnswerIcon}
                            <span>Bad answer</span>
                          </span>
                          <span>
                            Score
                            <span className="bg-white shadow px-2 py-1 ml-2 rounded-lg">
                              0-3
                            </span>
                          </span>
                        </div>
                        <div className="w-full p-4 text-sm text-gray-800">
                          <span>{item?.sample_answer?.sample_answer_low} </span>
                          <div className="w-full bg-white rounded-lg mt-2 p-2 flow-root italic">
                            <h4 className="w-full mb-2">
                              Score explanation :-
                            </h4>
                            {item?.sample_answer?.low_answer_reason}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div
              className="col-xs-1 no-padding right-side-icon"
              style={{
                width: "5%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {index === showAns ? (
                <span
                  onClick={(e) => setShowAns(null)}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  onClick={(e) => setShowAns(index)}
                  style={{ cursor: "pointer" }}
                >
                  
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 9L12 14.4844L17.5 9"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
            </div> */}
              </div>
            </>
          ))}
        </>
      ) : (
        <div className="w-full text-center text-gray-600 text-xl font-semibold mt-[100px] mb-[100px]">
          {loading ? (
            <div className="flex items-center justify-center">
              <ScaleLoader color="#1369e9" loading={loading} />
            </div>
          ) : (
            <>No questions available for this skill</>
          )}
        </div>
      )}
    </Modal>
  );
}

export default QuestionEvaluationModal;
