import React, { useState } from 'react'
import { useEffect } from 'react'
import Menu from '../../components/all/header/Menu'
import SideBar from '../../components/all/SideBar'
import Header from '../../components/all/header/Header'

const core = require("../../lib/core")

export default function ChatRecruiter() {

    const [chat, setChat] = useState([])
    const[text ,setText] = useState("")
    const [chatDetails, setChatDetails] = useState([])
    const [fromId, setFromId] = useState("")
    const [toId, setToId] = useState("")
    const [index, setIndex] = useState("0")

    useEffect(() => {
        getRecruiterChat()
        // get_chat_details()
    }, [])

    const getRecruiterChat = async () => {
        try {
            const json = await core.API(
                core.API_METHODS.GET,
                core.CHAT_API.GET_ALL_CHATS,
                1
            );
            if (json.data) {
                setChat(json.data.chats)
                console.log("recruiter", json.data);

            } else {
            }
        } catch (err) {
            console.log(err);
        }
    };

    const get_chat_details = async (id) => {
        try {
            const json = await core.API(
                core.API_METHODS.GET,
                core.CHAT_API.GET_CHAT_DETAILS + id,
                1
            );
            if (json.data) {
                // setChat(json.data.chats)
                console.log("message", json.data);
                setChatDetails(json.data.chat)
                setFromId(json.data.chat.messages[0].from)
                setToId(json.data.chat.messages[0].to)

            } else {
            }
        } catch (err) {
            console.log(err);
        }
    };


    const send_chat = async () => {
        try {
            const json = await core.API(
                core.API_METHODS.POST,
                core.CHAT_API.POST_SEND_MESSAGE

                ,
                1, {
                fromId: "63a428449488cf4d6f212fc8",
                toId: "63ac28f60beaacd783b02187",
                text: "i am doing good",
                type: "text",
                link: ""
                // fromId: fromId,
                // toId: toId,
                // text: text,
                // type: "text",
                // link: ""
            }
            );
            if (json.data) {
                // setChat(json.data.chats)
                console.log("message", json.data);
                setText("")

            } else {
            }
        } catch (err) {
            console.log(err);
        }
    };

console.log(text)


    return (
        <div className="page recruiter " id="chats">
            <Header category="recruiter" showOnlyLogo={false} menu={<Menu />} />
            <SideBar />
            <main className="main-content col-xs-12 no-padding">
                <div className='col-xs-3  left'>
                    <h4>Message</h4>
                    {chat?.map((item, idx) => (

                        <div className='list'
                            onClick={() => {
                                setIndex(idx)
                                get_chat_details(item.id)
                            }}
                        // onClick={setIndex(idx)}
                        >
                            <img src={item.from.pic} alt="" />
                            <div className='notification'>
                                <span className='name'>{item?.from?.name} </span>
                                <span className='message'>{item?.lastMessage}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='col-xs-9 right'>
                    <div className='chat-section col-xs-12 no-padding'>
                        <div className='candidate-info'>
                            <img src={chat[index]?.from.pic} alt="" />
                            <span>{chat[index]?.from.name}</span>
                        </div>
                        {chatDetails?.messages?.map((iter) => (
                            <div className='col-xs-12'>
                                {iter.from == "63a428449488cf4d6f212fc8" ? (
                                    <>
                                        <span className='sender col-xs-12 '> {iter.text}</span>

                                    </>
                                ) : (
                                    <>
                                        <span className='reciver col-xs-12 '> {iter.text}</span>
                                    </>
                                )}
                                {/* <span>{iter.text}</span> */}
                            </div>
                        ))}


                    </div>
                    <div className='input-section col-xs-12'>
                        {/* <form action=""> */}
                        <input type="file" className='hide' id='file' />
                        <label htmlFor="file"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5226 2.4356C13.4277 1.5164 14.6553 1 15.9353 1C17.2153 1 18.4428 1.5164 19.3479 2.4356C20.253 3.3548 20.7615 4.6015 20.7615 5.90145C20.7615 7.20139 20.253 8.44809 19.3479 9.36729L11.0001 17.8453C10.7424 18.1071 10.4364 18.3148 10.0996 18.4566C9.76281 18.5983 9.40183 18.6713 9.03727 18.6714C8.30102 18.6715 7.59486 18.3746 7.07415 17.846C6.55344 17.3174 6.26083 16.6003 6.2607 15.8526C6.26056 15.1048 6.55291 14.3877 7.07343 13.8588L15.1718 5.63269L16.1984 6.67532L8.09859 14.9C7.97549 15.025 7.87783 15.1734 7.81121 15.3368C7.74458 15.5001 7.71029 15.6752 7.71029 15.852C7.71029 16.0289 7.74458 16.2039 7.81121 16.3673C7.87783 16.5306 7.97549 16.6791 8.09859 16.8041C8.2217 16.9291 8.36785 17.0283 8.5287 17.096C8.68954 17.1636 8.86194 17.1985 9.03604 17.1985C9.21014 17.1985 9.38253 17.1636 9.54338 17.096C9.70422 17.0283 9.85037 16.9291 9.97348 16.8041L18.3213 8.32614C18.6348 8.00772 18.8835 7.62971 19.0532 7.21368C19.2229 6.79765 19.3102 6.35175 19.3102 5.90145C19.3102 5.45114 19.2229 5.00524 19.0532 4.58921C18.8835 4.17318 18.6348 3.79517 18.3213 3.47675C18.0078 3.15834 17.6355 2.90576 17.2259 2.73343C16.8163 2.56111 16.3772 2.47241 15.9338 2.47241C15.4904 2.47241 15.0514 2.56111 14.6417 2.73343C14.2321 2.90576 13.8599 3.15834 13.5463 3.47675L4.95059 12.208C3.95344 13.246 3.3992 14.6412 3.40799 16.0913C3.41679 17.5414 3.9879 18.9296 4.99755 19.955C6.00721 20.9804 7.37408 21.5604 8.80192 21.5693C10.2298 21.5782 11.6036 21.0154 12.6256 20.0027L20.9734 11.5232L22 12.5659L13.6507 21.0438C12.3532 22.3142 10.6165 23.0165 8.81427 22.9997C7.01204 22.9829 5.28833 22.2482 4.014 20.9539C2.73968 19.6595 2.01658 17.9088 2.00028 16.0784C1.98399 14.2481 2.6758 12.4844 3.92687 11.1669L12.5226 2.43413V2.4356Z" fill="#001B4B" />
                        </svg></label>

                        <textarea
                            placeholder='Type here..'
                            name="" id="" cols="" rows="1"
                            value={text}
                            onChange={(event) => {
                                setText(event.target.value);
                              }}
                            
                            ></textarea>
                        <button onClick={send_chat}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_4254_1263)">
                                    <path d="M22.6078 1.39421C22.3672 1.15475 22.0655 0.986408 21.7357 0.907691C21.4059 0.828973 21.0608 0.842923 20.7384 0.948008L2.13355 7.16927C1.78851 7.27888 1.4836 7.48853 1.25734 7.77176C1.03108 8.05498 0.893626 8.39907 0.862328 8.76056C0.831031 9.12206 0.907298 9.48474 1.0815 9.80281C1.2557 10.1209 1.52001 10.3801 1.84106 10.5476L9.56024 14.3722L13.3753 22.136C13.5287 22.4399 13.7633 22.695 14.053 22.8729C14.3427 23.0508 14.6761 23.1443 15.0158 23.1431H15.143C15.5068 23.1163 15.8541 22.9803 16.1399 22.753C16.4256 22.5256 16.6366 22.2174 16.7453 21.8683L23.0402 3.26823C23.1526 2.94688 23.1716 2.60014 23.0951 2.26837C23.0185 1.93659 22.8495 1.63344 22.6078 1.39421ZM2.922 8.92856L19.1615 3.49771L10.1452 12.5364L2.922 8.92856ZM15.1048 21.1289L11.4932 13.8877L20.5095 4.84905L15.1048 21.1289Z" fill="#001B4B" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4254_1263">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        {/* </form> */}
                    </div>
                </div>
            </main>
        </div>
    )
}
