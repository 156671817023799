/**
 * Parameters -
 * id,
 * data => [{label: "", value:00}, ...] ,
 * chartWidth => outer width = width of the complete svg
 * chartHeight => outer height = height of the complete svg
 * barGap => gap between columns/bars
 * chartMargin => space for axes,
 * barColor => color of bar,
 **/

import * as d3 from "d3";
import { useEffect } from "react";

export default function SkillsColumnChart({
  id,
  data,
  chartWidth,
  chartHeight,
  barGap,
  chartMargin,
  barColor,
  rectRounded,
}) {
  useEffect(() => {
    try {
      // On every page load
      //  Data values and labels
      //  Inner dimensions of the chart => excluding the axes
      var innerWidth = chartWidth - chartMargin.left -  barGap,
        innerHeight = chartHeight - 1.5 * chartMargin.bottom;
      // Select SVG and set dimensions
      var svg = d3
        .select("svg.columnChart#" + id)
        .attr("width", chartWidth)
        .attr("height", chartHeight);

      // Enable scaling to fit data to chart dimensions
      var xScale = d3
        .scaleBand()
        .domain(data.map((d) => d.label?.name ?? d.label ?? d.name))
        .range([0, innerWidth]);
      var yScale = d3.scaleLinear().domain([0, 10]).range([0, innerHeight]);

      // Add axes to chart
      // Set axes, scaled to chart dimensions
      var xAxis = d3.axisBottom().scale(xScale);
      var yAxis = d3
        .axisLeft()
        .scale(yScale)
        .tickFormat((d) => String(10 - d))
        .ticks(5);

      // Remove previous/necessary g tags
      svg.selectAll("g").remove();
      svg
        .append("g")
        .classed("xAxis", true)
        .call(xAxis)
        .attr(
          "transform",
          "translate(" +
            chartMargin.left +
            "," +
            String(innerHeight + chartMargin.bottom / 2) +
            ")"
        )
        .selectAll("text")
        .on("mouseover", (e, i) => {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .text((a) => {
              return i;
            })
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mousemove", (e) => {
          tooltip
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mouseout", function (e) {
          d3.selectAll(".tooltip").remove();
        })
        // .attr("transform", "translate(0,-10)")
        .call(wrap, xScale.bandwidth() - 2 * barGap);

      svg
        .append("g")
        .call(yAxis)
        .classed("yAxis", true)
        .attr(
          "transform",
          "translate(" + chartMargin.left + "," + chartMargin.bottom / 2 + ")"
        );
      svg.selectAll(".xAxis .tick line").remove();
      // svg.selectAll(".xAxis text").remove();
      d3.selectAll(".tooltip").remove();

      var tooltip;

      // Add columns to chart
      // Add a group to contain the columns
      var graph = svg
        .append("g")
        .classed("graph", true)
        .attr("width", innerWidth)
        .attr("height", innerHeight);

      // Set the columns data and structure
      graph
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("y", (d) => {
          return innerHeight - yScale(d?.value) + chartMargin.bottom / 2;
        })
        .attr("height", (d) => {
          return yScale(d?.value);
        })
        .attr("width", (_, i) => {
          return (chartWidth - 2 * chartMargin.left) / data?.length - barGap;
        })
        .attr("rx", () => {
          return rectRounded ?? 0;
        })
        .attr("x", (d, i) => {
          return i * xScale.bandwidth() + chartMargin.left + barGap/2;
        })
        .attr("fill", (_, i) => {
          return Array.isArray(barColor)
            ? barColor[i % barColor?.length]
            : barColor;
        })
        .on("mouseover", (e, i, o) => {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .text(() => {
              return (
                i?.label + " : " + Math.round(i?.value * 100) / 100 + "/10"
              );
            })
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mousemove", (e) => {
          tooltip
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mouseout", function () {
          d3.selectAll(".tooltip").remove();
        });
    } catch (err) {
      console.error(err);
    }
  }, [chartWidth, data]);

  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        longer = text.text().split(/\s+/).length > 2,
        words = text.text().split(/\s+/).slice(0, 2).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        if (!(word == "and" || word == "&")) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      }
      if (longer) {
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text("...");
      }
    });
  }
  return <svg id={id} className="columnChart"></svg>;
}
