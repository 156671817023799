import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../modal/Modal";
import { JobPageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";

const initialSubject =
  "Interview Availability for {{job_name}} at {{org_name}}";

const initialTemplate = `Dear {{first_name}},
I hope this email finds you well.

We were impressed by your application for the {{job_name}} position at {{org_name}} and would like to schedule an interview with you. Could you please provide your availability over the next few days so we can arrange a convenient time for the interview?

{{availability_link}}

We look forward to discussing your qualifications and the opportunity further.

Thank you, and we hope to hear from you soon.

Best regards,
{{org_name}}`;

function AvailabilityModal({ modalDismiss, matchprofileIds }) {
  const { setContentReload } = useContext(JobPageContext);
  const [Availability_subject, setAvailabilitySubject] =
    useState(initialSubject);
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [variables, setVariables] = useState([]);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { jobId, jobPosition, OrgName } = useSelector((state) => ({
    jobId: state?.JobExpandSlice?.profile?.id,
    jobPosition: state?.JobExpandSlice?.profile?.position,
    OrgName: state?.JobExpandSlice?.profile?.company?.name,
  }));
  const { user } = useContext(core.UserContext);

  useEffect(() => {
    getVariables();
    setInitialTemplate();
  }, []);

  const setInitialTemplate = () => {
    const contentState = Modifier.insertText(
      editorStateDesc.getCurrentContent(),
      editorStateDesc.getSelection(),
      initialTemplate,
    );
    const newEditorState = EditorState.push(
      editorStateDesc,
      contentState,
      "insert-characters",
    );
    setEditorStateDesc(newEditorState);
  };

  const getVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_VARIABLE_LIST,
      1,
    );
    if (json?.data) {
      setVariables([
        "{{job_name}}",
        "{{org_name}}",
        "{{first_name}}",
        "{{availability_link}}",
      ]);
    }
  };

  const addText = (text) => {
    const currentContent = editorStateDesc.getCurrentContent();
    const currentSelection = editorStateDesc.getSelection();
    const newContent = Modifier.insertText(
      currentContent,
      currentSelection,
      text,
    );
    let newEditorState = EditorState.push(
      editorStateDesc,
      newContent,
      "insert-characters",
    );
    const newSelection = newEditorState.getSelection().merge({
      anchorOffset: currentSelection.getAnchorOffset() + text.length,
      focusOffset: currentSelection.getFocusOffset() + text.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    setEditorStateDesc(newEditorState);
  };

  const AvailabilityInvite = async () => {
    const bodyContent = convertToHTML(editorStateDesc?.getCurrentContent());
    console.log("BodyContent", bodyContent);
    const requestData = {
      subject: Availability_subject,
      body: bodyContent,
      matchProfiles: matchprofileIds,
      job: jobId,
      org_name: OrgName ? OrgName : user.organization.name,
      job_name: jobPosition,
    };

    setLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_AVAILABILITY_INVITE,
        1,
        requestData,
      );

      if (response.code == 201) {
        setLoading(false);
        toast.success("Invite Sent Successfully");
        modalDismiss();
        setMessage({
          type: "success",
          message: "Invitation sent to candidate.",
        });
      } else {
        toast.error("Error Sending Invite");
        setLoading(false);
        setMessage({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: error?.message ?? "Something went wrong. Please try again",
      });
    }
  };

  const formatText = (text) => {
    text = String(text).slice(2, -2);
    text = text.replace(/_/g, " ");
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };

  const modalElements = {
    heading: "Invite candidates for Availability",
    subheading: "Send availability invite to candidates.",
    modalDismiss: modalDismiss,
    onSave: AvailabilityInvite,
    defaultButtons: {
      primaryDisabled:
        loading ||
        !(
          Availability_subject?.length > 0 &&
          editorStateDesc.getCurrentContent().hasText()
        ),
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Invite"
      ),
    },
  };

  return (
    <Modal {...modalElements} className={"sm:!max-w-[60%] !pb-5 h-fit"}>
      <div className="mt-[2px]">
        <label
          className="text-[14px] font-normal not-italic required"
          htmlFor=""
        >
          Subject
        </label>
        <input
          className="border border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px] w-full"
          type="text"
          required={true}
          value={Availability_subject}
          onChange={(e) => setAvailabilitySubject(e?.target?.value)}
        />
      </div>

      <div className="mt-[20px]" id="description">
        <label
          className="text-right text-[14px] mt-[5px] font-normal not-italic"
          htmlFor=""
        >
          Body
        </label>
        <Editor
          editorState={editorStateDesc}
          onEditorStateChange={setEditorStateDesc}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
        <div className="w-[calc(100%-2px)] flex flex-row flex-wrap rounded-b-lg mb-2px ml-px mt-px z-50 flex h-auto bg-[#f7f7fb] py-1 px-2 h-9 items-center gap-2">
          <h4>Add variables :</h4>
          {variables?.map((item, index) => (
            <button
              key={"var" + (index + 1)}
              onClick={() => addText(item)}
              className="btn-sm btn-sec text-sm"
            >
              {formatText(item)}
            </button>
          ))}
        </div>
      </div>

      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
}

export default AvailabilityModal;
