import React, { useMemo, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
const localizer = momentLocalizer(moment);

const CustomToolbar = ({ date, onNavigate }) => {
  const goToBack = () => {
    onNavigate("PREV");
  };

  const goToNext = () => {
    onNavigate("NEXT");
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>
          &lt;
        </button>
      </span>
      <span className="rbc-toolbar-label">
        {moment(date).format("MMMM D, YYYY")}
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={goToNext}>
          &gt;
        </button>
      </span>
    </div>
  );
};

function GoogleCalenderView({
  eventsForSelectedDay,
  CandidateAvailability,
  selectedEmails,
  selectedDate,
  onDateChange,
}) {
  const [currentDate, setCurrentDate] = useState(selectedDate);

  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setCurrentDate(date);
    onDateChange(date);
  };
  const calendarStyle = {
    height: 700,
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "16px",
    fontFamily: "Arial, sans-serif",
  };
  const colorPalette = [
    "#3788d8", // Blue
    "#4caf50", // Green
    "#f44336", // Red
    "#9c27b0", // Purple
    "#00bcd4", // Cyan
    "#795548", // Brown
    "#607d8b", // Blue Grey
  ];

  const splitMultiDayEvents = (events) => {
    return events.flatMap((event) => {
      const { start, end, title, ...eventProps } = event;
      const startDate = moment(start).startOf("day");
      const endDate = moment(end).startOf("day");
      const daysDiff = endDate.diff(startDate, "days");

      if (daysDiff === 0) {
        return [event];
      }

      const fullRangeTitle = `${title} (${moment(start).format("MMM D, HH:mm")} - ${moment(end).format("MMM D, HH:mm")})`;

      return Array.from({ length: daysDiff + 1 }, (_, index) => {
        const day = moment(startDate).add(index, "days");
        return {
          ...eventProps,
          title: fullRangeTitle,
          start: index === 0 ? start : day.toDate(),
          end: index === daysDiff ? end : day.clone().endOf("day").toDate(),
          isMultiDay: true,
          originalEvent: event,
        };
      });
    });
  };

  const allEvents = useMemo(() => {
    return splitMultiDayEvents([
      ...eventsForSelectedDay,
      ...CandidateAvailability.map((event) => ({
        ...event,
        isCandidate: true,
      })),
    ]);
  }, [eventsForSelectedDay, CandidateAvailability]);

  const filteredEvents = useMemo(() => {
    return allEvents.filter(
      (event) =>
        event.isCandidate || selectedEmails.includes(event.interviewer),
    );
  }, [allEvents, selectedEmails]);

  const interviewerColors = useMemo(() => {
    const uniqueInterviewers = [
      ...new Set([
        ...selectedEmails,
        ...eventsForSelectedDay.map((event) => event.interviewer),
      ]),
    ];
    return Object.fromEntries(
      uniqueInterviewers.map((interviewer, index) => [
        interviewer,
        colorPalette[index % colorPalette.length],
      ]),
    );
  }, [eventsForSelectedDay, selectedEmails]);

  const eventStyleGetter = (event) => {
    const baseStyle = {
      borderRadius: "4px",
      opacity: 0.8,
      color: "#fff",
      border: "0",
      display: "block",
      fontWeight: "500",
    };

    if (event.isCandidate) {
      return {
        style: { ...baseStyle, backgroundColor: "orange" },
      };
    }

    const backgroundColor =
      interviewerColors[event.interviewer] || colorPalette[0];

    if (event.isMultiDay) {
      return {
        style: {
          ...baseStyle,
          backgroundColor,
          borderRadius: 0,
          paddingLeft: "4px",
          paddingRight: "4px",
        },
        className: "multi-day-event",
      };
    }

    return {
      style: { ...baseStyle, backgroundColor },
    };
  };

  const handleSelectEvent = (event) => {
    if (event.isMultiDay) {
      console.log("Multi-day event selected:", event.originalEvent);
    } else {
      console.log("Event selected:", event);
    }
  };
  const interviewersWithEvents = selectedEmails.filter((email) =>
    allEvents.some((event) => event.interviewer === email),
  );
  const views = ["day"];
  return (
    <div className="w-full">
      <div className="flex flex-wrap gap-4 mb-4">
        {interviewersWithEvents.map((email, index) => (
          <div key={email} className="flex items-center px-4 py-3 my-2">
            <div
              className="w-4 h-4 mr-2 rounded-full"
              style={{
                backgroundColor:
                  interviewerColors[email] ||
                  colorPalette[index % colorPalette.length],
              }}
            ></div>
            <span>{email.split("@")[0]}</span>
          </div>
        ))}
      </div>
      <Calendar
        localizer={localizer}
        events={filteredEvents ?? []}
        date={currentDate}
        defaultView="day"
        startAccessor="start"
        views={views}
        endAccessor="end"
        style={calendarStyle}
        onNavigate={handleDateChange}
        BackgroundWrapper="red"
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleSelectEvent}
        components={{
          toolbar: CustomToolbar,
        }}
        formats={{
          timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, "HH:mm", culture),
          eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, "HH:mm", culture) +
            " - " +
            localizer.format(end, "HH:mm", culture),
        }}
      />
    </div>
  );
}

export default GoogleCalenderView;
