import React from "react";
import { Link } from "react-router-dom";

// Structure of the job card
const JobTable = ({ job, setOpenModal, setSelectedJob, status }) => {
  return (
    <div className="w-full flex lg:flex-row lg:items-center flex-col gap-2 py-2">
      <span className="w-full lg:w-1/3 grow text-base text-gray-800 font-normal">
        {job?.position}
      </span>
      <span className="w-full lg:w-1/5 grow text-sm text-gray-700 font-normal">
        {job?.department?.map((d) => d)}
      </span>
      <span className="w-full lg:w-1/5 text-sm text-gray-700 font-normal">
        {job?.location?.map((d) => d?.name ?? d)}
      </span>
      <span className="w-full lg:w-1/5 grow text-sm text-gray-700 font-normal">
        <span className="w-auto p-1 rounded bg-gray-200 mr-2">
          {job?.jobType?.map((loc) => loc?.type ?? loc)}
        </span>
        <span className="w-auto p-1 rounded bg-gray-200">{job?.workplace}</span>
      </span>
      <span className="lg:w-auto self-end flex gap-4 items-center lg:flex-row flex-row-reverse">
        <button
          disabled={status[job?._id] == "SENT"}
          onClick={() => {
            setSelectedJob(job?._id);
            setOpenModal(true);
          }}
          className={
            status[job?._id] == "SENT"
              ? "btn-sec btn-md pointer-events-none text-base w-auto"
              : "btn-primary btn-md hover:bg-white hover:text-blue-800 text-base w-auto"
          }
        >
          {status[job?._id] == "SENT" ? "Applied" : "Apply"}
        </button>
        <Link
          className="flex gap-2 items-center flex-row-reverse"
          to={"/jobpost/" + job?._id}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 inline-block"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
          <span className="lg:hidden inline">View details</span>
        </Link>
      </span>
    </div>
  );
};
export default JobTable;
