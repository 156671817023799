import React from 'react'
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from '../../../components/all/header/Header';
import { UserContext } from "../../../lib/core";

function InviteVerifyCode() {
    const nav = useNavigate();
    const loc = useLocation();
    const [email, setEmail] = useState("id@email.com");
    const [pin1, setPin1] = useState("");
    const [pin2, setPin2] = useState("");
    const [pin3, setPin3] = useState("");
    const [pin4, setPin4] = useState("");
    const [pin5, setPin5] = useState("");
    const [pin6, setPin6] = useState("");
    const { user, setUser } = useContext(UserContext);
    const core = require("../../../lib/core");
  
    let code = "";
  
    useEffect(() => {
      if (user?.email) {
        setEmail(user.email);
      } else {
        nav("/signup", { replace: true });
      }
      var inputs = document.querySelectorAll(".code");
      inputs.forEach((e, i) => {
        e.dataset.index = i;
        e.addEventListener("keyup", handleInput);
      });
    }, []);
  
    function handleInput(e) {
      e.preventDefault();
      const input = e.target;
      let value = input.value;
      input.value = value ? value[0] : "";
  
      if (value && input.dataset.index < 5) {
        input.nextElementSibling.focus();
      }
      if (value && input.dataset.index == 5) {
        document.querySelector("button").focus();
      }
      if (e.key == "Backspace" && input.dataset.index > 0) {
        input.previousElementSibling.focus();
      }
    }
  
    async function handleVerification(e) {
      e.preventDefault();
      code = parseFloat(
        String(pin1) +
          String(pin2) +
          String(pin3) +
          String(pin4) +
          String(pin5) +
          String(pin6)
      );
      console.log({
        email: email,
        code: code,
      });
      let resp = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_VERIFY_EMAIL_OTP,
        0,
        {
          email: email,
          code: code,
        }
      );
      console.log(resp);
      console.log("one",core.data.ACCESS_TOKEN,"two",resp.data.token)
      core.setData(core.data.ACCESS_TOKEN, resp.data.token);
      core.setData(core.data.UID, resp.data.user);
      setUser({ email: email, id: resp.data.user });
      nav("/invite-set-password");
    }
    async function resendConfirmationCode() {
      try {
        //   await Auth.resendSignUp(email);
        alert("Code resent, please check on mail");
        console.log("code resent successfully");
      } catch (err) {
        console.log("error resending code: ", err);
      }
    }
    return (
      <div className="page auth-pages" id="verification">
        <Header category="" showOnlyLogo={true} menu={null} />
        <main className="main-content">
          <form
            onSubmit={handleVerification}
            className="col-xs-12 no-padding sub-container no-padding"
          >
            <h1 className="headingText">Enter Verification Code</h1>
            <h4 className="subHeadingText">
              Please enter the 6-digit code sent to you at <b>{email}</b>
            </h4>
            <div className="col-xs-12 no-padding code-section">
              <input
                type="text"
                className="code input"
                maxLength={1}
                required
                onChange={(event) => {
                  setPin1(() => event.target.value);
                }}
              />
              <input
                type="text"
                className="code input"
                maxLength={1}
                required
                onChange={(event) => {
                  setPin2(() => event.target.value);
                }}
              />
              <input
                type="text"
                className="code input"
                maxLength={1}
                required
                onChange={(event) => {
                  setPin3(() => event.target.value);
                }}
              />
              <input
                type="text"
                className="code input"
                maxLength={1}
                required
                onChange={(event) => {
                  setPin4(() => event.target.value);
                }}
              />
              <input
                type="text"
                className="code input"
                maxLength={1}
                required
                onChange={(event) => {
                  setPin5(() => event.target.value);
                }}
              />
              <input
                type="text"
                className="code input"
                maxLength={1}
                required
                onChange={(event) => {
                  setPin6(() => event.target.value);
                }}
              />
            </div>
            <button className="signInBtn" type="submit">
              <span className="label">Continue</span>
            </button>
            <p className="col-xs-12 no-padding">
              Didn't get the code
              <a onClick={resendConfirmationCode}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M16.2718 6.71476H14.1038C13.3409 6.71476 12.7246 7.35309 12.7246 8.14318C12.7246 8.93327 13.3409 9.57159 14.1038 9.57159H19.6208C20.3837 9.57159 21 8.93327 21 8.14318V2.42953C21 1.63944 20.3837 1.00112 19.6208 1.00112C18.8579 1.00112 18.2415 1.63944 18.2415 2.42953V4.71499L17.4829 3.92936C13.7116 0.0235465 7.59986 0.0235465 3.82851 3.92936C0.0571628 7.83517 0.0571628 14.1648 3.82851 18.0706C7.59986 21.9765 13.7116 21.9765 17.4829 18.0706C18.0217 17.5127 18.0217 16.6065 17.4829 16.0485C16.9442 15.4906 16.0692 15.4906 15.5305 16.0485C12.8366 18.8384 8.4705 18.8384 5.77668 16.0485C3.08286 13.2587 3.08286 8.73686 5.77668 5.94699C8.4705 3.15713 12.8366 3.15713 15.5305 5.94699L16.2718 6.71476Z"
                    fill="#043DAB"
                    stroke="white"
                    strokeWidth="0.6"
                  />
                </svg>{" "}
                Send Again
              </a>
            </p>
          </form>
        </main>
      </div>
    );
  }
  

export default InviteVerifyCode