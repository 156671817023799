// JobIntegrationCard.js
import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../lib/core";
const core = require("../../../lib/core");
import ColorModal from "./ColorModal";
import SelectJobModal from "./SelectJobModal";
import Meetingauthorization from "./PlatformAuthentication/Meetingauthorization";
const JobIntegrationCard = () => {
  const [toggle, setToggle] = useState(false);
  const [theme, setTheme] = useState("blue");
  const [selectedColor, setSelectedColor] = useState("");
  const [buttonText, setButtonText] = useState("Apply Theme");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [jobList, setJobList] = useState([]);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setToggle(!toggle);
    update_org(selectedColor, !toggle);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    update_org(color, toggle);
    setButtonText("Apply Theme");
  };

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    setToggle(user?.organization?.careers_page_integration);
    setSelectedColor(user?.organization?.theme_color);
    setTheme(user?.organization?.theme_color);
    setShowFilter(user?.organization?.showFilter ?? false);
  }, [user]);

  const update_org = async (theme, int_status) => {
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.ORGANIZATION_API.UPDATE_ORGANIZATION,
        1,
        {
          careers_page_integration: int_status,
          theme: theme,
          org_id: user.organization._id,
        },
      );
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };
  const updateOrgAttr = async (show) => {
    try {
      await core.API(
        core.API_METHODS.PUT,
        core.ORGANIZATION_API.UPDATE_ORGANIZATION,
        1,
        {
          org_id: user.organization._id,
          showFilter: show,
        },
      );
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };
  const fetchJob = async () => {
    let allJobs = [];
    let currentPage = 1;
    const pageSize = 20;

    try {
      while (currentPage < 6) {
        console.log("Current Page:", currentPage);
        let json = await core.API(
          core.API_METHODS.GET,
          core.RECRUITER_API.GET_ORG_JOB_LIST +
            `/${user?.organization?.name}?page=${currentPage}&posted=false`,
          1,
          {},
        );

        if (json && json.data && json.data.content.length > 0) {
          allJobs = [...allJobs, ...json.data.content];
          console.log("Jobs:", json.data.content.length);
          if (json.data.content.length < pageSize) break;
          currentPage++;
        } else break;
      }
      if (allJobs?.length > 0) setJobList(allJobs);
      else setJobList(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const removeJob = (index) => {
    setJobList((prevJobList) => prevJobList.filter((_, i) => i !== index));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    fetchJob();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div>
        <Meetingauthorization />
      </div>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 text-justify w-full mx-auto">
        <div className="w-full flex justify-between items-center border-b border-gray-400">
          <div className="w-3/4">
            <h2 className="text-lg font-medium text-gray-800">
              Job Integration Permission
            </h2>
            <p className="text-xs  pt-1 text-gray-700 mb-3">
              Provide the consent for Job posting integration
            </p>
          </div>
          <div
            className="flex flex-col relative w-auto text-gray-700"
            ref={dropdownRef}
          >
            {toggle && (
              <button
                onClick={() => setIsJobModalOpen(true)}
                className="btn-sec btn-md"
              >
                Manage job posts
              </button>
            )}
          </div>
        </div>
        <div className="text-gray-700 text-sm pt-8">
          <p className="text-xs font-medium text-gray-700 mb-4">
            We would like to integrate the job posts with your career page. This
            integration will allow us to post job openings directly on our
            website, making it easier for candidates to apply. Please review the
            information below and provide your consent for the integration.
          </p>
          <ul className="list-disc font-medium list-inside text-xs text-gray-700 mb-8">
            <li>Provide a logo and select a theme.</li>
            <li>Provide job details for the job post.</li>
            <li>Ensure all job posts are up-to-date and relevant.</li>
          </ul>
          {toggle && (
            <div className="mb-4">
              <label
                htmlFor="theme-selector"
                className="text-xs font-semibold text-gray-700 mb-2 block"
              >
                Select Theme
              </label>
              <button
                onClick={() => setIsThemeModalOpen(true)}
                className="w-1/4 p-2 text-xs border border-gray-400 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
              >
                {selectedColor ? selectedColor : "Select a theme"}
              </button>
            </div>
          )}

          {toggle && (
            <div className="w-full mb-4">
              <label className="text-xs font-semibold text-gray-700 mb-2 block">
                Enable job filters
              </label>
              <div className="inline-flex w-auto border border-gray-600 rounded-lg">
                <button
                  onClick={() => {
                    setShowFilter(true);
                    updateOrgAttr(true);
                  }}
                  className={`px-6 py-2 font-bold rounded-l-lg ${showFilter ? "bg-blue-800 text-white" : "bg-gray-200 text-gray-800"}`}
                >
                  Enable
                </button>
                <button
                  onClick={() => {
                    setShowFilter(false);
                    updateOrgAttr(false);
                  }}
                  className={`px-6 py-2 font-bold rounded-r-lg ${!showFilter ? "bg-gray-600 text-white" : "bg-gray-200 text-gray-800"} `}
                >
                  Disable
                </button>
              </div>
            </div>
          )}
          {/* <div className="text-gray-700 text-sm flex flex-col items-center justify-center pt-8">
            <p className="text-xs font-medium text-center text-gray-700 mb-4">
              We prioritize user privacy and ensure that your data is only used for providing our scheduling service. We do not share data with third-party AI tools or build databases using it. Your data is secure and not shared with any third parties for AI processing or any other purposes beyond the intended scheduling service.
            </p>
            <p className="text-xs font-medium text-center text-gray-700 mb-4">Our app complies with the Google API Services User Data Policy, including the Limited Use requirements. Read more <a href="https://developers.google.com/terms/api-services-user-data-policy" className="text-blue-800 font-semibold hover:text-blue-950 hover:no-underline text-decoration:no-underline">here</a>.</p>

          </div> */}

          <div className="flex items-center justify-center mt-2 pt-5">
            <div className="flex items-center">
              <a
                href="/settings/guidelines"
                className="text-blue-800 font-semibold hover:text-blue-950 hover:no-underline text-decoration:no-underline"
                target="_blank"
              >
                Click here to view the guidelines
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center pt-10 mt-5">
            <div className="flex">
              <button
                onClick={handleToggle}
                className={`px-6 py-2 font-bold rounded-l-lg ${toggle ? "bg-blue-800 text-white" : "opacity-50 bg-gray-300 text-gray-800"}`}
              >
                Allow
              </button>
              <button
                onClick={handleToggle}
                className={`px-6 py-2 font-bold rounded-r-lg ${!toggle ? "bg-red-900 text-white" : "opacity-50 bg-gray-300 text-gray-800"}`}
              >
                Disable
              </button>
            </div>
          </div>
        </div>
      </div>

      <ColorModal
        isOpen={isThemeModalOpen}
        onClose={() => setIsThemeModalOpen(false)}
        color={selectedColor}
        setColor={setSelectedColor}
        setTheme={(hexInput) => {
          setTheme(hexInput);
          update_org(hexInput, toggle);
        }}
      />
      <SelectJobModal
        isOpen={isJobModalOpen}
        onClose={() => setIsJobModalOpen(false)}
        jobList={jobList}
        removeJob={removeJob}
        reloadList={fetchJob}
      />
    </>
  );
};

export default JobIntegrationCard;
