import { useEffect, useMemo, useState } from "react";
import InitialsLogo from "../../../all/InitialsLogo";
import PercentDonut from "../../../analytics/PercentDonut";
import { useNavigate } from "react-router";
import Table from "../../../table/Table";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAssessment, fetchReport } from "../../../../redux/services";
import usePrevious from "../../../../hooks/use-previous";
import { ScaleLoader } from "react-spinners";

export default function AssessmentScores({ jobId, ...props }) {
  const dispatch = useDispatch();
  const { assessmentReport, assessment } = useSelector((state) => {
    return {
      assessmentReport: state?.JobExpandSlice?.report,
      assessment: state?.JobExpandSlice?.assessment?.assessments,
    };
  });

  const [loading, setLoading] = useState(false);

  // console.log(assessment);

  const [searchName, setSearchName] = useState("");
  const [queryParams, setQueryParams] = useState({
    page: 1,
    pageSize: 10,
    search: "",
    sortBy: "name",
    filter: "",
    orderBy: "ascend",
  });

  function dateFormat(date) {
    return (
      new Date(date).toString().split(" ")[2] +
      " " +
      new Date(date).toString().split(" ")[1] +
      ", " +
      new Date(date).toString().split(" ")[3]
    );
  }

  const getReports = async () => {
    if (!jobId) {
      return;
    }
    setLoading(true);
    try {
      await dispatch(fetchReport({ jobId, params: queryParams }));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssessmentlist = async () => {
    if (!jobId) return;
    dispatch(fetchAssessment(jobId + "?status=active"));
  };

  useEffect(() => {
    fetchAssessmentlist();
  }, [jobId]);

  useEffect(() => {
    getReports();
  }, [jobId, queryParams]);

  const columnData = [
    {
      title: "Candidate name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record) => (
        <div className="flex flex-row justify-start items-center">
          <InitialsLogo
            styleClass="img-responsive !inline-flex mr-[16px]"
            str={record?.name ?? record?.email}
            width={40}
            height={40}
            bgRounded="50%"
            fontSize={14}
          />
          <div style={{ width: "calc(100% - 60px)" }} className="personal-info">
            <p className="w-full">{record?.name ?? "Not Mentioned"}</p>
            <p className="w-full">{record?.email ?? "Not Mentioned"}</p>
          </div>
        </div>
      ),
      // filterDropdown: ({ close }) => (
      //   <div className="flex flex-col gap-4 p-3">
      //     <input
      //       placeholder="search here"
      //       type="text"
      //       className="p-2 rounded-lg border border-gray-300"
      //       value={searchName}
      //       onChange={(e) => setSearchName(e.target.value)}
      //     />
      //     <div className="flex justify-between">
      //       <button onClick={close}>Reset</button>

      //       <button
      //         className="bg-primary text-white px-4 py-2 rounded-lg"
      //         onClick={() => {
      //           setQueryParams({
      //             ...queryParams,
      //             search: searchName,
      //             sortBy: "",
      //             orderBy: "asc",
      //           });
      //           close();
      //         }}
      //       >
      //         Search
      //       </button>
      //     </div>
      //   </div>
      // ),
    },
    {
      title: "Assessment",
      dataIndex: "assessment",
      key: "assessment",
      sorter: true,
      render: (text, record) => (
        <span className="text-truncate">
          {record?.assessment || "Unable to fetch"}
        </span>
      ),
      filterMultiple: false,
      filters: assessment?.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      }),
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      sorter: true,
      render: (text, record) => (
        <span className="text-truncate">
          {Number(record?.overallScore?.totalPercentage).toFixed(2) + "%" ||
            "Unable to fetch"}
        </span>
      ),
    },
    {
      title: "Applied date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => <span>{dateFormat(record?.createdAt)}</span>,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <button
          className="inline-flex flex-row gap-[10px] items-center justify-start  pl-2.5 pr-3 py-1 bg-white rounded-lg shadow border border-gray-300 text-center text-slate-800 text-[14px] font-normal leading-[24px]"
          onClick={() =>
            window.open("/assessment-report/" + record?._id, "_blank")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.5603 5.08116C9.22841 4.90375 8.86979 4.78484 8.50016 4.72835V1.35205C9.42308 1.42146 10.3234 1.68246 11.1428 2.12047C12.1097 2.63728 12.9342 3.38457 13.5433 4.29614C14.1524 5.20772 14.5273 6.25544 14.6347 7.3465C14.6398 7.39763 14.6442 7.44878 14.6481 7.49994H11.2718C11.1987 7.02176 11.0215 6.56465 10.752 6.16124C10.4496 5.7087 10.0403 5.33772 9.5603 5.08116ZM4.29636 2.45681C5.25362 1.81719 6.35772 1.43797 7.50016 1.35205V4.72835C7.02326 4.80124 6.56595 4.97786 6.16146 5.24813C5.6172 5.61179 5.19301 6.12868 4.94251 6.73342C4.69202 7.33817 4.62648 8.00362 4.75418 8.64561C4.84909 9.12275 5.04758 9.57101 5.33327 9.95977L2.94586 12.3472C2.19877 11.4786 1.6862 10.4297 1.4616 9.30055C1.20436 8.00734 1.33638 6.6669 1.84097 5.44872C2.34555 4.23055 3.20004 3.18936 4.29636 2.45681ZM3.65297 13.0543C4.35465 13.6578 5.1758 14.1098 6.06495 14.3795C7.11408 14.6978 8.22551 14.7524 9.30078 14.5385C10.3661 14.3266 11.3633 13.8577 12.2058 13.1727L9.20106 11.084C9.02169 11.1538 8.83595 11.2081 8.64585 11.2459C8.11204 11.3521 7.56029 11.325 7.03946 11.167C6.67934 11.0578 6.34168 10.8883 6.04038 10.6669L3.65297 13.0543ZM11.0578 9.26647C11.1601 9.01956 11.2317 8.76214 11.2718 8.49994H14.6481C14.5952 9.20286 14.431 9.89542 14.1594 10.5512C13.8668 11.2576 13.4558 11.906 12.9465 12.4697L10.1438 10.5215C10.5435 10.1817 10.8569 9.7516 11.0578 9.26647Z"
              fill="#1369E9"
            />
          </svg>
          See full report
        </button>
      ),
    },
  ];

  return (
    <div className="relative ">
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center z-50">
          <div className="">
            <ScaleLoader color="#1369E9" loading={loading} />
          </div>
        </div>
      )}
      <Table
        columns={columnData}
        dataSource={assessmentReport?.data || []}
        pageSize={queryParams?.pageSize || 10}
        current={assessmentReport?.page?.currentPage || 1}
        totalPage={assessmentReport?.page?.totalCount}
        onChange={(p, f, s, extra) => {
          console.log(extra, f, s);
          if (extra.action === "filter") {
            if (f?.assessment?.length > 0) {
              setQueryParams({
                ...queryParams,
                filter: f?.assessment[0],
              });
              return;
            }
            setQueryParams({
              ...queryParams,
              filter: "",
            });
          } else if (extra.action === "sort") {
            setQueryParams({
              ...queryParams,
              orderBy: s.order,
              sortBy: s.field,
            });
          }
        }}
        onPageChange={(e, size) => {
          console.log(e);
          setQueryParams({
            ...queryParams,
            page: e,
            pageSize: size,
          });
        }}
      />
    </div>
  );
}
