import { useState } from "react";
import { toast } from "react-toastify";

const core = require("../../../lib/core");

export default function ApplyBtn({ applied, application_status, job_id, reload_function }) {
  const [jobIndex, setJobIndex] = useState([])

  async function applyJob(e, job_id) {
    try {
      e.preventDefault();
      let response = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_APPLY_JOB + String(job_id),
        1,
        {}
      );
      console.log(response);
      // reload_function();
      toast.success(response.data.message)
      setJobIndex([...jobIndex, job_id]);
      console.log(response)
    } catch (err) {
      console.log(err);
    }
  }
  return jobIndex?.includes(job_id) ? (
    <h4 style={{color:"green"}}>
      {"Applied"}
    </h4>
  ) : (
    <button
      className="apply"
      onClick={(e) => {
        applyJob(e, job_id);
      }}
    >
      <span >Apply</span>
    </button>
  );
}
