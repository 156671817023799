import React, { useEffect, useState } from "react";
import TemplateCRUD from "../TemplateListCRUD";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Modal from "../../../modal/Modal";
import InputBox from "../../../../layouts/InputBox";
import TemplateList from "./TemplateListData";
import Skeleton from "react-loading-skeleton";
const core = require("../../../../lib/core");

const Structure = () => {
  const id = useParams().id;
  const nav = useNavigate();
  const [templates, reloadList] = useOutletContext();
  const [data, setData] = useState({});
  const [name, setName] = useState("");
  const [steps, setSteps] = useState([]);
  const [originalSteps, setOriginalSteps] = useState([]); // Stroing original steps to revert changes and to cross check the changes
  const [delMessage, setDelMessage] = useState({});
  const [formMessage, setFormMessage] = useState({});
  const [stepsUpdated, setStepsUpdated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllData();
  }, [id]);

  const getAllData = async () => {
    setLoading(true);
    const json = await core.API(
      core.API_METHODS.GET,
      core.SEQUENCE_API.GET_SEQUENCE_DATA + id,
      1,
    );
    if (json?.response) {
      setData(json?.response);
      setName(json?.response?.name);
      setSteps(json?.response?.steps);
      setOriginalSteps(json?.response?.steps);
    }
    setLoading(false);
  };

  const deleteSequence = async () => {
    let json = await core.API(
      core.API_METHODS.DELETE,
      core.SEQUENCE_API.DEL_SEQUENCE_DATA + id,
      1,
    );
    if (json?.message) {
      setDelMessage({ type: "success", message: json?.message });
      setTimeout(() => {
        setDelMessage({});
        setShowModal(false);
        nav("/sequences");
        reloadList();
      }, 2500);
    }
  };

  const revertChanges = () => {
    getAllData();
    setFormMessage({});
    setStepsUpdated(false);
    setEdit(false);
  };

  const submitSequence = async () => {
    try {
      if (name?.trim()?.length <= 0) {
        setFormMessage({
          type: "error",
          message: "Sequence name cannot be empty",
        });
        return;
      }
      let tempData = steps?.map((iter) => {
        let { template, isModified, body, subject, ...rest } = iter;
        return rest;
      });
      let json = await core.API(
        core.API_METHODS.PUT,
        core.SEQUENCE_API.PUT_SEQUENCE_DATA + data?._id,
        1,
        {
          name: name,
          steps: tempData,
        },
      );
      if (json?.message) {
        setFormMessage({
          type: "success",
          message: json?.message ?? "Sequence updated successfully",
        });
        getAllData();
        setFormMessage({});
        reloadList();
        setEdit(false);
      } else if (json?.code && json?.code != 200) {
        setFormMessage({
          type: "error",
          message:
            json?.message ?? "Unable to update sequence. Please try again",
        });
      }
    } catch (err) {
      console.error(err);
      setFormMessage({
        type: "error",
        message: "Unable to edit the sequence. Please try again",
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-5 p-5 bg-white grow w-full rounded-lg">
          <div className="flex justify-between items-center">
            <Skeleton height={25} width={300} />
            <div className="flex gap-4">
              <Skeleton height={50} width={50} />
              <Skeleton height={50} width={50} />
            </div>
          </div>
          <div className="mt-5">
            <Skeleton height={25} width={200} />
          </div>
          <div className="mt-3">
            <Skeleton height={40} className="w-full" />
          </div>
          <div className="mt-3">
            <Skeleton height={100} className="w-full" />
          </div>
        </div>
      ) : (
        <div className="w-full grow relative p-4 rounded-lg bg-white flex flex-col gap-4">
          <div className="w-full flex justify-between items-start gap-4">
            {edit ? (
              <InputBox
                placeholder="Name"
                className="!h-10 py-0 placeholder-gray-600"
                value={name}
                required
                onChange={(event) => setName(event.target.value)}
              />
            ) : (
              <h1 className="text-black text-base font-medium not-italic">
                {name}
              </h1>
            )}
            {!data ||
            data == {} ||
            steps?.length == 0 ||
            data.is_default ? null : (
              <div className="flex gap-4 w-auto relative">
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Edit"}
                  onClick={() => setEdit(edit ? revertChanges() : true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={edit ? "blue" : "currentColor"}
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </button>
                <button
                  className="btn-sec btn-md"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={"Delete"}
                  onClick={() => setShowModal("DELETE")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="red"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          {edit ? (
            <TemplateCRUD
              defaultEdit={true}
              steps={steps}
              setSteps={setSteps}
              originalSteps={originalSteps}
              templates={templates}
              onSubmit={submitSequence}
              onCancel={() =>
                stepsUpdated ? setShowModal("CONFIRM") : revertChanges()
              }
              stepsUpdated={stepsUpdated}
              setStepsUpdated={setStepsUpdated}
              btnGrpClass="!relative !pb-0"
              message={formMessage}
              setMessage={setFormMessage}
            />
          ) : (
            <TemplateList data={steps} />
          )}
        </div>
      )}
      {showModal == "DELETE" ? (
        <Modal
          heading={"Delete sequence - " + name}
          subheading={"Are you sure you want to delete this email sequence."}
          defaultButtons={{
            primaryLabel: "Delete",
            primaryClass: "!bg-red-800 !border-red-900 hover:!bg-red-900",
          }}
          onSave={deleteSequence}
          modalDismiss={() => setShowModal(false)}
        >
          <h2 className="text-red-900 text-sm -mt-3 not-italic font-medium flex gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
            All running instances of this sequence will be stopped.
          </h2>
          {Object?.entries(delMessage)?.length > 0 && (
            <p
              style={
                delMessage?.type == "error"
                  ? { color: "red" }
                  : { color: "green" }
              }
              className="block text-sm font-medium grow"
            >
              {delMessage?.message}
            </p>
          )}
        </Modal>
      ) : showModal == "CONFIRM" ? (
        <Modal
          heading={"Cancel changes - " + name}
          subheading={
            "Are you sure you want to cancel changes in the sequence."
          }
          defaultButtons={{
            primaryLabel: "Revert changes",
            primaryClass:
              "!bg-white !border-red-800 !text-red-800 hover:!text-red-950 hover:!border-red-950",
            secondaryLabel: "Go back to editing",
          }}
          onSave={() => {
            revertChanges();
            setShowModal(false);
          }}
          onCancel={() => setShowModal(false)}
          modalDismiss={() => setShowModal(false)}
        >
          <h2 className="text-red-900 text-sm -mt-3 not-italic font-medium flex gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
            All updates made in the template will be reverted.
          </h2>
          {/* {Object?.entries(delMessage)?.length > 0 && (
            <p
              style={
                message?.type == "error" ? { color: "red" } : { color: "green" }
              }
              className="block text-sm font-medium grow"
            >
              {delMessage?.message}
            </p>
          )} */}
        </Modal>
      ) : null}
    </>
  );
};

export default Structure;
