import { useContext, useEffect, useState } from "react";
import { Device } from "@twilio/voice-sdk";
import PhoneInput from "react-phone-number-input";
import InitialsLogo from "../../components/all/InitialsLogo";
import { MdMicOff } from "react-icons/md";
import LockedComponent from "../../layouts/LockedComponent";
const core = require("../../lib/core");

//Types
const USER_STATE = {
  CONNECTING: "Connecting",
  READY: "Ready",
  ON_CALL: "On call",
  OFFLINE: "Offline",
};

const numberList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "0"];

const stateColor = {
  [USER_STATE.CONNECTING]: "#B7AC44",
  [USER_STATE.READY]: "#DAD870",
  [USER_STATE.ON_CALL]: "#FF5C4D",
  [USER_STATE.OFFLINE]: "#FFB52E",
};

//Helpers
const Timer = () => {
  const [timer, setTimer] = useState({ mins: 0, sec: 0 });
  const getTime = () => {
    setTimer((state) => ({
      mins: state.sec === 60 ? state.mins + 1 : state.mins,
      sec: state.sec === 60 ? 0 : state.sec + 1,
    }));
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer">
      {`${timer.mins < 9 ? "0" + timer.mins : timer.mins} : ${
        timer.sec < 9 ? "0" + timer.sec : timer.sec
      }`}
    </div>
  );
};

//Components
const Phone = ({ token, setHideCallBar, fetchNumber, chatDetail }) => {
  const [userState, setUserState] = useState(USER_STATE.OFFLINE);
  const [phoneNumber, setPhoneNumber] = useState();
  const [connection, setConnection] = useState(null);
  const [callDevice, setCallDevice] = useState(undefined);
  const [value, setValue] = useState("+915551234567");

  //Callback
  useEffect(() => {
    init();
  }, [token]);

  function validatePhoneNumber(phoneNumber) {
    // Remove all spaces from the phone number
    const cleanedPhoneNumber = phoneNumber?.replace(/\s/g, "");

    // Define a regular expression pattern for a valid phone number
    const phonePattern = /^\+\d{10,15}$/;

    // Test if the cleaned phone number matches the pattern
    return phonePattern?.test(cleanedPhoneNumber);
  }

  useEffect(() => {
    console.log(chatDetail);
    // if(fetchNumber){
    //   setPhoneNumber(fetchNumber)

    //   // const isValid = validatePhoneNumber(fetchNumber);
    // }

    if (chatDetail?.source === "Greenhouse") {
      setPhoneNumber(chatDetail?.phone[0]);
    } else {
      setPhoneNumber(chatDetail?.phone[0]);
    }

    console.log(chatDetail);

    // if (isValid) {
    //   console.log('Phone number is  valid:', fetchNumber);
    //   // handleCall()
    // } else {
    //   console.log('Phone number is not valid:', fetchNumber);
    // }
  }, [fetchNumber, chatDetail]);
  // Example usage:

  const [twilioNo, setTwilioNo] = useState();

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
        {},
      );
      if (json) {
        setTwilioNo(json?.data?.user?.phone2);
      }
    } catch (err) {
      console.error(err);
    }
  }

  //Helpers
  const init = async () => {
    if (token) {
      try {
        console.log("Token connected successfully!!", token);
        const device = new Device(token, {
          logLevel: 1,
          edge: "ashburn",
        });
        device.register();
        setCallDevice(device);
        device.addListener("connect", (device) => {
          console.log("Connect event listener added .....", device);
          return device;
        });
        device.on("registered", (e) => {
          console.log("Agent registered  ------>", e);
          setUserState(USER_STATE.READY);
        });
        device.on("connect", (connection) => {
          console.log("Call connect  ------>", connection);
          setConnection(connection);
          setUserState(USER_STATE.ON_CALL);
        });

        device.on("incoming", (call) => {
          console.log("incoming call ------> ", call);
        });

        device.on("disconnect", (e) => {
          console.log("Disconnect event ---->", e);
          setUserState(USER_STATE.READY);
          setConnection(null);
        });

        return () => {
          device.destroy();
          console.log("Device destroyed");
          setCallDevice(undefined);
          setUserState(USER_STATE.OFFLINE);
        };
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  const acceptCall = async () => {
    console.log("Call connect");
    callDevice.acceptCall();
  };

  const [callvar, setCallVar] = useState();

  const handleCall = async () => {
    console.log("called");
    const params = { To: phoneNumber };
    callDevice?.emit("connect");
    callDevice
      ?.connect({
        params: params,
        rtcConstraints: {
          audio: true,
        },
      })
      .then((call) => {
        setCallVar(call);
        call.on("accept", () => {
          setConnection(connection);
          setUserState(USER_STATE.ON_CALL);
          console.log("call accepted");
        });
        call.on("disconnect", (e) => {
          console.log("The call has been disconnected.", e);
          endCallApi(e);
          setUserState(USER_STATE.READY);
          setConnection(null);
        });
        call.on("reject", () => {
          console.log("The call was rejected.");
        });
      });
  };

  const endCallApi = async (data) => {
    try {
      console.log("End call api called");
      let json = await core.API(
        core.API_METHODS.POST,
        core.VOICE_API.POST_END_CALL,
        1,
        {
          data: data,
        },
      );
      if (json) {
        console.log("Call ended successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const endCall = () => {
    callDevice.destroy();
    setHideCallBar(false);
  };

  const [mutemic, setMutemic] = useState(false);

  const mute = (term) => {
    if (term === true) {
      callvar.mute(true);
      setMutemic(true);
    } else {
      callvar.mute(false);
      setMutemic(false);
    }
  };

  const { user, resources } = useContext(core.UserContext);

  return (
    <div className="  flex items-center justify-center">
      <button
        onClick={(e) => setHideCallBar(false)}
        className="absolute right-[10px] top-[10px]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.83594 5.83594L14.1693 14.1693"
            stroke="white"
            strokeOpacity="0.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.83463 14.1693L14.168 5.83594"
            stroke="white"
            strokeOpacity="0.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      {/* {true ? ( */}
      {userState === USER_STATE.ON_CALL ? (
        // {true ? (
        <div className="bg-[#18181B]  w-[256px] p-[8px] rounded-[12px]">
          <div className="bg-[#18181B]  rounded-lg shadow-lg flex flex-col">
            <div className="flex justify-between">
              <div className="flex">
                <InitialsLogo str={chatDetail?.first_name} />
                <div className="flex flex-col ml-[8px]">
                  <span className="text-[#FFF] font-medium text-[16px] leading-[18px] not-italic">
                    {chatDetail?.first_name}
                  </span>
                  {/* <span className="text-textColor2 font-normal text-[14px] leading-[18px] not-italic">calling...</span> */}
                  <span className="text-textColor2 font-normal text-[14px] leading-[18px] not-italic">
                    {<Timer />}
                  </span>
                </div>
              </div>
              <div>
                <button
                  onClick={() => endCall()}
                  className="bg-[#FF475D] w-[36px] h-[36px] flex justify-center items-center rounded-[8px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_4139_66016)">
                      <path
                        d="M19.7337 16.927C20.3292 17.1805 21.0216 17.0448 21.4821 16.5843L23.0309 15.0356C23.62 14.4464 23.6235 13.4946 23.0385 12.9096C18.066 7.93714 9.97502 7.96594 4.96701 12.974C4.37784 13.5631 4.3746 14.5149 4.9596 15.0999L6.49738 16.6382C6.95457 17.0954 7.64589 17.2262 8.24321 16.9685L10.317 16.0734C10.8535 15.8419 11.2221 15.336 11.2769 14.7554L11.3428 14.055C11.42 13.2374 12.1078 12.6085 12.9285 12.6056L15.0793 12.5989C15.9001 12.596 16.5836 13.2187 16.655 14.0358L16.7159 14.7356C16.7666 15.3159 17.1311 15.8199 17.666 16.0476L19.7337 16.927Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4139_66016">
                        <rect
                          width="19.11"
                          height="18.9745"
                          fill="white"
                          transform="translate(27.4648 13.9531) rotate(135)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex justify-between bg-callbuttonbg rounded-[8px] px-[6px] py-[2px] mt-[14px]">
              {!mutemic ? (
                <button
                  onClick={(e) => mute(true)}
                  className=" h-[40px] w-[40px] flex justify-center items-center border-r border-r-[#2F2F2F]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="18"
                    viewBox="0 0 12 18"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6 0.664062C4.15905 0.664062 2.66667 2.15645 2.66667 3.9974V8.99739C2.66667 10.8383 4.15905 12.3307 6 12.3307C7.84095 12.3307 9.33333 10.8383 9.33333 8.9974V3.9974C9.33333 2.15645 7.84095 0.664062 6 0.664062ZM1 7.53906C1.34518 7.53906 1.625 7.81888 1.625 8.16406V8.9974C1.625 11.4136 3.58375 13.3724 6 13.3724C8.41625 13.3724 10.375 11.4136 10.375 8.9974V8.16406C10.375 7.81888 10.6548 7.53906 11 7.53906C11.3452 7.53906 11.625 7.81888 11.625 8.16406V8.9974C11.625 11.8927 9.43747 14.2772 6.625 14.5881V16.0807H8.5C8.84518 16.0807 9.125 16.3606 9.125 16.7057C9.125 17.0509 8.84518 17.3307 8.5 17.3307H3.5C3.15482 17.3307 2.875 17.0509 2.875 16.7057C2.875 16.3606 3.15482 16.0807 3.5 16.0807H5.375V14.5881C2.56253 14.2772 0.375 11.8927 0.375 8.9974V8.16406C0.375 7.81888 0.654822 7.53906 1 7.53906Z"
                      fill="white"
                    />
                  </svg>
                </button>
              ) : (
                <button
                  onClick={(e) => mute(false)}
                  className=" h-[40px] w-[40px] flex justify-center items-center border-r border-r-[#2F2F2F]"
                >
                  <MdMicOff />
                </button>
              )}
              <button className=" h-[40px] w-[40px] flex justify-center items-center border-r border-r-[#2F2F2F]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4139_66030)">
                    <path
                      d="M6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4Z"
                      fill="white"
                    />
                    <path
                      d="M12 4C12 5.10457 11.1046 6 10 6C8.89543 6 8 5.10457 8 4C8 2.89543 8.89543 2 10 2C11.1046 2 12 2.89543 12 4Z"
                      fill="white"
                    />
                    <path
                      d="M18 4C18 5.10457 17.1046 6 16 6C14.8954 6 14 5.10457 14 4C14 2.89543 14.8954 2 16 2C17.1046 2 18 2.89543 18 4Z"
                      fill="white"
                    />
                    <path
                      d="M6 10C6 11.1046 5.10457 12 4 12C2.89543 12 2 11.1046 2 10C2 8.89543 2.89543 8 4 8C5.10457 8 6 8.89543 6 10Z"
                      fill="white"
                    />
                    <path
                      d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
                      fill="white"
                    />
                    <path
                      d="M18 10C18 11.1046 17.1046 12 16 12C14.8954 12 14 11.1046 14 10C14 8.89543 14.8954 8 16 8C17.1046 8 18 8.89543 18 10Z"
                      fill="white"
                    />
                    <path
                      d="M6 16C6 17.1046 5.10457 18 4 18C2.89543 18 2 17.1046 2 16C2 14.8954 2.89543 14 4 14C5.10457 14 6 14.8954 6 16Z"
                      fill="white"
                    />
                    <path
                      d="M12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16Z"
                      fill="white"
                    />
                    <path
                      d="M18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4139_66030">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button className=" h-[40px] w-[40px] flex justify-center items-center border-r border-r-[#2F2F2F]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.1019 4.94441V15.0553C12.1019 15.4167 12.1019 15.5974 12.1267 15.7477C12.2286 16.3647 12.6287 16.8474 13.1409 16.9701C13.2657 17 13.4157 17 13.7156 17C14.0156 17 14.1662 17 14.2909 16.9701C14.8032 16.8474 15.2035 16.3647 15.3054 15.7477C15.33 15.5987 15.33 15.4199 15.33 15.0648V4.93489C15.33 4.57983 15.33 4.40065 15.3054 4.25171C15.2035 3.63465 14.8029 3.15263 14.2907 3.02989C14.1659 3 14.0159 3 13.7159 3C13.4159 3 13.2657 3 13.1409 3.02989C12.6287 3.15263 12.2286 3.63465 12.1267 4.25171C12.1019 4.40197 12.1019 4.58302 12.1019 4.94441Z"
                    fill="white"
                  />
                  <path
                    d="M5 4.94441V15.0553C5 15.4167 5 15.5974 5.02481 15.7477C5.1267 16.3647 5.52683 16.8474 6.03905 16.9701C6.16379 17 6.31378 17 6.61378 17C6.91376 17 7.06434 17 7.18907 16.9701C7.7013 16.8474 8.10165 16.3647 8.20354 15.7477C8.22813 15.5987 8.22813 15.4199 8.22813 15.0648V4.93489C8.22813 4.57983 8.22813 4.40065 8.20354 4.25171C8.10165 3.63465 7.70101 3.15263 7.18878 3.02989C7.06405 3 6.91406 3 6.61406 3C6.31407 3 6.16379 3 6.03905 3.02989C5.52683 3.15263 5.1267 3.63465 5.02481 4.25171C5 4.40197 5 4.58302 5 4.94441Z"
                    fill="white"
                  />
                </svg>
              </button>

              <button className=" h-[40px] w-[40px] flex justify-center items-center border-r border-r-[#2F2F2F]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 4.5C6.068 4.5 4.5 6.068 4.5 8C4.5 9.932 6.068 11.5 8 11.5C9.932 11.5 11.5 9.932 11.5 8C11.5 6.068 9.9355 4.5 8 4.5Z"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="7.5"
                    stroke="white"
                  />
                </svg>
              </button>

              <button className=" h-[40px] w-[40px] flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14ZM19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-[#18181B]   rounded-lg shadow-lg flex flex-col w-[256px] p-[8px] ">
          <div className="bg-[#18181B]  text-white py-2  rounded-t-lg">
            <h2 className="text-[16px] text-[#FFF] font-medium not-italic ">
              Phone Call Interface
            </h2>

            <span className="text-[14px] text-textColor2 font-normal not-italic ">
              Enter mobile number and call candidate
            </span>
          </div>
          <div className=" flex flex-col ">
            <div className=" flex justify-center">
              <PhoneInput
                placeholder="Enter phone number"
                className="border rounded-lg px-4 py-2  w-[240px]  bg-[#fff] mb-4 mt-4 focus:outline-none focus:ring focus:border-blue-300"
                value={phoneNumber}
                country="US"
                onChange={setPhoneNumber}
              />
            </div>
            <div className="grid grid-cols-3 bg-callbuttonbg rounded-[8px] ">
              {numberList.map((number) => (
                <button
                  key={number}
                  className="bg-callbuttonbg hover:bg-gray-400 text-gray-700 py-2 px-4 border-r border-r-[#2F2F2F]"
                  onClick={() => {
                    if (phoneNumber === undefined) {
                      setPhoneNumber(number);
                    } else {
                      setPhoneNumber(phoneNumber + number);
                    }
                  }}
                >
                  {number}
                </button>
              ))}
              <button
                className="bg-callbuttonbg hover:bg-gray-400 text-gray-700 py-2 px-4 "
                onClick={() => {
                  setPhoneNumber(
                    phoneNumber.substring(0, phoneNumber.length - 1),
                  );
                }}
              >
                {"<<"}
              </button>
            </div>
            <div className="flex justify-center items-center mt-4 py-2">
              {userState === USER_STATE.ON_CALL ? (
                <button
                  onClick={() => handleCall()}
                  className="bg-red-500 hover:bg-red-600 text-white py-2 px-6 rounded-lg"
                >
                  End Call
                </button>
              ) : (
                <LockedComponent
                  isLocked={
                    resources?.filter((item) => item === "Candidate_create")
                      ?.length > 0
                      ? false
                      : true
                  }
                >
                  <button
                    onClick={() => handleCall()}
                    className="bg-[#00AC85] hover:bg-green-600 text-white flex items-center justify-center py-2 px-6 rounded-lg w-[256px] text-[16px]"
                  >
                    <svg
                      className="mr-[6px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="28"
                      viewBox="0 0 27 28"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_4109_69031)">
                        <path
                          d="M19.2337 16.927C19.8292 17.1805 20.5216 17.0448 20.9821 16.5843L22.5309 15.0356C23.12 14.4464 23.1235 13.4946 22.5385 12.9096C17.566 7.93714 9.47502 7.96594 4.46701 12.974C3.87784 13.5631 3.8746 14.5149 4.4596 15.0999L5.99738 16.6382C6.45457 17.0954 7.14589 17.2262 7.74321 16.9685L9.81695 16.0734C10.3535 15.8419 10.7221 15.336 10.7769 14.7554L10.8428 14.055C10.92 13.2374 11.6078 12.6085 12.4285 12.6056L14.5793 12.5989C15.4001 12.596 16.0836 13.2187 16.155 14.0358L16.2159 14.7356C16.2666 15.3159 16.6311 15.8199 17.166 16.0476L19.2337 16.927Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4109_69031">
                          <rect
                            width="19.11"
                            height="18.9745"
                            fill="white"
                            transform="translate(26.9648 13.9531) rotate(135)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    Call
                  </button>
                </LockedComponent>
              )}
            </div>
          </div>
        </div>
      )}
    </div>

    // <div className="phone">
    //   <div className="user-state">
    //     <i style={{ color: stateColor[userState] }} className="fas fa-stop"></i>{" "}
    //     {`Status - > ${userState}`}
    //   </div>
    //   <input
    //     className="number-input"
    //     value={phoneNumber}
    //     onChange={(event) => setPhoneNumber(event.target.value)}
    //   />
    //   {userState === USER_STATE.ON_CALL ? (
    //     <Timer />
    //   ) : (
    //     <div className="gird">
    //       {numberList.map((value) => (
    //         <div
    //           key={value}
    //           className="number"
    //           onClick={() => {
    //             if (value === "<<") {
    //               setPhoneNumber(
    //                 phoneNumber.substring(0, phoneNumber.length - 1)
    //               );
    //             } else {
    //               setPhoneNumber(phoneNumber + value);
    //             }
    //           }}
    //         >
    //           {value}
    //         </div>
    //       ))}
    //     </div>
    //   )}
    //   <div
    //     className={`${
    //       userState === USER_STATE.ON_CALL ? "in-call" : "call"
    //     } button`}
    //     onClick={() => handleCall()}
    //   >
    //     {userState === USER_STATE.ON_CALL ? (
    //       <i className="material-icons">call_end</i>
    //     ) : (
    //       <i className="material-icons">call</i>
    //     )}
    //   </div>
    // </div>
  );
};

export default Phone;
