import { createAsyncThunk } from '@reduxjs/toolkit';
const core = require("../../lib/core");
// Async thunk action creators

export const createChimeMeetingService = createAsyncThunk(
    'chimeMeeting/createChimeMeeting',
    async (payload, thunkAPI) => {
        await core.API(
            core.API_METHODS.POST,
            core.CHIME_API.CREATE_MEETING,
            1,
            payload
        );
        // Async logic for leaving a Chime meeting
    }
);

export const getMyChimeMeetingListService = createAsyncThunk(
    'chimeMeeting/getChimeMeetingList',
    async (payload, thunkAPI) => {
        await core.API(
            core.API_METHODS.GET,
            core.CHIME_API.GET_MY_CHIME_LIST,
            1,
            payload
        );
        // Async logic for leaving a Chime meeting
    }
);

export const joinChimeMeetingService = createAsyncThunk(
    'chimeMeeting/joinChimeMeeting',
    async (payload, thunkAPI) => {
        return await core.API(
            core.API_METHODS.POST,
            core.CHIME_API.JOIN_MEETING,
            1,
            payload
        );
    }
);

export const leaveChimeMeetingService = createAsyncThunk(
    'chimeMeeting/leaveChimeMeeting',
    async (payload, thunkAPI) => {
        await core.API(
            core.API_METHODS.POST,
            core.CHIME_API.JOIN_MEETING,
            1,
            payload
        );
        // Async logic for leaving a Chime meeting
    }
);