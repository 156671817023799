import React from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from '../../../components/all/header/Header';
import $ from "jquery";
import { toast } from "react-toastify";
import { useEffect } from 'react';

const core = require("../../../lib/core");


function InvitePassword() {
  const nav = useNavigate();
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [warn, setWarning] = useState("Atleast 8 characters");
  const [loginText, setLoginText] = useState("Continue");
  const loc = useLocation();

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      if (pwd.length >= 8 && pwd == confirmPwd) {
        await core
          .API(core.API_METHODS.PUT, core.USER_API.PUT_UPDATE_USER, 1, {
            password: pwd,
          })
          .then(() => handleSubmitAccountType());
      } else {
        if (pwd.length < 8) {
          setWarning('Too short, try again')
          $('.warning').css('color', 'red')
        }
        if (pwd != confirmPwd) {
          setWarning('Passwords do not match')
          $('.warning').css('color', 'red')
        }
      }
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  }

  console.log(loc)
  const [user, setUser] = useState()
  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
        {}
      );
      if (json?.data) {
        console.log(json?.data)
        setUser(json.data.user.email)
      }
    }
    catch {

    }
  }
console.log(user)


  useEffect(() => {
    getUser()
  }, [])

  async function handleSubmitAccountType() {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_CREATE_PROFILE,
        1,
        {
          name: user,
          isUser:true

        }
      );
      if (json.data) {
        nav("/assessment-dashboard");
        window.location.reload()
      }
    }
    catch {

    }
  }




  return (
    <div className="page auth-pages" id="password">
      <Header category="" showOnlyLogo={true} menu={null} />
      <main className="main-content">
        <form className="col-xs-12 no-padding sub-container" onSubmit={handleSubmit}>
          <h1 className="headingText">Create your password</h1>
          <h4 className="subHeadingText">
            Create a new, strong password (at least 8 characters) that you don’t
            use for other websites.
          </h4>
          <div className="col-xs-12 no-padding input-wrapper">
            <input
              name="new-password"
              type={showPwd ? "text" : "password"}
              className="password input"
              minLength={8}
              required
              value={pwd}
              onChange={(e) => {
                setPwd(() => e.target.value);
              }}
            /></div>
          <div className="col-xs-12 no-padding input-wrapper">
            <input
              name="confirm-password"
              type={showPwd ? "text" : "password"}
              className="password confirm input"
              minLength={8}
              required
              value={confirmPwd}
              onChange={(e) => {
                setConfirmPwd(() => e.target.value);
              }}
            />
          </div>
          <span className="warning col-xs-12 no-padding">{warn}</span>
          <div className="show-hide col-xs-12 no-padding">
            <input
              type="checkbox"
              id="show-pwd"
              value={showPwd}
              onChange={() => {
                setShowPwd(!showPwd);
              }}
            />
            <label htmlFor="show-pwd" className="show-pwd">
              Show password
            </label>
          </div>
          <button className="signInBtn" type="submit">
            <span className="label">{loginText}</span>
          </button>
        </form>
      </main>
    </div>
  );
}

export default InvitePassword