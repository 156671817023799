import React from "react";
import { useState } from "react";
import PercentDonut from "../../../analytics/PercentDonut";
import InitialsLogo from "../../../all/InitialsLogo";

const AssessmentReport = ({ data }) => {
  const [dataIdx, setDataIdx] = useState(-1);
  const colors = ["#1369E9", "#80BB01", "#FF7324"];
  const chartSizes = [220, 170, 120];

  function scoreLevel(score) {
    switch (Math.round(score / 10)) {
      case 0: {
        return "No";
      }
      case 1: {
        return "Extremely poor";
      }
      case 2: {
        return "Very poor";
      }
      case 3: {
        return "Poor";
      }
      case 4: {
        return "Below average";
      }
      case 5: {
        return "Average";
      }
      case 6: {
        return "Above average";
      }
      case 7: {
        return "Good";
      }
      case 8: {
        return "Very Good";
      }
      case 9: {
        return "Excellent";
      }
      case 10: {
        return "Perfect";
      }
    }
  }
  function sectionLabel(label) {
    switch (label) {
      case "mcq": {
        return "Multiple choice questions";
      }
      default: {
        return String(label).charAt(0).toUpperCase() + String(label).slice(1);
      }
    }
  }
  function dateFormat(date) {
    return (
      new Date(date).toString().split(" ")[2] +
      " " +
      new Date(date).toString().split(" ")[1] +
      ", " +
      new Date(date).toString().split(" ")[3]
    );
  }
  return data?.map((iter, idx) => (
    <>
      <div
        key={idx}
        className="w-full py-4 last:pb-0 first:pt-0 flex flex-row justify-between items-center"
      >
        <div className="w-1/4 flex flex-row justify-start items-center">
          <InitialsLogo
            styleClass="img-responsive !inline-flex mr-[16px]"
            str={iter?.job}
            width={40}
            height={40}
            bgRounded="50%"
            fontSize={14}
          />
          <div
            style={{ width: "calc(100% - 60px)" }}
            className="text-sm font-semibold text-gray-800"
          >
            <p className="w-full">{iter?.job ?? "Job"}</p>
          </div>
        </div>
        <div className="w-auto data-item score flex flex-row justify-start items-center gap-2">
          <PercentDonut
            id={"assessment_score_" + idx}
            chartSize={44}
            radius={11}
            width={4}
            cornerRadius={3}
            percentData={+iter?.overallScore?.split("%")[0] ?? 40}
            fontSize={0}
          />
          <span>{iter?.overallScore} </span>
          <span>{scoreLevel(+iter?.overallScore?.split("%")[0])}</span>
        </div>
        <div className="w-auto">
          <span>{dateFormat(iter?.interviewDate)}</span>
        </div>
        <div className="w-1/4 flex flex-row justify-between">
          <button
            className="inline-flex flex-row gap-[10px] items-center justify-start  pl-2.5 pr-3 py-1 bg-white rounded-lg shadow border border-gray-300 text-center text-slate-800 text-[14px] font-normal leading-[24px]"
            onClick={() =>
              window.open("/assessment-report/" + iter?.interviewId, "_blank")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5603 5.08116C9.22841 4.90375 8.86979 4.78484 8.50016 4.72835V1.35205C9.42308 1.42146 10.3234 1.68246 11.1428 2.12047C12.1097 2.63728 12.9342 3.38457 13.5433 4.29614C14.1524 5.20772 14.5273 6.25544 14.6347 7.3465C14.6398 7.39763 14.6442 7.44878 14.6481 7.49994H11.2718C11.1987 7.02176 11.0215 6.56465 10.752 6.16124C10.4496 5.7087 10.0403 5.33772 9.5603 5.08116ZM4.29636 2.45681C5.25362 1.81719 6.35772 1.43797 7.50016 1.35205V4.72835C7.02326 4.80124 6.56595 4.97786 6.16146 5.24813C5.6172 5.61179 5.19301 6.12868 4.94251 6.73342C4.69202 7.33817 4.62648 8.00362 4.75418 8.64561C4.84909 9.12275 5.04758 9.57101 5.33327 9.95977L2.94586 12.3472C2.19877 11.4786 1.6862 10.4297 1.4616 9.30055C1.20436 8.00734 1.33638 6.6669 1.84097 5.44872C2.34555 4.23055 3.20004 3.18936 4.29636 2.45681ZM3.65297 13.0543C4.35465 13.6578 5.1758 14.1098 6.06495 14.3795C7.11408 14.6978 8.22551 14.7524 9.30078 14.5385C10.3661 14.3266 11.3633 13.8577 12.2058 13.1727L9.20106 11.084C9.02169 11.1538 8.83595 11.2081 8.64585 11.2459C8.11204 11.3521 7.56029 11.325 7.03946 11.167C6.67934 11.0578 6.34168 10.8883 6.04038 10.6669L3.65297 13.0543ZM11.0578 9.26647C11.1601 9.01956 11.2317 8.76214 11.2718 8.49994H14.6481C14.5952 9.20286 14.431 9.89542 14.1594 10.5512C13.8668 11.2576 13.4558 11.906 12.9465 12.4697L10.1438 10.5215C10.5435 10.1817 10.8569 9.7516 11.0578 9.26647Z"
                fill="#1369E9"
              />
            </svg>
            See full report
          </button>
          <button
            className={idx == dataIdx ? "collapse-button" : "expand-button"}
            onClick={() => {
              if (idx != dataIdx) {
                setDataIdx(idx);
              } else {
                setDataIdx(-1);
              }
            }}
          >
            {dataIdx == idx ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 9L12 14.4844L17.5 9"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      {idx == dataIdx && (
        <div className="w-full bg-[#f2f4f880] py-5 px-6  flex flex-row flex-wrap justify-around">
          <div className="min-w-[240px] flex flex-col flex-wrap gap-4 justify-self-center">
            <h2 className="text-md text-gray-800">
              {scoreLevel(+iter?.overallScore?.split("%")[0])} total score
            </h2>
            <div className="w-[200px] h-[200px] flex items-center justify-center my-5 mr-10">
              <span className="text-md text-gray-800">
                {iter?.overallScore}
              </span>
              {iter?.score &&
                Object.keys(iter?.score).map((key, idx) => (
                  <PercentDonut
                    styleClass="absolute"
                    key={idx}
                    id={"assessment_score_section_" + idx}
                    chartSize={chartSizes[idx]}
                    radius={chartSizes[idx] / 2}
                    width={15}
                    cornerRadius={10}
                    percentData={+iter.score[key].split("%")[0]}
                    fontSize={0}
                    customColor={colors[idx]}
                    customTooltipText={
                      sectionLabel(key) + " score = " + iter.score[key]
                    }
                  />
                ))}
            </div>
          </div>
          <div className="w-full md:w-7/12 score-table">
            <div className="heading-row w-full ">
              <div className="w-1/2 ">Section</div>
              <div className="w-1/3 ">Attempted Questions</div>
              <div className="w-1/6 ">Score</div>
            </div>
            {Object.keys(iter?.score).map((key, idx) => (
              <div className="data-row w-full " key={idx}>
                <div className="w-1/2 ">
                  <span
                    className="legend-color"
                    style={{
                      background: colors[idx],
                    }}
                  />
                  {sectionLabel(key)}
                </div>
                <div className="w-1/3 ">{iter?.attemptedQuestions[key]}</div>
                <div className="w-1/6 ">{iter?.score[key]}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  ));
};

export default AssessmentReport;
