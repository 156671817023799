import { useSelector } from "react-redux";
import { useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { useEffect } from "react";
export default function ResumeTab() {
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.resume_link,
  );

  const { coverLetter, screeningAnswers } = useSelector((state) => {
    return {
      coverLetter:
        state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_latter,
      screeningAnswers:
        state?.jobCandidateMatch?.profile?.screeningQsnAns
          ?.screening_questions_answers,
    };
  });

  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (profile) {
      setDocs([{ uri: profile }]);
    }
  }, [profile]);

  return (
    <div className="w-full p-2 ">
      <div className="flex items-center justify-center top-2 ">
        <DocViewer
          style={{
            width: 1000,
            borderColor: "lightgray",
            borderRadius: 10,
            borderWidth: 1,
            minHeight: 900,
          }}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true,
            },
            noRenderer: {
              overrideComponent: () => (
                <div className="w-full h-full flex justify-center items-center">
                  Failed to load
                </div>
              ),
            },
            pdfZoom: {
              defaultZoom: 0.8, // 1 as default,
            },
          }}
          documents={docs}
          pluginRenderers={DocViewerRenderers}
        />
      </div>

      {/* <iframe className="w-full h-full" title="PDF Viewer" src="https://sproutsai-staging.s3.amazonaws.com/03b96a99-22cf-4abb-9c98-442a843d73aa/file-sample_100kB.doc"/> */}

      <div>
        {" "}
        {screeningAnswers?.length > 0 ? (
          <div className="flex flex-col mt-2 p-2 bg-white rounded-lg">
            <h2 className="text-base font-medium text-[#16182f] mb-2">
              Screening Question Responses
            </h2>
            {screeningAnswers.map((question, index) => (
              <div key={index} className="w-full p-4  rounded-md">
                <div className="">
                  <h2 className="text-base font-medium text-[#16182f]">
                    {`${index + 1}: ${question?.question?.title}`}
                  </h2>
                </div>
                <div>
                  <span className="text-gray-800 font-normal text-sm">
                    {`Answer: ${question?.answer ? question?.answer : ""}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
