import { useEffect, useState } from "react";
import Header from "../../components/all/header/Header";
import Menu from "../../components/all/header/Menu";
import SideBar from "../../components/all/SideBar";
import Profile from "../../components/candidate/profile/CandidateProfile";
import ResumeAnalytics from "../../components/candidate/profile/ResumeAnalytics";
import $ from "jquery";
import Preferences from "../../components/candidate/profile/Preferences";
import { useLocation } from "react-router";
import HeaderNew from "../../components/all/header/HeaderNew";
import CandidateSidebar from "../../components/all/CandidateSidebar";
const core = require("../../lib/core");

export default function ProfilePage() {
  const [profile, setProfile] = useState({});
  const [activeTab, setActiveTab] = useState(<Profile profile={profile} />);
  const location = useLocation()

  useEffect(() => {
    getProfile();
    toggleSection(location?.search?.split("?").at(-1));
  }, []);

  async function getProfile() {
    try {
      let response = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_PROFILE_DETAILS,
        1,
        {}
      );
      console.log(response.data);
      setProfile(response?.data);
    } catch (err) {
      console.log(err);
    }
  }
  function toggleSection(tab) {
    // console.log(tab);
    switch (tab) {
      case "edit-profile": {
        setActiveTab(<Profile profile={profile} />);
        window.history.replaceState("", "", (location.pathname.split("/").at(-1)) + "?edit-profile")
        $("#profile-page #analytics").removeClass("active");
        $("#profile-page #preferences").removeClass("active");
        $("#profile-page #edit").addClass("active");
        break;
      }
      case "analytics": {
        setActiveTab(<ResumeAnalytics profile={profile} />);
        window.history.replaceState("", "", (location.pathname.split("/").at(-1)) + "?analytics")
        $("#profile-page #preferences").removeClass("active");
        $("#profile-page #edit").removeClass("active");
        $("#profile-page #analytics").addClass("active");
        break;
      }
      case "preferences": {
        setActiveTab(<Preferences profile={profile} />);
        window.history.replaceState("", "", (location.pathname.split("/").at(-1)) + "?preferences")
        $("#profile-page #edit").removeClass("active");
        $("#profile-page #analytics").removeClass("active");
        $("#profile-page #preferences").addClass("active");

        break;
      }
    }
  }




  // return (
  //   <div className="page candidate-pages" id="profile-page">
  //     <Header category="candidate" showOnlyLogo={false} menu={<Menu />} />
  //     <SideBar />
  //     <main className="main-content col-xs-12 no-padding">
  //       <div className="col-xs-12 no-padding heading-title">
  //           <h2 className="profile-page-heading">My profile</h2>
  //         <div className="button-section">
  //           <button
  //             className="button active"
  //             onClick={() => toggleSection("edit-profile")}
  //             id="edit"
  //           >
  //             Edit profile
  //           </button>

  //           {/* <button
  //             className="button"
  //             onClick={() => toggleSection("analytics")}
  //             id="analytics"
  //           >
  //             Resume analytics
  //           </button> */}
  //           <button
  //             className="button"
  //             onClick={() => toggleSection("preferences")}
  //             id="preferences"
  //           >
  //             Job preferences
  //           </button>
  //         </div>
  //       </div>
  //       <div className="col-xs-12 no-padding tab">{activeTab}</div>
  //     </main>
  //   </div>
  // );


  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="job_details_page">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <CandidateSidebar active="" open={false} />
        <div className="w-full">
          <HeaderNew />
          <ProfileContent
            toggleSection={toggleSection}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  )
}
const ProfileContent = ({
  toggleSection,
  activeTab,
  ...props
}) => {
  return (
    <main
      className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate"
    >

      <div className="page " id="profile-page">
        <main className="main-content col-xs-12 no-padding">
          <div className="col-xs-12 no-padding heading-title">
            <h2 className="profile-page-heading">My profile</h2>
            <div className="button-section">
              <button
                className="button active"
                onClick={() => toggleSection("edit-profile")}
                id="edit"
              >
                Edit profile
              </button>
              <button
                className="button"
                onClick={() => toggleSection("preferences")}
                id="preferences"
              >
                Job preferences
              </button>
            </div>
          </div>
          <div className="col-xs-12 no-padding tab">{activeTab}</div>
        </main>
      </div>
    </main>
  );
};