export const minAlphaNumericVal = (val) => {
  if (String(val).trim().length <= 0) throw "Empty value not allowed, please enter a value";
  if (/[a-zA-Z0-9]/.test(val)) return true;
  throw "Invalid value, should contain atleast one alphanumeric value";
};

export const valBetweenRange = (val, [min, max], inclusive = true) => {
  if (val < min) throw "Input value is less than acceptable range";
  if (val > max) throw "Input value is more than acceptable range";
  if (!inclusive && (val == min || val == max))
    throw "Input value is not in acceptable range";
  return true;
};

export const minMaxValid = (minVal, maxVal, range = [0, 100]) => {
  valBetweenRange(minVal, range);
  valBetweenRange(maxVal, range);
  if (+minVal > 0 && +maxVal > 0 && +maxVal < +minVal)
    throw "The maximum value should be more than the minimum value";
  return true;
};

// export const
