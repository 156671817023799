import React, { useContext, useState } from "react";
import InputBox from "../../../layouts/InputBox";
import Dropdown from "../../../layouts/Dropdown";
import { useNavigate, useOutletContext } from "react-router-dom";
import TemplateCRUD from "./TemplateListCRUD";

const core = require("../../../lib/core");

const CreateSequence = () => {
  const { templates, reloadList } = useOutletContext();
  const { user } = useContext(core.UserContext);
  const nav = useNavigate();
  const [name, setName] = useState("");
  const [steps, setSteps] = useState([]);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const addStep = (template, idx) => {
    let temp = [...steps];
    if (steps.length == 0) {
      temp.splice(0, 0, {
        action: "SEND_TEMPLATE",
        template_id: template?._id,
        template: template,
      });
    } else {
      temp.splice(
        idx + 1,
        0,
        { wait_time: 0, action: "WAIT" },
        {
          action: "SEND_FOLLOW_UP_EMAIL",
          template_id: template?._id,
          template: template,
        },
      );
    }
    setSteps(temp);
  };

  const createSequence = async () => {
    try {
      if (name?.trim()?.length <= 0) {
        setMessage({ type: "error", message: "Please enter sequence name" });
        return;
      }
      setLoading(true);
      let tempData = steps?.map((iter) => {
        let { template, isModified, body, subject, ...rest } = iter;
        return rest;
      });
      let json = await core.API(
        core.API_METHODS.POST,
        core.SEQUENCE_API.POST_CREATE_SEQUENCE,
        1,
        {
          name: name.trim(),
          steps: tempData,
          created_by: user?.id,
        },
      );
      if (json?.message) {
        setMessage({
          type: "success",
          message: "Sequence created successfully",
        });
        setTimeout(() => {
          setLoading(false);
          setMessage({});
          reloadList();
          nav("/sequences/" + json?.data?._id);
        }, 1500);
      }
    } catch (error) {
      console.error("Error creating sequence", error);
      setLoading(false);
    }
  };

  return (
    <div
      className={`w-full grow relative p-4 rounded-lg bg-white flex flex-col gap-4 ${loading ? "pointer-events-none" : ""}`}
    >
      <h1 className="text-black text-base font-medium not-italic">
        Create new sequence
      </h1>
      <div className="w-full border-b border-gray-500 pb-4">
        <label className="text-sm font-normal not-italic required" htmlFor="">
          Sequence name
        </label>
        <InputBox
          size={"small"}
          value={name}
          onChange={(e) => setName(e?.target?.value)}
        />
      </div>
      <div
        className={`w-full  flex flex-col gap-4 ${steps?.length == 0 ? "min-h-[500px]" : ""}`}
      >
        {steps?.length > 0 ? (
          <TemplateCRUD
            defaultEdit={true}
            steps={steps}
            setSteps={setSteps}
            templates={templates}
            onSubmit={createSequence}
            onCancel={() => nav(-1)}
            btnGrpClass="!relative !pb-0"
            message={message}
            setMessage={setMessage}
          />
        ) : (
          <Dropdown
            listAlign="left"
            buttonLabel={
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span>Add step</span>
              </>
            }
            customList={true}
            dropdownItems={
              templates?.length > 0
                ? templates?.map((item, key) => (
                    <li
                      key={key}
                      className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 `}
                      onClick={() => addStep(item, 0)}
                    >
                      {item?.template_name}
                    </li>
                  ))
                : [
                    <li
                      onClick={() => nav("/templates/new")}
                      className="w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 "
                    >
                      Add new template
                    </li>,
                  ]
            }
          />
        )}
      </div>
    </div>
  );
};

export default CreateSequence;
