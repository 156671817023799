import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { fetchJobListByCompany } from "../../../redux/services/JobBoardService";
import Dropdown from "../../../layouts/Dropdown";

const FilterCategories = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => {
    if (state?.JobBoardSlice) return state?.JobBoardSlice?.jobs?.filter;
    else return [];
  });
  const [toggleAccordion, setToggleAccordion] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});
  const queryCount =
    [...searchParams].filter(
      ([key, value]) =>
        (key !== "search" || key !== "location") && value.trim() !== ""
    )?.length ?? 0;

  useEffect(() => {
    // Add a click event listener to the document
    const handleResize = (e) => {
      let screenWidth = window.screen.width;
      // screen width >= 1024 --> Laptop and desktop view
      if (screenWidth >= 1024) {
        setToggleAccordion(true);
        return;
      }
      setToggleAccordion(false);

      // screen width < 1024 --> Mobile and tablet view
    };
    // Attach the event listener when the component mounts or resizes
    window.addEventListener("resize", handleResize);
    handleResize();
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleFilter = (category, value) => {
    const currentOptions = searchParams
      .getAll(category)[0]
      ?.split(",")
      .filter((str) => str !== "");
    console.log(category, currentOptions);
    if (currentOptions?.includes(value)) {
      if (currentOptions?.length === 1) {
        searchParams.delete(category);
      } else {
        const updatedOptions = currentOptions.filter((val) => val !== value);
        searchParams.set(category, updatedOptions);
      }
    } else {
      if (currentOptions?.length < 1) {
        searchParams.set(category, value);
      } else {
        searchParams.set(category, [currentOptions, value]);
      }
    }
    setSearchParams(searchParams);
    getJobs();
    return;
  };

  const getJobs = () => {
    const name = "natera";
    const query = window.location.search ?? "";
    dispatch(fetchJobListByCompany({ companyName: name, query: query }));
  };

  return (
    <div
      className="h-auto w-full bg-gray-100 border-y border-gray-500 self-end"
      id="filters"
    >
      <div className="lg:hidden container before:hidden w-full h-12 px-5 flex justify-start items-center gap-2  divide-x divide-gray-500">
        <button
          onClick={() => setToggleAccordion((prev) => !prev)}
          className="h-min group inline-flex items-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#5F6989"
            className={`w-5 h-5 group-hover:stroke-black`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
            />
          </svg>
          {queryCount > 0 && (
            <span className="px-2 bg-gray-300 text-black text-sm rounded">
              {queryCount}
            </span>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="black"
            className={`w-6 h-5 group-hover:visible invisible ${
              toggleAccordion ? "-scale-y-[1]" : ""
            }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
        {queryCount > 0 && (
          <button
            onClick={getJobs}
            className="h-min group inline-flex items-center gap-2 group pl-4"
          >
            <Link
              to={"/careers"}
              className="inline-block text-gray-700 font-medium text-base group-hover:text-black"
            >
              Clear all
            </Link>
          </button>
        )}
      </div>

      {toggleAccordion && typeof filter != "function" && (
        <div
          id="filter-accordion"
          className="w-full h-auto bg-gray-100 lg:h-12 lg:px-0 flex justify-start items-center gap-2"
        >
          <div className="container lg:px-5 px-0">
            <div
              className={`grid lg:gap-8 gap-2 py-1 lg:py-0 grid-cols-1 lg:grid-cols-4`}
            >
              {filter?.map((item) => (
                <Dropdown
                  removeDefaultBtnStyle={true}
                  buttonStyle="w-full lg:w-auto btn-lg text-base text-black font-normal flex  gap-2 items-center hover:bg-gray-200 lg:justify-start justify-between"
                  buttonLabel={
                    <>
                      <span>
                        {String(item?.type)?.charAt(0)?.toUpperCase() +
                          String(item?.type)
                            ?.slice(1)
                            .replace(/([A-Z])/g, " $1")
                            .trim()}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="black"
                        className={`w-6 h-5`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </>
                  }
                  dropdownItems={item?.values?.map((lst) => ({
                    label: (
                      <>
                        <input
                          name={lst}
                          type="checkbox"
                          className="!mt-0"
                          checked={searchParams.get(item?.type)?.includes(lst)}
                          // onClick={() => toggleFilter(item?.type, lst)}
                        />
                        <label className="!mb-0 !font-normal" htmlFor={lst}>
                          {lst}
                        </label>
                      </>
                    ),
                    style: "px-4",
                    activeCondition: searchParams
                      .get(item?.type)
                      ?.includes(lst),
                    action: () => toggleFilter(item?.type, lst),
                  }))}
                  listAlign="right"
                  removeDefaultDropdownStyle={true}
                  dropdownStyle="relative lg:z-[400] lg:absolute lg:left-0 lg:top-12 w-full lg:w-48 lg:max-h-[400px] h-auto list-style-none bg-white lg:shadow-menuOptionShadow lg:rounded-lg"
                />
              ))}
              {queryCount > 0 && (
                <button
                  onClick={getJobs}
                  className="hidden w-auto h-12 group lg:!inline-flex items-center gap-2 group pl-4"
                >
                  <Link
                    to={"/careers"}
                    className="inline-block text-gray-700 font-medium text-base group-hover:text-black"
                  >
                    Clear all
                  </Link>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterCategories;
