import * as d3 from "d3";
import { useEffect } from "react";
import { ellipsis, ellipsisVariable } from "./TextWrap";

export default function InlineGanttChart({
  id,
  chartWidth,
  colors,
  chartMargin,
  experienceRange,
  totalExperience,
  dataList,
  barSize = 25,
  yearWise = false,
  fontSize = barSize / 2,
}) {
  const barGap = 2;
  const fullExperience = totalExperience ?? 6;
  const data = dataList.sort((a, b) => a.type - b.type);
  var svg, tooltip, innerHeight, innerWidth, colorScale;
  var types = [...data.map((d) => d.type)].reduce(
    (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
    {}
  );
  var categories = Object.keys(types);
  var range = experienceRange ?? [0, fullExperience];

  useEffect(() => {
    try {
      if (chartWidth != undefined) {
        innerWidth = chartWidth - chartMargin.left - 20;
        innerHeight = categories?.length * (barSize + barGap);

        svg = d3
          .select("svg.timelineChart#" + id)
          .attr("width", chartWidth)
          .attr("height", innerHeight + chartMargin.bottom);
        colorScale = d3
          .scaleLinear()
          .domain([0, categories.length])
          .range(colors ?? ["#0096ff", "#FF1E6F"])
          .interpolate(d3.interpolateHcl);
        svg.selectAll("g").remove();
        svg.selectAll("rect").remove();

        var xScale = axis();
        leftAxis();
        graph(xScale);
      }
    } catch (err) {
      console.error(err);
    }
  });

  function graph(xScale) {
    var categories = Object.keys(types);
    let seq = 0;
    var graph = svg
      .append("g")
      .classed("chart", true)
      .attr("width", innerWidth)
      .attr("height", innerHeight);

    for (let iter = 0; iter < categories.length; iter++) {
      var outerGraph = graph
        .append("g")
        .classed(categories[iter].replace(" ", "_").toLowerCase(), true)
        .attr("width", innerWidth)
        .attr("height", barSize);

      outerGraph
        .selectAll("rect")
        .data(data.filter((d) => d.type == categories[iter]))
        .enter()
        .append("rect")
        .classed("bar cursor-pointer", true)
        .attr("id", (d) => String(d.name).replace(" ", "_").toLowerCase())
        .attr("x", (d) => xScale(d.minvalue) + chartMargin.left)
        .attr("y", (_, i) => iter * (barSize + barGap) + barGap)
        .attr("rx", 4)
        .attr("ry", 4)
        .attr("width", (d) =>
          xScale((d.maxvalue ?? d3.max(range)) - d.minvalue + d3.min(range))
        )
        .attr("height", barSize - 2)
        .attr("fill", () => d3.rgb(colorScale(seq++)))
        .on("mouseover", (e, it) => {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
          tooltip.append("b").text(it?.type + " - ");
          tooltip.append("span").text(it?.name);
          tooltip
            .append("p")
            .text(() =>
              yearWise
                ? `${it?.minvalue} - ${it?.maxvalue}`
                : `${it?.minvalue} - ${it?.maxvalue ?? fullExperience} yrs`
            );
        })
        .on("mousemove", (e) => {
          tooltip
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
        })
        .on("mouseout", function () {
          d3.selectAll(".tooltip").remove();
        });
    }

    outerGraph
      .selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .text((d) => d.name)
      .attr("id", (d) => String(d.name).replace(" ", "_").toLowerCase())
      .classed("cursor-pointer", true)
      .attr("x", (d) => {
        return (
          (xScale(d.maxvalue) - xScale(d.minvalue)) / 2 +
          xScale(d.minvalue) +
          chartMargin.left
        );
      })
      .attr(
        "y",
        (d, i) =>
          categories.indexOf(d.type) * (barSize + barGap) +
          (barSize + fontSize) / 2 -
          1
      )
      .attr("font-size", fontSize)
      .attr("style", "font-weight: 400")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .on("mouseover", (e, it) => {
        tooltip = d3
          .select("body")
          .append("div")
          .classed("tooltip", true)
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
        tooltip.append("b").text(it?.type + " - ");
        tooltip.append("span").text(it?.name);
        tooltip
          .append("p")
          .text(() =>
            yearWise
              ? `${it?.minvalue} - ${it?.maxvalue}`
              : `${it?.minvalue} - ${it?.maxvalue ?? fullExperience} yrs`
          );
      })
      .on("mousemove", (e) => {
        tooltip
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
      })
      .on("mouseout", function () {
        d3.selectAll(".tooltip").remove();
      });

    var widthArr = [];
    data.forEach((element) => {
      widthArr.push(
        xScale(
          (element?.maxvalue ?? d3.max(range)) -
            element?.minvalue +
            d3.min(range)
        )
      );
    });

    graph.selectAll("text").call(ellipsisVariable, widthArr);
  }

  function axis() {
    var xScale = d3
      .scaleTime()
      .domain(range)
      .range([0, innerWidth + 4]);
    // Add axes to chart
    // Set axes, scaled to chart dimensions
    var xAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickFormat((d) => d3.format(".4")(d));

    // Remove previous/necessary g tags
    svg
      .append("g")
      .classed("xAxis", true)
      .call(xAxis)
      .attr(
        "transform",
        "translate(" + chartMargin?.left + "," + innerHeight + ")"
      );
    return xScale;
  }

  function leftAxis() {
    var leftScale = d3
      .scaleBand()
      .domain([...new Set(data.map((d) => d.type))])
      .range([0, innerHeight]);
    var leftAxis = d3.axisLeft().scale(leftScale);

    svg
      .append("g")
      .call(leftAxis)
      .classed("leftAxis", true)
      .attr("width", chartMargin.left)
      .attr("height", innerHeight)
      .attr("transform", "translate(" + chartMargin?.left + "," + 0 + ")")
      .selectAll(".tick text")
      .attr("font-size", 14)
      .attr("text-anchor", "end")
      .attr("text-height", barSize / 2 + 4)
      .attr("class", "cursor-pointer text-medium")
      .on("mouseover", (e, it) => {
        if (e.target.innerHTML.endsWith("...")) {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .attr("class", "cursor-pointer tooltip")
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
          tooltip.append("p").text(it);
        }
      })
      .on("mousemove", (e) => {
        if (e.target.innerHTML.endsWith("...")) {
          tooltip
            .attr("class", "cursor-pointer tooltip")
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
        }
      })
      .on("mouseout", function () {
        d3.selectAll(".tooltip").remove();
      })
      .call(ellipsis, chartMargin?.left, 1);
  }

  return <svg id={id} className="timelineChart"></svg>;
}
