import React, { useEffect, useState } from "react";
import $ from "jquery";
import InputBox from "../../../layouts/InputBox";

export default function AutoCompletedInput({
  list,
  idx,
  setvalue,
  initialArray,
  classText,
}) {
  const [hide, setHide] = useState([]);

  $(document).on("click", function (e) {
    if ($(e.target).is("#" + idx + ".auto-complete-input") === false) {
      setHide(true);
    } else {
      setHide(false);
    }
  });
  const [tempValue, setTempValue] = useState();
  const [searchList, setSearchList] = useState();

  const search = (e) => {
    const filteredItems = list?.filter((item) =>
      item?.toLowerCase()?.includes(e?.toLowerCase()),
    );
    setSearchList(filteredItems);
  };

  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    setSearchList([searchValue]);
  }, [searchValue]);

  // useEffect(() => {
  //     handleFilterSubmit();
  // }, [initialArray]);

  return (
    <div className={idx} id={idx}>
      <InputBox
        placeholder="Search..."
        className={` auto-complete-input ${classText}`}
        size={"small"}
        onChange={(e) => {
          search(e.target.value);
          setSearchValue(e.target.value);
          setHide(false);
        }}
      />
      {searchList?.length > 0 && (
        <ul
          className={
            hide
              ? "auto-complete-list hide"
              : "auto-complete-list mt-10 bg-white  no-list-style py-[20px]  rounded-lg  shadow-menuOptionShadow z-10"
          }
        >
          {searchList
            ?.filter((element) => !initialArray?.includes(element))
            .map((item, i) => (
              <li
                key={i}
                className="match-value px-[20px] py-[10px]"
                onClick={() => {
                  $("#" + idx + " .auto-complete-input").val("");
                  // console.log($("#" + idx + " .auto-complete-input"))
                  setvalue([...initialArray, item]);
                  setTempValue();
                  setHide(true);
                }}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
