import React, { useContext, useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import TextAreaBox from "../../../layouts/TextAreaBox";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import InputBox from "../../../layouts/InputBox";
import { Link } from "react-router-dom";
const core = require("../../../lib/core");

function BulkActionModal({
  changeModal,
  isSourceCandidate,
  selectedSequence,
  updateBulkStatus,
  pipeline,
  bulkupdateCandidatesFavourite,
  Push_candidate_greenhouse,
  modalDismiss,
  cancel,
  sendText,
  candidates,
  checkedItems,
}) {
  const [pipelineData, setPipelineData] = useState(pipeline);
  const [sequenceData, setSequenceData] = useState([]);
  const [message, setMessage] = useState([]);

  useEffect(() => {
    setPipelineData(pipeline);
  }, [pipeline]);

  const [selectPipeline, setSelectPipeline] = useState("");
  const [reason, setReason] = useState("");
  const [formview, setFormview] = useState(false);
  const [addFavourite, setAddFavourite] = useState("");
  const [addAts, setAddAts] = useState("false");

  const [loading, setLoading] = useState(false);

  const SavePipeline = (send_email) => {
    setLoading(true);
    if (
      addFavourite === true ||
      addFavourite === false ||
      addFavourite === "rematch" ||
      addFavourite === "reparse"
    ) {
      if (addFavourite === "rematch") {
        reparseMatchProfile();
      } else if (addFavourite === "reparse") {
        reparseMatchProfile(true);
      } else {
        bulkupdateCandidatesFavourite(addFavourite);
        setTimeout(() => {
          modalDismiss();
        }, 3000);
      }
      setLoading(false);
    } else if (addAts === true) {
      Push_candidate_greenhouse();
      setLoading(false);
      setTimeout(() => {
        modalDismiss();
      }, 3000);
    } else {
      if (selectPipeline === "Rejected") {
        console.log("rejectedReason", rejectedReason);
        if (formview === true) {
          updateBulkStatus(
            false,
            selectPipeline,
            reason,
            rejectionTemplate,
            rejection_reason_id,
            onBehalfId,
            scheduleDate !== "now"
              ? new Date(date + "T" + time + ":00").toISOString()
              : null,
            send_email,
            subject,
          );
          setLoading(false);
          setTimeout(() => {
            modalDismiss();
          }, 3000);
        } else {
          setFormview(true);
          setLoading(false);
        }
      } else {
        updateBulkStatus(false, selectPipeline, reason);
        setLoading(false);
        setTimeout(() => {
          modalDismiss();
        }, 3000);
      }
    }
  };

  const [rejectedReason, setRejectedReason] = useState([]);
  const [rejectedTemplates, setRejectedTemplates] = useState([]);
  const [rejectionTemplate, setRejectionTemplate] = useState("");
  const [rejection_reason_id, setRejection_reason_id] = useState("");
  const [onBehalfId, setOnBehalfId] = useState("");

  const { source, jobId, jobName } = useSelector((state) => {
    return {
      source: state?.JobExpandSlice?.profile?.source,
      jobId: state?.JobExpandSlice?.profile?.id,
      jobName: state?.JobExpandSlice?.profile?.position,
    };
  });

  const reparseMatchProfile = async (onlyResume) => {
    setLoading(true);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_RE_MATCH_CANDIDATES,
        1,
        {
          jobid: jobId,
          matchprofileIds: checkedItems,
          onlyResume: onlyResume,
        },
      );
      if (json?.data) {
        setLoading(false);
        modalDismiss();
      } else {
        setMessage({
          type: "error",
          message:
            json?.error?.message ??
            "Unable to re-parse the profile. Please try again",
        });
      }
    } catch (err) {}
  };

  // const SavePipeline = (send_email) => {
  //   updateRejectedStatus(
  //     candidateId,
  //     "Rejected",
  //     reason,
  //     rejectionTemplate,
  //     rejection_reason_id,
  //     onBehalfId,
  //     scheduleDate !== "now"
  //       ? new Date(date + "T" + time + ":00").toISOString()
  //       : null,
  //     send_email,
  //   );
  // };

  const [jobData, setJobData] = useState([]);
  async function getHiringTeam() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_HIRING_TEAM + `/${jobId}`,
        1,
      );
      if (json?.data) {
        setJobData(json?.data);
      } else {
      }
    } catch (err) {}
  }

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );

  useEffect(() => {
    if (jobData?.length > 0) {
      setOnBehalfId(jobData?.id);
    }
  }, [jobData]);

  useEffect(() => {
    getHiringTeam();
  }, []);

  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [scheduleDate, setScheduleDate] = useState("now");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [to, setTo] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setInputValue(""); // Clear input after selecting an option
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };
  useEffect(() => {
    setEditorValue(convertToHTML(editorStateDesc?.getCurrentContent()));
  }, [editorStateDesc]);

  async function getRejectedReason() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_REJECTION_REASON,
        1,
      );
      if (json?.data) {
        setRejectedReason(json?.data);
        setRejection_reason_id(json?.data[0]?.id);
      } else {
      }
    } catch (err) {}
  }

  async function getRejectedTemplate() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_REJECTION_TEMPLATE,
        1,
      );
      if (json?.data) {
        setRejectedTemplates(json?.data);
        setRejectionTemplate(json?.data[0]?.id);
        setSubject(json?.data[0]?.subject);
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(
                new String(
                  json?.data[0]?.html_body?.split("&lt;").join("<"),
                ).toString(),
              ),
            ),
          ),
        );
      } else {
      }
    } catch (err) {}
  }

  const getAllSequence = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.SEQUENCE_API.GET_ALL_SEQUENCES,
        1,
      );
      if (json?.response) {
        console.log("json?.data", json?.response);
        setSequenceData(json?.response);
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    getRejectedTemplate();
    getRejectedReason();
    getAllSequence();
  }, []);

  return (
    <Modal
      modalDismiss={cancel}
      heading={"Edit candidate(s)"}
      onSave={SavePipeline}
      className={"md:max-w-[60%]"}
      noBtns={source === "Greenhouse" && formview ? true : false}
      defaultButtons={{
        primaryDisabled: !selectPipeline.length > 1,
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : formview ? (
          "Reject"
        ) : (
          "Save"
        ),
      }}
    >
      {!formview ? (
        <>
          {source === "Greenhouse" && (
            <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
              <h5 className="w-full text-[#252E4B] text-[16px] font-medium not-italic mb-2">
                Push to ATS
              </h5>
              <ul className="flex gap-4">
                <li
                  className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addAts === true ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                  onClick={() => {
                    if (addAts === true) {
                      setAddAts(false);
                    } else {
                      setAddAts(true);
                    }
                    setSelectPipeline("");
                    setAddFavourite("");
                  }}
                >
                  Greenhouse
                </li>
              </ul>
              <div></div>
            </div>
          )}
          <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
            <h5 className="w-full text-[#252E4B] text-[16px] font-medium not-italic mb-2">
              Candidate Actions
            </h5>
            <ul className="flex gap-4">
              <li
                className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === "reparse" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite !== "reparse") {
                    setAddFavourite("reparse");
                  } else {
                    setAddFavourite("");
                  }
                  setSelectPipeline("");
                  setAddAts("false");
                }}
              >
                Re parse (resume)
              </li>
              <li
                className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === "rematch" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite !== "rematch") {
                    setAddFavourite("rematch");
                  } else {
                    setAddFavourite("");
                  }
                  setSelectPipeline("");
                  setAddAts("false");
                }}
              >
                Re score (match)
              </li>
              <li
                className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === true ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite === true) {
                    setAddFavourite("");
                  } else {
                    setAddFavourite(true);
                  }
                  setSelectPipeline("");
                }}
              >
                Add to Favorites
              </li>
              <li
                className={`bg-opacity-80 text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === false ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite === false) {
                    setAddFavourite("");
                  } else {
                    setAddFavourite(false);
                  }
                  setSelectPipeline("");
                }}
              >
                Remove from Favorites
              </li>
            </ul>
            <div></div>
          </div>
          {!isSourceCandidate && (
            <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
              <h5 className="text-[#252E4B] text-base font-medium not-italic">
                Move to another stage
              </h5>
              <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
                {pipelineData.map((item) => (
                  <li
                    className={` bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer  ${selectPipeline === item ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 text-black"}`}
                    onClick={() => {
                      if (selectPipeline === item) {
                        setSelectPipeline("");
                        setAddFavourite("");
                        setAddAts("false");
                      } else {
                        setSelectPipeline(item);
                        setAddFavourite("");
                        setAddAts("false");
                      }
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {sequenceData?.length > 0 && (
            <div className="w-full">
              <h5 className="text-[#252E4B] text-base font-medium not-italic">
                Add email sequence
              </h5>
              <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
                {sequenceData.map((item) => (
                  <li
                    key={item?._id}
                    className={` bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer  ${selectedSequence[0] === item?._id ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 text-black"}`}
                    onClick={() => {
                      setSelectPipeline("");
                      setAddFavourite("");
                      setAddAts("false");
                      selectedSequence[0] == item
                        ? selectedSequence[1]("")
                        : selectedSequence[1](item);
                      changeModal("email-sequencing");
                    }}
                  >
                    {item?.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {Object?.entries(message)?.length > 0 && (
            <p
              style={
                message?.type == "error" ? { color: "red" } : { color: "green" }
              }
              className="block text-sm font-medium mt-2"
            >
              {message?.message}
            </p>
          )}
        </>
      ) : (
        <div>
          {source === "Greenhouse" && (
            <div>
              <label className="text-sm font-normal  text-gray-800 not-italic required">
                Reason of rejection
              </label>
              <select
                className={`w-[100%]  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
                name="title"
                placeholder={"Select"}
                onChange={(e) => setRejection_reason_id(e.target.value)}
                style={{
                  appearance: "none", // Remove default arrow icon
                  paddingRight: "24px", // Adjust padding to make space for the placeholder
                  backgroundImage:
                    "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
                  backgroundSize: "16px", // Adjust size of the arrow icon
                }}
                // value={defaultValue}
              >
                {rejectedReason?.map((option) => (
                  <option key={option?.id} value={option?.id}>
                    {option?.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label className="text-sm font-normal mt-[20px]  text-gray-800 not-italic">
              Rejection notes
            </label>
            <TextAreaBox
              onChange={(e) => setReason(e.target.value)}
              size={"200px"}
            />
          </div>
          {source === "Greenhouse" && (
            <>
              <div className="border-t border-[#E1E1EE] pt-[20px] mt-[20px]">
                <h5 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
                  Rejection Email
                </h5>
              </div>

              <div className="">
                <div className=" mt-[20px]">
                  <label
                    className="  text-[14px] font-normal not-italic required"
                    htmlFor=""
                  >
                    Template
                  </label>
                  <select
                    className={`w-[100%]  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
                    name="title"
                    placeholder={"Select"}
                    defaultValue={rejectionTemplate}
                    style={{
                      appearance: "none", // Remove default arrow icon
                      paddingRight: "24px", // Adjust padding to make space for the placeholder
                      backgroundImage:
                        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
                      backgroundSize: "16px", // Adjust size of the arrow icon
                    }}
                    onChange={(e) => {
                      {
                        setRejectionTemplate(e.target.value);
                        setSubject(
                          rejectedTemplates.filter(
                            (item) => item.id == e.target.value,
                          )[0]?.subject,
                        );
                        setEditorStateDesc(
                          EditorState?.createWithContent(
                            ContentState?.createFromBlockArray(
                              convertFromHTML(
                                new String(
                                  rejectedTemplates
                                    .filter(
                                      (item) => item.id == e.target.value,
                                    )[0]
                                    ?.html_body.split("&lt;")
                                    .join("<"),
                                ).toString(),
                              ),
                            ),
                          ),
                        );
                      }
                    }}

                    // value={defaultValue}
                  >
                    {rejectedTemplates?.map((option) => (
                      <option key={option?.id} value={option?.id}>
                        {option?.name}
                      </option>
                    ))}
                  </select>
                </div>
                {jobData?.length > 0 && (
                  <div>
                    <label className="text-sm font-normal  text-gray-800 not-italic  mt-[20px]">
                      From
                    </label>
                    <select
                      className={`w-[100%]  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
                      name="from"
                      placeholder={"Select"}
                      onChange={(e) => setOnBehalfId(e.target.value)}
                      defaultValue={jobData.length > 0 && jobData[0]?.id}
                      // value={defaultValue}
                    >
                      {jobData?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="  mt-[20px]">
                  <label
                    className="  text-[14px] font-normal not-italic required"
                    htmlFor=""
                  >
                    Subject
                  </label>
                  <InputBox
                    size={"small"}
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e?.target?.value)}
                  />
                </div>

                {/* <div className=" mt-[20px]">
                  <label
                    className=" text-[14px] font-normal not-italic"
                    htmlFor=""
                  >
                    Cc
                  </label>
                  <div className="relative border flex flex-wrap py-[5px]  border-[#E1E1EE] bg-[#FFF]  rounded-[8px] w-full px-[20px] ">
                    {selectedOptions.map((option, index) => (
                      <span
                        className="h-[20px] flex items-center mr-[10px] rounded-[4px] bg-[#F3F5F9] px-[10px]"
                        key={index}
                      >
                        {option}
                        <svg
                          className="cursor-pointer"
                          onClick={(e) => handleRemoveOption(index)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 7L17 17"
                            stroke="#252E4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 17L17 7"
                            stroke="#252E4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    ))}
                    <div className="relative">
                      <input
                        type="email"
                        className="w-auto"
                        value={inputValue}
                        onChange={handleInputChange}
                      />

                      {inputValue?.length > 2 && (
                        <ul className="absolute bg-[#F3F5F9] px-[10px] py-[5px] rounded-[5px] top-[24px]">
                          <li onClick={() => handleOptionClick(inputValue)}>
                            {inputValue}
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="mt-[20px]" id="description">
                  <label
                    className=" text-right text-[14px] mt-[15px] font-normal not-italic"
                    htmlFor=""
                  >
                    Body
                  </label>
                  <Editor
                    editorState={editorStateDesc}
                    // onEditorStateChange={setEditorStateDesc}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </div>
                <h5 className="text-black text-sm font-medium  not-italic mt-[20px]">
                  Attechments
                </h5>

                <div>
                  <label className="text-sm font-normal  text-gray-800 not-italic  mt-[20px]">
                    Send email when
                  </label>
                  <select
                    className={`w-[100%]  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
                    name="from"
                    value={scheduleDate}
                    onChange={(e) => setScheduleDate(e.target.value)}
                    style={{
                      appearance: "none", // Remove default arrow icon
                      paddingRight: "24px", // Adjust padding to make space for the placeholder
                      backgroundImage:
                        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
                      backgroundSize: "16px", // Adjust size of the arrow icon
                    }}
                    // value={defaultValue}
                  >
                    <option value={"now"}>Now</option>
                    <option value={"custom"}>Pick a custom time...</option>
                  </select>
                </div>
                {scheduleDate === "custom" && (
                  <div>
                    <label className="text-sm font-normal  text-gray-800 not-italic  mt-[20px]">
                      Send email on
                    </label>
                    <div>
                      <input
                        type="date"
                        className="  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
                        name="from"
                        min={new Date().toISOString().split("T")[0]}
                        placeholder={"Select"}
                        onChange={(e) => setDate(e.target.value)}
                        // value={defaultValue}
                      />
                      <input
                        type="time"
                        placeholder="Select time"
                        className="  h-[32px] ml-[20px] border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
                        onChange={(e) => setTime(e.target.value)}
                        style={{
                          appearance: "none", // Remove default arrow icon
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-[20px] gap-4">
                <button className={"btn-md btn-sec "} onClick={modalDismiss}>
                  {"Cancel"}
                </button>
                <button
                  onClick={() => SavePipeline(false)}
                  className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
                >
                  {"Reject and don't send email"}
                </button>
                <button
                  onClick={() => {
                    if (scheduleDate === "custom") {
                      if (date.length > 0 && time.length > 0) {
                        SavePipeline(true);
                      }
                    }
                    if (scheduleDate === "now") {
                      SavePipeline(true);
                    }
                  }}
                  className=" px-[20px] h-[40px] rounded-[8px] bg-[#EFF0F2]  shadow-jobPostButtonShadow flex items-center justify-center text-[##5F6989] text-[16px] not-italic font-normal"
                >
                  {`Reject and ${scheduleDate === "now" ? "send" : "schedule"} email`}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}

export default BulkActionModal;
