import React, { useState } from "react";
import TemplateForm from "./TemplateForm";
import { useParams } from "react-router-dom";
import { getCharacterCountWithoutHTML } from "./getCharCount";
const core = require("../../../lib/core");

const EditTemplate = ({ data, setEdit, reload }) => {
  const template_id = useParams().id;
  const [message, setMessage] = useState({});
  const [bodyLen, setBodyLen] = useState(0);
  const [form, setForm] = useState({
    name: data?.template_name,
    type: data?.template_type,
    subject: data?.subject,
    body: data?.body,
  });
  const setData = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const editTemplate = async () => {
    if (
      !(
        form?.name?.trim()?.length > 0 &&
        form?.type?.trim()?.length > 0 &&
        form?.subject?.trim()?.length > 0 &&
        bodyLen > 0
      )
    ) {
      setMessage({ type: "error", message: "Please enter all required data" });
      return;
    }
    let json = await core.API(
      core.API_METHODS.PUT,
      core.TEMPLATE_API.PUT_TEMPLATE_DATA + template_id,
      1,
      {
        template_name: form?.name,
        template_type: form?.type,
        subject: form?.subject,
        body: form?.body,
      },
    );
    if (json?.data) {
      reload();
      setMessage({ type: "success", message: "Template updated successfully" });
      setTimeout(() => {
        setMessage({});
        setEdit(false);
      }, 2500);
    } else {
      setMessage({
        type: "error",
        message:
          json?.message ?? "Unable to update the template. Please try again",
      });
    }
  };

  return (
    <>
      <TemplateForm
        formData={form}
        setData={setData}
        bodyCharCount={setBodyLen}
      />
      <div className="w-full flex items-center gap-4 flex-row-reverse self-end">
        <button onClick={editTemplate} className="btn-md btn-primary">
          Save
        </button>
        <button onClick={() => setEdit(false)} className="btn-md btn-sec">
          Cancel
        </button>
        {Object?.entries(message)?.length > 0 && (
          <p
            style={
              message?.type == "error" ? { color: "red" } : { color: "green" }
            }
            className="block text-sm font-medium grow"
          >
            {message?.message}
          </p>
        )}
      </div>
    </>
  );
};

export default EditTemplate;
