import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import recruiterLogo from "../../components/assets/LogoColor.png";
import Description from "../../components/recruiter/job/description/Description";
import InitialsLogo from "../../components/all/InitialsLogo";
import { toast } from "react-toastify";
import Modal from "../../components/modal/Modal";
import ApplyModal from "../../components/jobBoard/ApplyModal";
const core = require("../../lib/core");

export default function JobDescription() {
  const params = useParams();
  const [job, setJob] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    // Get job details from database
    fetchJob();
  }, []);

  const fetchJob = async () => {
    try {
      var id = params?.id;
      var jobapi = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_PUBLIC_JOBS_POST + String(id),
        1
      );
      setJob(jobapi.data);
    } catch (error) {
      console.error("error on fetching Jobs", error);
    }
  };

  const handleCopyText = () => {
    let text = window.location.href;
    setShowTooltip(true);
    navigator.clipboard.writeText(text);

    setTimeout(() => {
      setShowTooltip(false);
    }, 1500); // Set the timeout for how long the tooltip should be visible (in milliseconds)
  };

  return (
    <div className="w-screen flex flex-col h-screen">
      <div className="h-16 w-full px-20 py-4 bg-gray-300">
        <img className="h-full" src={recruiterLogo} alt="" />
      </div>
      {Object?.keys(job)?.length > 0 && (
        <main className=" relative w-full h-full overflow-y-auto flex flex-row justify-between items-start bg-gray-300 p-20 overflow-auto scrollbar-hide">
          <div className="container m-0 py-4 px-10 flex flex-col gap-4 w-4/6 relative z-10 justify-center mb-8 bg-white rounded-lg">
            <div className="grid grid-cols-[40px_1fr] gap-x-4 gap-2">
              <InitialsLogo
                className={"row-span-2"}
                str={job?.company?.name ?? "C"}
                width={40}
                height={40}
                fontSize={18}
              />
              <span className="grow">
                <div className="w-full text-lg">{job?.position}</div>
                {job?.company?.name && (
                  <div className="text-gray-700 text-md">
                    {job?.company?.name}
                  </div>
                )}
              </span>
            </div>
            <Description
              classTxt={"w-full bg-white mb-6 relative"}
              paraStyle="!text-base !text-gray-800"
              headingStyle="text-lg text-black font-medium"
              job={job}
            />
          </div>
          <div className="w-1/4 sticky top-0 flex flex-col gap-6">
            <div className="flex flex-col gap-4 w-full p-6 items-start border border-gray-300 bg-white rounded-lg shadow-md">
              <h1 className="text-xl">Interested in job?</h1>
              <button
                disabled={status[job?._id] == "SENT"}
                onClick={() => setOpenModal(true)}
                className={
                  status[job?._id] == "SENT"
                    ? "btn-sec btn-lg pointer-events-none text-base w-full"
                    : "btn-primary btn-lg hover:bg-white hover:text-blue-800 text-base w-full"
                }
              >
                {status[job?._id] == "SENT" ? "Applied" : "Apply with resume"}
              </button>
            </div>
            <div className="relative inline-flex justify-between gap-5 w-full p-6 items-center border border-gray-300 bg-white rounded-lg shadow-md">
              <h1 className="text-md">Share</h1>
              <button
                onClick={handleCopyText}
                className={
                  "btn-sec btn-md hover:bg-white hover:text-blue-800 text-sm w-auto"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                  />
                </svg>
                <span>Copy the link</span>
              </button>
              <div
                className="rounded-md px-3 flex justify-center w-[200px] absolute z-20 top-[72px] right-0 text-white py-1 bg-black"
                style={{
                  visibility: showTooltip ? "visible" : "hidden",
                }}
              >
                Copied to clipboard!
              </div>
            </div>
          </div>
        </main>
      )}
      {openModal ? (
        <ApplyModal
          status={status}
          setOpenModal={setOpenModal}
          setStatus={setStatus}
          jobId={job?.id}
        />
      ) : null}
    </div>
  );
}
