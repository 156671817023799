import React, { useContext, useRef } from "react";
import { UserContext } from "../lib/core";
import { Tooltip as ReactTooltip } from "react-tooltip";

const LockedComponent = ({
  isLocked = true,
  place,
  children,
  message = "You don't have permission to access this resource",
  className = "",
}) => {
  const { user } = useContext(UserContext);

  return user?.locked && isLocked ? (
    <div
      className={"group relative bg-gray-300 rounded-lg " + className}
      data-tooltip-id="my-tooltip-2"
    >
      <ReactTooltip
        id="my-tooltip-2"
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: "400",
        }}
        place={"bottom"}
        variant="info"
        content={message}
      />
      <div className="pointer-events-none">
        <div className="opacity-70">{children}</div>
      </div>
    </div>
  ) : (
    <> {children}</>
  );
};

export default LockedComponent;
