export default function Modal({
  id,
  heading,
  subheading,
  message,
  modalDismiss,
  children,
  onSave,
  dismissOnSave = false,
  customButtons,
  defaultButtons,
  className,
  onCancel,
  noBtns = false,
  style = {},
}) {
  return (
    <>
      <div
        className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity z-[900]"
        onClick={modalDismiss}
      />
      <div
        className={`pointer-events-none fixed inset-0 flex justify-center items-center w-screen z-[999]`}
        id={id ?? "custom-modal"}
      >
        <div
          className={`text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg max-w-[66%] min-w-[280px]  max-h-[90vh] overflow-auto bg-white rounded-lg p-5 pointer-events-auto relative w-full  ${className}`}
          style={{ ...style }}
        >
          <button
            onClick={modalDismiss}
            type="button"
            className="cursor-pointer absolute right-5 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M24 24L8 8M24 8L8 24"
                stroke="#001B4B"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          <div className="w-full flex flex-col gap-5  mb-4 ">
            {heading && String(heading)?.length > 0 && (
              <h1 className="text-black  text-base font-bold w-[calc(100%-32px)] not-italic">
                {heading}
              </h1>
            )}
            {subheading && String(subheading)?.length > 0 && (
              <h6 className="text-gray-700 text-sm -mt-3 not-italic font-normal">
                {subheading}
              </h6>
            )}
          </div>

          {children}
          {customButtons?.length > 0 ? (
            <div className="w-full flex items-end gap-4 flex-row-reverse self-end mt-2">
              {customButtons?.map((btn, idx) => (
                <button
                  key={idx}
                  className={`btn-md ${btn?.className}`}
                  type={btn?.type ?? "button"}
                  onClick={btn.function}
                >
                  {btn?.label}
                </button>
              ))}
            </div>
          ) : (
            !noBtns && (
              <div className="w-full flex items-end gap-4 flex-row-reverse self-end mt-2">
                <button
                  disabled={defaultButtons?.primaryDisabled ?? false}
                  type="button"
                  className={
                    "btn-md btn-primary disabled:opacity-60 disabled:pointer-events-none !bg-blue " +
                    defaultButtons?.primaryClass
                  }
                  onClick={() => {
                    onSave();
                    if (dismissOnSave) {
                      setTimeout(() => modalDismiss(), 1500);
                    }
                  }}
                >
                  {defaultButtons?.primaryLabel ?? "Save"}
                </button>
                <button
                  className={"btn-md btn-sec " + defaultButtons?.secClass}
                  onClick={onCancel ? onCancel : modalDismiss}
                  type="button"
                >
                  {defaultButtons?.secLabel ?? "Cancel"}
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
