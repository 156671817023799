import React, { useState, useEffect } from "react";
import Modal from "../../modal/Modal";
import { PlusCircle, Trash2 } from "lucide-react";
import TextAreaBox from "../../../layouts/TextAreaBox";
import InputBox from "../../../layouts/InputBox";
import { ScaleLoader } from "react-spinners";
import SearchUser from "../user/SearchUser";
import InitialsLogo from "../../all/InitialsLogo";
const core = require("../../../lib/core");

function InterviewPlanModal({ jobId, modalDismiss }) {
  const [plan, setPlan] = useState({
    description: "",
    stages: [],
  });
  const [message, setMessage] = useState({});
  const [editingStageIndex, setEditingStageIndex] = useState(null);
  const [isPlanLoading, setisPlanLoading] = useState(false);

  const GET_INTERVIEW_PLAN = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_INTERVIEW_PLAN + jobId,
        1,
        {},
      );
      if (json?.data) {
        setPlan(json?.data?.plan);
        setisPlanLoading(false);
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
      setisPlanLoading(false);
    }
  };

  const UPDATE_INTERVIEW_PLAN = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.GET_INTERVIEW_PLAN + jobId,
        1,
        { interviewPlan: plan },
      );
      if (json?.data) {
        modalDismiss();
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
    }
  };

  const Create_InterviewPlan = async () => {
    setisPlanLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_GEN_INTERVIEW_PLAN,
        1,
        { job_id: jobId },
      );
      if (json?.data) {
        GET_INTERVIEW_PLAN();
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
      setisPlanLoading(false);
    }
  };

  useEffect(() => {
    GET_INTERVIEW_PLAN();
  }, []);

  const modalElements = {
    heading: "Interview Plan",
    subheading: "Create and edit interview stages",
    modalDismiss: () => modalDismiss(),
    onSave: () => UPDATE_INTERVIEW_PLAN(),
    defaultButtons: {
      primaryLabel: "Save",
      primaryDisabled: !(plan?.stages?.length > 0),
      primaryClass: plan?.stages?.length > 0 ? "" : "hidden",
    },
    className: "sm:!max-w-[60%]",
  };

  const handleDescriptionChange = (e) => {
    setPlan({ ...plan, description: e.target.value });
  };

  const handleStageChange = (index, field, value) => {
    const newStages = [...plan.stages];
    newStages[index][field] = value;
    setPlan({ ...plan, stages: newStages });
  };

  const addStage = () => {
    setPlan({
      ...plan,
      stages: [
        ...plan.stages,
        { name: "", description: "", duration: 0, skills: [] },
      ],
    });
  };

  const removeStage = (index) => {
    const newStages = plan.stages.filter((_, i) => i !== index);
    setPlan({ ...plan, stages: newStages });
  };

  const addSkill = (stageIndex) => {
    const newStages = [...plan.stages];
    newStages[stageIndex].skills.push("");
    setPlan({ ...plan, stages: newStages });
  };

  const removeSkill = (stageIndex, skillIndex) => {
    const newStages = [...plan.stages];
    newStages[stageIndex].skills = newStages[stageIndex].skills.filter(
      (_, i) => i !== skillIndex,
    );
    setPlan({ ...plan, stages: newStages });
  };

  const EditIcon = () => (
    <svg
      fill="none"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <g id="button_2">
        <rect height="32" width="32" fill="white" rx="8" x="0.5" y="0.5" />
        <rect height="31" width="31" rx="7.5" stroke="#E1E1EE" x="1" y="1" />
        <path
          d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
          fill="#5F6989"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(5, 5) scale(0.85)"
        />
      </g>
    </svg>
  );

  const SaveIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="m15.3 5.3l-6.8 6.8l-2.8-2.8l-1.4 1.4l4.2 4.2l8.2-8.2z"
      />
    </svg>
  );

  const CancelIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 2048 2048"
    >
      <path
        fill="currentColor"
        d="m1115 1024l690 691l-90 90l-691-690l-691 690l-90-90l690-691l-690-691l90-90l691 690l691-690l90 90z"
      />
    </svg>
  );

  const addInterviewer = (stageIndex, interviewer) => {
    setPlan((prevData) => {
      const newStages = [...prevData.stages];
      newStages[stageIndex] = {
        ...newStages[stageIndex],
        interviewers: [
          ...(newStages[stageIndex]?.interviewers ?? []),
          interviewer,
        ],
      };
      return {
        ...prevData,
        stages: newStages,
      };
    });
  };

  return (
    <Modal {...modalElements}>
      <div className="w-full flex flex-col gap-4">
        {plan.description ? (
          <div>
            <label className="mt-2 text-base font-semibold">Description</label>
            <p className="text-sm text-justify">{plan?.description}</p>
          </div>
        ) : null}

        <div>
          {plan.stages.length > 0 ? (
            <h2 className="text-base font-semibold mb-2">Stages</h2>
          ) : null}
          {plan.stages.length > 0 ? (
            <>
              {" "}
              {plan?.stages?.map((stage, index) => (
                <>
                  <div
                    key={index}
                    className="relative mb-4 p-4 border shadow-sm rounded-lg"
                  >
                    <div className="flex justify-between items-center mb-2">
                      <div className="w-[70%]">
                        {editingStageIndex === index ? (
                          <InputBox
                            className="w-full mb-2 p-2 border rounded"
                            value={stage.name}
                            onChange={(e) =>
                              handleStageChange(index, "name", e.target.value)
                            }
                            placeholder="Stage Name"
                          />
                        ) : (
                          <h3 className="text-base font-medium truncate">
                            {stage.name}
                          </h3>
                        )}
                      </div>
                      <div className="flex flex-shrink-0">
                        {editingStageIndex === index ? (
                          <>
                            <button
                              onClick={() => setEditingStageIndex(null)}
                              className="mr-2 text-green-700"
                            >
                              <SaveIcon />
                            </button>
                            <button
                              onClick={() => setEditingStageIndex(null)}
                              className="mr-2 text-red-700"
                            >
                              <CancelIcon />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => setEditingStageIndex(index)}
                              className="mr-2 text-blue-600"
                            >
                              <EditIcon />
                            </button>
                            <button
                              onClick={() => removeStage(index)}
                              className="ml-2 text-red-700"
                            >
                              <Trash2 size={16} />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {editingStageIndex === index ? (
                      <>
                        <TextAreaBox
                          className="w-full mb-2 p-2 border rounded"
                          value={stage.description}
                          onChange={(e) =>
                            handleStageChange(
                              index,
                              "description",
                              e.target.value,
                            )
                          }
                          placeholder="Stage Description"
                          size="auto"
                        />

                        <div className="mb-2">
                          <label className="block text-base font-medium text-gray-700">
                            Duration (minutes)
                          </label>
                          <InputBox
                            type="number"
                            value={stage.duration}
                            min="0"
                            onChange={(e) =>
                              handleStageChange(
                                index,
                                "duration",
                                parseInt(e.target.value),
                              )
                            }
                            size="medium"
                          />
                        </div>
                        <div>
                          <label className="block text-base font-medium text-gray-700">
                            Skills
                          </label>
                          {stage.skills.map((skill, skillIndex) => (
                            <div
                              key={skillIndex}
                              className="flex items-center mt-1"
                            >
                              <InputBox
                                value={skill}
                                onChange={(e) => {
                                  const newSkills = [...stage.skills];
                                  newSkills[skillIndex] = e.target.value;
                                  handleStageChange(index, "skills", newSkills);
                                }}
                                placeholder="Skill"
                              />
                              <button
                                onClick={() => removeSkill(index, skillIndex)}
                                className="ml-2 text-red-700"
                              >
                                <Trash2 size={16} />
                              </button>
                            </div>
                          ))}
                          <button
                            onClick={() => addSkill(index)}
                            className="mt-2 text-blue-800 hover:text-blue-900 flex items-center"
                          >
                            <PlusCircle size={16} className="mr-1" /> Add Skill
                          </button>
                        </div>
                        <div className="mt-2">
                          <SearchUser
                            label={"Interviewers"}
                            emails={stage.interviewers ?? []}
                            setEmails={(e) => {
                              addInterviewer(index, e);
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mb-2">
                          <label className="text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <p className="mt-1">{stage.description}</p>
                        </div>
                        <p className="mb-2">
                          <span className="font-medium text-gray-700">
                            Duration:
                          </span>{" "}
                          {stage.duration} minutes
                        </p>
                        <div>
                          <p className="font-medium mb-1 text-gray-700">
                            Skills
                          </p>
                          <div className="flex flex-wrap gap-2">
                            {stage.skills.map((skill, skillIndex) => (
                              <span
                                key={skillIndex}
                                className="bg-gray-200 text-gray-800 text-sm px-3 py-1 rounded-lg"
                              >
                                {skill}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="mt-2">
                          <p className="font-medium mb-1 text-gray-700">
                            interviewers
                          </p>
                          <div className="flex flex-wrap gap-2">
                            {stage?.interviewers?.map((int, intIndex) => (
                              <span
                                key={intIndex}
                                className="border-gray-300 border-[2px] gap-2 flex items-center  text-gray-800 text-sm px-3 py-1 rounded-lg"
                              >
                                <InitialsLogo
                                  str={int?.name ?? int?.email}
                                  width="20px"
                                  height="20px"
                                  fontSize="12px"
                                  borderRadius="50%"
                                />
                                {int?.name ?? int?.email}
                              </span>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2 flex justify-center">
                      <button
                        onClick={() => {
                          const newStages = [...plan.stages];
                          newStages.splice(index + 1, 0, {
                            name: `Round ${index + 2}`,
                            description: "",
                            duration: 0,
                            skills: [],
                          });
                          setPlan({ ...plan, stages: newStages });
                        }}
                        className="bg-white rounded-full p-4 shadow-md hover:bg-gray-100 transition-opacity opacity-0 hover:opacity-100"
                        title="Add new stage"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#00008B"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                      </button>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            <div className="flex justify-center py-4 mb-4">
              <button
                onClick={Create_InterviewPlan}
                className="btn-primary btn-md"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
                {isPlanLoading ? (
                  <>
                    <ScaleLoader height={14} color="#FFF" loading={true} />
                  </>
                ) : (
                  "Generate interview plan"
                )}
              </button>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>

              <p>No Interview Plan found</p> */}
            </div>
          )}
          {/* <button
            onClick={addStage}
            className="mt-4 px-3 py-2 text-white rounded-md btn-primary"
          >
            Add Stage
          </button> */}
        </div>
      </div>
      {Object.entries(message)?.length > 0 && (
        <p
          style={
            message?.type == "error" ? { color: "red" } : { color: "green" }
          }
          className="block text-sm font-medium mt-2"
        >
          {message?.message}
        </p>
      )}
    </Modal>
  );
}

export default InterviewPlanModal;
