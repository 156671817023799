import React, { useContext, useEffect, useState } from "react";
import Progressbar from "./interviewFeedback/Progressbar";
import { ScaleLoader } from "react-spinners";
import AddNewFeedback from "./interviewFeedback/AddNewFeedback";
import FeedbackInviteModal from "./FeedbackInviteModal";
import FeedbackInviteEmailModal from "./FeedbackInviteEmailModal";
var parse = require("html-react-parser");
const core = require("../../../../lib/core");

function FeedbackIndividual({ appId, review, candidateData, jobId }) {
  const [showSection, setShowSection] = useState("list");
  const [feedbackList, setFeedbackList] = useState([]);

  console.log(appId);

  const [loading, setLoading] = useState(false);

  const { user } = useContext(core.UserContext);

  const getAllfeedback = async () => {
    try {
      setLoading(true);

      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_INTERVIEW_FEEDBACK_LIST + "/" + appId,
        1,
        {
          matchprofile: appId,
        },
      );

      if (json?.data) {
        setFeedbackList(json?.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  console.log(feedbackList);
  useEffect(() => {
    getAllfeedback();
  }, []);

  const [feedbackIndex, setFeedbackIndex] = useState(null);

  function calculatScore(data) {
    let totalScore = 0;
    let totalRequired = 20;

    data.forEach((criteria) => {
      // Add the criteria value and required value if present

      criteria.value && (totalScore += criteria.value);
      criteria.required && (totalRequired += 10);

      // Add the scores of the skills within each criteria
      criteria.skills.forEach((skill) => {
        totalScore += skill.value;
        totalRequired += 10;
      });
    });

    // Calculate the percentage
    const percentage = (totalScore / totalRequired) * 10;

    return percentage.toFixed(1); // Returning the percentage up to 2 decimal places
  }

  const [showModal, setShowModal] = useState("");

  return (
    <div className="p-4 bg-bg1">
      {loading ? (
        <ScaleLoader height={14} color="#1563E9" loading={true} />
      ) : (
        <>
          {showSection === "list" && (
            <>
              <div className="flex  items-center">
                <button
                  className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                  onClick={() => setShowSection("addPipeline")}
                >
                  <span>+ Add feedback</span>
                </button>
                {/* {!candidateData && (
                  <button
                    className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                    onClick={() => setShowModal("invite")}
                  >
                    <span>Invite</span>
                  </button>
                )} */}
              </div>

              <div
                className={`justify-between grid ${review ? "grid-cols-2" : "grid-cols-4"} gap-[20px] mb-[20px] mt-5`}
              >
                {feedbackList?.map((item, index) => (
                  <div
                    onClick={() => {
                      if (feedbackIndex === index) {
                        setFeedbackIndex(null);
                      } else {
                        setFeedbackIndex(index);
                      }
                    }}
                    className={`col-span-1 flex flex-col rounded-[12px] px-[16px] py-[12px] bg-[#FFF] shadow-PipelineCardsShadow h-[120px] cursor-pointer border-2 ${feedbackIndex === index ? "ring-2 ring-blue-500" : ""}`}
                  >
                    <span className="flex text-[#5F6989] text-[14px] font-normal leading-[22px] not-italic">
                      {item?.interviewStage}
                    </span>
                    <span className=" mt-[15px] text-[#252E4B] text-[24px] font-semibold leading-[32px] not-italic flex items-center">
                      {item?.recomandation}
                      <span
                        className={`ml-[5px] flex items-center rounded-[14px] py-[3px] px-[6px] ${["No", "Definitely Not"].includes(item?.recomandation) ? "text-red-800 bg-red-100" : "text-[#00B998] bg-[#E6F8F5]"} text-[12px]`}
                      >
                        {calculatScore(item?.feedback) > 0
                          ? calculatScore(item?.feedback)
                          : 0}
                        /10
                      </span>
                    </span>
                    <span className="mt-[6px] text-[#5F6989] text-[12px] font-normal leading-[22px] not-italic">
                      {item?.externalInterviewer}
                    </span>
                  </div>
                ))}
              </div>

              {feedbackIndex !== null && (
                <div className="mt-10">
                  <div>
                    <div className="flex justify-between items-center ">
                      <h2 className="text-xl font-semibold mb-4">
                        Overall Conclusions
                      </h2>
                      {user.id === feedbackList?.[feedbackIndex]?.recruiter && (
                        <button
                          onClick={(e) => setShowSection("edit")}
                          className="p-2 text-gray-800 text-sm font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded flex gap-2 items-start "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 shrink-0 mt-px"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            ></path>
                          </svg>
                          Edit
                        </button>
                      )}
                    </div>
                    <p className="p-4 bg-[#fff] rounded-lg mb-5">
                      {parse(
                        feedbackList?.[feedbackIndex]?.overallComment
                          ?.split("&lt;")
                          ?.join("<"),
                      )}
                    </p>
                  </div>
                  <h2 className="text-xl font-semibold mb-4">
                    Evaluation Categories
                  </h2>
                  <Progressbar
                    skills={feedbackList?.[feedbackIndex]?.feedback}
                  />
                </div>
              )}
            </>
          )}
          {showSection === "addPipeline" ? (
            <AddNewFeedback
              setShowSection={setShowSection}
              getAllfeedback={getAllfeedback}
              candidateData={candidateData}
              jobId={jobId}
              edit={false}
            />
          ) : showSection === "edit" ? (
            <AddNewFeedback
              setShowSection={setShowSection}
              getAllfeedback={getAllfeedback}
              candidateData={candidateData}
              jobId={jobId}
              edit={true}
              feedback={feedbackList?.[feedbackIndex]}
            />
          ) : null}

          {showModal === "invite" ? (
            <FeedbackInviteModal
              modalDismiss={(e) => setShowModal("")}
              setShowModal={setShowModal}
              jobId={jobId}
              matchprofile={appId}
            />
          ) : showModal === "emailInvite" ? (
            <FeedbackInviteEmailModal modalDismiss={(e) => setShowModal("")} />
          ) : null}
        </>
      )}
    </div>
  );
}

export default FeedbackIndividual;
