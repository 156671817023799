import React, { useEffect, useState } from "react";
import InviteRecruiter from "./InviteRecruiter";
import EditRoleModal from "./EditRoleModal";
import ReactPaginate from "react-paginate";
import LoadingSkeleton from "../../components/jobBoard/jobListing/LoadingSkeleton";
import CreateNewUser from "./CreateNewUser";
const core = require("../../lib/core");

function User({}) {
  const [users, setUsers] = useState([]);

  const [allRoles, setAllRoles] = useState([]);

  const Get_all_roles = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_ROLES,
      1,
    );
    if (json.data) {
      setAllRoles(json?.data);
    } else {
    }
  };

  useEffect(() => {
    Get_all_roles();
  }, []);

  const [loading, setLoading] = useState(false);

  const [SelectedRoles, setSelectedRoles] = useState([]);

  const [totalPage, setTotalPage] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (data) => {
    console.log(data?.selected);
    setCurrentPage(data?.selected + 1);
    // scrollToComponent();
  };

  const Get_users_with_role = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USERS_WITH_ROLE + `?page=${currentPage}`,
        1,
      );
      if (json.data) {
        setUsers(json?.data);
        setTotalPage(json?.data?.metadata?.totalPages);
        setLoading(false);
        setShowModal(false);
      } else {
        setLoading(false);
        setShowModal(false);
      }
    } catch (e) {
      setLoading(false);
      setShowModal(false);
    }
  };

  useEffect(() => {
    Get_users_with_role();
  }, [currentPage]);

  const [showModal, setShowModal] = useState(false);

  console.log(users);

  return (
    <div>
      <div className=" flex justify-between items-center">
        <h1 className="text-[#252E4B] font-medium text-[18px] py-[8px] not-italic leading-[22px] ">
          Users
        </h1>
        {/* <button
          onClick={() => setShowModal("invite")}
          className="flex gap-2 items-center rounded-lg py-2 px-4 border border-[#DADAE4] bg-white shadow-jobPostButtonShadow "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <g clipPath="url(#clip0_1324_121084)">
              <path
                d="M4.15397 14.6666C5.39896 14.6666 6.44468 13.8135 6.73835 12.6599C6.82919 12.3031 7.11911 12 7.4873 12H12.154M4.15397 14.6666C2.68121 14.6666 1.4873 13.4727 1.4873 12V3.33331C1.4873 2.22874 2.38274 1.33331 3.4873 1.33331H10.154C11.2585 1.33331 12.154 2.22874 12.154 3.33331V12M4.15397 14.6666H12.154C13.399 14.6666 14.4447 13.8135 14.7384 12.6599C14.8292 12.3031 14.5222 12 14.154 12H12.154"
                stroke="#252E4B"
                strokeLinecap="round"
              />
              <path
                d="M8.96944 9.5C8.96944 8.78408 8.04084 8.2037 6.89536 8.2037C5.74988 8.2037 4.82129 8.78408 4.82129 9.5M6.89507 6.59259C6.17915 6.59259 5.59878 6.01222 5.59878 5.2963C5.59878 4.58037 6.17915 4 6.89507 4C7.611 4 8.19137 4.58037 8.19137 5.2963C8.19137 6.01222 7.611 6.59259 6.89507 6.59259Z"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1324_121084">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0.154297)"
                />
              </clipPath>
            </defs>
          </svg>
          Invite team members
        </button> */}

        <button
          onClick={() => setShowModal("createUser")}
          className="flex gap-2 items-center rounded-lg py-2 px-4 border border-[#DADAE4] bg-white shadow-jobPostButtonShadow "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <g clipPath="url(#clip0_1324_121084)">
              <path
                d="M4.15397 14.6666C5.39896 14.6666 6.44468 13.8135 6.73835 12.6599C6.82919 12.3031 7.11911 12 7.4873 12H12.154M4.15397 14.6666C2.68121 14.6666 1.4873 13.4727 1.4873 12V3.33331C1.4873 2.22874 2.38274 1.33331 3.4873 1.33331H10.154C11.2585 1.33331 12.154 2.22874 12.154 3.33331V12M4.15397 14.6666H12.154C13.399 14.6666 14.4447 13.8135 14.7384 12.6599C14.8292 12.3031 14.5222 12 14.154 12H12.154"
                stroke="#252E4B"
                strokeLinecap="round"
              />
              <path
                d="M8.96944 9.5C8.96944 8.78408 8.04084 8.2037 6.89536 8.2037C5.74988 8.2037 4.82129 8.78408 4.82129 9.5M6.89507 6.59259C6.17915 6.59259 5.59878 6.01222 5.59878 5.2963C5.59878 4.58037 6.17915 4 6.89507 4C7.611 4 8.19137 4.58037 8.19137 5.2963C8.19137 6.01222 7.611 6.59259 6.89507 6.59259Z"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1324_121084">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0.154297)"
                />
              </clipPath>
            </defs>
          </svg>
          Create User
        </button>
      </div>
      <div className="overflow-x-auto h-[calc(100vh-220px)] overflow-y-scroll mt-[20px]">
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <table className="min-w-full">
            <thead className="sticky top-0 z-50 bg-[#F2F4F8] text-[#252E4B]  text-[12px]">
              <tr>
                <th className="px-6 py-3 text-left  font-medium   tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left  font-medium   tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left  font-medium   tracking-wider">
                  Roles
                </th>
                <th className="px-6 py-3 text-left  font-medium   tracking-wider">
                  status
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {users?.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-no-wrap">{user?.name}</td>
                  <td className="px-6 py-4 whitespace-no-wrap">
                    {user?.email}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap flex flex-wrap gap-2 max-w-[400px]">
                    {user?.role?.map((role) => (
                      <span className="bg-[#F2F4F8] p-[3px] rounded-sm">
                        {role?.name}
                      </span>
                    ))}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap ">
                    <div className="flex gap-2 items-center justify-between">
                      <span
                        className={` whitespace-no-wrap  ${user?.active ? "text-[green]" : "text-[red]"}`}
                      >
                        {" "}
                        {user?.active ? "Active" : "Inactive"}
                      </span>
                      <button
                        className="border-button rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
                        title="Edit"
                        onClick={() => {
                          setShowModal("edit");
                          setSelectedRoles(user);
                        }}
                        style={{ color: "#FF475D" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
                            stroke="#252E4B"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {totalPage > 1 && (
          <div className="flex justify-center relative bg-[#FFF] border-t border-t-[#EFF0F2]">
            <ReactPaginate
              previousLabel={"<- Previous"}
              nextLabel={"Next ->"}
              breakLabel={"..."}
              pageCount={totalPage} // Total number of pages
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={currentPage - 1}
            />
          </div>
        )}
      </div>
      {showModal === "invite" ? (
        <InviteRecruiter
          modalDismiss={() => {
            setShowModal(false);
          }}
          allRoles={allRoles}
        />
      ) : showModal === "edit" ? (
        <EditRoleModal
          modalDismiss={() => {
            setShowModal(false);
          }}
          Get_users_with_role={Get_users_with_role}
          loading={loading}
          setLoading={setLoading}
          allRoles={allRoles}
          selectedRoles={SelectedRoles}
        />
      ) : showModal === "createUser" ? (
        <CreateNewUser
          modalDismiss={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default User;
