import React , { useContext} from 'react';

// import './App.css';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

const core = require("../../../lib/core")

function Zoom() {

    const { user } = useContext(core.UserContext);

  const client = ZoomMtgEmbedded.createClient();

  var authEndpoint = ''
  var sdkKey = ''
  var meetingNumber = '123456789'
  var passWord = ''
  var role = 0
  var userName = 'React'
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''


  async function startMeeting() {
    try {
      let meetingSDKElement = document.getElementById("ZoomMeet");
      let json = await core.API(
        core.API_METHODS.POST,
        core.BOT_API.CREATE_ZOOM_MEET,
        1,
        {}
      );
      console.log(json.data);
      client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        customize: {
          meetingInfo: [
            'topic', 'host', 'mn', 'pwd', 'telPwd', 'participant', 'dc', 'enctype'
          ],
          toolbar: {
            buttons: [
              {
                text: 'Custom Button',
                className: 'CustomButton',
                onClick: () => {
                  console.log('custom button');
                }
              }
            ]
          }
        },
      });
      client.join({
        signature: json.signature,
        sdkKey: "fzbCTlQTR6eM4Rog8GMqfg",
        meetingNumber: json.data.id,
        password: json.data.encrypted_password,
        userName: user.name,
        userEmail: user.email,
        //   tk: registrantToken,
        zak: json.zak,
      });
  
    //   enterBot(json.data.join_url);
    //   setStartInt(true);
  
      // Get meeting object
      let meetingObj = client.getMeetingObj();
  
      // Disable active speaker view and enable gallery view
      meetingObj.activeSpeakerView = false; // Disable active speaker view
      meetingObj.showGalleryView();
  
      // Show host's video by default
      let hostVideo = meetingObj.getAttendees().find((attendee) => attendee.isHost);
      if (hostVideo) {
        hostVideo.videoOn = true;
      }
  
    } catch (err) {
      console.log(err);
    //   setStartInt(true);
    }
  }


  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting SDK Sample React</h1>

        {/* For Component View */}
        <div id="meetingSDKElement">
          {/* Zoom Meeting SDK Component View Rendered Here */}
        </div>

        <button onClick={startMeeting}>Join Meeting</button>
      </main>
    </div>
  );
}

export default Zoom;

