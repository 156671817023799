import React, { useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router";
import { ScaleLoader } from "react-spinners";
const core = require("../../../lib/core");

function EmailSequenceConfigModal({ modalDismiss, jobId }) {
  const onCancel = () => {
    modalDismiss();
  };

  const nav = useNavigate();
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [eventMap, setEventMap] = useState([]);

  // Fetch Sequences and Events
  const getData = async () => {
    try {
      const [sequences, events, eventMaps] = await Promise.all([
        core.API(core.API_METHODS.GET, core.SEQUENCE_API.GET_ALL_SEQUENCES, 1),
        core.API(
          core.API_METHODS.GET,
          core.SEQUENCE_API.GET_SEQUENCE_EVENTS,
          1,
        ),
        await core.API(
          core.API_METHODS.GET,
          core.SEQUENCE_API.GET_SEQUENCE_EVENTS_MAP + jobId,
          1,
        ),
      ]);
      if (sequences.response) {
        setSequences(sequences.response);
      }
      if (events.response) {
        setEvents(events.response);
      }
      if (eventMaps.response) {
        setEventMap(eventMaps.response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < eventMap.length; i++) {
        const event = eventMap[i];
        if (!event?.is_modified) continue;
        if (!event.sequence_id && !event._id) continue;
        if (!event.sequence_id) {
          // Delete the event mapping
          const res = await core.API(
            core.API_METHODS.DELETE,
            core.SEQUENCE_API.SEQUENCE_EVENTS_MAP + event._id,
            1,
          );
          if (res.response) {
            eventMap[i].is_modified = false;
          }
        } else if (event?._id) {
          // Update the event mapping
          const sequence = event.sequence_id;
          const eventId = event.event_type_id;
          const res = await core.API(
            core.API_METHODS.PUT,
            core.SEQUENCE_API.SEQUENCE_EVENTS_MAP + event._id,
            1,
            {
              sequence_id: sequence,
              event_type_id: eventId,
              job_id: jobId,
            },
          );
          if (res.response) {
            eventMap[i].is_modified = false;
          }
        } else {
          // Create the event mapping
          const sequence = event.sequence_id;
          const eventId = event.event_type_id;
          const res = await core.API(
            core.API_METHODS.POST,
            core.SEQUENCE_API.POST_SEQUENCE_EVENTS_MAP,
            1,
            {
              sequence_id: sequence,
              event_type_id: eventId,
              job_id: jobId,
            },
          );
          if (res.response) {
            eventMap[i].is_modified = false;
          }
        }
      }
      setLoading(false);
      modalDismiss();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const modalElements = {
    heading: "Email Sequence Configuration",
    subheading: "Configure the email sequence for the job",
    modalDismiss: () => onCancel(),
    defaultButtons: {
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Sava Changes"
      ),
      secLabel: "Cancel",
    },
    onSave: () => {
      handleSubmit();
    },
  };
  return (
    <Modal {...modalElements}>
      <div className="w-full flex flex-col">
        <div className=" flex flex-col gap-5 max-w-[500px] w-full">
          <div className="w-full flex flex-col gap-1">
            {events.map((event, index) => (
              <div key={event._id}>
                <label
                  htmlFor=""
                  className="w-full text-gray-800 text-sm font-normal not-italic mt-6 mb-1"
                >
                  {event.event_name}
                </label>
                <select
                  name={event.event_type}
                  className="w-full border border-gray-300 rounded-md p-2"
                  id=""
                  value={
                    eventMap.find((e) => e.event_type_id === event._id)
                      ?.sequence_id
                  }
                  onChange={(e) => {
                    const eventMapArr = [...eventMap];
                    const emap = eventMapArr.find(
                      (e) => e.event_type_id === event._id,
                    );
                    if (emap) {
                      emap.sequence_id = e.target.value;
                      emap.is_modified = true;
                      setEventMap(eventMapArr);
                    } else {
                      eventMapArr.push({
                        event_type_id: event._id,
                        sequence_id: e.target.value,
                        is_modified: true,
                      });
                      setEventMap(eventMapArr);
                    }
                  }}
                >
                  <option value="">None</option>
                  {sequences.map((sequence, index) => (
                    <option value={sequence._id} key={sequence._id}>
                      {sequence.name}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmailSequenceConfigModal;
