import React from "react";
var parse = require("html-react-parser");

const TemplateData = ({ data, showType = true }) => {
  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }
  return (
    data && (
      <>
        <h2 className="text-gray-800 text-base not-italic font-medium ">
          {data?.template_name}
        </h2>
        {showType && (
          <h2 className="text-gray-700 text-sm not-italic font-semibold">
            {data?.template_type}
          </h2>
        )}
        <div className="w-full">
          <label className="text-sm font-normal not-italic">Subject</label>
          <p className="py-1 px-1.5 border-gray-300 border rounded-lg text-sm font-normal not-italic text-gray-800">
            {data?.subject}
          </p>
        </div>
        <div className="w-full">
          <label className="text-sm font-normal not-italic">Body</label>
          <p className="py-1 px-1.5 border-gray-300 border rounded-lg text-sm font-normal not-italic text-gray-800">
            {parse(getText(data?.body))}
          </p>
        </div>
      </>
    )
  );
};

export default TemplateData;
