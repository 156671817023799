import React, { useState, useEffect } from "react";
import Footer from "./JobPostFooter";
import { useLocation, useNavigate } from "react-router-dom";
import ScreeningQuestionForm from "./ScreeningQuestionForm";
import { ScaleLoader } from "react-spinners";
import Header from "./JobPostHeader";
const core = require("../../../src/lib/core");

function JobForm() {
  const navigate = useNavigate();
  const locate = useLocation();
  const { name, locationName } = locate.state || {};

  const loc = useLocation();

  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState("");
  const [location, setLocation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState({});
  const [orgDetail, setOrgDetail] = useState({});
  const [jobDetail, setJobDetail] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const firstName = params.get("firstName");
    const lastName = params.get("lastName");
    const email = params.get("email");

    if (firstName) setFirstName(firstName);
    if (lastName) setLastName(lastName);
    if (email) setEmail(email);
  }, []);

  const fetchJob = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.ORGANIZATION_API.GET_SCREENING_QUESTION +
          loc.pathname.split("/").at(-1),
        1,
        {},
      );
      if (json && json.data) {
        setOrgDetail(json?.data?.organization);
        setJobDetail(json?.data);
        setQuestions(json?.data?.screeningQuestion);
      }
    } catch (err) {
      console.log("Error in fetching data from API:", err);
    }
  };

  useEffect(() => {
    fetchJob();
  }, []);

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.pdf|\.doc|\.docx|\.txt)$/i;

    if (!allowedExtensions.exec(file.name)) {
      setError((prev) => ({
        ...prev,
        resume: "Invalid file type. Please select a PDF, DOC, DOCX or TXT.",
      }));
      event.target.value = "";
    } else {
      setError((prev) => ({ ...prev, resume: "" }));
      setResume(file);
    }
  };

  const validatePhone = (value) => {
    const phoneRegex = /^\d{10,14}$/;
    if (!phoneRegex.test(value)) {
      setError((prev) => ({
        ...prev,
        phone:
          "Phone number must be 10 to 14 digits long and contain only numbers",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, phone: "" }));
      return true;
    }
  };

  const validateLinkedIn = (value) => {
    if (!value.includes("linkedin")) {
      setError((prev) => ({
        ...prev,
        linkedInProfile: "Enter a valid LinkedIn profile",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, linkedInProfile: "" }));
      return true;
    }
  };

  const validateAndShowPhoneError = (value) => {
    if (value && !validatePhone(value)) {
      setError((prev) => ({
        ...prev,
        phone:
          "Phone number must be 10 to 12 digits long and contain only numbers",
      }));
    } else {
      setError((prev) => ({ ...prev, phone: "" }));
    }
  };

  const validateAndShowLinkedInError = (value) => {
    if (value && !validateLinkedIn(value)) {
      setError((prev) => ({
        ...prev,
        linkedInProfile: "Enter a valid LinkedIn profile",
      }));
    } else {
      setError((prev) => ({ ...prev, linkedInProfile: "" }));
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    // Allow only numbers, spaces, hyphens, parentheses, and plus signs
    const regex = /^[0-9+\-()\s]*$/;

    if (regex.test(input)) {
      setPhone(input);
    }
    validateAndShowPhoneError(input);
  };

  const handleLinkedInChange = (e) => {
    const { value } = e.target;
    setLinkedInProfile(value);
    validateAndShowLinkedInError(value);
  };

  useEffect(() => {
    if (phone) {
      validateAndShowPhoneError(phone);
    }
  }, [phone]);

  useEffect(() => {
    if (linkedInProfile) {
      validateAndShowLinkedInError(linkedInProfile);
    }
  }, [linkedInProfile]);

  const validateForm = () => {
    let valid = true;
    let newError = {};

    if (!resume) {
      newError.resume = "Resume is required.";
      valid = false;
    }
    if (!firstName) {
      newError.firstName = "First name is required.";
      valid = false;
    }
    if (!email) {
      newError.email = "Email is required.";
      valid = false;
    }
    if (!phone) {
      newError.phone = "Phone number is required.";
      valid = false;
    }
    if (!location) {
      newError.location = "Location is required.";
      valid = false;
    }
    if (!linkedInProfile) {
      newError.linkedInProfile = "Enter LinkedIn Profile.";
      valid = false;
    }

    const isPhoneValid = validatePhone(phone);
    const isLinkedInValid = validateLinkedIn(linkedInProfile);

    if (!isPhoneValid || !isLinkedInValid) {
      valid = false;
    }

    setError(newError);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    try {
      let formData = new FormData();
      formData.append("files", resume);
      formData.append("coverLetter", coverLetter);
      formData.append("jobId", loc.pathname.split("/").at(-1));
      formData.append("location", location);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("linkedInProfile", linkedInProfile);
      formData.append("currentCompany", currentCompany);
      formData.append("screeningQuestion", JSON.stringify(questions));

      await fetch(core.ORGANIZATION_API.POST_CANDIDATE_PROFILE, {
        method: "POST",
        headers: {},
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.data === "Duplicate found") {
            navigate("/job-post/submit", {
              state: { orgDetail, jobDetail, message: result.data },
            });
          } else {
            navigate("/job-post/submit", { state: { orgDetail, jobDetail } });
          }
        });

      setLoading(false);
    } catch (err) {
      console.error("Error submitting the form", err);
      setError((prev) => ({
        ...prev,
        form: "An error occurred while submitting the form.",
      }));
      setLoading(false);
    }
  };

  const [theme, setTheme] = useState("bg-blue-800");
  useEffect(() => {
    if (orgDetail?.theme_color) {
      setTheme(orgDetail?.theme_color);
    }
  }, [orgDetail]);

  return (
    <>
      <div className="h-full overflow-auto bg-gray-300 ">
        <Header orgDetails={orgDetail} />
        <div className="max-w-3xl mx-auto p-8 pt-2 mt-8">
          <h1 className="text-3xl font-bold">{jobDetail?.name}</h1>
          <div className="text-gray-600 mb-8 ">
            <p>{locationName}</p>
          </div>
          <h1 className="text-xl text-gray-800 font-bold mb-6">
            Submit Your Application
          </h1>
          <div className="flex justify-center items-center"></div>
          <form
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded-lg shadow-2xl"
          >
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2 required">
                Resume/CV
              </label>
              <input
                type="file"
                onChange={handleResumeChange}
                accept=".pdf, .doc, .docx, .txt, .rtf"
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full focus:!outline-1 transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
              <p className="text-sm text-gray-600 mt-2">
                (File types: pdf, doc, docx, txt)
              </p>
              {error.resume && (
                <p className="text-red-900 mt-2">{error.resume}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2">
                Cover Letter
              </label>
              <textarea
                rows={5}
                value={coverLetter}
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1 transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
                onChange={(e) => setCoverLetter(e.target.value)}
              />
              {error.coverLetter && (
                <p className="text-red-900 mt-2">{error.coverLetter}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2 required">
                First Name
              </label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
                placeholder="Enter your first name"
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1 transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
              {error.firstName && (
                <p className="text-red-900 mt-2">{error.firstName}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2">
                Last Name
              </label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1 transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2 required">
                Email
              </label>
              <input
                type="email"
                value={email}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className={`mt-1 p-3 border hover:shadow-md  hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1  transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
              {error.email && (
                <p className="text-red-900 mt-2">{error.email}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2 required">
                Phone
              </label>
              <input
                type="tel"
                value={phone}
                required={true}
                onChange={handlePhoneChange}
                placeholder="Enter your phone number"
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1  transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
              {error.phone && (
                <p className="text-red-900 mt-2">{error.phone}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2 required">
                Location (City)
              </label>
              <div className="flex items-center">
                <input
                  type="text"
                  value={location}
                  required={true}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="Enter your location"
                  className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1 transition-colors`}
                  style={{ borderColor: theme, outlineColor: theme }}
                />
              </div>
              {error.location && (
                <p className="text-red-900 mt-2">{error.location}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2 required">
                LinkedIn Profile
              </label>
              <input
                type="url"
                value={linkedInProfile}
                required={true}
                onChange={handleLinkedInChange}
                placeholder="Enter your LinkedIn profile URL"
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1  transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
              {error.linkedInProfile && (
                <p className="text-red-900 mt-2">{error.linkedInProfile}</p>
              )}
            </div>
            <div className="mb-6">
              <label className="block text-gray-800 font-semibold mb-2">
                Current Company
              </label>
              <input
                type="text"
                value={currentCompany}
                onChange={(e) => setCurrentCompany(e.target.value)}
                placeholder="Enter your current company"
                className={`mt-1 p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline rounded-lg w-full placeholder-gray-600 focus:!outline-1  transition-colors`}
                style={{ borderColor: theme, outlineColor: theme }}
              />
            </div>
            {jobDetail?.screeningQuestion?.length > 0 && (
              <ScreeningQuestionForm
                questions={questions}
                setQuestions={setQuestions}
                theme={theme}
              />
            )}
            <div className="mt-8">
              <button
                type="submit"
                disabled={loading}
                className={`w-full text-white hover:text-white hover:no-underline font-medium px-6 py-2 rounded-md`}
                style={{ backgroundColor: theme }}
              >
                {loading ? (
                  <ScaleLoader height={14} color="#ffffff" loading={true} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            {error.form && <p className="text-red-900 mt-2">{error.form}</p>}
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default JobForm;
