import React from "react";
import { async } from "q";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BeatLoader, PropagateLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const core = require("../../lib/core");

export default function FileUploader({
  dataUploaded,
  setDataUploaded,
  disbaleUploadResume,
  setDisableUploadresume,
  getIntDashboard,
  jobIdStatus,
  singleUpload,
  currentPath,
  disbaleUploadcandidate,
  inputdata,
  setInput,
  add_applicant,
  setApplicantData,
  applicantData,
  resumeFile,
  loadingAdd,
  setShowUploader,
}) {
  const { user } = useContext(core.UserContext);
  const [loading, setLoading] = useState(false);

  const loc = useLocation();

  useEffect(() => {
    setInput("jobId", loc.pathname.split("/").at(-1).split("&")[0]);
    setApplicantData({
      ...applicantData,
      ["job"]: loc.pathname.split("/").at(-1).split("&")[0],
    });
  }, [resumeFile]);

  const [resumeFileArray, setResumeFileArray] = useState();
  const [errMsg, setErrorMsg] = useState(null);

  console.log(currentPath);

  useEffect(() => {
    setResumeFileArray(resumeFile);
  }, [resumeFile]);

  const { jobId } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  async function upload_resume() {
    setErrorMsg(null);
    setDisableUploadresume(true);
    try {
      if (resumeFile == "Upload Resume") {
        alert("Please select a resume");
      }
      setLoading(true);
      let formData = new FormData();
      for (let i = 0; i < resumeFileArray.length; i++) {
        formData.append("files", resumeFileArray[i]);
      }
      if (jobIdStatus != false) {
        formData.append("jobId", jobId);
      }

      console.log(formData);
      const token = await core.getData(core.data.ACCESS_TOKEN);
      await fetch(core.RECRUITER_API.POST_MULTIPLE_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.error) {
            setErrorMsg("Failed to upload");

            setDataUploaded(false);
            setDisableUploadresume(false);
            setLoading(false);
            return;
          }
          console.log(data?.data?.link);
          setInput("resumeLink", data?.data?.link);
          setApplicantData({
            ...applicantData,
            ["resumeLink"]: data?.data?.link,
          });
          setLoading(false);
          setDataUploaded(true);
        })
        .catch((err) => {
          setErrorMsg("Failed to upload");
          console.log(err);

          setDataUploaded(false);
          setDisableUploadresume(false);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setErrorMsg(err);
      setDataUploaded(false);
      setDisableUploadresume(false);
      setLoading(false);
    }
  }

  async function upload_single_resume() {
    // setLoading(true)
    var formData = new FormData();
    formData.append("file", resumeFileArray[0]);
    const token = await core.getData(core.data.ACCESS_TOKEN);

    try {
      if (resumeFile == "Upload Resume") {
        alert("Please select a resume");
      }
      setLoading(true);
      await fetch(core.CANDIDATE_API.POST_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setDisableUploadresume(true);
          setLoading(false);
          setDataUploaded(true);
          console.log("success");
          getIntDashboard();
        });
    } catch (err) {
      setDisableUploadresume(false);
      setLoading(false);
      toast.error("there is something error");
      console.log("success");
    }
  }

  const [height, setHeight] = useState("auto");
  const [showall, setShowall] = useState(true);

  const down = () => {
    if (height == "auto") {
      setHeight("60px");
      setShowall(!showall);
    } else {
      setHeight("auto");
      setShowall(!showall);
    }
  };

  console.log(resumeFileArray);

  return (
    <div
      className="col-xs-12 no-padding"
      id="fileUpload"
      style={{ height: height }}
    >
      <form onSubmit={add_applicant}>
        <div className="col-xs-12 no-padding header p-2">
          <button
            className="cancel-button"
            onClick={(e) => setShowUploader(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M24 24L8 8M24 8L8 24"
                stroke="#001B4B"
                strokeWidth="2.8"
                strokeLinecap="round"
              ></path>
            </svg>
          </button>
          <button onClick={down} type="button" className="down-button">
            {showall ? (
              <svg
                x="0px"
                y="0px"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                focusable="false"
              >
                <path
                  fill="currentColor"
                  d="M21.17,5.17L12,14.34l-9.17-9.17L0,8l12,12,12-12z"
                ></path>
              </svg>
            ) : (
              <svg
                x="0px"
                y="0px"
                width="14px"
                height="14px"
                viewBox="0 0 24 24"
                focusable="false"
              >
                <path
                  fill="currentColor"
                  d="M2.83,18.83L12,9.66l9.17,9.17L24,16,12,4,0,16z"
                ></path>
              </svg>
            )}
          </button>
          <span className="col-xs-12">
            Total files: {resumeFileArray?.length ?? 0}
          </span>
          <div className="col-xs-5 no-padding">
            {dataUploaded ? (
              <>
                <span className=""> Uploaded successfully</span>
              </>
            ) : (
              <></>
            )}
            {errMsg && <span className=""> {errMsg}</span>}
          </div>
          <div className="col-xs-4 no-padding">
            {!dataUploaded ? (
              <button
                className=" button"
                disabled={disbaleUploadResume}
                type="button"
                onClick={(e) => {
                  if (singleUpload) {
                    upload_single_resume();
                  } else {
                    upload_resume();
                  }
                }}
              >
                {loading ? (
                  <PropagateLoader
                    color="blue"
                    loading={loading}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Upload"
                )}
              </button>
            ) : (
              <>
                {/* <button
                                className=" button"
                                type="submit"
                                disabled={disbaleUploadcandidate}
                            >
                                {loadingAdd ? <PropagateLoader color="blue" loading={loadingAdd}
                                    size={15}
                                    aria-label="Loading Spinner"
                                    data-testid="loader" /> : " Add candidate"}
                            </button> */}
              </>
            )}
          </div>
        </div>
        <div className="col-xs-12 bottom no-padding">
          {dataUploaded ? (
            <></>
          ) : (
            <>
              {resumeFileArray?.map((item, idx) => (
                <div className="col-xs-12 list-resume" key={idx}>
                  <span className="col-xs-12">{item?.name}</span>
                  <button
                    className="remove-resume-button"
                    type="button"
                    onClick={(e) => {
                      var newArray = [...resumeFileArray];
                      newArray.splice(idx, 1);
                      setResumeFileArray(newArray);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M24 24L8 8M24 8L8 24"
                        stroke="#001B4B"
                        strokeWidth="2.8"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </button>
                </div>
              ))}
            </>
          )}

          {dataUploaded ? (
            <>
              {/* <div className="col-xs-12 no-padding">
                                <div className='col-xs-6'>
                                    <label htmlFor="" className="col-xs-12 no-padding required">
                                        Name
                                    </label>
                                    <input
                                        className="col-xs-12 no-padding input_text name"
                                        type="text"
                                        name="name"
                                        defaultValue={inputdata?.name}
                                        onChange={(e) => {
                                            setInput("name", e.target.value);
                                        }}
                                        required="required"
                                    />
                                </div>
                                <div className='col-xs-6'>
                                    <label htmlFor="" className="col-xs-12 no-padding required">
                                        Email
                                    </label>
                                    <input
                                        className="col-xs-12 no-padding input_text"
                                        type="email"
                                        name="title"
                                        required="required"
                                        defaultValue={inputdata?.email}
                                        onChange={(e) => {
                                            setInput("email", e.target.value);
                                        }}
                                    />
                                </div>
                            </div> */}
            </>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}
