import * as d3 from "d3";
import { useEffect, useRef } from "react";

export default function PercentDonut({
  chartSize,
  radius,
  width,
  cornerRadius,
  percentData,
  fontSize,
  customColor,
  blankColor,
  customTooltipText,
  hidePercent,
  styleClass = "",
}) {
  const svgRef = useRef();
  useEffect(() => {
    var colors = [
      "#b80000",
      "#cc540a",
      "#de862b",
      "#efb353",
      "#ffdf82",
      "#d2cb5c",
      "#a0b83a",
      "#67a51d",
      "#009107",
      "#009107",
    ];
    var data = percentData <= 100 ? [100 - percentData, percentData] : [100, 0];
    d3.selectAll(".tooltipDiv").remove();
    var tooltip;
    // Select SVG and set dimensions
    var svg = d3
      .select(svgRef?.current)
      .on("mouseover", (e) => {
        tooltip = d3
          .select("body")
          .append("div")
          .attr(
            "class",
            "tooltipDiv cursor-pointer absolute bg-white p-3 rounded-lg border border-gray-500 shadow max-w-[600px]"
          )
          .html(() => {
            return customTooltipText ?? "Match percent : " + percentData + "%";
          })
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
      })
      .on("mousemove", (e) => {
        tooltip
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
      })
      .on("mouseout", function () {
        d3.selectAll(".tooltipDiv").remove();
      });

    //   Drawing circle/donut
    svg.selectAll("g").remove();
    // Pie data => angles
    var pie = d3.pie().value((d) => {
      return d;
    });
    //   Pie colors
    var color = d3
      .scaleOrdinal()
      .range([
        blankColor ?? "rgba(51, 52, 142, 0.05)",
        customColor ?? colors[Math.floor(percentData / 10) - 1],
      ]);
    //   Pie dimensions
    var arc = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - width)
      .cornerRadius(cornerRadius);
    // Group with transforms
    var g1 = svg.append("g").classed("graph", true);

    g1.attr(
      "style",
      "transform : translate(" + chartSize / 2 + "px, " + chartSize / 2 + "px)"
    );

    // Group with data
    var g2 = g1
      .selectAll("arc")
      .data(pie(data))
      .enter()
      .append("g")
      .classed("arc", true);

    //   Path with color
    g2.append("path")
      .attr("d", arc)
      .attr("style", () => {
        if (percentData >= 50) return "transform : scaleX(-1)";
      })
      .attr("fill", (d) => {
        return color(d);
      });

    var txtwidth = svg.select("text").attr("width");
  }, [percentData]);
  return (
    <svg
      className={"donutChart " + styleClass}
      width={chartSize}
      height={chartSize}
      ref={svgRef}
    >
      <text
        x={chartSize / 2 - fontSize}
        y={chartSize / 2 + fontSize / 2}
        style={{ fontSize: fontSize }}
      >
        {hidePercent ? "" : percentData + "%"}
      </text>
    </svg>
  );
}
