import { useState, useRef } from "react";
import { useEffect } from "react";
import Header from "../../../components/all/header/Header";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import { Link } from "react-router-dom";
import AppliedJobPost from "../../../components/candidate/dashboard/AppliedJobPost";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
const core = require("../../../lib/core");

const jobPosts = [
  {
    id: 1,
    company: {
      name: "Tech Solutions",
      establishedYear: 2010,
      employees: "200-500",
    },
    position: "Software Engineer",
    location: ["New York, NY"],
    jobType: "Full-time",
    description:
      "We are looking for a skilled software engineer to join our team and work on cutting-edge projects.",
    skills: ["JavaScript", "React", "Node.js"],
    createdAt: "2023-07-25",
  },
  {
    id: 2,
    company: {
      name: "Innovatech",
      establishedYear: 2015,
      employees: "50-200",
    },
    position: "Frontend Developer",
    location: ["San Francisco, CA"],
    jobType: "Part-time",
    description:
      "Seeking a talented frontend developer to create beautiful and responsive web applications.",
    skills: ["HTML", "CSS", "JavaScript", "Vue.js"],
    createdAt: "2023-07-20",
  },
  {
    id: 3,
    company: {
      name: "NextGen Solutions",
      establishedYear: 2012,
      employees: "100-300",
    },
    position: "Backend Developer",
    location: ["Austin, TX"],
    jobType: "Full-time",
    description:
      "Join our team as a backend developer and help us build scalable and efficient server-side applications.",
    skills: ["Node.js", "Express", "MongoDB"],
    createdAt: "2023-07-18",
  },
  {
    id: 4,
    company: {
      name: "Cloud Innovators",
      establishedYear: 2008,
      employees: "500-1000",
    },
    position: "DevOps Engineer",
    location: ["Seattle, WA"],
    jobType: "Full-time",
    description:
      "Looking for a DevOps engineer to automate and streamline our operations and processes.",
    skills: ["AWS", "Docker", "Kubernetes"],
    createdAt: "2023-07-22",
  },
  {
    id: 5,
    company: {
      name: "CyberTech",
      establishedYear: 2016,
      employees: "30-100",
    },
    position: "Security Analyst",
    location: ["Boston, MA"],
    jobType: "Contract",
    description:
      "We need a security analyst to help protect our systems and data from cyber threats.",
    skills: ["Cybersecurity", "Network Security", "SIEM"],
    createdAt: "2023-07-27",
  },
];

export default function AppliedJobs() {
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    fetchAppliedJobs();
  }, []);

  const fetchAppliedJobs = async () => {
    const jobList = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_APPLIED_JOBS,
      1,
      {},
    );
    console.log(jobList);
    if (jobList.data) {
      setAppliedJobs(jobList.data.jobs);
    } else {
      console.log(jobList.error);
    }
  };

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <CandidateSidebar active="Applied" open={false} />
        <div className="w-full">
          <HeaderNew />
          <JobContent
            appliedJobs={appliedJobs}
            fetchAppliedJobs={fetchAppliedJobs}
          />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="page candidate-pages" id="applied-jobs">
  //     <Header showOnlyLogo={false} menu={<Menu />} />
  //     <SideBar />
  //     <main className="main-content">
  //       <div className="page-container col-xs-12 ">
  //         <h1 className="headingText">Applied Jobs</h1>
  //         {appliedJobs.length != 0 && (
  //           <div>
  //             {appliedJobs.map((job, i) => (
  //               <AppliedJobPost
  //                 key={i}
  //                 job={job}
  //                 reload_function={fetchAppliedJobs}
  //               />
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //     </main>
  //   </div>
  // );
}

const JobContent = ({ fetchAppliedJobs, appliedJobs, ...props }) => {
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
      <span className="font-semibold text-[28px] not-italic text-[#16182F]">
        Applied Jobs
      </span>

      <div className="page candidate-pages mt-3 rounded-md" id="applied-jobs">
        <main className="main-content">
          <div className="page-container col-xs-12 ">
            {jobPosts.length != 0 && (
              <div>
                {jobPosts.map((job, i) => (
                  <AppliedJobPost
                    key={i}
                    job={job}
                    reload_function={fetchAppliedJobs}
                  />
                ))}
              </div>
            )}
          </div>
        </main>
      </div>
    </main>
  );
};
