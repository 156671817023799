import React, { useContext, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import TemplateSidebar from "../../components/recruiter/templates/TemplateSidebar";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
const core = require("../../lib/core");

const TemplatePage = () => {
  const nav = useNavigate();
  const loc = useLocation();
  const { id } = useParams();
  const { user } = useContext(core.UserContext);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getAllData();
  }, []);

  // useEffect(() => {
  //   if (
  //     loc.pathname.split("/").filter((path) => path != "")?.length == 2 &&
  //     templates?.length > 0
  //   )
  //     console.log("nav", templates[0]?._id);
  //     nav(templates[0]?._id);
  // }, [templates]);
  // console.log("rendering")

  useEffect(() => {
    if (!id && templates?.length > 0 && !location.pathname.includes("new")) {
      nav(templates[0]?._id);
    }
  }, [id, templates]);
  console.log(id);

  const getAllData = async () => {
    const list = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_ALL_TEMPLATE + user?.id,
      1,
    );
    if (list?.data) {
      setTemplates(list?.data);
    }
  };
  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="template_page">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="Templates"
          CandidateFilter={<TemplateSidebar templates={templates} />}
        />
        <div className="w-[calc(100%-350px)] grow h-screen flex flex-col gap-4 pb-4">
          <HeaderNew />
          <main className="w-[calc(100%-16px)] relative grow flex flex-col gap-4 mx-2 p-4 rounded-lg bg-[#F3F5F9] overflow-y-scroll scrollbar-candidate">
            <Outlet context={{ reloadList: getAllData }} />
          </main>
        </div>
      </div>
    </div>
  );
};

export default TemplatePage;
