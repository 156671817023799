import React from "react";
const core = require("../../../lib/core");

function AssessmentButtons({
  getCookie,
  currentQuestion,
  type,
  selectedAnswers,
  UpdateTestState,
  setCurrentQuestion,
  questions,
  navigation,
  setTestOutput,
  testCases,
  endTest,
  qsnData,
}) {
  function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  const trackResponse = async (question_id, ans, test_case_current = false) => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_TRACK_CANDIDATE_ANSWER +
          getCookie("assessmentId") +
          "/track_response",
        1,
        {
          question_id: question_id,
          answer: ans,
          type: type,
          test_cases: test_case_current,
        },
      );
    } catch (e) {
      console.log(e);
    }
  };
  console.log(
    "navigation.currentPage",
    navigation.currentPage,
    currentQuestion,
  );
  return (
    <div className="button-section col-xs-12 no-padding">
      {qsnData ? (
        <button
          className="previous"
          disabled={navigation.currentPage == 0 && currentQuestion == 0}
          onClick={(e) => {
            if (navigation.currentPage == 1 && currentQuestion == 0) {
            } else {
              if (currentQuestion == 0) {
                if (navigation.currentPage > 0) {
                  navigation?.changePage(navigation.currentPage - 1);
                  setCurrentQuestion();
                }
              } else {
                setCurrentQuestion(currentQuestion - 1);
                if (navigation.allPages[navigation.currentPage] == "coding") {
                  setTestOutput(testCases[currentQuestion - 1] || []);
                }
              }
            }
          }}
        >
          Back
        </button>
      ) : (
        <div></div>
      )}

      <div className="right-button-sec">
        <button
          className="next"
          onClick={() => {
            if (questions?.length == currentQuestion + 1) {
              navigation?.changePage(navigation.currentPage + 1);
              UpdateTestState("");
            } else {
              setCurrentQuestion(currentQuestion + 1);
              if (navigation.allPages[navigation.currentPage] == "coding") {
                setTestOutput(testCases[currentQuestion + 1] || []);
              }
            }

            console.log(
              "currentQuestion",
              questions,
              currentQuestion,
              questions[currentQuestion].id,
            );
            trackResponse(
              questions[currentQuestion].id,
              selectedAnswers[currentQuestion],
              testCases ? testCases[currentQuestion] : false,
            );
            console.log(questions, currentQuestion, type, questions.length);
            if (questions.length == currentQuestion + 1 && type == "coding") {
              console.log("here I am");
              // endTest();
            }
          }}
        >
          {questions.length != currentQuestion + 1 ? "Next" : "Submit"}
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.0247 4.94141L17.0831 9.99974L12.0247 15.0581"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.91666 10L16.9417 10"
              stroke="white"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
        </button>
        {/* )} */}
      </div>
    </div>
  );
}

export default AssessmentButtons;
