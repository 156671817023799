import React from "react";
import { useNavigate } from "react-router-dom";

const JobDescHeader = ({
  jobDetails,
  criteria,
  departmentName,
  description,
  education,
  buttonBgColor,
}) => {
  const navigate = useNavigate();

  if (!jobDetails) {
    return <p>Loading...</p>;
  }

  const { roles, location, job_type, salary, experience } = jobDetails ?? {};
  const benefits = jobDetails?.benefits?.filter((benefit) => benefit.status);
  const bonus = jobDetails?.bonus?.filter((benefit) => benefit.status);

  return (
    <>
      <div className="inline-flex items-center mb-4 bg-green-200 px-3 py-2 rounded-md">
        <svg
          viewBox="0 -1 32 32"
          className="w-5 h-5 text-green-900 mr-2"
          fill="currentColor"
        >
          <g id="SVGRepo_iconCarrier">
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Icon-Set-Filled"
                transform="translate(-206.000000, -206.000000)"
                fill="#4CA"
              >
                <path
                  d="M224,222 C224,221.634 223.895,221.296 223.723,221 L220.277,221 C220.105,221.296 220,221.634 220,222 C220,223.104 220.896,224 222,224 C223.104,224 224,223.104 224,222 L224,222 Z M226,222 C226,224.209 224.209,226 222,226 C219.791,226 218,224.209 218,222 C218,221.652 218.054,221.319 218.141,221 L206,221 L206,232 C206,234.209 207.791,236 210,236 L234,236 C236.209,236 238,234.209 238,232 L238,221 L225.859,221 C225.946,221.319 226,221.652 226,222 L226,222 Z M226,210 L218,210 L218,209 C218,208.448 218.448,208 219,208 L225,208 C225.552,208 226,208.448 226,209 L226,210 L226,210 Z M234,210 L228,210 L228,208 C228,206.896 227.104,206 226,206 L218,206 C216.896,206 216,206.896 216,208 L216,210 L210,210 C207.791,210 206,211.791 206,214 L206,219 L238,219 L238,214 C238,211.791 236.209,210 234,210 L234,210 Z"
                  id="briefcase"
                ></path>
              </g>
            </g>
          </g>
        </svg>

        <span className="text-green-700 font-semibold">Actively hiring</span>
      </div>

      <h1 className="text-2xl font-bold mb-2">{jobDetails?.name}</h1>
      <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-1 mb-4 pb-5">
        <span className="text-gray-700 pr-2">{location?.[0]?.name}</span>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 pb-3">
        {job_type?.length > 0 && (
          <div className="flex">
            <div className="font-semibold">Job type : </div>
            <div className="pl-3">
              {job_type
                ?.filter((type) => type.status)
                ?.map((type) => type.type)
                ?.join(", ")}
            </div>
          </div>
        )}
        {departmentName && (
          <div className="flex">
            <div className="font-semibold">Department : </div>
            <div className="pl-4"> {departmentName} </div>
          </div>
        )}
        {/* <div className="flex">
            <div className="font-semibold">Notice period : </div>
            <div className="pl-4"> Immediate</div>
          </div> */}
        {jobDetails?.workplace && (
          <div className="flex">
            <div className="font-semibold">Workplace type : </div>
            <div className="pl-4"> {jobDetails?.workplace}</div>
          </div>
        )}
        {salary?.min !== undefined && (
          <div className="flex">
            <div className="font-semibold">Expected salary :</div>
            <div className="pl-4">
              {salary.currency ? salary.currency : "₹"}
              {salary.min}
              {salary.max !== undefined
                ? ` - ${salary.currency ? salary.currency : "₹"}${salary.max}`
                : ""}
              {salary.duration ? `  ${salary.duration}` : ""}
            </div>
          </div>
        )}

        {/* <div className="flex">
            <div className="font-semibold">Expected Equity : </div>
            <div className="pl-4"> 2% - 5%</div>
          </div> */}
        {/* {experience?.max && (
            <div className="flex">
              <div className="font-semibold">Experience : </div>
              <div className="pl-4"> {experience?.min} - {experience?.max} years</div>
            </div>
          )} */}
        {experience?.min !== undefined && (
          <div className="flex">
            <div className="font-semibold">Experience :</div>
            <div className="pl-4">
              {experience.min}
              {experience.max !== undefined ? ` - ${experience.max}` : ""} years
            </div>
          </div>
        )}
      </div>

      <hr className="border-gray-400 " />
      <div className=" text-justify text-sm">
        {description && (
          <div className="mb-4 pt-4">
            <h2 className="text-lg font-semibold mb-2">Job Details</h2>
            <p className="mb-2 text-gray-700">{description}</p>
          </div>
        )}
        {roles && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2 pt-4">
              Roles And Responsibilities
            </h2>
            <p className="mb-2 text-gray-700">{roles}</p>
          </div>
        )}
        {education && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2 pt-4">
              Experience And Education
            </h2>
            <p className="mb-2 text-gray-700">{education}</p>
          </div>
        )}
      </div>
      {criteria?.length > 0 && (
        <div className="mb-4">
          <h2 className="text-lg pt-3 font-semibold mb-2">Skills</h2>
          <div className="flex text-gray-700 flex-wrap gap-2 pt-2">
            {criteria?.map((criteriaValue, index) => (
              <div key={index} className="mb-4">
                <span className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg mr-2 mb-2">
                  {criteriaValue}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {bonus?.length > 0 && (
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2 pt-3">
            Do you offer any other compensation pay?
          </h2>
          <div className="flex text-gray-700 flex-wrap gap-2 pt-2">
            {bonus?.map((bonusItem, index) => (
              <span
                key={index}
                className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg"
              >
                {bonusItem?.benifits}
              </span>
            ))}
          </div>
        </div>
      )}
      {benefits?.length > 0 && (
        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2 pt-3">Benefits offered</h2>
          <div className="flex text-gray-700 flex-wrap gap-2 py-2">
            {benefits?.map((benefit, index) => (
              <span
                key={index}
                className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg"
              >
                {benefit?.benifits}
              </span>
            ))}
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center mt-4 py-4 md:pt-10 space-y-4 ">
        <button
          onClick={() =>
            navigate(`/job-post/form/${jobDetails?._id}`, {
              state: {
                name: jobDetails?.name,
                locationName: location?.[0]?.name,
              },
            })
          }
          className={` text-white gap-2 font-semibold hover:font-bold px-4 py-2 rounded-md flex items-center justify-center transition duration-200 ease-in-out transform  hover:scale-105 w-full md:w-[10vw]`}
          style={{ backgroundColor: buttonBgColor }}
        >
          Apply
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default JobDescHeader;
