import React from 'react'
import logoSuccess from '../../assets/recruiter/group-100710.png'
import companyLogo from "../../assets/Enterprise.png"
import { useLocation, useNavigate } from 'react-router-dom'

function JobSuccessfullPage({
    jobId,
    formData
}) {
const location = useLocation()
    const nav = useNavigate()
    return (
        <div className='content-section col-xs-12 no-padding '>
            {/* <img className='logo-success' src={logoSuccess} alt="" /> */}
            {location.pathname.split("/")[1] !== "edit-job" ? (
                <h2>Job created successfully</h2>
            ) : (
                <h2>Job updated successfully</h2>
            )}

            <span className='success-message'>{`${formData?.position} is  ${location.pathname.split("/")[1] !== "edit-job" ? "successfully created":"successfully updated"} `} </span>

            <span>you can view it on jobs page add candidate if you have any resume to the job post</span>
            <div className='view-job'>
                <div className='company-logo'>
                    <img src={companyLogo} alt="" style={{ width: "36px", height: "36px" }} />
                    <div className='text'>
                        <h6>{formData?.position}</h6>
                        <span>{formData?.company}</span>
                    </div>
                </div>
                <button type='button' onClick={e => nav(jobId)}>View</button>
            </div>
        </div>
    )
}

export default JobSuccessfullPage