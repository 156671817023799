import { useEffect, useState } from "react";
import $ from "jquery";
// import EditSkills from "../../analytics/edit/Skills";
import EditEvaSkills from "./EditEvaSkills";
const core = require("../../lib/core");


export default function EditEvaluation({
  analytics,
  classTxt,
  skills,
  setSkills,
}) {
  const [reload, setReload] = useState(0);
  const [barChartWidth, setBarChartWidth] = useState(280);

  useEffect(() => {
    getChartSizes();
  }, [reload, skills]);

  $(window).on("load resize", getChartSizes);

  function showKeySkills(id) {
    setReload(reload + 1);
    if (id) {
      var data = skills?.filter((d) => d.label == id)[0];
    }
  }

  function editSkillValue(slabel, kslabel, value) {
    var skill_arr = skills;
    setReload(reload + 1);
    skill_arr.map((skill, i) => {
      if (skill.label == slabel) {
        skills[i].keySkills.map((ks, it) => {
          if (ks.question == kslabel || ks.label.name == kslabel) {
            ks.value = value;
          }
        });
        let values = skills[i].keySkills.map((d) => d.value);
        skills[i].value = values.reduce((a, b) => a + b) / values.length;
      }
    });
    setSkills(skill_arr);
    showKeySkills(slabel);
  }

  function getChartSizes() {
    setBarChartWidth($("#job-analytics .key-skills .row").width() * 0.77);
  }

  return (
    analytics != null && (
      <div className={classTxt} id="job-analytics">
        <div className="col-xs-12 data-section no-padding">
          <div className="col-xs-12 section-header no-padding">
            <h4 className="col-xs-9 no-padding">Skills</h4>
          </div>
          <EditEvaSkills
            skills={skills}
            setSkills={setSkills}
            update_function={editSkillValue}
            barChartWidth={barChartWidth}
            reload={{ var: reload, func: setReload }}
          />
        </div>
      </div>
    )
  );
}
