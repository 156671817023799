import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/all/header/Header";
import SideBar from "../../components/all/SideBar";
import Menu from "../../components/all/header/Menu";
import { toast } from "react-toastify";
// import FormAutocomplete from "../createJob/Autocomplete";
import FormAutocomplete from "../../components/recruiter/createJob/Autocomplete";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
import Modal from "../../components/modal/Modal";
const core = require("../../lib/core");

const company_data = {
  name: "",
  logo: "",
  size: "",
  about: "",
  links: "",
  location: "",
  industry: "",
  founder: "",
};

export default function CreateCompany2({
  form,
  reload,
  showCompanyPage,
  getCompanies,
  companyDetail,
  status,
  id,
  fetchcompanydetail,
  modalDismiss,
}) {
  const [formJobdetails, setFormJobdetails] = useState(company_data);
  const [about, setAbout] = useState("");
  function setJobdetailsInput(key, value) {
    setFormJobdetails({ ...formJobdetails, [key]: value });
  }

  useEffect(() => {
    if (status === "new") {
      setFormJobdetails(company_data);
    } else {
      console.log(companyDetail, status);
      setFormJobdetails(companyDetail);
    }
  }, [status, companyDetail]);

  const [companyList, setCompanyList] = useState([]);
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");

  useEffect(() => {
    if (status === "new") {
      if (typeof companyDetail?.about == "string") {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(new String("").toString()),
            ),
          ),
        );
      }
    } else {
      if (typeof companyDetail.about == "string") {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(
                new String(
                  companyDetail.about.split("&lt;").join("<"),
                ).toString(),
              ),
            ),
          ),
        );
      }
    }
  }, [status, companyDetail]);

  useEffect(() => {
    setAbout(convertToHTML(editorStateDesc?.getCurrentContent()));
    setEditorValue(editorStateDesc?.getCurrentContent().getPlainText());
  }, [editorStateDesc]);

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  const createNewCompanyPost = async () => {
    const json = await core.API(
      core.API_METHODS.POST,
      core.COMPANY_API.POST_CREATE_COMPANY_PROFILE,
      1,
      {
        name: formJobdetails.name,
        about: about,
        links: formJobdetails.links,
        industry: formJobdetails.industry,
      },
    );
    if (json.data) {
      toast.success("Company added successfully");
      getCompanies("");
    } else {
      toast.error(json.error.message);
      // Error
    }
  };

  const updateNewCompanyPost = async () => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.COMPANY_API.PUT_UPDATE_COMPANY_PROFILE + companyDetail?.id,
      1,
      {
        name: formJobdetails.name,
        about: about,
        links: formJobdetails.links,
        industry: formJobdetails.industry,
      },
    );
    if (json.data) {
      toast.success("Company updated successfully");
      fetchcompanydetail(companyDetail?.id);
    } else {
      toast.error(json.error.message);
      // Error
    }
  };

  const industryTypes = [
    "Accounting",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts and Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies and Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "E-Learning",
    "Education Management",
    "Electrical/Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food & Beverages",
    "Food Production",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness and Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import and Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology and Services",
    "Insurance",
    "International Affairs",
    "International Trade and Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics and Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing and Advertising",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Devices",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining & Metals",
    "Motion Pictures and Film",
    "Museums and Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Non-Profit Organization Management",
    "Oil & Energy",
    "Online Media",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging and Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing",
    "Professional Training & Coaching",
    "Program Development",
    "Public Policy",
    "Public Relations and Communications",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational Facilities and Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security and Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing and Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation and Interpretation",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital & Private Equity",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine and Spirits",
    "Wireless",
    "Writing and Editing",
  ];

  const [searchList, setSearchList] = useState();

  const search = (e) => {
    const filteredItems = industryTypes?.filter((item) =>
      item?.toLowerCase()?.includes(e?.toLowerCase()),
    );
    setSearchList(filteredItems);
  };

  console.log(formJobdetails);
  return (
    <Modal
      heading={"Company details"}
      subheading={"Add below detail to list down the company"}
      modalDismiss={() => modalDismiss()}
      onSave={() => {
        if (formJobdetails.name.length === 0) {
          alert("Please enter company name");
          return;
        }
        if (status === "update") updateNewCompanyPost();
        else createNewCompanyPost();
        modalDismiss();
      }}
    >
      <div className="grid grid-cols-2 gap-4" id="add-company">
        <div className="col-span-2">
          <h3 className="w-full text-sm text-black font-normal not-italic">
            Company Name
          </h3>
          <input
            type="text"
            className="w-full border border-gray-400 px-4 py-2 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            value={formJobdetails?.name}
            required={true}
            onChange={(e) => setJobdetailsInput("name", e.target.value)}
          />
        </div>

        <div className="col-span-1">
          <h3 className="w-full text-sm text-black font-normal not-italic">
            Company url
          </h3>
          <input
            type="text"
            className="w-full border border-gray-400 px-4 py-2 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            placeholder="https://"
            value={formJobdetails.links}
            onChange={(e) => setJobdetailsInput("links", e.target.value)}
          />
        </div>
        <div className="col-span-1">
          <h3 className="w-full text-sm text-black font-normal not-italic">
            Industry
          </h3>

          <FormAutocomplete
            type="text"
            value={formJobdetails?.industry}
            idName="companyIndustryType"
            required={false}
            defaultValue={"Type here"}
            name="industry"
            data={searchList}
            searchFunction={search}
            selectionFunction={(name, value) => {
              setJobdetailsInput(name, value);
            }}
            className={
              "w-full border border-gray-400 px-4 py-2 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
            }
          />
        </div>
        <div className="col-span-2">
          <h3 className="w-full text-sm text-black font-normal not-italic">
            About company
          </h3>
          <Editor
            editorState={editorStateDesc}
            onEditorStateChange={setEditorStateDesc}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </div>
      </div>
    </Modal>
  );
}
