import React, { useContext, useEffect, useState } from "react";
import FormAutocomplete from "../createJob/Autocomplete";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { minAlphaNumericVal } from "./ValidateValues";
import { ScaleLoader } from "react-spinners";
import JobTempData from "../templates/JobTempData";
import JobTemplateDataModal from "./JobTemplateDataModal";
import { useNavigate } from "react-router-dom";
import CompanyModal from "./CompanyModal";
import AnimatedTextFormAutocomplete from "../createJob/AnimatedAutocomplete";
import TemplateDropdownAutocomplete from "./TemplateAutocomplete";
const core = require("../../../lib/core");

function AddCompany({
  setShowGenPage,
  autofillParsedData,
  showGenPage,
  form,
  companyNew,
  isCompanyNew,
  reload,
  showCompanyPage,
  setShowCompanyPage,
  setReviewPage,
  selected,
  locationLength,
}) {
  const [companyList, setCompanyList] = useState([]);
  const [template, setTemplate] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [status, setStatus] = useState("");

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [loading, setLoading] = useState(false);

  const generate_template = async (position) => {
    setStatus("Fetching template...");
    setTemplateList([]);
    setLoading(true);

    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.TEMPLATE_API.POST_FETCH_TEMPLATE,
        1,
        {
          // hiring_info: position,
          job_position: form?.data?.template_name,
        },
      );
      if (json?.data) {
        setTemplateList(json?.data);
        console.log("Template list set:", json?.data);

        setShowModal("templateData");
        console.log("Modal should be shown");
        setStatus("Template fetched");
      } else {
        console.log("No data in response");
        setStatus("No template data received.");
      }
    } catch (error) {
      console.error("Error fetching template:", error);
      setStatus("Error fetching template.");
    } finally {
      setLoading(false);
    }
  };

  const generate_job = async (position) => {
    try {
      setStatus("");
      setLoading(true);

      let payload = {
        company_template: {
          company_name: companyData?.name,
          location: companyData?.location,
          about_company: companyData?.about,
        },
        remote_id: templateList?.remote_id,
        hiring_info: position,
        job_position: form?.data?.template_name,
      };

      if (!templateList?.remote_id) {
        const jsonTemp = await core.API(
          core.API_METHODS.POST,
          core.TEMPLATE_API.POST_FETCH_TEMPLATE,
          1,
          {
            // hiring_info: position,
            job_position: form?.data?.template_name,
          },
        );

        let templateResp = jsonTemp?.data;
        if (templateResp) {
          templateResp.job_position = form?.data?.template_name;
        }
        // payload.job_template = templatel;
        payload.job_template = templateList;
        payload.remote_id = templateResp.remote_id;
        payload.hiring_info = position;
      }

      setStatus("Generating job...");

      const json = await core.API(
        core.API_METHODS.POST,
        core.TEMPLATE_API.POST_GENERATE_JOB_DESCRIPTION,
        1,
        payload,
      );

      if (json?.data) {
        setStatus("Job generated");
        console.log(json?.data);
        setLoading(false);

        setShowGenPage(false);
        autofillParsedData(json?.data);
        setGenerateStatus(false);
        if (
          json?.data?.position?.length > 0 &&
          json?.data?.company?.length > 0 &&
          json?.data?.company?.description > 0 &&
          locationLength > 0
        ) {
          setReviewPage("review");
        } else {
          setWarnText({
            type: "error",
            message:
              "Please provide all required information before publishing the generated job post.",
          });
        }
      } else {
        setGenerateStatus(false);
        setStatus("");
      }

      setLoading(false);
    } catch (error) {
      setStatus("");
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (form?.data?.aboutCompany?.length > 0) {
      if (typeof form?.data?.aboutCompany !== "string") {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(
                new String(
                  form?.data?.aboutCompany
                    ?.split("\n•")
                    .join("\n·")
                    .split("\n·")
                    .map((str) => "<li>" + str + "</li>")
                    .join(" "),
                ),
              ),
            ),
          ),
        );
      } else {
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(new String(getText(form?.data?.aboutCompany))),
            ),
          ),
        );
      }
    }
  }, [reload]);

  useEffect(() => {
    form?.setFunction(
      "aboutCompany",
      convertToHTML(editorStateDesc?.getCurrentContent()),
    );
    setEditorValue(editorStateDesc?.getCurrentContent().getPlainText());
  }, [editorStateDesc]);

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  const [templateData, setTemplateData] = useState({});

  const getMyOrganization = async () => {
    try {
      let response = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_MY_ORGANIZATION,
        1,
      );
      if (response?.data) {
        setCompanyData(response?.data);
        // console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganizationTemplates = async () => {
    try {
      let response = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_ORGANIZATION_TEMPLATES,
        1,
      );
      if (response?.data) {
        console.log(response?.data);
        setTemplateData(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMyOrganization();
    getOrganizationTemplates();
  }, []);

  function searchListCompany(term) {
    try {
      isCompanyNew(true);
      if (term?.length >= 1 && minAlphaNumericVal(term)) {
        setCompanyList(["loader"]);
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.USER_API.GET_ORGANIZATION_LIST + "?name=" + term,
            1,
            {},
          );
          if (response?.data?.length > 0) setCompanyList(response?.data);
          else setCompanyList([]);
        }, 300);
      }
    } catch (err) {
      console.error(err);
      // toast.error(err);
    }
  }

  const [warnText, setWarnText] = useState({
    message:
      "Please provide the company and job title information before generating the job description.",
  });

  const [generateStatus, setGenerateStatus] = useState(false);
  const [selectTemp, setSelectTemp] = useState("");
  const [tempName, setTempName] = useState("");
  console.log("tempName", tempName.aboutCompany);

  const [showModal, setShowModal] = useState(false);

  const nav = useNavigate();

  return (
    <div id="add-company">
      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding text-label required" htmlFor="">
          Company name
        </label>
        <div className="relative">
          <FormAutocomplete
            type="text"
            value={form?.data?.company ?? ""}
            defaultValue={"Search here"}
            idName="company"
            required={true}
            searchType="company"
            setShowCompanyPage={setShowCompanyPage}
            showCompanyPage={showCompanyPage}
            companyNew={companyNew}
            name="company"
            data={companyList}
            searchFunction={searchListCompany}
            selectionFunction={(name, value) => {
              form.setFunction(name, value);
              setCompanyData(companyList.find((item) => item.name === value));
            }}
          />

          <button
            className="absolute top-[65px] right-[16px] hover:text-[#1369E9]  active:text-[#1369E9] font-normal not-italic"
            type="button"
            onClick={() => {
              setShowModal("openCompanyModal");
            }}
          >
            View
          </button>
        </div>

        {!companyNew && (
          <div className="col-xs-12 no-padding warning-section">
            {/* <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g id="Icon" clipPath="url(#clip0_2665_202209)">
                  <path
                    id="Vector"
                    d="M6.99996 4.6665V5.24984M6.99996 6.70817V9.33317M6.99996 12.8332C10.2216 12.8332 12.8333 10.2215 12.8333 6.99984C12.8333 3.77818 10.2216 1.1665 6.99996 1.1665C3.7783 1.1665 1.16663 3.77818 1.16663 6.99984C1.16663 10.2215 3.7783 12.8332 6.99996 12.8332Z"
                    stroke="#FF475D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2665_202209">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Company Details Required: Please fill in the necessary information
              about your company
            </span> */}
            {/* <button onClick={() => {
              setShowCompanyPage(true);
              isCompanyNew(true);
            }}>+ Add new company</button> */}
          </div>
        )}
      </div>

      <>
        {selected != "option1" && (
          <>
            <div>
              <label className=" text-label required" htmlFor="description">
                Select template
                {/* (
                <span
                  onClick={() => {
                    nav("/templates/job/new");
                  }}
                  className="text-xs text-[#1369E9] cursor-pointer required rounded-md px-[4px] py-[4px] "
                >
                  + Add new
                </span>
                ) */}
              </label>
            </div>

            <div className="w-full line  flex items-center relative">
              <div className="col-xs-12 no-padding name ">
                <TemplateDropdownAutocomplete
                  value={form?.data?.template_name}
                  onSelectOption={(name, idx) => {
                    setSelectTemp(idx);
                    setTempName(name);
                  }}
                  selectionFunction={(value) =>
                    form.setFunction("template_name", value)
                  }
                />
              </div>
              {/* {selectTemp?.length > 0 &&
                form?.data?.template_name === tempName && ( */}
              {form?.data?.template_name && (
                <button
                  className="absolute right-[16px] hover:text-[#1369E9]  active:text-[#1369E9] font-normal not-italic"
                  type="button"
                  onClick={() => {
                    generate_template(template);
                    setTemplateData(selectTemp);
                    // setShowModal("templateData");
                  }}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? "" : "View"}
                </button>
              )}
              {/* )} */}
            </div>
            {/* <p className="text-sm text-gray-800 font-semibold text-center py-3">
              Or
            </p> */}
            <p className="mt-2"></p>
            <AnimatedTextFormAutocomplete
              type="text"
              value={template}
              name="template_name"
              searchType="template"
              required={true}
              generate_template={generate_template}
              status={status}
              setStatus={setStatus}
              generate_job={generate_job}
              selectionFunction={(name, value) => setTemplate(value)}
              template={template}
              loading={loading}
              tempName={form?.data?.template_name}
            />
            {templateList?.length > 0 && (
              <div className="w-full compensation-tags flex flex-wrap mt-2">
                {templateList?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center gap-4 mt-2 mr-2"
                    >
                      <button
                        className={`mt-1 rounded-[68px] border border-[#E1E1EE] flex items-center py-2 px-3 
                          ${item?._id == selectTemp ? "!bg-[#00b98d] hover:!bg-[#699c8f]" : "hover:!bg-[#00b98e34]"}`}
                        type="button"
                        onClick={() => {
                          form.setFunction(
                            "template_name",
                            item?.template_name,
                          );
                          setSelectTemp(item?._id);
                          setTempName(item?.template_name);
                        }}
                      >
                        <span
                          className={
                            item?._id == selectTemp
                              ? "text-white"
                              : "text-gray-800"
                          }
                        >
                          {item?.template_name}
                        </span>
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {/* {form?.data?.template_name === tempName &&
              form?.data?.company?.length > 0 &&
              selectTemp?.length > 0 && (
                <button
                  className="bg-[#1369E9] py-2 rounded-[8px] mt-4 px-4 flex items-center disabled:opacity-50 disabled:pointer-events-none justify-center text-white text-base font-normal not-italic leading-[24px]"
                  type="button"
                  onClick={(e) => {
                    generateDescription();
                  }}
                >
                  {generateStatus ? (
                    <ScaleLoader height={20} color="#ffffff" loading={true} />
                  ) : (
                    <>Generate job description1</>
                  )}
                </button>
              )} */}
            <span
              className={
                warnText?.type == "error"
                  ? "inline-block text-xs mt-4 font-semibold text-red-900"
                  : "inline-block text-xs mt-4 font-semibold text-green-900"
              }
            >
              {/* {warnText?.message} */}
            </span>
          </>
        )}
      </>

      {showModal === "openCompanyModal" && (
        <CompanyModal
          data={companyData}
          templateData={templateData}
          modalDismiss={(e) => {
            setShowModal(false);
          }}
        />
      )}

      {showModal === "templateData" && (
        <JobTemplateDataModal
          data={templateList}
          setData={setTemplateList}
          modalDismiss={(e) => {
            setShowModal(false);
          }}
          tempid={selectTemp}
        />
      )}
    </div>
  );
}

export default AddCompany;
