import { createAsyncThunk } from '@reduxjs/toolkit';
const core = require("../../lib/core");
// Async thunk action creators

export const sproutsCandidateService = createAsyncThunk(
    'recruiter/sproutsCandidate',
    async (payload, thunkAPI) => {
        console.log(payload)
       return await core.API(
            core.API_METHODS.GET,
            core.RECRUITER_API.GET_SPROUTS_CANDIDATE + payload,
            1,
            payload
        );
        // Async logic for validate ats token
    }
);
