import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CONTENT } from "../../../lib/constants";
import Modal from "../../../components/modal/Modal";
const core = require("../../../lib/core");

const ApplyForJobModal = ({ id, candidate, modalDismiss }) => {
  const jobList = useSelector((state) => state?.JobExpandSlice?.jobPositions);
  const ref = useRef();
  const [showDropdown, setShowDropDown] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobSelected, setJobSelected] = useState([]);
  const [message, setMessage] = useState({});

  console.log(candidate);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) setShowDropDown(false);
        else setShowDropDown(true);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleSearch = (e) => {
    let jobRemaining = allJobs.filter((job) => !jobSelected.includes(job));
    let term = e.target.value.toLowerCase();
    if (term.length === 0) {
      setFilteredJobs(jobRemaining);
    } else {
      let jobs = jobRemaining.filter((job) =>
        job.name.toLowerCase().includes(term),
      );
      setFilteredJobs(jobs);
    }
  };

  const selectJob = (job) => {
    if (jobSelected.includes(job)) return;
    setJobSelected((prev) => [...prev, job]);
    ref.current.value = "";
    setFilteredJobs(allJobs.filter((job) => !jobSelected.includes(job)));
  };

  const [applied, setApplied] = useState(false);

  const handleSubmit = async () => {
    try {
      const data = jobSelected.map((job) => ({
        jobId: job._id,
        canidate_details_id: candidate,
      }));
      await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_ATTACH_CANDIDATE_JOB,
        1,
        { data },
      );
      setMessage({
        type: "success",
        message: CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.SUCCESS,
      });
      setApplied(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getJobList = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ALL_JOBS_POSITIONS,
        1,
      );
      setAllJobs(response.data);
      setFilteredJobs(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputClick = () => {
    if (allJobs.length === 0) {
      getJobList();
    }
    setFilteredJobs(allJobs.filter((job) => !jobSelected.includes(job)));
    setShowDropDown(true);
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      heading={CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.HEADING}
      subheading={CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.SUBHEADING}
      onSave={handleSubmit}
      dismissOnSave={false}
      noBtns={applied}
    >
      {!applied && (
        <>
          <div className="w-full">
            <input
              className="w-full h-10 px-4 border-gray-500 border-2 rounded-lg"
              ref={ref}
              type="text"
              onChange={handleSearch}
              onClick={handleInputClick}
            />
            {showDropdown && (
              <div className="bg-white shadow w-full top-[50%] left-40 py-1 rounded-lg max-h-[360px] z-[9990] overflow-auto list-none cursor-pointer">
                {filteredJobs.map((job) => (
                  <li
                    key={job._id}
                    className="text-sm py-2 px-3 hover:bg-blue-100 hover:text-blue-800"
                    onClick={() => selectJob(job)}
                  >
                    {job.name}
                  </li>
                ))}
              </div>
            )}
          </div>
          <div className="w-full my-3 flex flex-wrap gap-2">
            {jobSelected.map((job) => (
              <span
                key={job._id}
                className="inline-block px-2 py-1  rounded-lg shadow border border-gray-300 bg-blue-100"
              >
                {job.name}
              </span>
            ))}
          </div>
        </>
      )}

      {Object.entries(message).length > 0 && (
        <span
          className={
            message.type === "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
};

export default ApplyForJobModal;
