import { useEffect, useState } from "react";

export default function EducationForm({ input_function, formState }) {

  const date = new Date()


  const [todaysDate, setTodaysDate] = useState("")
  const [checked, setChecked] = useState(formState?.ongoing ?? false);

  useEffect(() => {
    setTodaysDate(date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate()))
  }, [todaysDate])

  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  return (
    <form className="col-xs-12 no-padding" id="education">
      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding required" htmlFor="school">
          University name
        </label>
        <input
          type="text"
          className="input col-xs-12 col-md-12"
          name="school"
          value={formState?.school}
          required="required"
          placeholder="Type to search... "
          onChange={(event) => {
            input_function("school", event.target.value);
          }}
        />
      </div>
      <div className="col-md-6 no-padding">
        <label htmlFor="degree" className="col-xs-12 no-padding required">
          Degree
        </label>
        <input
          className="col-md-12"
          type="text"
          name="degree"
          value={formState?.degree}
          required="required"
          placeholder="Type to search... "
          onChange={(event) => {
            input_function("degree", event.target.value);
          }}
        />
      </div>
      <div className="col-md-6 no-padding">
        <label htmlFor="major" className="col-xs-12 no-padding required">
          Field of study
        </label>
        <input
          className="col-md-12"
          type="text"
          name="major"
          value={formState?.major}
          required="required"
          onChange={(event) => {
            input_function("major", event.target.value);
          }}
        />
      </div>
      <div className="col-md-6 no-padding">
        <label htmlFor="start" className="col-xs-12 no-padding required">
          Start date
        </label>
        <input
          className="col-md-12"
          type="date"
          name="start"
          value={formState?.start?.split("T")[0]}
          max={todaysDate}
          required="required"
          onChange={(event) => {
            if (new Date(event.target.value) < new Date()) {
              input_function("start", event.target.value);
            }
          }}
        />
      </div>
      <div className="col-md-6 no-padding">
        <label htmlFor="final" className="col-xs-12 no-padding">
          End date
        </label>
        <input
          className="col-xs-12 no-padding"
          type="date"
          name="final"
          required={!checked}
          min={formState?.start}
          max={todaysDate}
          disabled={checked}
          value={formState?.end?.split("T")[0]}
          onChange={(event) => {
            if (new Date(event.target.value) < new Date()) {
              input_function("end", event.target.value);
            }
          }}
        />
        <div className="col-xs-12 no-padding checkbox-input">
          <input
            type="checkbox"
            value={formState?.ongoing}
            onChange={(event) => {
              setChecked(event.target.checked);
              input_function("ongoing", event.target.checked);
            }}
          />
          <label htmlFor="checkbox">I currently work here</label>
        </div>
      </div>
      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding" htmlFor="gpa">
          GPA
        </label>
        <input
          type="text"
          className="input col-xs-12"
          name="gpa"
          value={formState?.gpa}
          placeholder="0.0"
          onChange={(event) => {
            input_function("gpa", event.target.value);
          }}
        />
      </div>
    </form>
  );
}
