import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LoadingSkeleton = () => {
  return (
    <div className="container h-full relative px-5 py-10">
      <SkeletonTheme baseColor="#E1E1EE" highlightColor="#ffffff">
        <span className="min-w-full w-2/3 lg:w-[320px]">
          <Skeleton className="mb-7" height={30} />
        </span>
        <div className="relative w-full h-auto px-4 bg-white rounded-lg  divide-y divide-gray-300">
          {[0, 0, 0].map((_, i) => (
            <div
              key={i}
              className="w-full flex lg:flex-row lg:items-center lg:justify-between flex-col gap-2 py-4"
            >
              <div className="w-full lg:w-1/4">
                <Skeleton className="w-full" height={30} />
              </div>
              <div className="w-2/3 lg:w-1/5">
                <Skeleton className="w-full" height={30} />
              </div>
              <div className="w-2/3 lg:w-1/5">
                <Skeleton className="w-full" height={30} />
              </div>
              <div className="w-1/2 lg:w-1/5 flex">
                <span className="w-1/3 mr-4">
                  <Skeleton className="w-full" height={30} />
                </span>
                <span className="w-1/3 mr-4">
                  <Skeleton className="w-full" height={30} />
                </span>
              </div>
              <Skeleton className="w-full" width={100} height={30} />
            </div>
          ))}
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default LoadingSkeleton;
