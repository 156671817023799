import { useState } from "react";

export default function ResumeForm({ fileName, upload_function }) {
 
  const [resumeName,setResumeName]=useState(fileName)
  return (
    <form className="col-xs-12 no-padding">
      <label className="col-xs-12 no-padding">Upload your recent resume</label>
      <div className="col-xs-12 no-padding upload-section ">
        <input
          title={fileName ? resumeName : 'Select resume'}
          type="file"
          className="upload"
          name="resume"
          onChange={(e) => {upload_function(e.target.files[0])
            setResumeName(e.target.files[0].name)
          }}
        />
        <p>Supported formats: doc, docs, pdf, rtf, upto 2 MB</p>
      </div>
    </form>
  );
}
