import { createAsyncThunk } from '@reduxjs/toolkit';
const core = require('../../lib/core');

export  const fetchDashboardData = createAsyncThunk(
  'dashboardData/fetchDashboardData',
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_DASHBOARD + "?page=" + payload,
        1
    );
      const data = list?.data?.content; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  }
);

export  const fetchDashboardTask = createAsyncThunk(
  'taskList/fetchTaskList',
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_TASKS,
        1
    );
      const data = list?.data?.tasks; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  }
);

