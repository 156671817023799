import React from 'react'
import { useState } from 'react'
import $ from "jquery";
import { useEffect } from 'react';
import Autocomplete from '../../../all/Autocomplete';
const core = require("../../../../lib/core")


var initialState = {
    companyType: "",
    companySize: ""
}

export default function JobPreference({ getProfile, data }) {


    const [jobTypeInput, setJobTypeInput] = useState([])
    const [locationInput, setLocationInput] = useState([])
    const [location, setLocation] = useState("");
    const [locationData, setLocationData] = useState([])
    const [inputdata, setInputdata] = useState(initialState);

    const [workplace, setWorkplace] = useState([])
    const [jobtype, setJobType] = useState([])


    // function setInput(key, value) {
    //     setInputdata({ ...inputdata, [key]: value });
    //   }



    function setInput(key, value) {
        setInputdata({ ...inputdata, [key]: value });
    }

    var jobType = [
        "Full Time",
        "Part Time",
        "Contract",
        "Internship",
        "Volunteer",
        "Other"]

    var company_type = [
        "Private Limited Company",
        "Public Limited Company",
        "Micro Companies",
        "Small Companies",
        "Medium Companies",
        "Limited By Shares",
        "Limited by Guarantee"
    ]

    var company_size = [
        "1-10 employees",
        "11-20 employees",
        "20-50 employees",
        "50-100 employees",
        "100-200 employees",
        "200 & above "
    ]

    useEffect(() => {
        getLocation()
        setJobTypeInput(data.interests)
        setWorkplace(data.workplace)
        setJobType(data.jobType)

        if(data.location!=null){
            setLocationInput(data.location)
        }


    }, [data])
    const [locationList, setLocationList] = useState([])
    const [jobtypeList, setJobtypeList] = useState([])
    async function getLocation() {
        let resp_loc = await core.API(
            core.API_METHODS.GET,
            core.JOB_API.GET_JOB_LOCATIONS,
            1,
            {}
        );
        setLocationData(resp_loc?.data?.locations);

    }


    const [hidejobtype, setHidejobtype] = useState([]);
    const [hideLocation, setHideLocation] = useState([]);




    function searchListLocation(term) {
        try {
            setHideLocation(false);
            if (term?.length < 1) {
                setLocationList(locationData);
            } else {
                setTimeout(async () => {
                    let response = await core.API(
                        core.API_METHODS.GET,
                        core.JOB_API.GET_JOB_LOCATIONS + "?name=" + term,
                        1,
                        {}
                    );

                    if (response?.data["locations"].length > 0) {
                        if (typeof response?.data["locations"][0] === "string") {
                            setLocationList(response?.data["locations"]);
                        } else {
                            setLocationList(response?.data["locations"].map((d) => d.name));
                        }
                    } else {
                        setLocationList(["No Option"]);
                    }
                }, 300);
            }
        } catch (err) {
            setLocationList(locationData);
        }
    }


    function searchListJobs(term) {
        try {
            setHidejobtype(false);
            if (term?.length < 1) {
                // setLocationList(locationData);
            } else {
                setTimeout(async () => {
                    let response = await core.API(
                        core.API_METHODS.GET,
                        core.JOB_API.GET_JOB_TITLES + "?name=" + term,
                        1,
                        {}
                    );
                    if (response?.data["positions"].length > 0) {
                        if (typeof response?.data["positions"][0] === "string") {
                            setJobtypeList(response?.data["positions"]);
                        } else {
                            setJobtypeList(response?.data["positions"].map((d) => d.name));
                        }
                    } else {
                        setJobtypeList(["No Option"]);
                    }
                }, 300);
            }
        } catch (err) {
            //   setLocationList(locationData);
        }
    }


    $(document).on("click", function (e) {
        if ($(e.target).is("#" + "location" + " .auto-complete-input") === false) {
            setHideLocation(true);
        } else {
            setHideLocation(false);
        }
    });

    $(document).on("click", function (e) {
        if ($(e.target).is("#" + "jobtype" + " .auto-complete-input") === false) {
            setHidejobtype(true);
        } else {
            setHidejobtype(false);
        }
    });


    const update_job_prefrence = async () => {
       

        const json = await core.API(
            core.API_METHODS.PUT,
            core.CANDIDATE_API.PUT_UPDATE_PROFILE,
            1,
            {
                companyType: inputdata.companyType,
                companySize: inputdata.companySize,
                salary: "20K-30K $",
                interests: jobTypeInput,
                jobType: jobtype,
                location: locationInput,
                workplace: workplace
            },
        );
        if (json.data) {
            // Success
            getProfile()
            //   submitFileData(json?.data?.company);
        } else {
          
            // Error
        }
    };

    return (
        <form className="col-xs-12 no-padding" id="jobPreference">
            <div className='col-xs-12 no-padding section'>
                <h2 className="col-xs-12 no-padding">
                    Company expectations
                </h2>
                <div className="col-xs-12 col-md-6 no-padding">
                    <label className="col-xs-12 no-padding " htmlFor="school">
                        Company type
                    </label>
                    <select
                        type="text"
                        className="input col-xs-12 col-md-12"
                        name="school"
                        // required="required"
                        placeholder="Type to search... "
                        onChange={e => setInput("companyType", e.target.value)}
                    >
                        <option disabled value="">Add company type</option>
                        {company_type.map(item => (
                            <option value={item} key={item}>{item}</option>
                        ))}
                    </select>

                </div>
                <div className="col-md-6 no-padding">
                    <label htmlFor="degree" className="col-xs-12 no-padding ">
                        Company size
                    </label>
                    <select
                        className="col-md-12"
                        type="text"
                        name="degree"
                        placeholder="Type to search... "
                        onChange={e => setInput("companySize", e.target.value)}
                    >
                        <option disabled value="">Add company size</option>
                        {company_size.map(item => (
                            <option value={item}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* <div className='col-xs-12 no-padding section'>
                <h2 className="col-xs-12 no-padding">
                    Salary expectations
                </h2>
                <div className="col-xs-12 col-md-6 no-padding">
                    <label className="col-xs-12 no-padding required" htmlFor="school">
                        Salary range
                    </label>
                    <input
                        type="text"
                        className="input col-xs-12 col-md-12"
                        name="school"
                        required="required"
                        placeholder="Salary "
                    />


                </div>
                <div className="col-md-6 no-padding">
                    <label htmlFor="degree" className="col-xs-12 no-padding ">
                        label
                    </label>
                    <select
                        className="col-md-12"
                        type="text"
                        name="degree"
                        required="required"
                        placeholder="Type to search... "

                    >
                        <option value="">United states Dollors($)</option>
                    </select>
                </div>
            </div> */}
            <div className='col-xs-12 no-padding section'>
                <h2 className="col-xs-12 no-padding">
                    Job details
                </h2>
                <div className="col-xs-12 col-md-6 no-padding" id="jobtype">
                    <label className="col-xs-12 no-padding " htmlFor="school">
                        Fields of interest
                    </label>
                    <input
                        className="col-md-12 auto-complete-input"
                        type="text"
                        name="degree"
                        placeholder="Type to search... "
                        onChange={(e) => {
                            searchListJobs(e.target.value);
                        }}
                    />
                    {jobtypeList?.length > 0 && (
                        <ul className={hidejobtype ? "auto-complete-list hide" : "auto-complete-list"}>
                            {jobtypeList?.filter(value => !jobTypeInput.includes(value)).map((item, i) => (
                                <li
                                    key={item}
                                    className="match-value"
                                    onClick={() => {
                                        $("#" + "jobtype" + " .auto-complete-input").val("");
                                        setJobTypeInput(
                                            ...jobTypeInput.push(item)
                                        );
                                    }}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="col-xs-12 no-padding tab-section">
                        {jobTypeInput?.map((iter, i) => (

                            <span key={i} className="tag">
                                <span className="text">{iter}</span>
                                <span
                                    className="remove"
                                    onClick={() => {
                                        setJobTypeInput(jobTypeInput.filter((i) => i != iter))
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.25 4.75L4.75 11.25M4.75 4.75L11.25 11.25"
                                            stroke="#001B4B"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>{" "}
                                </span>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="col-md-6 no-padding" id="location">
                    <label htmlFor="degree" className="col-xs-12 no-padding ">
                        Location
                    </label>
                    <input
                        className="col-md-12 auto-complete-input"
                        type="text"
                        name="degree"
                        placeholder="Type to search... "
                        onChange={(e) => {
                            searchListLocation(e.target.value);
                        }}
                    />
                    {locationList?.length > 0 && (
                        <ul className={hideLocation ? "auto-complete-list hide" : "auto-complete-list"}>
                            {locationList?.filter((value) => !locationInput?.includes(value))?.map((item, i) => (
                                <li
                                    key={item}
                                    className="match-value"
                                    onClick={() => {
                                        $("#" + "location" + " .auto-complete-input").val("");
                                        setLocationInput(
                                            ...locationInput.push(item)
                                        );
                                    }}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="col-xs-12 no-padding tab-section">
                        {locationInput?.map((iter, i) => (

                            <span key={i} className="tag">
                                <span className="text">{iter}</span>
                                <span
                                    className="remove"
                                    onClick={() => {
                                        setLocationInput(locationInput.filter((i) => i != iter))
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M11.25 4.75L4.75 11.25M4.75 4.75L11.25 11.25"
                                            stroke="#001B4B"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>{" "}
                                </span>
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            {workplace &&

                <div className='col-xs-12 no-padding section'>
                    <h2 className="col-xs-12 no-padding">
                        Workplace type
                    </h2>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="on-site"
                            required="required"
                            // defaultChecked='checked'
                            defaultChecked={(workplace?.includes("On-Site")) ? true : false}
                            placeholder="Type to search... "
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    workplace.push("On-Site")
                                }
                                else {
                                    setWorkplace(workplace.filter((item) => item != "On-Site"))
                                }
                            }
                            }
                        />
                        <label className=" no-padding " htmlFor="school">
                            On-Site
                        </label>
                    </div>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="school"
                            required="required"
                            placeholder="Type to search... "
                            defaultChecked={(workplace?.includes("Remote")) ? true : false}
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    workplace.push("Remote")
                                }
                                else {
                                    setWorkplace(workplace.filter((item) => item != "Remote"))
                                }
                            }
                            }
                        />
                        <label className=" no-padding " htmlFor="school">
                            Remote
                        </label>
                    </div>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="school"
                            required="required"
                            placeholder="Type to search... "
                            defaultChecked={(workplace?.includes("Hybrid")) ? true : false}
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    workplace.push("Hybrid")
                                }
                                else {
                                    setWorkplace(workplace.filter((item) => item != "Hybrid"))
                                }
                            }
                            }

                        />
                        <label className=" no-padding " htmlFor="school">
                            Hybrid
                        </label>
                    </div>

                </div>
            }
            {jobtype &&
                <div className='col-xs-12 no-padding section'>
                    <h2 className="col-xs-12 no-padding">
                        Job type
                    </h2>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="school"
                            defaultChecked={(jobtype?.includes("Full time")) ? true : false}
                            required="required"
                            placeholder="Type to search... "
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    jobtype.push("Full time")
                                }
                                else {
                                    setJobType(jobtype.filter((item) => item != "Full time"))
                                }
                            }
                            }
                        />
                        <label className=" no-padding " htmlFor="school">
                            Full time
                        </label>
                    </div>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="school"
                            defaultChecked={(jobtype?.includes("Part time")) ? true : false}
                            required="required"
                            placeholder="Type to search... "
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    jobtype.push("Part time")
                                }
                                else {
                                    setJobType(jobtype.filter((item) => item != "Part time"))
                                }
                            }
                            }
                        />
                        <label className=" no-padding " htmlFor="school">
                            Part time
                        </label>
                    </div>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="school"
                            defaultChecked={(jobtype?.includes("Internship")) ? true : false}
                            required="required"
                            placeholder="Type to search... "
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    jobtype.push("Internship")
                                }
                                else {
                                    setJobType(jobtype.filter((item) => item != "Internship"))
                                }
                            }
                            }
                        />
                        <label className=" no-padding " htmlFor="school">
                            Internship
                        </label>
                    </div>
                    <div className="col-xs-12 col-md-6 no-padding">
                        <input
                            type="checkbox"
                            className=" "
                            name="school"
                            defaultChecked={(jobtype?.includes("Contract")) ? true : false}
                            required="required"
                            placeholder="Type to search... "
                            onChange={(e) => {
                                if (e.target.checked == true) {
                                    jobtype.push("Contract")
                                }
                                else {
                                    setJobType(jobtype.filter((item) => item != "Contract"))
                                }
                            }
                            }
                        />
                        <label className=" no-padding " htmlFor="school">
                            Contract
                        </label>
                    </div>

                </div>
            }
            <div className='col-xs-12 no-padding preserence-button-section'>
                <button className='update-preserence-button' onClick={update_job_prefrence} data-dismiss="modal">update</button>
            </div>
        </form>

    )
}
