import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Calmlogo from "../images.jpg";
import Sprouts from "../sprouts.png";

const JobSubmitted = () => {
  const location = useLocation();
  const { orgDetail, jobDetail, message } = location.state || {};
  console.log(orgDetail);
  const [theme, setTheme] = useState("bg-blue-800");

  useEffect(() => {
    if (orgDetail?.theme_color) {
      setTheme(orgDetail?.theme_color);
    }
  }, [orgDetail]);

  return (
    <>
      <div className="h-full overflow-auto bg-gray-100">
        <div className="overflow-auto pt-0 mt-0 bg-white ">
          {/* <div className="max-w-3xl mx-auto p-6 mt-5">
            <div className="flex flex-col md:flex-row justify-between items-center gap-3 mb-4">
              <div className="flex-shrink-0 mb-4 md:mb-0">
                <img
                  src={orgDetail?.logo}
                  alt="Logo"
                  className="ml-2 md:ml-0 w-auto max-w-[120px] rounded-lg"
                />

              </div>
            </div>
          </div> */}
          <div className="flex items-center justify-between py-5">
            <img
              src={orgDetail?.logo}
              alt="Logo"
              className="ml-4 md:ml-24  w-40 h-auto"
            />
            <div
              className={`text-white mr-4 md:mr-20 hover:text-white hover:no-underline font-medium px-6 py-2 rounded-md hover:scale-105 transition duration-300`}
              style={{ backgroundColor: theme }}
            >
              <a
                href="https://www.sproutsai.com/"
                className="hover:no-underline text-white hover:text-white no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Company Website
              </a>
            </div>
          </div>
        </div>
        <div className="bg-gray-300">
          <hr className="border-gray-400" />
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl md:text-3xl font-bold text-center md:text-left pt-8 ">
              {jobDetail?.name}
            </h1>
            {/* <div className="text-gray-600 text-center md:text-left">
              <p>{jobDetail?.location[0]?.name}</p>
            </div> */}
          </div>
          <div className="py-72 pt-20 mx-10 ">
            <div className="flex flex-col items-center justify-center">
              <p className="pt-4 text-base md:text-lg font-semibold text-gray-700 mb-4">
                {message === "Duplicate found"
                  ? "Already Responded!"
                  : "Application Submitted!"}
              </p>
              <button
                onClick={() =>
                  (window.location.href = `/job-post/${orgDetail?.name} `)
                }
                className="mt-4 mx-4 sm:mx-8 sm:px-0 text-white font-semibold hover:font-bold  px-6 py-1 rounded-md flex justify-center items-center w-full md:w-auto md:px-8 md:py-3 transition transform hover:scale-105 focus:outline-none focus:ring-2"
                style={{ backgroundColor: theme }}
              >
                RETURN TO THE MAIN PAGE
              </button>
            </div>
          </div>
        </div>
        <footer className="bg-gray-100 pt-4 pb-4">
          <div className="container mx-auto flex items-center justify-center">
            <p className="text-gray-600 mr-1">Hire with</p>
            <img
              src={Sprouts}
              alt="Sprouts Logo"
              className="w-24 h-auto ml-4"
            />
          </div>
          <div className="text-center mt-2">
            <p className="text-sm">
              Read our{" "}
              <a
                href="https://www.sproutsai.com/privacy"
                className="text-blue-500 hover:underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default JobSubmitted;
