import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from 'react-phone-input-2'
// import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import { AuthStageContext } from "../../lib/context";
import { useNavigate } from "react-router-dom";
import flags from "react-phone-number-input/flags";
import { authStages } from "../../lib/constants";

const core = require("../../lib/core");

export default function Personal() {
  const nav = useNavigate();
  const { user, setUser } = useContext(core.UserContext);
  const { setStage, email, setEmail } = useContext(AuthStageContext);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [organId, setOrganId] = useState("");

  const createNewprofile = async (e) => {
    e.preventDefault();
    const api =
      user?.account == 1
        ? core.RECRUITER_API.POST_CREATE_PROFILE
        : core.CANDIDATE_API.POST_CREATE_PROFILE;
    const stage =
      user?.account == 1 ? authStages.SUBMITTED : authStages.COMPLETED;
    let json = await core.API(core.API_METHODS.POST, api, 1, {
      name: name,
      phone: value,
    });
    json = await core.API(
      core.API_METHODS.PUT,
      core.USER_API.PUT_UPDATE_USER,
      1,
      {
        stage: stage,
      },
    );
    if (json.data) {
      setUser({ ...user, stage: stage });
      setStage(stage);
    }
  };

  const [organisations, setOrganisations] = useState([]);
  const get_organization_list = async () => {
    let json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ORGANIZATION_LIST,
      1,
    );
    if (json.data) {
      setOrganisations(json.data);
    }
  };

  useEffect(() => {
    get_organization_list();
  }, []);
  console.log(user);
  const handleLogOut = () => {
    setEmail("");
    setStage("NEWUSER");
    setUser({});
    core.logout();
    nav("/login");
  };

  return (
    <form className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
      <h1 className="w-full text-black text-4xl font-semibold">
        Add your personal details
      </h1>
      <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
        Add your personal details like your name and contact number, so we can
        send you future updates.
      </h4>
      <input
        required
        type="text"
        placeholder="Your name"
        className="w-full border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <PhoneInput
        required
        international
        countryCallingCodeEditable={false}
        flags={flags}
        className="w-full border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
        defaultCountry="IN"
        placeholder="Your contact number"
        value={value}
        onChange={setValue}
      />

      <select
        name=""
        id=""
        className="w-full border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
        style={{
          appearance: "none", // Remove default arrow icon
          paddingRight: "24px", // Adjust padding to make space for the placeholder
          backgroundImage:
            "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
          backgroundRepeat: "no-repeat",
          backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
          backgroundSize: "16px", // Adjust size of the arrow icon
        }}
        onChange={(e) => {
          setOrganId(e.target.value);
        }}
        defaultValue=""
      >
        <option value={user?.organization}>{user?.organization}</option>
      </select>

      <button
        onClick={createNewprofile}
        className="!btn-primary btn-md h-12 min-w-[120px] disabled:opacity-60 disabled:pointer-events-none"
        disabled={name?.length <= 0 || value?.length <= 3}
      >
        Continue
      </button>
      <h4 className="text-gray-700 text-sm w-full">
        Not <b>{email ?? user?.email}</b>? Login using another email
        <a onClick={handleLogOut} className="text-blue-800 ml-2">
          here
        </a>
      </h4>
    </form>
  );
}
