import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import JobFilter from "./JobFilter";
import { FaMapMarkerAlt, FaBriefcase } from "react-icons/fa";
const core = require("../../../src/lib/core");

const JobOpening = ({ jobList, orgName, orgDetails }) => {
  const [jobListed, setJobListed] = useState([]);
  const [filteredJobList, setFilteredJobList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [workplaces, setWorkplaces] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);

  const nav = useNavigate();

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      setJobListed(jobList);
      setFilteredJobList(jobList);
      const uniqueLocations = Array.from(
        new Set(jobList.flatMap((job) => job.location.map((loc) => loc.name))),
      );
      const uniquePositions = Array.from(
        new Set(jobList.map((job) => job.position)),
      );
      const uniqueWorkplaces = Array.from(
        new Set(jobList.map((job) => job.workplace)),
      );
      const uniqueJobTypes = Array.from(
        new Set(jobList.flatMap((job) => job.job_type.map((jt) => jt.type))),
      );
      setLocations(uniqueLocations);
      setPositions(uniquePositions);
      setWorkplaces(uniqueWorkplaces);
      setJobTypes(uniqueJobTypes);
    }
  }, [jobList]);

  const handleFilter = (filters) => {
    const { location, position, workplace, jobType } = filters;
    const filtered = jobListed.filter((job) => {
      const locationMatch = location
        ? job.location.some((loc) => loc.name === location)
        : true;
      const positionMatch = position ? job.position === position : true;
      const workplaceMatch = workplace ? job.workplace === workplace : true;
      const jobTypeMatch = jobType
        ? job.job_type.some((jt) => jt.type === jobType)
        : true;
      return locationMatch && positionMatch && workplaceMatch && jobTypeMatch;
    });
    setFilteredJobList(filtered);
  };
  return (
    <div className="bg-gray-100 w-full flex flex-col lg:flex-row justify-between items-start px-4 sm:px-[5%] gap-8">
      {/* List of jobs */}
      <div
        className={
          orgDetails?.showFilter
            ? "order-2 lg:order-1 w-full lg:w-2/3 grid grid-cols-1 gap-6"
            : "w-full order-2 grid grid-cols-1 gap-6"
        }
      >
        {filteredJobList.map((job) => (
          <React.Fragment key={job.id}>
            <div className="bg-white rounded-lg overflow-hidden shadow-PipelineCardsShadow flex items-center justify-between px-5 py-4">
              <div className="flex flex-col gap-2 items-start">
                <h3 className="text-gray-700 text-base font-bold">
                  {job?.position}
                </h3>
                {job?.job_type.length > 0 || job?.workplace ? (
                  <>
                    <p className="text-sm flex flex-row gap-2 text-gray-700">
                      <FaBriefcase className="mt-1 text-gray-700" />
                      {job?.job_type.length > 0
                        ? job?.job_type.map((item) => item?.type).join(",")
                        : ""}
                      {job?.job_type.length > 0 && job.workplace ? " • " : ""}
                      {job?.workplace ?? ""}
                    </p>
                  </>
                ) : null}
                {job?.location?.length > 0 ? (
                  <p className="text-sm flex flex-row gap-2 text-gray-600">
                    <FaMapMarkerAlt className="mt-1 text-gray-600" />
                    {job?.location?.length > 0
                      ? job?.location
                          ?.map((item) => item?.name ?? item)
                          ?.join(",")
                      : ""}
                  </p>
                ) : null}
              </div>
              <button
                onClick={() => nav(`/job-post/details/${job?.id}`)}
                className="flex flex-row gap-2 text-white hover:text-white hover:no-underline border border-white outline outline-0 hover:outline-1 font-medium px-6 py-2 rounded-md"
                style={{
                  backgroundColor: orgDetails?.theme_color,
                  outlineColor: orgDetails?.theme_color,
                }}
              >
                APPLY
              </button>
            </div>
          </React.Fragment>
        ))}
      </div>
      {/* Job filters */}
      {orgDetails?.showFilter ? (
        <div className="order-1 lg:order-2 w-full lg:w-1/3">
          <div
            className="p-4 border mb-6 shadow-md bg-defaultBg rounded-lg"
            style={{ boxShadow: orgDetails?.theme_color }}
          >
            <JobFilter
              onFilter={handleFilter}
              locations={locations}
              positions={positions}
              workplaces={workplaces}
              jobTypes={jobTypes}
              orgDetails={orgDetails}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default JobOpening;
