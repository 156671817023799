import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Landing from "../../../components/auth/Landing";
import BeatLoader from "react-spinners/BeatLoader";
const core = require("../../../lib/core");

function InviteSignUp() {
  const loc = useLocation();

  console.log(loc);
  const nav = useNavigate();
  const [email, setEmail] = useState(loc.state.split("=").at(-1));
  const [loginText, setLoginText] = useState("Continue");
  const { user, setUser } = useContext(core.UserContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Google Auth
    const initGoogleClient = () => {
      gapi.client.init({
        clientId: core.googleClientId,
        scope: "",
      });
    };

    gapi.load("client:auth2", initGoogleClient);
  }, []);

  // Google Auth Success
  const onSuccess = async (res) => {
    setLoading(true);
    const gid = res?.googleId;
    const email = res?.profileObj?.email;
    console.log(gid, email);
    let json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_GOOGLE_AUTH,
      0,
      {
        email: email,
        gid: gid,
      },
    );
    console.log(json);
    if (json.data) {
      core.setData(core.data.ACCESS_TOKEN, json.data.token);
      core.setData(core.data.UID, json.data.user);
      setLoading(false);
      if (json.data?.user?.new) {
        nav("/set-account");
      } else {
        nav("/");
      }

      window.location.reload();
    } else {
      setLoading(false);
    }
  };

  // Google Auth Failure
  const onFailure = (err) => {
    // setError(true)
    console.log(err?.message);
    // setLoading(false)
    setLoginText("Continue");
  };

  async function handleSignUp(e) {
    e.preventDefault();
    setLoading(true);
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_SEND_EMAIL_OTP,
      0,
      {
        email: email,
      },
    );
    if (json.data) {
      setUser({ email: email });
      setLoading(true);
      nav("/invite-enter-code");
    } else {
      setError(true);
      setErrorMessage(json?.error?.message);
      setLoading(false);
    }
  }

  return (
    process.env.REACT_APP_ENV != "production" && (
      <div className="page auth-pages" id="login">
        <main className="main-content">
          <div className="left-section col-xs-6 no-padding">
            <Landing />
          </div>
          <form
            onSubmit={handleSignUp}
            className="sub-container no-padding col-xs-6 "
          >
            <img
              src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/logo.png"
              className="img-responsive logo"
              alt="SproutsAI"
            />
            <h1 className="col-xs-12 no-padding headingText">
              Let's get started!
            </h1>
            <h4 className="col-xs-12 no-padding subHeadingText">
              Enter your email to create your account
            </h4>
            <div className="input-wrapper col-xs-12 no-padding">
              <input
                type="email"
                placeholder="Email"
                className="email input"
                value={email}
                required
                onChange={(event) => {
                  setEmail(() => event.target.value);
                }}
              />
            </div>
            <div
              className="col-xs-12 no-padding"
              style={{ marginTop: 10, display: error ? "flex" : "none" }}
            >
              <span style={{ color: "red", fontSize: 14, textAlign: "left" }}>
                {errorMessage}
              </span>
            </div>
            <div className="input-wrapper col-xs-12 no-padding">
              <button className="signInBtn" type="submit">
                <span className="label">{loginText}</span>
              </button>
            </div>
            <h4
              className="policy_text"
              style={{
                fontSize: 14,
                marginTop: 20,
                marginBottom: 20,
                color: "#767676",
              }}
            >
              By clicking Continue, you are agreeing to the SproutsAI user
              agreement, privacy, and cookie policy.
            </h4>
            {/* <div className="line col-xs-12 no-padding">
                <span>Or</span>
              </div> */}
            {/* <div className="buttons-section">
                <GoogleLogin
                  className="google-button"
                  type="submit"
                  clientId={core.googleClientId}
                  buttonText="Sign up with Google"
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                />
              </div> */}
            <h4 className="registerOption col-xs-12 no-padding">
              Already have an account?
              <Link to="/login" className="joinUsBtn">
                Log in
              </Link>
            </h4>
          </form>
        </main>
      </div>
    )
  );
}

export default InviteSignUp;
