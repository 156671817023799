import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchJobList, fetchJobDetails, fetchQuestionsFroms3,fetchCandidatesList,fetchPipeline, fetchReport ,fetchDescription, fetchJobPostList, fetchAssessment,fetchResumesList, fetchJobPositionList } from "../services";

const core = require("../../lib/core");

const initialState = {
  isLoading: false,
  candidateList: null,
  resumeList: null,
  jobList:null,
  jobPositions:[],
  candidates:null,
  pipeline:[],
  profile: null,
  analytics: null,
  assessment:[],
  description: null,
  overallEvalScore: {
    matchScore: 0,
    intevalScore: 0,
    criteriaScore: 0,
  },
  questions: [],
};

const jobExpandSlice = createSlice({
  name: "candidateProfile",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobList.fulfilled, (state, action) => {
        state.candidateList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchJobList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchJobPostList.fulfilled, (state, action) => {
        state.jobList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchJobPostList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchJobPositionList.pending, (state, action) => {
        state.jobPositions = null;
        state.isLoading = true;
      })
      .addCase(fetchJobPositionList.fulfilled, (state, action) => {
        state.jobPositions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchJobPositionList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchJobDetails.pending, (state, action) => {
        state.profile = null;
        state.isLoading = true;
      })
      .addCase(fetchJobDetails.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchJobDetails.rejected, (state, action) => {
        state.profile = null;
        state.isLoading = false;
      })
      .addCase(fetchAssessment.pending, (state, action) => {
        state.assessment = null;
        state.isLoading = true;
      })
      .addCase(fetchAssessment.fulfilled, (state, action) => {
        state.assessment = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAssessment.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchCandidatesList.pending, (state, action) => {
        state.candidates = null;
        state.isLoading = true;
      })
      .addCase(fetchCandidatesList.fulfilled, (state, action) => {
        state.candidates = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchResumesList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchResumesList.pending, (state, action) => {
        state.resumeList = null;
        state.isLoading = true;
      })
      .addCase(fetchResumesList.fulfilled, (state, action) => {
        state.resumeList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCandidatesList.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchPipeline.pending, (state, action) => {
        state.pipeline = null;
        state.isLoading = true;})
      .addCase(fetchPipeline.fulfilled, (state, action) => {
        state.pipeline = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPipeline.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchDescription.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchDescription.fulfilled, (state, action) => {
        state.description = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDescription.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchReport.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchReport.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchQuestionsFroms3.pending, (state, action) => {
        state.questions = [];
        state.isLoading = true;
      })
      .addCase(fetchQuestionsFroms3.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuestionsFroms3.rejected, (state, action) => {
        state.isLoading = false;
        state.questions = [];
      });
  },
});

// Export actions
export const {} = jobExpandSlice.actions;

export default jobExpandSlice.reducer;
