import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditButton from "../../assets/Edit";
import EducationForm from "./forms/Education";
import ExperienceForm from "./forms/Experience";
import LinksForm from "./forms/Links";
import PersonalForm from "./forms/Personal";
import ProjectForm from "./forms/Project";
import ResumeForm from "./forms/Resume";
import Skills from "./forms/Skills";
import ResumeAnalytics from "./ResumeAnalytics";
import Avatar from "../../assets/candidate/Avatar.png";
import ResumeImg from "../../assets/candidate/Resume .png";
import Modal from "../../modal/Modal";

const core = require("../../../lib/core");

const experience_data = {
  company: "",
  position: "",
  start: "",
  end: "",
  ongoing: "",
  description: "",
};
const education_data = {
  school: "",
  degree: "",
  major: "",
  start: "",
  end: "",
  ongoing: "",
  description: "",
  GPA: "",
};
const project_data = {
  name: "",
  link: "",
  description: "",
};
export default function Profile() {
  // Var for User
  const { user } = useContext(core.UserContext);
  // Var for Profile data
  const [profile, setProfile] = useState();
  const [resumeFile, setResumeFile] = useState("Upload Resume");
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [resumeBtnName, setResumeBtnName] = useState("Upload Resume");
  const [imgFile, setImgFile] = useState();
  const [img, setImg] = useState(user?.pic);
  const [urls, setUrls] = useState([]);
  const [education, setEducation] = useState();
  const [skill, setSkill] = useState([]);
  const [tool, setTool] = useState([]);
  const [experience, setExperience] = useState();
  const [project, setProject] = useState();

  //  Var for CRUD operation
  const [selection, setSelection] = useState();
  const [formState, setFormState] = useState({});
  const [criteriaSkilArray, setCriteriaSkilArray] = useState([]);
  const [experienceArr, setExperienceArr] = useState([experience_data]);
  const [projectArr, setProjectArr] = useState([project_data]);
  const [educationArr, setEducationArr] = useState([education_data]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    let response = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_PROFILE_DETAILS,
      1,
      {},
    );
    setProfile(response?.data);
    setSkills(response?.data.skills);
    setFormState(response?.data);
    if (response?.data?.experience.length > 0) {
      setExperienceArr(response?.data?.experience);
    } else {
      setExperienceArr([
        {
          company: "",
          position: "",
          start: "",
          end: "",
          ongoing: "",
          description: "",
        },
      ]);
    }

    if (response?.data?.education.length > 0) {
      setEducationArr(response?.data?.education);
    } else {
      setEducationArr([
        {
          school: "",
          degree: "",
          major: "",
          start: "",
          end: "",
          ongoing: "",
          description: "",
          GPA: "",
        },
      ]);
    }
    if (response?.data?.projects.length > 0) {
      setProjectArr(response?.data?.projects);
    } else {
      setProjectArr([
        {
          name: "",
          link: "",
          description: "",
        },
      ]);
    }
    // setCriteriaSkilArray(response?.data?.skills);
    setTool(response?.data?.tools);
  }
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }
  function onFileChange(file) {
    try {
      setResumeBtnName("Loading");
      setResumeFile(file);
      setResumeFileName(file.name);
      setResumeBtnName("Change Resume");
    } catch (err) {
      setResumeBtnName("Upload Resume");
    }
  }

  const [resumeLink, setResumeLink] = useState("");
  const [resumeData, setResumeData] = useState("");

  async function upload_resume() {
    var formData = new FormData();
    formData.append("file", resumeFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);

    await fetch(core.CANDIDATE_API.POST_UPLOAD_RESUME, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        uid: user?.id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setResumeLink(data?.data?.link);
      });

    await fetch("https://parser.sproutsai.com/resume_parser", {
      method: "POST",
      body: formData,
      enctype: "multipart/form-data",
    })
      .then((res) => res.json())
      .then((data) => {
        setResumeData(data);
        upload_resume_data(resumeLink, data);
      });
  }

  async function upload_resume_data(resumeLink, resumeData) {
    await core
      .API(core.API_METHODS.POST, core.CANDIDATE_API.POST_UPDATE_RESUME, 1, {
        link: resumeLink,
        resume: resumeData,
      })
      .then((res) => res.json())
      .then((data) => {});
  }

  function onImgChange(file) {
    setImgFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImg(reader.result);
    };
    reader.readAsDataURL(file);
  }
  async function upload_picture() {
    let formData = new FormData();
    formData.append("file", imgFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);
    fetch(core.USER_API.POST_UPLOAD_USER_PIC, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        uid: user?.id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {});
  }
  function setEducationInput(key, value) {
    setEducation({ ...education, [key]: value });
  }
  function setExperienceInput(key, value) {
    setExperience({ ...experience, [key]: value });
  }
  function setProjectInput(key, value) {
    setProject({ ...project, [key]: value });
  }

  async function add_skills(e) {
    e.preventDefault();
    try {
      formState.skills = criteriaSkilArray;
      update_profile();
    } catch (err) {}
  }
  async function update_experience() {
    try {
      let response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_EXPERIENCE + experience?._id,
        1,
        experience,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }
  async function add_experience() {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_EXPERIENCE,
        1,
        experience,
      );
      window.location.reload();
    } catch (err) {}
  }
  async function remove_experience() {
    try {
      let response = await core.API(
        core.API_METHODS.DELETE,
        core.USER_API.DELETE_EXPERIENCE + experience?._id,
        1,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }
  async function update_education() {
    try {
      let response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_EDUCATION + education?._id,
        1,
        education,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }
  async function add_education() {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_EDUCATION,
        1,
        education,
      );
      window.location.reload();
    } catch (err) {}
  }
  async function remove_education() {
    try {
      let response = await core.API(
        core.API_METHODS.DELETE,
        core.USER_API.DELETE_EDUCATION + education?._id,
        1,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }

  async function add_project() {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_PROJECT,
        1,
        project,
      );
      // window.location.reload();
    } catch (err) {}
  }

  async function update_project() {
    try {
      let response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_PROJECT + project?._id,
        1,
        project,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }

  async function remove_project() {
    try {
      let response = await core.API(
        core.API_METHODS.DELETE,
        core.USER_API.DELETE_PROJECT + project?._id,
        1,
      );
      toast.success(response.message);
      window.location.reload();
    } catch (err) {
      toast.error(err);
    }
  }

  async function update_profile(e) {
    let response = await core.API(
      core.API_METHODS.PUT,
      core.CANDIDATE_API.PUT_UPDATE_PROFILE,
      1,
      {
        skills: skills,
        education: educationArr,
        experience: experienceArr,
        projects: projectArr,
      },
    );
    getProfile();
    setEditSkills(false);
    // window.location.reload();
  }

  async function update_personal_profile() {
    let response = await core.API(
      core.API_METHODS.PUT,
      core.CANDIDATE_API.PUT_UPDATE_PROFILE,
      1,
      formState,
    );
    getProfile();
  }

  const [reload, setReload] = useState(0);
  // edit the experience data get from resume parser
  const handleChange = (index, key, value) => {
    setExperienceArr((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index][key] = value;
      return updatedExperience;
    });
  };

  const handleChangeEdu = (index, key, value) => {
    setEducationArr((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index][key] = value;
      return updatedEducation;
    });
  };
  const handleChangeProject = (index, key, value) => {
    setProjectArr((prevProject) => {
      const updatedProject = [...prevProject];
      updatedProject[index][key] = value;
      return updatedProject;
    });
  };

  const [view, setView] = useState("Details");
  const [editSkills, setEditSkills] = useState(false);

  function editEnabled() {
    setEditSkills(true);
  }

  const updateSkills = () => {};

  // to add new experience
  const addEmptyExp = () => {
    setExperienceArr([
      ...experienceArr,
      {
        company: "",
        position: "",
        start: "",
        end: "",
        ongoing: "",
        description: "",
      },
    ]);
    setReload(reload + 1);
  };
  // to add new education
  const addEmptyEdu = () => {
    setEducationArr([
      ...educationArr,
      {
        school: "",
        degree: "",
        major: "",
        start: "",
        end: "",
        ongoing: "",
        description: "",
        GPA: "",
      },
    ]);
    setReload(reload + 1);
  };
  const addEmptyProject = () => {
    setProjectArr([
      ...projectArr,
      {
        name: "",
        link: "",
        description: "",
      },
    ]);
    setReload(reload + 1);
  };

  return (
    <div id="edit-profile">
      <div className=" col-xs-12 no-padding personal-section data-section">
        <div className="col-xs-2 no-padding profile-pic">
          <img src={Avatar} className="img-responsive" />
        </div>
        <div className="col-xs-10 no-padding personal-info">
          <div className="col-xs-12 no-padding">
            <span className="profile-name">{profile?.name}</span>
            <button
              className="edit"
              data-tooltip="Edit"
              data-toggle="modal"
              data-target="#profile-modal"
            >
              <EditButton />
            </button>
          </div>
          <span className="profile-position">{profile?.title}</span>
          <button className="resume-modal-button no-padding">
            {profile?.resume ? (
              <>
                <span>
                  <a href={profile?.resume} target="_blank" rel="noreferrer">
                    <img src={ResumeImg} className="img-responsive icon" />
                  </a>
                  <h4 data-toggle="modal" data-target="#upload-resume">
                    Resume
                  </h4>
                </span>
              </>
            ) : (
              <>{"Upload Resume"}</>
            )}
          </button>
          <div id="upload-resume" className="modal fade " role={"dialog"}>
            <Modal
              form={
                <ResumeForm
                  fileName={profile?.resume ?? resumeFileName}
                  upload_function={onFileChange}
                />
              }
              onSave={upload_resume}
            />
          </div>
          <span className="profile-about col-xs-11 no-padding">
            {profile?.bio}
          </span>
        </div>
        <div id="profile-modal" className="modal fade " role={"dialog"}>
          <Modal
            form={
              <PersonalForm
                image_link={user?.pic}
                input_function={setInput}
                form_state={formState}
              />
            }
            onSave={update_personal_profile}
          />
        </div>
      </div>

      <div className="col-xs-12 no-padding toggle-section">
        {!editSkills ? (
          <button
            className="edit-button"
            data-tooltip="Edit"
            onClick={() => {
              editEnabled();
            }}
          >
            <EditButton />
          </button>
        ) : (
          <>
            <button
              className="done-button edit-button"
              data-tooltip="Done"
              onClick={() => {
                update_profile();
              }}
            >
              <svg
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 122.881 89.842"
              >
                <path
                  d="M1.232,55.541c-1.533-1.388-1.652-3.756-0.265-5.289c1.388-1.534,3.756-1.652,5.29-0.265l34.053,30.878l76.099-79.699 c1.429-1.501,3.804-1.561,5.305-0.132c1.502,1.428,1.561,3.803,0.133,5.305L43.223,88.683l-0.005-0.005 c-1.396,1.468-3.716,1.563-5.227,0.196L1.232,55.541L1.232,55.541z"
                  fill="#001B4B"
                  stroke="#001B4B"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <button
              className="revert-button edit-button"
              data-tooltip="Revert"
              onClick={() => {
                getProfile();
                setEditSkills(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="4 4 24 24"
                fill="none"
              >
                <path
                  d="M24 24L8 8M24 8L8 24"
                  stroke="#001B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
              </svg>
            </button>
          </>
        )}

        {/* <div className="toggle-button ">
          <button
            className={
              view == "Details" ? "toggle-button active" : "toggle-button"
            }
            onClick={() => setView("Details")}
          >
            Details
          </button>
          <button
            className={
              view == "Analytics" ? "toggle-button active" : "toggle-button "
            }
            onClick={() => setView("Analytics")}
          >
            Analytics
          </button>
          <button
            className={
              view == "Both" ? "toggle-button active" : "toggle-button "
            }
            onClick={() => setView("Both")}
          >
            Both
          </button>
        </div> */}
      </div>

      {view != "Analytics" && (
        <div
          className={
            view == "Both"
              ? "col-xs-7 details-section no-padding"
              : "col-xs-12 details-section no-padding"
          }
        >
          {/* <div className="col-xs-12 no-padding skills-section data-section">
          <div className="col-xs-12 section-header no-padding">
            <h4>Skills</h4>

            {(profile?.skills?.length > 0 || profile?.tools?.length > 0) &&

              <button
                className="add-button"
                data-toggle="modal"
                data-target="#skills-modal"
                data-tooltip="Add"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 20V12M12 12V4M12 12H20M12 12H4"
                    stroke="#363636"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            }

            <div id="skills-modal" className="modal fade " role={"dialog"}>
              <Modal
                form={
                  <Skills
                    skillArr={skill}
                    setSkillArr={setSkill}
                    toolArr={tool}
                    setToolArr={setTool}
                    criteriaSkilArray={criteriaSkilArray}
                    setCriteriaSkilArray={setCriteriaSkilArray}
                  />
                }
                onSave={add_skills}
              />
            </div>
          </div>
          <div className="section-data col-xs-12 no-padding">
            {criteriaSkilArray?.length > 0 || tool?.length > 0 ? (
              <>
                {criteriaSkilArray != null && (
                  <div className="col-xs-12 col-md-6 no-padding skills">
                    <h6>Skills</h6>
                    {criteriaSkilArray?.map((item, itr) => (
                      <>
                        <h5>{item?.label}</h5>
                        {item?.keySkills?.map((skills) => (
                          <div key={itr} className="tag">
                            <span className="text">{skills?.label}</span>
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                )}

                {tool != null && (
                  <div className="col-xs-12 col-md-6 no-padding tools">
                    <h6>Tool</h6>
                    {tool.map((item, itr) => (
                      <div key={itr} className="tag">
                        <span className="text">{item.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              // <EmptyState text={"No skills or tools added yet"} />
              <div className="no-data">
                <button
                  data-toggle="modal"
                  data-target="#skills-modal"
                >
                  {"+ "} {" Add skill & tool"}
                </button>
              </div>
            )}
          </div>
        </div> */}

          <div className="col-xs-12 no-padding experience-section data-section">
            <div className="col-xs-12 section-header no-padding ">
              <h4>Work Experience</h4>

              {editSkills && (
                <button
                  className="add-button"
                  onClick={addEmptyExp}
                  // data-toggle="modal"
                  // data-target="#exp-modal"
                  data-tooltip="Add"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 20V12M12 12V4M12 12H20M12 12H4"
                      stroke="#363636"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              )}
              <div id="exp-modal" className="modal fade " role={"dialog"}>
                <Modal
                  form={
                    <ExperienceForm
                      input_function={setExperienceInput}
                      formState={experience}
                    />
                  }
                  onSave={add_experience}
                  onRemove={() => {
                    window.location.reload();
                  }}
                />
              </div>
            </div>

            {editSkills ? (
              <>
                {experienceArr.map((exp, index) => (
                  <div key={index} className="edit-candidate">
                    <h5 className="col-xs-12 no-padding mt-[20px]">
                      <span>Experience {index + 1}</span>
                      <span
                        onClick={() => {
                          let tempvar = experienceArr;
                          tempvar.splice(index, 1);
                          setExperienceArr(tempvar);
                          setReload(reload + 1);
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className="w-[18px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </h5>
                    <label className="col-xs-12 no-padding">
                      Company Name:
                    </label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={exp["company"]}
                      onChange={(event) =>
                        handleChange(index, "company", event.target.value)
                      }
                    />

                    <label className="col-xs-12 no-padding">
                      Job Position:
                    </label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={exp["position"]}
                      onChange={(event) =>
                        handleChange(index, "position", event.target.value)
                      }
                    />

                    <div className="col-md-6 start ">
                      <label
                        htmlFor="start"
                        className="col-xs-12 no-padding required"
                      >
                        Start date
                      </label>
                      <input
                        className="col-md-12"
                        type="date"
                        name="start"
                        required="required"
                        onChange={(event) =>
                          handleChange(index, "start", event.target.value)
                        }
                        // max={todaysDate}
                        value={exp?.start?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("start", event.target.value);
                        //   }
                        // }}
                      />
                    </div>
                    <div className="col-md-6 end">
                      <label htmlFor="end" className="col-xs-12 no-padding">
                        End date
                      </label>
                      <input
                        className="col-xs-12 no-padding"
                        type="date"
                        name="final"
                        onChange={(event) =>
                          handleChange(index, "end", event.target.value)
                        }
                        // required={!checked}
                        // disabled={checked}
                        min={formState?.start}
                        // max={todaysDate}
                        value={exp?.end?.split("T")[0]}
                        // value={formState?.end?.split("T")[0] ?? formState?.eend?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("eend", event.target.value);
                        //     input_function("end", event.target.value);
                        //   }
                        // }}
                      />
                      {/* <div className="col-xs-12 no-padding checkbox-input">
          <input
            type="checkbox"
            value={formState?.ongoing}
            onChange={(event) => {
              setChecked(event.target.checked);
              input_function("ongoing", event.target.checked);
            }}
          />
          <label htmlFor="checkbox">I currently work here</label>
        </div> */}
                    </div>

                    <label className="col-xs-12 no-padding">Description:</label>
                    <textarea
                      className="col-xs-12"
                      value={exp["description"]}
                      onChange={(event) =>
                        handleChange(index, "description", event.target.value)
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {profile?.experience != null &&
                profile?.experience.length > 0 ? (
                  <div className="section-data no-padding col-xs-12 ">
                    {profile?.experience.map((item, i) => (
                      <div className="col-xs-12 data-row no-padding" key={i}>
                        <div className="col-xs-12 no-padding">
                          <div className="col-xs-12 no-padding date-row">
                            <span className="title">{item.position}</span>
                            <span className=" duration">
                              {new Date(item.start).toString().split(" ")[1]}{" "}
                              {new Date(item.start).toString().split(" ")[3]}
                              {" - "}
                              {new Date(item.end).toString().split(" ")[1]}{" "}
                              {new Date(item.end).toString().split(" ")[3]}
                            </span>
                          </div>
                          <div className="col-xs-12 no-padding sub-title">
                            {item.company}
                          </div>
                          <div className="col-xs-12 no-padding edit-row">
                            <span className="col-xs-10 no-padding description">
                              {item.description}
                            </span>
                            {/* <button
                            className="col-xs-2 edit"
                            data-tooltip="Edit"
                            data-toggle="modal"
                            data-target="#edit-exp-modal"
                            onClick={() => {
                              setExperience(formState.experience[i]);
                            }}
                          >
                            <EditButton />
                          </button> */}
                            <div
                              id="edit-exp-modal"
                              className="modal fade"
                              role={"dialog"}
                            >
                              <Modal
                                form={
                                  <ExperienceForm
                                    input_function={setExperienceInput}
                                    formState={experience}
                                  />
                                }
                                onSave={update_experience}
                                onRemove={remove_experience}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="section-data no-padding col-xs-12 ">
                    {/* <EmptyState text={"No work experience added yet"} /> */}
                    <div className="no-data">
                      <button
                        // data-toggle="modal"
                        // data-target="#exp-modal"
                        onClick={(e) => editEnabled(true)}
                      >
                        + Add work experience
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-xs-12 no-padding project-section data-section">
            <div className="col-xs-12 section-header no-padding ">
              <h4>Project</h4>

              {editSkills && (
                <button
                  className="add-button"
                  onClick={addEmptyProject}
                  // data-toggle="modal"
                  // data-target="#exp-modal"
                  data-tooltip="Add"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 20V12M12 12V4M12 12H20M12 12H4"
                      stroke="#363636"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              )}

              <div id="project-modal" className="modal fade " role={"dialog"}>
                <Modal
                  form={
                    <ProjectForm
                      formState={project}
                      input_function={setProjectInput}
                    />
                  }
                  onSave={add_project}
                  onRemove={() => {
                    window.location.reload();
                  }}
                />
              </div>
            </div>
            {/* <div className="section-data no-padding col-xs-12 ">
              <div className="col-xs-12 no-padding website-link">
                <div className="col-xs-9 no-padding">
                  <div className="col-xs-12 no-padding personal">
                    <span className="label col-xs-3 no-padding">
                      Personal Website
                    </span>
                    <span className="link col-xs-9 no-padding">
                      {" "}
                      {urls?.length > 0 && urls[0]}
                    </span>
                  </div>
                  {urls?.length > 1 ? (
                    <div className="col-xs-12 no-padding other">
                      <span className="label col-xs-3 no-padding">
                        Other Website
                      </span>
                      <span className="link col-xs-9 no-padding">
                        {urls
                          .filter((_, id) => id != 0)
                          .map((item, i) => (
                            <span key={i} className="col-xs-12 no-padding">
                              {" "}
                              {item}
                            </span>
                          ))}
                      </span>
                    </div>
                  ) : null}
                </div>
                <button
                  className="col-xs-2 edit"
                  data-tooltip="Edit"
                  data-toggle="modal"
                  data-target="#link-modal"
                >
                  <EditButton />
                </button>
                <div id="link-modal" className="modal fade " role={"dialog"}>
                  <Modal
                    form={<LinksForm websiteUrl={urls} setWebsiteUrl={setUrls} />}
                  />
                </div>
              </div>
            </div> */}
            {editSkills ? (
              <>
                {projectArr.map((project, index) => (
                  <div key={index} className="edit-candidate">
                    <h5 className="col-xs-12 no-padding mt-[20px]">
                      <span>Project {index + 1}</span>
                      <span
                        onClick={() => {
                          let tempvar = projectArr;
                          tempvar.splice(index, 1);
                          setProjectArr(tempvar);
                          setReload(reload + 1);
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className="w-[18px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </h5>

                    <div className="col-xs-12 no-padding">
                      <label
                        className="col-xs-12 no-padding required"
                        htmlFor="name"
                      >
                        Project Title
                      </label>
                      <input
                        type="text"
                        className="input col-xs-12 col-md-12 no-padding"
                        name="name"
                        value={project?.name}
                        required="required"
                        placeholder="Type to search... "
                        onChange={(event) => {
                          handleChangeProject(
                            index,
                            "name",
                            event.target.value,
                          );
                        }}
                      />
                    </div>
                    <div className="col-xs-12 no-padding">
                      <label
                        className="col-xs-12 no-padding required"
                        htmlFor="name"
                      >
                        Project link
                      </label>
                      <input
                        type="text"
                        className="input col-xs-12 col-md-12 no-padding"
                        name="name"
                        value={project?.link}
                        required="required"
                        placeholder="Type to search... "
                        onChange={(event) => {
                          handleChangeProject(
                            index,
                            "link",
                            event.target.value,
                          );
                        }}
                      />
                    </div>
                    <div className="col-xs-12 no-padding">
                      <label
                        className="col-xs-12 no-padding"
                        htmlFor="description"
                      >
                        Project description
                      </label>
                      <textarea
                        type="text"
                        className="input col-xs-12 col-md-12 no-padding"
                        name="description"
                        value={project?.description}
                        placeholder="Type to search... "
                        onChange={(event) => {
                          handleChangeProject(
                            index,
                            "description",
                            event.target.value,
                          );
                        }}
                      ></textarea>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {profile?.projects != null && profile?.projects.length != 0 ? (
                  <div className="data-row">
                    {profile?.projects.map((item, i) => (
                      <div className="col-xs-12 data-row no-padding" key={i}>
                        <div className="col-xs-12 no-padding">
                          <div className="col-xs-12 no-padding date-row">
                            <span className="title">{item.name}</span>
                          </div>
                          <div className="col-xs-12 no-padding sub-title">
                            {item.link}
                          </div>
                          <div className="col-xs-12 no-padding edit-row">
                            <span className="col-xs-10 no-padding description">
                              {item.description}
                            </span>
                            {/* <button
                            className="col-xs-2 edit"
                            data-tooltip="Edit"
                            data-toggle="modal"
                            data-target="#edit-project-modal"
                            onClick={() => {
                              setProject(formState.projects[i]);
                            }}
                          >
                            <EditButton />
                          </button> */}
                            <div
                              id="edit-project-modal"
                              className="modal fade"
                              role={"dialog"}
                            >
                              <Modal
                                form={
                                  <ProjectForm
                                    input_function={setProjectInput}
                                    formState={project}
                                  />
                                }
                                onSave={update_project}
                                onRemove={remove_project}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {profile?.projects.map((item, i) => (
                <div key={i} className="data-item col-xs-4 ">
                  <img
                    className="col-xs-12 no-padding img-responsive"
                    src="https://portal-storage-19fd1431184217-dev.s3.us-east-2.amazonaws.com/public/Rectangle+145.png"
                    alt=""
                  />
                  <div className="col-xs-12 no-padding title">{item.name}</div>
                  <div className="col-xs-12 no-padding description">
                    {item.description}
                  </div>
                </div>
              ))} */}
                  </div>
                ) : (
                  // <EmptyState text={"No projects added yet"} />
                  <div className="no-data">
                    <button>+ Add academic/ personal project</button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-xs-12 no-padding education-section data-section">
            <div className="col-xs-12 section-header no-padding">
              <h4>Education</h4>
              {editSkills && (
                <button
                  className="add-button"
                  onClick={addEmptyEdu}
                  // data-toggle="modal"
                  // data-target="#edu-modal"
                  // data-tooltip="Add"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 20V12M12 12V4M12 12H20M12 12H4"
                      stroke="#363636"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              )}
              <div id="edu-modal" className="modal fade " role={"dialog"}>
                <Modal
                  form={
                    <EducationForm
                      input_function={setEducationInput}
                      formState={education}
                    />
                  }
                  onSave={add_education}
                  onRemove={() => {
                    window.location.reload();
                  }}
                />
              </div>
            </div>
            {editSkills ? (
              <>
                {educationArr.map((edu, index) => (
                  <div key={index} className="edit-candidate">
                    <h5 className="col-xs-12 no-padding mt-[20px]">
                      Education {index + 1}
                      <span
                        onClick={() => {
                          let tempvar = educationArr;
                          tempvar.splice(index, 1);
                          setEducationArr(tempvar);
                          setReload(reload + 1);
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          className="w-[18px] h-[18px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </h5>
                    <label className="col-xs-12 no-padding">Institute:</label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={edu?.school}
                      onChange={(event) =>
                        handleChangeEdu(index, "school", event.target.value)
                      }
                    />
                    <div className="col-md-6 start ">
                      <label className="col-xs-12 no-padding">Degree:</label>
                      <input
                        type="text"
                        className="col-xs-12"
                        value={edu?.degree}
                        onChange={(event) =>
                          handleChangeEdu(index, "degree", event.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-6 end ">
                      <label className="col-xs-12 no-padding">
                        Score/CGPA:
                      </label>
                      <input
                        type="text"
                        className="col-xs-12"
                        value={edu.GPA}
                        onChange={(event) =>
                          handleChangeEdu(index, "GPA", event.target.value)
                        }
                      />
                    </div>

                    <div className="col-md-6 start ">
                      <label
                        htmlFor="start"
                        className="col-xs-12 no-padding required"
                      >
                        Start date
                      </label>
                      <input
                        className="col-md-12"
                        type="date"
                        name="start"
                        required="required"
                        onChange={(event) =>
                          handleChangeEdu(index, "start", event.target.value)
                        }
                        // max={todaysDate}
                        value={edu?.start?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("start", event.target.value);
                        //   }
                        // }}
                      />
                    </div>
                    <div className="col-md-6 end">
                      <label htmlFor="end" className="col-xs-12 no-padding">
                        End date
                      </label>
                      <input
                        className="col-xs-12 no-padding"
                        type="date"
                        name="final"
                        onChange={(event) =>
                          handleChangeEdu(index, "end", event.target.value)
                        }
                        // required={!checked}
                        // disabled={checked}
                        // min={formState?.start}
                        // max={todaysDate}
                        // value={formState?.end?.split("T")[0] ?? formState?.eend?.split("T")[0]}
                        value={formState?.end?.split("T")[0]}
                        // onChange={(event) => {
                        //   if (new Date(event.target.value) < new Date()) {
                        //     input_function("eend", event.target.value);
                        //     input_function("end", event.target.value);
                        //   }
                        // }}
                      />
                      {/* <div className="col-xs-12 no-padding checkbox-input">
          <input
            type="checkbox"
            value={formState?.ongoing}
            onChange={(event) => {
              setChecked(event.target.checked);
              input_function("ongoing", event.target.checked);
            }}
          />
          <label htmlFor="checkbox">I currently work here</label>
        </div> */}
                    </div>
                    <label className="col-xs-12 no-padding">Description:</label>
                    <textarea
                      className="col-xs-12"
                      value={edu?.description}
                      onChange={(event) =>
                        handleChangeEdu(
                          index,
                          "description",
                          event.target.value,
                        )
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {profile?.education != null && profile?.education.length > 0 ? (
                  <div className="section-data no-padding col-xs-12 ">
                    {profile?.education.map((item, i) => (
                      <div className="col-md-12 data-row" key={i}>
                        <div className="col-xs-12 no-padding ">
                          <div className="col-xs-12 no-padding date-row">
                            <span className="title">
                              {item.degree}
                              {" | "}
                              {item.major}
                            </span>
                            <span className=" duration">
                              {new Date(item.start).toString().split(" ")[1]}{" "}
                              {new Date(item.start).toString().split(" ")[3]}
                              {" - "}
                              {new Date(item.end).toString().split(" ")[1]}{" "}
                              {new Date(item.end).toString().split(" ")[3]}
                            </span>
                          </div>
                          <span className="col-xs-10 no-padding sub-title">
                            {item.school}
                          </span>
                          <span className="col-xs-10 no-padding description"></span>
                          <div className="col-xs-12 no-padding edit-row">
                            <span className="col-xs-10 no-padding grade">
                              Grade: {item.GPA}
                            </span>
                            {/* <button
                            className="col-xs-2 edit"
                            data-tooltip="Edit"
                            data-toggle="modal"
                            data-target="#edit-edu-modal"
                            onClick={() => {
                              setEducation(formState.education[i]);
                            }}
                          >
                            <EditButton />
                          </button> */}
                            <div
                              id="edit-edu-modal"
                              className="modal fade "
                              role={"dialog"}
                            >
                              <Modal
                                form={
                                  <EducationForm
                                    input_function={setEducationInput}
                                    formState={education}
                                  />
                                }
                                onSave={update_education}
                                onRemove={remove_education}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="line"></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="section-data no-padding col-xs-12 ">
                    {/* <EmptyState text={"No educational qualifications added yet"} /> */}
                    <div className="no-data">
                      <button
                        onClick={(e) => editEnabled(true)}
                        // data-toggle="modal"
                        // data-target="#edu-modal"
                      >
                        + Add education
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* {view != "Details" && (
        <div className={
          view == "Both" ? "col-xs-5 details-section" : "col-xs-12 details-section no-padding"
        }>
          <ResumeAnalytics editSkills={editSkills}
            skills={skills}
            setSkills={setSkills}
            profile={profile}

          />
        </div>
      )} */}
    </div>
  );
}
