import React from 'react'
import Modal from '../../modal/Modal';

function QuestionGenerateModal({modalDismiss}) {

    const onCancel = () => {
        modalDismiss();
    };

    const modalElements = {
        heading: "Generate questions",
        subheading: "action has been queued and you can see the progress under status - question tab.",
        modalDismiss: () => onCancel(),
        onSave: () => {
          modalDismiss();
        },
    };
  return (
    <Modal {...modalElements}>
    <div className="w-full flex flex-col">
       

    </div>
</Modal>
  )
}

export default QuestionGenerateModal