import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import Logo from "../../assets/Logo";

export default function InterviewEnd({
  timeTaken,
  questionsAttempted,
  afterMeeting,
  selectedAnswers,
  countSkipQsn,

  ...props
}) {
  const nav = useNavigate();

  const [surveyStar, setSurveyStar] = useState(0);
  const [surveyDesc, setSurveyDesc] = useState("");

  useEffect(() => {
    $("#endPage .feedback-section .star").each(function (i) {
      if (i < surveyStar) {
        $(this).addClass("select");
      } else {
        $(this).removeClass("select");
      }
    });
  }, [surveyStar]);

  console.log(surveyDesc, surveyStar);

  const minutes = Math.floor(timeTaken / 60);
  const seconds = timeTaken % 60;
  const formattedTime = `${minutes} mins ${seconds} sec`;

  useEffect(() => {}, [selectedAnswers, countSkipQsn]);

  const loc = useLocation();

  console.log(loc, window.location.href);
  return (
    <section id="endPage" className="col-xs-12 no-padding landing-page ">
      <Logo />
      <button
        className="end-interview-btn flex items-center justify-center"
        onClick={() => {
          afterMeeting(surveyStar, surveyDesc);
          window.location.href =
            window.location.href.split("/assessment")[0] +
            "/assessment-dashboard";
        }}
      >
        <svg
          className="mr-[10px]"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g id="vuesax/linear/arrow-left">
            <g id="arrow-left">
              <path
                id="Vector"
                d="M7.97533 4.94141L2.91699 9.99974L7.97533 15.0581"
                stroke="#252E4B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_2"
                d="M17.0836 10H3.05859"
                stroke="#252E4B"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
        Go to dashboard
      </button>
      <div className="col-xs-8 no-padding data-section">
        <div className="header-sec col-xs-12 pt-[40px]">
          <h2>Your assessment has been submitted </h2>
          <span className="subtitle leading-[21px]">
            You can get the result after the review
          </span>
          <div className=" details">
            <span className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3337 10.0003C18.3337 14.6003 14.6003 18.3337 10.0003 18.3337C5.40033 18.3337 1.66699 14.6003 1.66699 10.0003C1.66699 5.40033 5.40033 1.66699 10.0003 1.66699C14.6003 1.66699 18.3337 5.40033 18.3337 10.0003Z"
                  stroke="#5F6989"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.0924 12.6495L10.509 11.1078C10.059 10.8411 9.69238 10.1995 9.69238 9.67448V6.25781"
                  stroke="#5F6989"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="duration-text">Time taken</span>
            <span className="duration-time">{formattedTime}</span>
          </div>
        </div>
        <div className="lower-sec col-xs-12">
          {selectedAnswers && (
            <>
              <h4>Questions Attempted:</h4>
              <div className="data-row col-xs-12 no-padding mt-[5px]">
                <div className="col-xs-4 no-padding data-col border-mid">
                  <span className="header">MCQ</span>
                  <span className="text">
                    {
                      selectedAnswers?.mcq?.filter((arr) => arr?.length > 0)
                        ?.length
                    }{" "}
                    Questions
                  </span>
                </div>
                <div className="col-xs-4 no-padding data-col border-mid">
                  <span className="header"> Coding</span>
                  <span className="text">
                    {selectedAnswers?.coding?.filter((str) => str != "").length}{" "}
                    Questions
                  </span>
                </div>
                <div className="col-xs-4 no-padding data-col">
                  <span className="header">Interview</span>
                  <span className="text">{countSkipQsn} Questions</span>
                </div>
              </div>
            </>
          )}

          <div className="col-xs-12 no-padding feedback-section">
            <h4 className="mb-[5px]">Share your feedback</h4>
            <div className="flex">
              {new Array(5).fill(0).map((_, i) => (
                <button
                  key={i}
                  className="star flex items-center justify-center"
                  onClick={() => setSurveyStar(i + 1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="22"
                    viewBox="0 0 23 22"
                    fill="none"
                  >
                    <path
                      d="M11.3976 17.1506L4.63555 21.2806L6.4739 13.5729L0.457275 8.41903L8.35482 7.78631L11.3976 0.469727L14.4405 7.78631L22.3392 8.41903L16.3214 13.5729L18.1597 21.2806L11.3976 17.1506Z"
                      fill="#8D98B1"
                    />
                  </svg>
                </button>
              ))}
            </div>
          </div>
          <label htmlFor="col-xs-12 no-padding desc">
            Share more about it?
          </label>

          <textarea
            className="col-xs-12 no-padding rounded-lg bg-[#FFF] border border-[#E1E1EE]  resize-none"
            rows="4"
            id="desc"
            placeholder="Type here"
            name="desc"
            onChange={(e) => {
              setSurveyDesc(e.target.value);
            }}
          />
        </div>
        <hr />
      </div>
      <button
        className="end-interview-btn flex items-center justify-center mt-5"
        onClick={() => {
          afterMeeting(surveyStar, surveyDesc);
          // nav("/assessment-dashboard");
          window.location.href =
            window.location.href.split("/assessment")[0] +
            "/assessment-dashboard";
        }}
      >
        Submit
      </button>
    </section>
  );
}
