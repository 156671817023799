import React from "react";
import { useState } from "react";
import Modal from "../modal/Modal";
import { ScaleLoader } from "react-spinners";
import { CONTENT } from "../../lib/constants";

const core = require("../../lib/core");

function AddUrlCandidates({ jobId, modalDismiss }) {
  const [urllist, setUrllist] = useState([]);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});

  function handleEnter(inputurl) {
    if (urllist.includes(url)) {
      setUrl("");
    } else {
      setUrllist([...urllist, inputurl]);
      setUrl("");
    }
  }

  const add_linkedin_candidate = async () => {
    try {
      if (String(url)?.length <= 0) {
        setMessage({
          type: "error",
          msg: "Please enter a valid URL.",
        });
        return;
      }
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_ADD_LINKEDIN_URL,
        1,
        {
          linkedinUrl: url,
          jobId: jobId,
        }
      );
      setLoading(false);
      if (json?.data === "Uploaded") {
        setMessage({
          type: "success",
          msg: CONTENT.MODAL_CONTENT.ADD_CANDIDATE_LINKEDIN_URL.SUCCESS,
        });
        setTimeout(() => {
          setUrl("");
        }, 2500);
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          msg: "User not found in the database. Please try again",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        msg: "User not found in the database. Please try again",
      });
    }
  };

  return (
    <Modal
      heading={CONTENT.MODAL_CONTENT.ADD_CANDIDATE_LINKEDIN_URL.HEADING}
      subheading={CONTENT.MODAL_CONTENT.ADD_CANDIDATE_LINKEDIN_URL.SUBHEADING}
      modalDismiss={modalDismiss}
      onSave={add_linkedin_candidate}
      dismissOnSave={false}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Add"
        ),
        primaryDisabled: loading || String(url)?.length == 0,
      }}
    >
      <form className="w-full text-left">
        <div className="flex justify-center flex-col w-full">
          <input
            className="border  border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px] w-full"
            required
            value={url}
            placeholder=""
            onChange={(e) => {
              if (e.target.value.length > 0) setLoading(false);
              setUrl(e.target.value);
            }}
            // onKeyDown={(e) => {
            //     if (e.key == "Enter") {
            //         e.preventDefault();
            //         handleEnter(e.target.value);
            //     }
            // }}
          />

          {urllist?.length > 0 && (
            <div className="w-full flex gap-[10px] flex-wrap mt-[16px]">
              {urllist?.map((item) => (
                <span
                  className="flex items-center bg-[#eff0f2] h-[28px] py-[5px] px-[6px] rounded-[5px] "
                  key={item}
                >
                  <span className="font-normal text-[14px] text-[#16182f] leading-[18px] not-italic">
                    {item}
                  </span>
                  <svg
                    className="ml-[8px] cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setUrllist(urllist?.filter((d) => d != item));
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M24 24L8 8M24 8L8 24"
                      stroke="#252e4b"
                      strokeWidth="2.8"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                </span>
              ))}
            </div>
          )}
          {Object?.entries(message)?.length > 0 && (
            <p
              style={
                message?.type == "error" ? { color: "red" } : { color: "green" }
              }
              className="block text-sm font-medium mt-2"
            >
              {message?.msg}
            </p>
          )}
        </div>
      </form>
    </Modal>
  );
}

export default AddUrlCandidates;
