import React, { useContext, useRef } from "react";
import { useState } from "react";
import InterviewReportCard from "./InterviewReportCard";
import AssessmentReport from "./AssessmentReport";
import { useEffect } from "react";
import { CandidatePageContext } from "../../../../lib/context";
import { useSelector } from "react-redux";
const core = require("../../../../lib/core");

const InterviewTestTab = () => {
  const { reload } = useContext(CandidatePageContext);
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [tabName, setTabName] = useState("interview");
  const [scheduledTab, setScheduledTab] = useState(<></>);
  const [reportTab, setReportTab] = useState(<></>);
  // const [dataList, setDataList] = useState([]);

  const interviewData = [
    {
      id: "64be520bda28ab5ae62da09f",
      name: "Technical Interview",
      pic: "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png",
      interviews: [
        {
          id: "64be8acfac498c18f8296bf3",
          candidate: {
            id: "64be520bda28ab5ae62da09f",
          },
          recruiter: {
            name: "Niraj Rai",
            pic: "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png",
            email: "niraj+demo@sproutsai.com",
          },
          title: "Software Engineer III | UI Round 1",
          date: "2023-06-22T00:00:00.000Z",
          link: "https://us05web.zoom.us/j/9241324495?pwd=UjBvNlAyZThRSk80WURMWU5vYnpBQT09#success",
          description: "Software Engineer III | UI Interview",
          notes: [],
          guests: [],
          scores: {
            match: 7.508159367324392,
            overall: 8.937421294550354,
            assessment: 7.954809141117076,
            interview: 8.252508205915362,
          },
          questions: [
            {
              question:
                "Explain a real-world scenario where using strong static types with TypeScript improved the performance of a UI application originally written in JavaScript.",
              answer:
                "Using strong static types with TypeScript improved performance in a data-grid component originally written in JavaScript. The component handled large datasets and updates, so type-checking during runtime caused slowdowns. With TypeScript, strong static type enforcement reduced runtime checks and removed ambiguities in object structures, leading to performance gains in data processing and rendering.",
            },
            {
              question:
                "Explain the steps you took to ensure an SPA was accessible for people with disabilities and compliant with the Web Content Accessibility Guidelines (WCAG).",
              answer:
                "Following a semantic HTML structure, using ARIA labels, ensuring proper contrast and font sizes, and implementing keyboard navigation and focus management were critical steps taken to comply with WCAG. Additionally, regular accessibility audits via automated tools and manual testing were conducted to ensure ongoing compliance.",
            },
            {
              question:
                "Design a state management system based on functional paradigms for a complex UI application. Explain the advantages of your design over traditional state management approaches.",
              answer:
                "I designed a state management system using the Redux library that follows functional paradigms - a single, immutable store to hold the application state and pure reducer functions to update the state. The advantages of this design include easy state predictability, simple testing, and better debugging capabilities. Additionally, using middleware like Redux Saga or Thunk allows handling side-effects in a more elegant way compared to traditional state management approaches.",
            },
            {
              question:
                "Explain how you would transform an existing SPA into a Progressive Web App. Describe the required changes and benefits of this transition.",
              answer:
                "To transform an SPA into a PWA, we need to incorporate a manifest file, service workers, and app shell architecture. The benefits of transitioning include enhanced performance, offline capabilities, cross-platform compatibility, and better user experience.",
            },
            {
              question:
                "Describe a situation where you had to optimize the performance of a high-traffic web application. What tools did you use to identify bottlenecks, and what specific techniques did you employ to improve performance?",
              answer:
                "I worked on a high-traffic e-commerce site where performance was crucial. I used Chrome DevTools to identify bottlenecks such as slow-rendering components and excessive network requests. To improve performance, I implemented lazy-loading for images, utilized a CDN for static assets, and reduced JavaScript bundle size using code splitting and tree shaking. I also optimized server-side rendering to reduce TTFB and used caching strategies to minimize client-side re-rendering.",
            },
            {
              question:
                "Discuss a case where you evaluated and improved the security of a GCP-based solution, describing the methods used and improvement outcomes.",
              answer:
                "Evaluated a GCP-based solution using Cloud Security Scanner, discovered a few vulnerabilities and misconfigurations. Implemented organization-level IAM policies, VPC Service Controls, Cloud Identity-Aware Proxy, and Encryption at Rest with Cloud KMS to improve the security posture. As a result, there was a significant reduction in security risks while satisfying compliance requirements.",
            },
            {
              question:
                "Describe the architecture of a highly scalable and maintainable Single Page Application you have successfully implemented.",
              answer:
                "The implemented SPA used a micro-frontend architecture, with each feature being independently deployable. Technologies used included React, Redux, and GraphQL as a data layer, with Node.js and Express for the backend API. The solution utilized module federation for code sharing and lazy loading with Webpack. In addition to this, it incorporated a continuous integration and deployment process using Docker and Kubernetes.",
            },
            {
              question:
                "Explain in detail a situation where you successfully implemented code splitting using Webpack to optimize the load time of a large-scale web application.",
              answer:
                "In a large-scale web application, the initial load time was a concern due to the app bundle's enormous size. I used code splitting with Webpack to create smaller chunks that are loaded on-demand. I configured Webpack to use dynamic imports that load specific modules when required, reducing the initial load time. SplitChunksPlugin was used to separate code shared by multiple entry points into a common shared chunk. This approach significantly improved the application's performance and user experience.",
            },
            {
              question:
                "You're responsible for developing a comprehensive testing strategy for a large-scale web application. How would you create a testing plan that balances test coverage, test performance, and developer velocity? Discuss specific testing techniques, tools, or methodologies.",
              answer:
                "I'd start by implementing a testing pyramid strategy, with a majority of unit tests, a smaller number of integration tests, and few end-to-end tests. I'd use Jest and Enzyme for unit testing React components, ensuring that each component's functionality is well-tested. For integration tests, I'd use testing-library/react to verify how components interact with one another. For end-to-end tests, I'd use Cypress or Puppeteer to simulate user interactions and test critical user flows. To maintain developer velocity, I'd set up continuous integration pipelines using tools like GitHub Actions to run tests automatically on each commit and pull request.",
            },
            {
              question:
                "What are the challenges you've faced building and maintaining a single SPA codebase that works across multiple platforms (e.g., desktop, mobile, and web)? How did you address these challenges?",
              answer:
                "Key challenges included responsive design, handling different input methods, and optimizing performance on multiple platforms. To overcome them, a mobile-first approach was taken for responsive design, platform-specific code was isolated, and performance testing on various devices was conducted to optimize resource utilization.",
            },
          ],
          overallScore: 7.6,
          eval: [
            {
              label: "Strong Static Types",
              value: 7,
              keySkills: [
                {
                  label: "Strong Static Types",
                  value: 7,
                },
              ],
            },
            {
              label: "Single Page Application",
              value: 7.125,
              keySkills: [
                {
                  label: "Single Page Application",
                  value: 6,
                },
                {
                  label: "Single Page Application",
                  value: 9,
                },
                {
                  label: "Single Page Application",
                  value: 7,
                },
                {
                  label: "Single Page Application",
                  value: 7,
                },
              ],
            },
            {
              label: "Functional Paradigms",
              value: 7,
              keySkills: [
                {
                  label: "Functional Paradigms",
                  value: 7,
                },
              ],
            },
            {
              label: "JavaScript",
              value: 7.5,
              keySkills: [
                {
                  label: "JavaScript",
                  value: 6,
                },
                {
                  label: "JavaScript",
                  value: 9,
                },
              ],
            },
            {
              label: "GCP",
              value: 9,
              keySkills: [
                {
                  label: "GCP",
                  value: 9,
                },
              ],
            },
            {
              label: "Webpack",
              value: 9,
              keySkills: [
                {
                  label: "Webpack",
                  value: 9,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "64be520bda28ab65ba2da09d",
      name: "Interview with Senior Developer",
      pic: "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png",
      interviews: [
        {
          id: "64be8aa0ac498c18f8296bf1",
          candidate: {
            id: "64be520bda28ab65ba2da09d",
          },
          recruiter: {
            name: "Niraj Rai",
            pic: "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png",
            email: "niraj+demo@sproutsai.com",
          },
          title: "Software Engineer III | UI Round 1",
          date: "2023-06-22T00:00:00.000Z",
          link: "https://us05web.zoom.us/j/9241324495?pwd=UjBvNlAyZThRSk80WURMWU5vYnpBQT09#success",
          description: "Software Engineer III | UI Interview",
          notes: [],
          guests: [],
          scores: {
            match: 8.480344574846287,
            overall: 8.435814565779163,
            assessment: 8.035779846974972,
            interview: 8.153508813100848,
          },
          questions: [
            {
              question:
                "Explain a real-world scenario where using strong static types with TypeScript improved the performance of a UI application originally written in JavaScript.",
              answer:
                "Using strong static types with TypeScript improved performance in a data-grid component originally written in JavaScript. The component handled large datasets and updates, so type-checking during runtime caused slowdowns. With TypeScript, strong static type enforcement reduced runtime checks and removed ambiguities in object structures, leading to performance gains in data processing and rendering.",
            },
            {
              question:
                "Explain the steps you took to ensure an SPA was accessible for people with disabilities and compliant with the Web Content Accessibility Guidelines (WCAG).",
              answer:
                "Following a semantic HTML structure, using ARIA labels, ensuring proper contrast and font sizes, and implementing keyboard navigation and focus management were critical steps taken to comply with WCAG. Additionally, regular accessibility audits via automated tools and manual testing were conducted to ensure ongoing compliance.",
            },
            {
              question:
                "Design a state management system based on functional paradigms for a complex UI application. Explain the advantages of your design over traditional state management approaches.",
              answer:
                "I designed a state management system using the Redux library that follows functional paradigms - a single, immutable store to hold the application state and pure reducer functions to update the state. The advantages of this design include easy state predictability, simple testing, and better debugging capabilities. Additionally, using middleware like Redux Saga or Thunk allows handling side-effects in a more elegant way compared to traditional state management approaches.",
            },
            {
              question:
                "Explain how you would transform an existing SPA into a Progressive Web App. Describe the required changes and benefits of this transition.",
              answer:
                "To transform an SPA into a PWA, we need to incorporate a manifest file, service workers, and app shell architecture. The benefits of transitioning include enhanced performance, offline capabilities, cross-platform compatibility, and better user experience.",
            },
            {
              question:
                "Describe a situation where you had to optimize the performance of a high-traffic web application. What tools did you use to identify bottlenecks, and what specific techniques did you employ to improve performance?",
              answer:
                "I worked on a high-traffic e-commerce site where performance was crucial. I used Chrome DevTools to identify bottlenecks such as slow-rendering components and excessive network requests. To improve performance, I implemented lazy-loading for images, utilized a CDN for static assets, and reduced JavaScript bundle size using code splitting and tree shaking. I also optimized server-side rendering to reduce TTFB and used caching strategies to minimize client-side re-rendering.",
            },
            {
              question:
                "Discuss a case where you evaluated and improved the security of a GCP-based solution, describing the methods used and improvement outcomes.",
              answer:
                "Evaluated a GCP-based solution using Cloud Security Scanner, discovered a few vulnerabilities and misconfigurations. Implemented organization-level IAM policies, VPC Service Controls, Cloud Identity-Aware Proxy, and Encryption at Rest with Cloud KMS to improve the security posture. As a result, there was a significant reduction in security risks while satisfying compliance requirements.",
            },
            {
              question:
                "Describe the architecture of a highly scalable and maintainable Single Page Application you have successfully implemented.",
              answer:
                "The implemented SPA used a micro-frontend architecture, with each feature being independently deployable. Technologies used included React, Redux, and GraphQL as a data layer, with Node.js and Express for the backend API. The solution utilized module federation for code sharing and lazy loading with Webpack. In addition to this, it incorporated a continuous integration and deployment process using Docker and Kubernetes.",
            },
            {
              question:
                "Explain in detail a situation where you successfully implemented code splitting using Webpack to optimize the load time of a large-scale web application.",
              answer:
                "In a large-scale web application, the initial load time was a concern due to the app bundle's enormous size. I used code splitting with Webpack to create smaller chunks that are loaded on-demand. I configured Webpack to use dynamic imports that load specific modules when required, reducing the initial load time. SplitChunksPlugin was used to separate code shared by multiple entry points into a common shared chunk. This approach significantly improved the application's performance and user experience.",
            },
            {
              question:
                "You're responsible for developing a comprehensive testing strategy for a large-scale web application. How would you create a testing plan that balances test coverage, test performance, and developer velocity? Discuss specific testing techniques, tools, or methodologies.",
              answer:
                "I'd start by implementing a testing pyramid strategy, with a majority of unit tests, a smaller number of integration tests, and few end-to-end tests. I'd use Jest and Enzyme for unit testing React components, ensuring that each component's functionality is well-tested. For integration tests, I'd use testing-library/react to verify how components interact with one another. For end-to-end tests, I'd use Cypress or Puppeteer to simulate user interactions and test critical user flows. To maintain developer velocity, I'd set up continuous integration pipelines using tools like GitHub Actions to run tests automatically on each commit and pull request.",
            },
            {
              question:
                "What are the challenges you've faced building and maintaining a single SPA codebase that works across multiple platforms (e.g., desktop, mobile, and web)? How did you address these challenges?",
              answer:
                "Key challenges included responsive design, handling different input methods, and optimizing performance on multiple platforms. To overcome them, a mobile-first approach was taken for responsive design, platform-specific code was isolated, and performance testing on various devices was conducted to optimize resource utilization.",
            },
          ],
          overallScore: 8.6,
          eval: [
            {
              label: "Strong Static Types",
              value: 10,
              keySkills: [
                {
                  label: "Strong Static Types",
                  value: 10,
                },
              ],
            },
            {
              label: "Single Page Application",
              value: 8.625,
              keySkills: [
                {
                  label: "Single Page Application",
                  value: 6,
                },
                {
                  label: "Single Page Application",
                  value: 9,
                },
                {
                  label: "Single Page Application",
                  value: 9,
                },
                {
                  label: "Single Page Application",
                  value: 9,
                },
              ],
            },
            {
              label: "Functional Paradigms",
              value: 9,
              keySkills: [
                {
                  label: "Functional Paradigms",
                  value: 9,
                },
              ],
            },
            {
              label: "JavaScript",
              value: 8,
              keySkills: [
                {
                  label: "JavaScript",
                  value: 9,
                },
                {
                  label: "JavaScript",
                  value: 7,
                },
              ],
            },
            {
              label: "GCP",
              value: 8,
              keySkills: [
                {
                  label: "GCP",
                  value: 8,
                },
              ],
            },
            {
              label: "Webpack",
              value: 10,
              keySkills: [
                {
                  label: "Webpack",
                  value: 10,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const getInterviewData = async () => {
    console.log("getInterviewData");
    let json = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.GET_INTERVIEWS,
      1,
      {
        match_profile_id: candidate?.matchProfile?._id,
      },
    );
    console.log(json);
    setReportTab(
      <React.Suspense fallback={<div>Loading...</div>}>
        <InterviewReportCard
          data={json?.data?.filter((iter) => iter?.status == "completed")}
          // data={interviewData}
        />
      </React.Suspense>,
    );
  };

  useEffect(() => {
    switch (tabName) {
      case "interview": {
        getInterviewData();
        setReportTab(
          <React.Suspense fallback={<div>Loading...</div>}>
            <InterviewReportCard
            // data={json?.data?.filter((iter) => iter?.status == "completed")}
            // data={interviewData}
            />
          </React.Suspense>,
        );
        break;
      }
      default: {
        setScheduledTab();
        setReportTab(
          <React.Suspense fallback={<div>Loading...</div>}>
            <AssessmentReport data={[]} />
          </React.Suspense>,
        );
        break;
      }
    }
  }, [tabName, reload, candidate?.matchProfile?._id]);

  return (
    <section
      id="interviewTest"
      className="w-full p-4 min-h-[calc(100vh-207px)] bg-defaultBg"
    >
      <div className="bg-customColor rounded-lg py-px px-px inline-flex items-center mb-4">
        <button
          disabled
          onClick={() => setTabName("assessment")}
          className={`px-4 py-2 flex items-center justify-center ${
            tabName === "assessment" ? "bg-white rounded-lg" : "bg-transparent"
          } `}
        >
          Assessments
        </button>
        <button
          onClick={() => setTabName("interview")}
          className={`px-4 py-2 flex items-center justify-center ${
            tabName === "interview" ? "bg-white rounded-lg" : "bg-transparent"
          } `}
        >
          Interviews
        </button>
      </div>
      <div className="my-4 w-full">
        <h2 className="text-gray-700 text-sm not-italic font-normal">
          Reports
        </h2>
        <div className="w-full bg-white rounded-lg border-b border-gray-100 p-4">
          {reportTab}
        </div>
      </div>
    </section>
  );
};

export default InterviewTestTab;
