import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CandidatePageContext } from "../../../../lib/context";
import InitialsLogo from "../../../all/InitialsLogo";
import { ScaleLoader } from "react-spinners";
import moment from "moment";

const colorArray = [
  "#61A3BA",
  "#A9A9A9",
  "#0C356A",
  "#04364A",
  "#99A98F",
  "#245953",
  "#8785A2",
  "#8D7B68",
];

const InterviewScheduledCard = ({ dataList }) => {
  const { setShowModal } = useContext(CandidatePageContext);
  const nav = useNavigate();
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);

  const jobsd = useSelector((state) => console.log(state));
  const [groupedMeetings, setGroupedMeetings] = useState({});
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [listLoading, setListLoading] = useState(true);

  function stringToColor(string) {
    if (!colorArray || colorArray.length === 0) {
      return null; // Handle the case when the colorArray is empty or undefined
    }

    let hash = 0;
    for (let i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    const index = Math.abs(hash) % colorArray.length; // Calculate the index based on the hash
    return colorArray[index];
  }

  const toggleDropdown = (date, index) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [date]: prevState[date] === index ? null : index,
    }));
  };
  const sortedDataList = [...dataList].sort((a, b) => {
    if (!a.start_time) return 1;
    if (!b.start_time) return -1;
    return moment(a.start_time).diff(moment(b.start_time));
  });

  console.log(candidate?.matchProfile?._id + "&" + jobId);

  useEffect(() => {
    setListLoading(true);
    if (dataList && dataList.length > 0) {
      const sortAndGroupMeetings = async () => {
        if (Array.isArray(dataList)) {
          const sortedDataList = [...dataList].sort((a, b) => {
            if (!a.start_time) return 1;
            if (!b.start_time) return -1;
            return moment(a.start_time).diff(moment(b.start_time));
          });

          const grouped = sortedDataList.reduce((acc, meeting) => {
            const date = moment(meeting.start_time).format("YYYY-MM-DD");
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(meeting);
            return acc;
          }, {});

          setGroupedMeetings(grouped);
        } else if (dataList && typeof dataList === "object") {
          const date = moment(dataList.start_time).format("YYYY-MM-DD");
          setGroupedMeetings({ [date]: [dataList] });
        } else {
          setGroupedMeetings({});
        }
        setListLoading(false);
      };
      sortAndGroupMeetings();
    }
  }, [dataList]);

  console.log(groupedMeetings, dataList);

  const myEventsList = [
    {
      title: "Daily Leads+Infra Sync",
      start: new Date(2024, 7, 8),
      end: new Date(2024, 7, 14),
      colorEvento: "green",
      color: "white",
    },
    {
      title: "Post today's goals ",
      start: new Date(2024, 7, 8),
      end: new Date(2024, 7, 14),
      colorEvento: "green",
      color: "white",
    },
    {
      title: "Scheduler update sync",
      start: new Date("7/2/2024, 9:45:00 PM"),
      end: new Date("7/2/2024, 10:00:00 PM"),
      colorEvento: "pink",
      color: "white",
    },
    {
      title: "Job post ",
      start: new Date(2024, 3, 15, 20, 15, 0, 0),
      end: new Date(2024, 3, 15, 21, 0, 0, 0),
      colorEvento: "green",
      color: "white",
    },
    {
      title: "Interv. Feedback",
      start: new Date(2024, 3, 15, 20, 0, 0, 0),
      end: new Date(2024, 3, 15, 21, 0, 0, 0),
      colorEvento: "green",
      color: "white",
    },
  ];

  if (Object.keys(groupedMeetings).length === 0)
    return (
      <div className="w-full mb-4 last:mb-0 flex justify-between">
        <div className="max-w-[50%] flex flex-col">
          <span className="text-xs text-gray-700 font-normal">
            No Interviews scheduled
          </span>
        </div>
      </div>
    );

  return (
    <>
      <div className="overflow-x-auto">
        {listLoading ? (
          <div className="flex w-full justify-center items-center h-32">
            <ScaleLoader height={30} color="#1569EB" loading={true} />
          </div>
        ) : (
          Object.entries(groupedMeetings).map(([date, meetings]) => (
            <div key={date} className="mb-8">
              <h2 className="text-md font-semibold mb-4">
                {moment(date).format("dddd, MMMM D, YYYY")}
              </h2>
              <table className="min-w-full rounded-lg w-full border-collapse border border-gray-400">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-gray-800 not-italic font-normal text-sm ml-[14px]">
                      Title
                    </th>
                    <th className="py-2 px-4 text-gray-800 not-italic font-normal text-sm ml-[14px]">
                      Organizer
                    </th>
                    <th className="py-2 px-4 text-gray-800 not-italic font-normal text-sm ml-[14px]">
                      Candidate
                    </th>
                    <th className="py-2 px-4 text-gray-800 not-italic font-normal text-sm ml-[14px]">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {meetings.map((meeting, index) => (
                    <React.Fragment key={index}>
                      <tr className="bg-[#ffffff] rounded-lg ">
                        <td className="py-4 px-4 ">
                          <div className="text-base not-italic  font-medium text-gray-800 ">
                            {meeting?.title}
                          </div>
                          <span className="text-sm not-italic font-normal  text-gray-700  mt-2">
                            {meeting?.start_time ? (
                              <>
                                <span className="text-xs">
                                  {moment(meeting.start_time).format("LL")}
                                </span>
                                <p></p>
                                {moment(meeting.start_time).format(
                                  "h:mm A",
                                )} - {moment(meeting.end_time).format("h:mm A")}
                                <span className="ml-2 capitalize text-blue-800 font-medium">
                                  {meeting?.status}
                                </span>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </td>
                        <td className="py-4 px-4 ">
                          <div className="text-base not-italic  font-medium text-gray-800 ">
                            {meeting?.organizer?.email}
                          </div>
                          {/* <span className="text-sm not-italic font-normal  text-gray-700  mt-2">
                          Software Engineer
                        </span> */}
                        </td>
                        <td className="py-4 px-4 ">
                          {meeting?.matchprofile?.[0]?.applicant?.first_name}{" "}
                          {meeting?.matchprofile?.[0]?.applicant?.last_name}
                        </td>
                        <td className="py-4 px-4 ">
                          <button
                            onClick={() => toggleDropdown(date, index)}
                            className="btn-sec btn-sm px-3 py-1 rounded mr-2"
                          >
                            Details
                          </button>
                          <button
                            className="btn-primary btn-sm px-3 py-1 rounded "
                            onClick={() => {
                              nav(
                                "/interview/" +
                                  meeting?._id +
                                  "?link=" +
                                  meeting?.meeting_link +
                                  "&" +
                                  candidate?.matchProfile?._id +
                                  "&" +
                                  jobId,
                                {
                                  state: {
                                    jobID: jobId,
                                    candID: candidate?.matchProfile?._id,
                                  },
                                },
                              );
                              window.open(meeting?.meeting_link, "_blank");
                            }}
                          >
                            Join
                          </button>
                        </td>
                      </tr>
                      {openDropdowns[date] === index && (
                        <tr>
                          <td colSpan="6" className="px-4 py-2  bg-defaultBg">
                            <div className="p-2  grid grid-cols-2">
                              <div>
                                <h3 className="flex items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="17"
                                    viewBox="0 0 20 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M19 15.9999C19 14.2583 17.3304 12.7767 15 12.2275M13 16C13 13.7909 10.3137 12 7 12C3.68629 12 1 13.7909 1 16M13 9C15.2091 9 17 7.20914 17 5C17 2.79086 15.2091 1 13 1M7 9C4.79086 9 3 7.20914 3 5C3 2.79086 4.79086 1 7 1C9.20914 1 11 2.79086 11 5C11 7.20914 9.20914 9 7 9Z"
                                      stroke="#252E4B"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Interviewer
                                </h3>
                                {meeting.guest.map((interviewer, index) => (
                                  <div
                                    key={index}
                                    className="flex gap-2 mb-4 mt-4 items-center"
                                  >
                                    <InitialsLogo
                                      width="20px"
                                      height="20px"
                                      str={interviewer.split("@")[0]}
                                    />
                                    <div className="flex flex-col">
                                      <h className="text-base not-italic font-medium text-gray-800 ">
                                        {interviewer}
                                      </h>
                                      <span className="text-sm not-italic font-normal text-gray-700">
                                        {/* {interviewer.organization} */}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                                {meeting.interviewer.map(
                                  (interviewer, index) => (
                                    <div
                                      key={index}
                                      className="flex gap-2 mb-4 mt-4 items-center"
                                    >
                                      <InitialsLogo
                                        width="20px"
                                        height="20px"
                                        str={interviewer.name}
                                      />
                                      <div className="flex flex-col">
                                        <h className="text-base not-italic font-medium text-gray-800 ">
                                          {interviewer?.name}
                                        </h>
                                        <span className="text-sm not-italic font-normal text-gray-700">
                                          {interviewer.email}
                                        </span>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                              <div className="mt-0">
                                <h1 className="flex items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M2 8C2 5.79086 3.79086 4 6 4H13C15.2091 4 17 5.79086 17 8V16C17 18.2091 15.2091 20 13 20H6C3.79086 20 2 18.2091 2 16V8Z"
                                      stroke="#252E4B"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M17 9.33333L18.5409 7.68968C19.781 6.36697 22 7.24449 22 9.05756V14.9424C22 16.7555 19.781 17.633 18.5409 16.3103L17 14.6667V9.33333Z"
                                      stroke="#252E4B"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M13 10C13 11.1046 12.1046 12 11 12C9.89543 12 9 11.1046 9 10C9 8.89543 9.89543 8 11 8C12.1046 8 13 8.89543 13 10Z"
                                      stroke="#252E4B"
                                      strokeWidth="1.5"
                                    />
                                  </svg>{" "}
                                  Meeting link
                                </h1>
                                {meeting?.meeting_link && (
                                  <p className="mt-2 flex items-center gap-4">
                                    {" "}
                                    <a
                                      href={meeting.url}
                                      className="text-blue-500 w-full overflow-hidden"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {meeting?.meeting_link}
                                    </a>
                                    <svg
                                      className="cursor-pointer"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          meeting.meeting_link,
                                        );
                                      }}
                                    >
                                      <path
                                        d="M7 7V5C7 2.79086 8.79086 1 11 1L17 1C19.2091 1 21 2.79086 21 5V11C21 13.2091 19.2091 15 17 15H15M7 7H5C2.79086 7 1 8.79086 1 11V17C1 19.2091 2.79086 21 5 21H11C13.2091 21 15 19.2091 15 17V15M7 7H11C13.2091 7 15 8.79086 15 11V15"
                                        stroke="#252E4B"
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </p>
                                )}
                              </div>
                              <div className="mt-8">
                                <h1 className="flex items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="22"
                                    viewBox="0 0 20 22"
                                    fill="none"
                                  >
                                    <path
                                      d="M19 8V17C19 19.2091 17.2091 21 15 21H5C2.79086 21 1 19.2091 1 17V5C1 2.79086 2.79086 1 5 1H12M19 8L12 1M19 8H16C13.7909 8 12 6.20914 12 4V1"
                                      stroke="#252E4B"
                                      strokeWidth="1.5"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{" "}
                                  Description
                                </h1>
                                <p className="mt-2 flex items-center gap-4">
                                  {" "}
                                  {meeting?.description || "No description"}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default InterviewScheduledCard;
