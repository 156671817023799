import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchDashboardTask } from "../../../redux/services";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
const core = require("../../../lib/core");

function DashboardTask({}) {
  const [data, setData] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState("");

  const fetchtaskList = useSelector(
    (state) => state?.dashboardSlice?.tasksList,
  );

  const taskList = useMemo(() => fetchtaskList, [fetchtaskList]);

  useEffect(() => {
    setData(taskList);
  }, [taskList]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboardTask());
  }, []);

  const [showInput, setShowInput] = useState(false);

  const createNewtask = async () => {
    try {
      if (textAreaValue.trim().length > 1) {
        let json = await core.API(
          core.API_METHODS.POST,
          core.RECRUITER_API.POST_CREATE_TASK,
          1,
          {
            name: textAreaValue,
          },
        );

        if (json.data) {
          setShowInput(false);
          dispatch(fetchDashboardTask());
        }
      } else {
        setShowInput(false);
      }
    } catch {}
  };

  const updateTaskStatus = async (status, id) => {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_DASHBOARD_TASK_UPDATE,
        1,
        {
          id: id,
          complete: status,
        },
      );
    } catch {}
  };

  return (
    <div className="  bg-[#FFF] rounded-[12px] pt-[18px] px-[18px] pb-[20px] shadow-PipelineCardsShadow">
      <span className="text-[16px] text-[#252E4B] font-medium not-italic flex items-center">
        <svg
          className="mr-[6px]"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M18.0515 8.70139L17.2348 12.1847C16.5348 15.1931 15.1515 16.4097 12.5515 16.1597C12.1348 16.1264 11.6848 16.0514 11.2015 15.9347L9.80145 15.6014C6.32645 14.7764 5.25145 13.0597 6.06812 9.57639L6.88479 6.08472C7.05145 5.37639 7.25145 4.75972 7.50145 4.25139C8.47645 2.23472 10.1348 1.69306 12.9181 2.35139L14.3098 2.67639C17.8015 3.49306 18.8681 5.21806 18.0515 8.70139Z"
            stroke="#252E4B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5513 16.1583C12.0346 16.5083 11.3846 16.8 10.5929 17.0583L9.27626 17.4917C5.96793 18.5583 4.22626 17.6667 3.15126 14.3583L2.08459 11.0667C1.01793 7.75833 1.90126 6.00833 5.20959 4.94167L6.52626 4.50833C6.86793 4.4 7.19293 4.30833 7.50126 4.25C7.25126 4.75833 7.05126 5.375 6.88459 6.08333L6.06793 9.575C5.25126 13.0583 6.32626 14.775 9.80126 15.6L11.2013 15.9333C11.6846 16.05 12.1346 16.125 12.5513 16.1583Z"
            stroke="#252E4B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5352 7.10938L14.5768 8.13438"
            stroke="#252E4B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.71875 10.3359L12.1354 10.9526"
            stroke="#252E4B"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Task list
      </span>
      {data?.map((item) => (
        <div className="flex items-center mt-[12px]" key={item?.id}>
          <input
            type="checkbox"
            name={item?.id}
            id={item?.id}
            className="w-[18px] h-[18px]"
            defaultChecked={item?.complete}
            onChange={(e) => updateTaskStatus(e?.target.checked, item?.id)}
          />
          <label
            htmlFor={item?.id}
            className="text-[14px] text-[#252E4B] font-normal ml-[8px] not-italic mb-[-5px]"
          >
            {item?.name}
          </label>
        </div>
      ))}

      <div className="flex flex-col mt-[12px] relative ">
        <div
          className={`rounded-[8px] border border-[#E1E1EE] bg-[#FFF]  py-[8px] ${showInput ? "" : "hidden"}`}
        >
          <textarea
            name=""
            id=""
            rows="2"
            className="w-full resize-none overflow-hidden"
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
          ></textarea>
          <div className="flex flex-row-reverse">
            <button
              className="mr-[16px] font-normal text-[14px] text-[#252E4B] not-italic"
              onClick={createNewtask}
            >
              Done
            </button>
          </div>
        </div>
        <div className="mt-[12px]">
          <button
            // disabled={textAreaValue.trim()?.length <= 0}
            onClick={(e) => {
              setShowInput(true);
              setTextAreaValue("");
            }}
          >
            <span className="text-[#1369E9] text-[16px] font-normal not-italic">
              + Add task
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default DashboardTask;
