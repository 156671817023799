import React, { useState } from 'react'
import Modal from '../../modal/Modal'
import { useNavigate } from 'react-router';
import { ScaleLoader } from 'react-spinners';
const core = require("../../../lib/core");

function CloneJobModal({ modalDismiss ,jobId }) {

    const onCancel = () => {
        modalDismiss();
    };

    const nav =useNavigate()
    const [loading, setLoading] = useState(false);

    const clonesimilerjob = async () => {
        setLoading(true);
        try {
          const json = await core.API(
            core.API_METHODS.POST,
            core.JOB_API.POST_CLONE_JOB+ jobId,
            // jobData?.id,
            1,
            {
            }
          );
          if (json.data) {
            nav('/job/active/')
          }
        } catch (error) {

        }
      };



    const modalElements = {
        heading: "Clone Job",
        subheading: "Duplicate an existing job for easy replication and modification",
        modalDismiss: () => onCancel(),
        defaultButtons:{
            primaryLabel: loading ? (
                <ScaleLoader height={14} color="#ffffff" loading={true} />
              ) : (
                "Clone job"
              ),
            secLabel: "Cancel",
        },
        onSave: () => {
            clonesimilerjob()
        },
    };
    return (
        <Modal {...modalElements}>
            <div className="w-full flex flex-col">
                {/* <div className='flex items-center '>
                    <input type='checkbox' className='mb-[5px]'/>
                    <label className='mb-0 mt-[4px] text-sm font-normal ml-[8px] text-gray-800 not-italic'>Clone applicants</label>
                </div> */}
            </div>
        </Modal>
    )
}

export default CloneJobModal