// import React, { useEffect, useContext, useState } from "react";
// const core = require("../../../src/lib/core");
// import Header from "./JobPostHeader";
// import JobOpening from "./JobOpening";
// import Footer from "./JobPostFooter";
// import { ScaleLoader } from "react-spinners";
// import { useLocation } from "react-router-dom";
// import NotFound from "./PageNotFound";
// // import { JobContext } from "../../context/JobContext";
// import queryString from "query-string";

// const Jobs = () => {
//   const [jobList, setJobList] = useState([]);
//   // const { jobList, setJobList, orgDetails, setOrgDetails } = useContext(JobContext);
//   const [orgDetails, setOrgDetails] = useState({}); // [1]
//   const location = useLocation();

//   const queryParams = queryString.parse(location.search);
//   const [currentPage, setCurrentPage] = useState(
//     queryParams?.page?.length > 0 ? queryParams?.page : 1,
//   );
//   const [loading, setLoading] = useState(true);
//   const fetchJob = async () => {
//     let query = "?";
//     setLoading(true);
//     try {
//       let json = await core.API(
//         core.API_METHODS.GET,
//         core.RECRUITER_API.GET_ORG_JOB_LIST +
//         `/${location?.pathname?.split("/")?.at(-1)}${query}&page=${currentPage}`,
//         1,
//         {},
//       );
//       if (json && json.data) {
//         setJobList(json?.data?.content);
//         setOrgDetails(json?.data?.organization);
//       }
//       setLoading(false);
//     } catch (error) {
//       console.error(error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchJob();
//   }, []);

//   console.log("JobList :", jobList);
//   console.log("orgainisation Details ", orgDetails);

//   return (
//     <>
//       {loading ? (
//         <div className="w-full h-screen flex items-center justify-center">
//           <ScaleLoader height={14} color="blue" loading={true} />
//         </div>
//       ) : (
//         <>
//           {orgDetails.careers_page_integration ? (
//             <div className="font-sans h-full  flex  flex-col overflow-auto">
//               <div className="flex justify-center items-center md:mx-16 w-11/12 sm:mx-0 sm:px-0">
//                 <Header orgDetails={orgDetails} />
//               </div>

//               <div className="flex justify-center items-center md:mx-16 w-11/12 sm:mx-0 sm:px-0">
//                 <JobOpening
//                   jobList={jobList}
//                   orgName={orgDetails?.names}
//                   orgDetails={orgDetails}
//                 />
//               </div>
//               <Footer />
//             </div>
//           ) : (
//             <NotFound />
//           )}
//         </>
//       )}
//     </>
//   );
// };

// export default Jobs;
import React, { useEffect, useState } from "react";
const core = require("../../../src/lib/core");
import Header from "./JobPostHeader";
import JobOpening from "./JobOpening";
import Footer from "./JobPostFooter";
import { ScaleLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import NotFound from "./PageNotFound";

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const fetchJob = async () => {
    setLoading(true);
    let allJobs = [];
    let currentPage = 1;
    const pageSize = 20;
    try {
      while (currentPage < 7) {
        let json = await core.API(
          core.API_METHODS.GET,
          core.RECRUITER_API.GET_ORG_JOB_LIST +
            `/${location?.pathname?.split("/")?.at(-1)}?page=${currentPage}`,
          1,
          {},
        );

        if (json && json.data && json.data.content.length > 0) {
          allJobs = [...allJobs, ...json.data.content];
          setOrgDetails(json.data.organization);

          if (json.data.content.length < pageSize) {
            break;
          }

          currentPage++;
        } else {
          break;
        }
      }
      setJobList(allJobs);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [location]);

  console.log("JobList:", jobList);
  console.log("Organization Details:", orgDetails);

  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <ScaleLoader height={36} color="blue" loading={true} />
        </div>
      ) : (
        <>
          {orgDetails.careers_page_integration ? (
            <div className="h-screen w-screen flex flex-col overflow-auto gap-12">
              <Header orgDetails={orgDetails} />
              <JobOpening
                jobList={jobList}
                orgName={orgDetails?.names}
                orgDetails={orgDetails}
              />
              <Footer />
            </div>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default Jobs;
