import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { FaRegClock } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { FiMapPin } from "react-icons/fi";
import { FaBriefcase } from "react-icons/fa";
import { FaLink } from "react-icons/fa";
import { TbExternalLink } from "react-icons/tb";
import { FaRegStar } from "react-icons/fa";
import { FaRegFlag } from "react-icons/fa";
import { HiOutlineUsers } from "react-icons/hi";

import "../../../style/analytics/table.scss";
import { useNavigate } from "react-router-dom";
import CandidateDrawer from "../../../components/recruiter/candidate/CandidateDrawer";
import { ScaleLoader } from "react-spinners";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { fieldMap } from "./helper";

export const Table = ({
  data,
  type,
  section,
  fetchMoreData,
  loading = true,
  setLoading,
  activeloading = true,
  setActiveLoading,
  activeCard,
  isQuery,
  fetchMoreNewApplicants,
}) => {
  const [sortOrder, setSortOrder] = useState("desc");
  const [showCandidate, setShowCandidate] = useState(false);
  const [candId, setCandId] = useState("");

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const excludeKeys = ["jobId"]; // Add the keys you want to exclude here
  const handleProfileClick = (id = "") => {
    setShowCandidate(true);
    setCandId(id);
  };

  const goToJobDescription = (jobId) => {
    navigate(`/job/active/${jobId}/description`);
  };

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => {
      const sumA = Object.values(a).reduce(
        (sum, val) => sum + (typeof val === "number" ? val : 0),
        0,
      );
      const sumB = Object.values(b).reduce(
        (sum, val) => sum + (typeof val === "number" ? val : 0),
        0,
      );
      return sortOrder === "asc" ? sumA - sumB : sumB - sumA;
    });
  }, [data, sortOrder]);

  const renderCell = (key, value, row) => {
    switch (type) {
      case "activeJobs":
        if (key === "jobName") {
          return value === null || value === undefined ? (
            <div className={`flex items-center justify-start `}>-</div>
          ) : (
            <div
              className="flex items-center justify-start hover:underline cursor-pointer"
              onClick={() => goToJobDescription(row?.jobId)}
            >
              {value}
            </div>
          );
        }
        if (key === "jobId") {
          return null;
        }

        return <div className="flex items-center justify-start">{value}</div>;

      case "upcomingEvents":
        if (key === "event")
          return (
            <div className="flex items-center justify-start">
              <FaBriefcase size={16} className="mr-2" />
              {value}
            </div>
          );
        if (key === "date")
          return (
            <div className="flex items-center justify-start">
              <SlCalender size={16} className="mr-2" />
              {value}
            </div>
          );
        if (key === "time")
          return (
            <div className="flex items-center justify-start">
              <FaRegClock size={16} className="mr-2" />
              {value}
            </div>
          );
        if (key === "location")
          return (
            <div className="flex items-center justify-start">
              <FiMapPin size={16} className="mr-2" />
              {value}
            </div>
          );
        break;
      case "pendingTasks":
        if (key === "priority") {
          const color =
            value === "High"
              ? "text-red-500"
              : value === "Medium"
                ? "text-yellow-500"
                : "text-green-500";
          return (
            <div className={`flex items-center justify-start ${color}`}>
              <FaRegFlag size={16} className="mr-2" />
              {value}
            </div>
          );
        }
        if (key === "assignee")
          return (
            <div className="flex items-center justify-start">
              <HiOutlineUsers size={16} className="mr-2" />
              {value}
            </div>
          );
        break;
      case "newApplicants":
        if (key === "linkedinId")
          return value === null || value === undefined ? (
            <div className="flex items-center justify-start">-</div>
          ) : (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline flex items-center justify-start"
            >
              <FaLink size={16} className="mr-2" />
              Profile
            </a>
          );

        if (key === "source")
          return value == null || value === undefined ? (
            <div className="flex items-center justify-start">-</div>
          ) : (
            <div className="flex items-center justify-start">{value}</div>
          );

        if (key === "overallMatch") {
          const color =
            value === "Strong Match"
              ? "text-[#009107]"
              : value === "Good Match"
                ? "text-[#a0b83a]"
                : "text-[#efb353]";
          return value === null || value === undefined ? (
            <div className={`flex items-center justify-start `}>-</div>
          ) : (
            <div className={`flex items-center justify-start ${color}`}>
              {/* <FaRegStar size={16} className="mr-2" /> */}
              {value}
            </div>
          );
        }

        if (key === "candidate_profile") {
          return value === null || value === undefined ? (
            <div className={`flex items-center justify-start `}>-</div>
          ) : (
            <button
              onClick={() => handleProfileClick(value)}
              className="text-blue-500 hover:underline flex items-center justify-start"
            >
              <TbExternalLink size={16} className="mr-2" />
              View Profile
            </button>
          );
        }
        if (key === "name") {
          return value === null || value === undefined ? (
            <div className={`flex items-center justify-start `}>-</div>
          ) : (
            <div
              className="flex items-center justify-start hover:underline cursor-pointer"
              onClick={() => handleProfileClick(row?.candidate_profile)}
            >
              {value}
            </div>
          );
        }
        if (key === "quickLinks") {
          return value === null || value === undefined ? (
            <div className={`flex items-center justify-start `}>-</div>
          ) : (
            <div className="flex flex-row items-center ">
              {value[0] !== null && (
                <a
                  className="candidate-resume-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                  href={value[0]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.9987 6.99935C8.60953 6.99935 9.91537 5.69351 9.91537 4.08268C9.91537 2.47185 8.60953 1.16602 6.9987 1.16602C5.38787 1.16602 4.08203 2.47185 4.08203 4.08268C4.08203 5.69351 5.38787 6.99935 6.9987 6.99935Z"
                      fill="#5F6989"
                    />
                    <path
                      d="M7.00172 8.45898C4.07922 8.45898 1.69922 10.419 1.69922 12.834C1.69922 12.9973 1.82755 13.1257 1.99089 13.1257H12.0126C12.1759 13.1257 12.3042 12.9973 12.3042 12.834C12.3042 10.419 9.92422 8.45898 7.00172 8.45898Z"
                      fill="#5F6989"
                    />
                  </svg>
                </a>
              )}
              {value[1] !== null && (
                <a
                  className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                  href={value[1]}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                      fill="#5F6989"
                    />
                  </svg>
                </a>
              )}

              {value[2] !== null && (
                <a
                  className="candidate-email-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                  // href={value[2]}
                  target="_blank"
                  rel="noreferrer"
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={value[2][0]}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_2992_136007)">
                      <rect
                        x="1.5"
                        y="1.15332"
                        width="11"
                        height="9.9"
                        rx="2"
                        fill="#5F6989"
                        stroke="white"
                      />
                      <path
                        d="M1.5 3.35352L4.50122 5.75449C5.96209 6.92319 8.03791 6.92319 9.49878 5.75449L12.5 3.35352"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2992_136007">
                        <rect
                          width="12.1"
                          height="11"
                          fill="white"
                          transform="translate(0.949219 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              )}
              {value[3] !== null && (
                <a
                  className="candidate-phone-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                  // href={value[3]}
                  target="_blank"
                  rel="noreferrer"
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={value[3][0]}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.73926 2.48338C5.58613 2.04044 5.21536 1.75 4.80305 1.75H3.4162C2.88862 1.75 2.46094 2.24472 2.46094 2.85514C2.46094 8.04371 6.09637 12.25 10.5808 12.25C11.1084 12.25 11.5359 11.7551 11.5359 11.1447L11.5362 9.53981C11.5362 9.06275 11.2852 8.63383 10.9024 8.45666L9.57318 7.84171C9.2293 7.68257 8.83778 7.75419 8.55326 8.02852L8.21021 8.35956C7.80957 8.74585 7.22008 8.71513 6.85132 8.28846L5.88535 7.16978C5.51658 6.74311 5.48933 6.06161 5.8232 5.59807L6.10925 5.20117C6.34635 4.87197 6.40881 4.41884 6.27126 4.02097L5.73926 2.48338Z"
                      fill="#5F6989"
                    />
                  </svg>
                </a>
              )}
            </div>
          );
        }

        return <div className="flex items-center justify-start">{value}</div>;
        break;
    }
    return value;
  };

  const handleScroll = () => {
    if (type !== "newApplicants") return;
    const tableElement = tableRef.current;
    if (!tableElement) return;
    const { scrollTop, clientHeight, scrollHeight } = tableElement;
    if (scrollTop + clientHeight >= scrollHeight - 10 && !loading) {
      if (isQuery === "newApplicants") {
        fetchMoreNewApplicants();
        console.log("calling new applicant search ");
      } else {
        fetchMoreData();
        console.log("calling new applicant  ");
      }
    }
  };

  return (
    <>
      <div
        className="bg-white rounded-lg shadow mt-4 w-full max-h-[360px] overflow-auto min-h-[100px]"
        ref={tableRef}
        onScroll={handleScroll}
      >
        <table className="w-full max-w-full">
          <thead className="sticky top-0">
            <tr className="bg-gray-50 overflow-y-auto border-b-2 border-gray-300">
              {activeCard &&
                fieldMap[activeCard] &&
                Object.keys(fieldMap[activeCard]).map(
                  (key) =>
                    !excludeKeys.includes(key) && ( // Check if key should be rendered
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-black-500 uppercase tracking-wider border-b-2 border-gray-300"
                      >
                        {fieldMap[activeCard][key]}
                      </th>
                    ),
                )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 overflow-y-auto">
            {sortedData.map((row, index) => (
              <tr key={index}>
                {Object.entries(row).map(
                  ([key, value], cellIndex) =>
                    !excludeKeys.includes(key) && ( // Check if key should be rendered
                      <td
                        key={cellIndex}
                        className={`px-6 py-4 whitespace-normal text-sm text-black-500 text-wrap ${
                          key === "appliedFor" || key === "name"
                            ? "max-w-[10rem]"
                            : key === "jobName"
                              ? "max-w-xs"
                              : "max-w-full"
                        }`}
                      >
                        {renderCell(key, value, row)}
                      </td>
                    ),
                )}
              </tr>
            ))}
            {type === "newApplicants" && loading ? (
              <tr className="h-[100px]">
                <td colSpan={Object.keys(fieldMap[activeCard]).length}>
                  <div className="flex justify-center items-center h-full">
                    <ScaleLoader color={"#0073E6"} loading={loading} />
                  </div>
                </td>
              </tr>
            ) : type === "activeJobs" && activeloading ? (
              <tr className="h-[100px]">
                <td colSpan={Object.keys(fieldMap[activeCard]).length}>
                  <div className="flex justify-center items-center h-full">
                    <ScaleLoader color={"#0073E6"} loading={activeloading} />
                  </div>
                </td>
              </tr>
            ) : null}

            {/* {type === "activeJobs" && activeloading && (
              <tr className="h-[100px]">
                <td colSpan={Object.keys(fieldMap[activeCard]).length}>
                  <div className="flex justify-center items-center h-full">
                    <ScaleLoader color={"#0073E6"} loading={activeloading} />
                  </div>
                </td>
              </tr>
            )} */}

            {type === "newApplicants" && !loading && data?.length == 0 && (
              <tr className="h-[60px]">
                <td colSpan={Object.keys(fieldMap[activeCard]).length}>
                  <div className="flex justify-center items-center h-full">
                    <span className="text-gray-800 font-medium text-[18px] leading-[17px] not-italic mt-[0px]">
                      No candidate found
                    </span>
                  </div>
                </td>
              </tr>
            )}

            {type === "activeJobs" && !activeloading && data?.length == 0 && (
              <tr className="h-[60px]">
                <td colSpan={Object.keys(fieldMap[activeCard]).length}>
                  <div className="flex justify-center items-center h-full">
                    <span className="text-gray-800 font-medium text-[18px] leading-[17px] not-italic mt-[0px]">
                      No jobs found
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {showCandidate && (
          <CandidateDrawer
            candId={candId}
            setShowCandidate={setShowCandidate}
            showNavbutton={false}
            showActivities={false}
            showActions={false}
          />
        )}
      </div>
      <ReactTooltip
        id="my-tooltip-9"
        noArrow={true}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: "400",
        }}
        place="top"
      />
    </>
  );
};
