import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../modal/Modal";
import Badge from "../../partial/Badge";
import { ICONS } from "../../assets/componentAssets";
import { Switch } from "antd";
const core = require("../../../lib/core");

const TemplateVaribleModal = ({
  showModal,
  setShowModal,
  getVariables,
  variables,
}) => {
  const modalDismiss = () => {
    setShowModal(false);
  };

  const [allVariables, setAllVariables] = useState([]);

  const myVariables = useMemo(() => {
    return variables;
  }, [variables]);

  const getAllVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_ALL_VARIABLES,
      1,
    );
    if (json?.data) setAllVariables(json?.data);
  };

  const [selectedVariable, setSelectedVariable] = useState(null);
  const [displayName, setDisplayName] = useState("");

  const handleSubmit = async () => {
    if (!selectedVariable || !displayName) return;
    const varibaleData = allVariables.find(
      (variable) => variable._id === selectedVariable,
    );
    const obj = {
      ...varibaleData,
      label: displayName,
    };
    const json = await core.API(
      core.API_METHODS.POST,
      core.TEMPLATE_API.CREATE_TEMPLATE_VARIABLES,
      1,
      { variable: obj },
    );
    if (json?.data) {
      getVariables();
      // modalDismiss();
    }
    setDisplayName("");
    setSelectedVariable("");
  };

  const handleDelete = (id) => async () => {
    const json = await core.API(
      core.API_METHODS.DELETE,
      core.TEMPLATE_API.DELETE_TEMPLATE_VARIABLES + "/" + id,
      1,
    );
    if (json?.data) {
      getVariables();
    }
  };
  // const handleSubmit = async () => {
  //   const json = await core.API(
  //     core.API_METHODS.POST,
  //     core.TEMPLATE_API.UPDATE_TEMPLATE_VARIABLES,
  //     1,
  //     {
  //       variables: myVariables,
  //     },
  //   );
  //   if (json?.data) {
  //     getVariables();
  //     modalDismiss();
  //   }
  // };

  useEffect(() => {
    getAllVariables();
  }, []);
  return (
    <Modal
      modalDismiss={modalDismiss}
      className={"md:max-w-[750px] scrollbar-hide"}
      noBtns={true}
    >
      <h3 className="text-lg">Manage Template Variables</h3>
      {/* <div className="flex flex-col">
        {allVariables.map((variable, index) => (
          <div
            className={`flex justify-between py-3 items-center border-gray-500 ${index === allVariables.length - 1 ? "" : " border-b"}`}
          >
            <div>
              <p className="text-md">{variable.label}</p>
              <p className="text-sm text-gray-600">{variable.variable}</p>
            </div>
            {myVariables.find((v) => v.variable.variable === variable.variable) ? (
              // <Badge
              //   type="danger"
              //   className="h-8 w-8"
              //   onClick={() => {
              //     setMyVariables(
              //       myVariables.filter((v) => v.variable.variable !== variable.variable),
              //     );
              //   }}
              // >
              //   <ICONS.Minus />
              // </Badge>
              <Switch
                checked={true}
                className="bg-gray-300 aria-checked:bg-teal-700"
                defaultChecked
                onChange={(checked) => {
                  if (!checked) {
                    setMyVariables(
                      myVariables.filter(
                        (v) => v.variable.variable !== variable.variable,
                      ),
                    );
                  }
                }}
              />
            ) : (
              // <Badge
              //   type="success"
              //   className="h-8 w-8"
              //   onClick={() => {
              //     setMyVariables([...myVariables, variable]);
              //   }}
              // >
              //   <ICONS.Plus />
              // </Badge>
              <Switch
              className="bg-gray-300 aria-checked:bg-teal-700"
                checked={false}
                defaultChecked
                onChange={(checked) => {
                  if (!checked) {
                    setMyVariables(
                      myVariables.filter(
                        (v) => v.variable.variable !== variable.variable,
                      ),
                    );
                  }
                }}
              />
            )}
          </div>
        ))}
      </div> */}
      <div className="flex gap-4 items-end">
        <div className="grid grid-cols-2 gap-2 grow">
          <div>
            <label
              className="text-sm font-normal not-italic required"
              htmlFor=""
            >
              Collection
            </label>
            <select
              name=""
              id=""
              className="w-full border border-gray-300 rounded-md p-2"
              value={selectedVariable}
              onChange={(e) => setSelectedVariable(e.target.value)}
            >
              <option value="">Select Variable</option>
              {allVariables
                .filter(
                  (variable) =>
                    !myVariables.some(
                      (v) => v.variable.variable === variable.variable,
                    ),
                )
                .map((variable, index) => (
                  <option key={index} value={variable._id}>
                    {variable.label}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              className="text-sm font-normal not-italic required"
              htmlFor=""
            >
              display Name
            </label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter display name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
        </div>
        <Badge
          type="success"
          className={`h-8 w-8 ${!selectedVariable || !displayName ? "cursor-not-allowed opacity-75" : "cursor-pointer"}`}
          onClick={() => {
            handleSubmit();
          }}
        >
          <ICONS.Plus />
        </Badge>
      </div>
      <div className="mt-3">
        {myVariables.map((variable, index) => (
          <div
            key={index}
            className="flex justify-between py-3 items-center border-gray-500"
          >
            <div>
              <p className="text-md">{variable.variable.label}</p>
              <p className="text-sm text-gray-600">
                {variable.variable.variable}
              </p>
            </div>
            <Badge
              type="danger"
              className="h-8 w-8 cursor-pointer"
              onClick={handleDelete(variable._id)}
            >
              <ICONS.Delete />
            </Badge>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TemplateVaribleModal;
