function SvgIcon5({ className }) {
  return (
    <div className={`grow-0 shrink-0 basis-auto box-border w-8 h-8 flex ${className}`}>
      <svg fill="none" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" x="0" y="0">
        <defs />
        <g id="button_3" xmlns="http://www.w3.org/2000/svg">
          <rect height="32" width="32" fill="white" rx="8" x="0.5" y="0.5" />
          <rect height="31" width="31" rx="7.5" stroke="#E1E1EE" x="1" y="1" />
          <g id="Frame">
            <path
              id="Vector_2"
              d="M16.501,8.167c-4.604,0 -8.333,3.729 -8.333,8.333c0,3.688 2.385,6.802 5.698,7.907c0.417,0.072 0.573,-0.178 0.573,-0.396c0,-0.198 -0.011,-0.854 -0.011,-1.552c-2.093,0.385 -2.635,-0.511 -2.802,-0.98c-0.093,-0.239 -0.5,-0.979 -0.854,-1.177c-0.292,-0.156 -0.708,-0.541 -0.01,-0.552c0.656,-0.01 1.125,0.604 1.281,0.854c0.75,1.261 1.948,0.907 2.427,0.688c0.073,-0.542 0.292,-0.906 0.531,-1.115c-1.854,-0.208 -3.791,-0.927 -3.791,-4.114c0,-0.906 0.323,-1.656 0.854,-2.24c-0.084,-0.208 -0.375,-1.062 0.083,-2.208c0,0 0.698,-0.219 2.292,0.854c0.666,-0.187 1.375,-0.281 2.083,-0.281c0.708,0 1.417,0.094 2.083,0.281c1.594,-1.083 2.292,-0.854 2.292,-0.854c0.458,1.146 0.167,2 0.083,2.208c0.532,0.584 0.855,1.323 0.855,2.24c0,3.198 -1.948,3.906 -3.802,4.114c0.302,0.261 0.562,0.761 0.562,1.542c0,1.115 -0.01,2.01 -0.01,2.292c0,0.218 0.156,0.479 0.573,0.396c3.392,-1.146 5.676,-4.326 5.677,-7.907c0,-4.604 -3.73,-8.333 -8.334,-8.333z"
              fill="#5F6989"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon5;
