import React, { useEffect } from 'react'

export default function About(props) {
  useEffect(() => {

  })
  return (
    <div className='col-xs-12 no-padding' id='about-company'>
      <div className='col-xs-10 key-points-section no-padding' >
        {props?.props?.company?.links[0]=="" ? (
          <></>
        ) : (
          <div className='key-points col-md-4 col-xs-12 no-padding'>
            <h6>Website:</h6>
            <span>{props?.props?.company?.links[0]} </span>
          </div>
        )}

        {props?.props?.company?.industry?.length>1 ?(
         <div className='key-points col-md-4 col-xs-12 no-padding'>
         <h6>Industry:</h6>
         <span >{props?.props?.company?.industry}</span>
       </div >
         
        ) : (
          <>
          
          </> 
        )}

        {props?.props?.company?.founder?.length>1 ? (
          <div className='key-points col-md-4 col-xs-12 no-padding'>
          <h6>Organization type:</h6>
          <span >{props?.props?.company?.founder}</span>
        </div >
        ) : (
          <></>
        )}

        {props?.props?.company?.size?.length>1 ? (
          
          <div className='key-points col-md-4 col-xs-12 no-padding'>
          <h6>Company size:
          </h6>
          <span>{props?.props?.company?.size} </span>
        </div>
        ) : (
          <></>
        )}
      </div>
      <div className='col-xs-10 no-padding description'>
        <h6>Overview</h6>
        <span> {props?.props?.company?.about}</span>
      </div>
    </div>
  )
}
