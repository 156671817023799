import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDashboardData, fetchDashboardTask } from '../services';

const core = require("../../lib/core")

const initialState = {
    isLoading: false,
    dashboardData: [],
    tasksList:[]
};



const dashboardSlice = createSlice({
    name: 'dashboardData',
    initialState,
    reducers: {
        // Add any other reducers you may need to manage the state
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardData.fulfilled, (state, action) => {
                state.dashboardData = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchDashboardData.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(fetchDashboardData.pending, (state, action) => {
                state.isLoading = true;
            })

            .addCase(fetchDashboardTask.fulfilled, (state, action) => {
                state.tasksList = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchDashboardTask.rejected, (state, action) => {
                state.isLoading = false;
            })
            .addCase(fetchDashboardTask.pending, (state, action) => {
                state.isLoading = true;
            })
    },
});

// Export actions
export const { } = dashboardSlice.actions;

export default dashboardSlice.reducer;
