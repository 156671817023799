import { useState } from "react";
import $ from "jquery";
const core = require("../../lib/core");

export default function Autocomplete({
  type,
  defaultValue,
  className,
  idName,
  data,
  APIattr,
  searchAPI,
  searchOff,
  selectionFunction,
}) {

  const [list, setList] = useState([]);
  const [hide, setHide] = useState([]);

  $(document).on("click", function (e) {
    if ($(e.target).is("#" + idName + " .auto-complete-input") === false) {
      setHide(true);
    } else {
      setHide(false);
    }
  });

  function searchList(term) {
    try {
      setHide(false);
      if (term?.length < 1 && searchOff) {
        setList(data);
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            searchAPI + "?name=" + term,
            1,
            {}
          );
          
          if (response?.data[APIattr].length > 0) {
            if (typeof response?.data[APIattr][0] === "string") {
              setList(response?.data[APIattr]);
            } else {
              setList(response?.data[APIattr].map((d) => d.name));
            }
          } else {
            setList(["No Option"]);
          }
        }, 300);
      }
    } catch (err) {
      setList(data);
    }
  }
  return (
    <div id={idName} className="col-xs-12 no-padding">
      <input
        className={`auto-complete-input  ${className}`}
        type={type}
        placeholder={defaultValue}
        onChange={(e) => {
          searchList(e.target.value);
        }}
      />
      {list.length > 0 && (
        <ul className={hide ? "auto-complete-list hide" : "auto-complete-list"}>
          {list.map((item, i) => (
            <li
              key={i}
              className="match-value"
              onClick={() => {
                $("#" + idName + " .auto-complete-input").val(item);
                selectionFunction(item);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
