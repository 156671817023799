/*
props structure 
props = {
    category = 'recruiter' or 'candidate'
    showOnlyLogo : Boolean
    menu: Menu component
}
*/

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SearchBar from "./SearchBar";
import RecruiterSearchBar from "./RecruiterSerachBar";
import { UserContext } from "../../../lib/core";
import Logo from "../../assets/Logo";
import Menu from "./Menu";
import Notification from "../../assets/candidate/Notification.svg"
import Avatar from "../../assets/candidate/Avatar.png"


export default function Header(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const nav = useNavigate();
  const { user } = useContext(UserContext);
  useEffect(() => {
    setShowNotif(false);
    setShowMenu(false);
  }, [user]);

  const toggleMenu = () => {
    setShowNotif(false);
    setShowMenu(!showMenu);
  };

  const toggleNtf = () => {
    setShowMenu(false);
    setShowNotif(!showNotif);
  };
  const jobPost = () => {
    nav("/create-job");
  };
  return (
    <div
      className={`component nav-bar col-xs-12 ${props.category}`}
      id="header"
    >
      <div className="col-md-6 middle" hidden={props.showOnlyLogo}>
        {user?.account === 0 ? (
          <div className="searchBar">
            <SearchBar dropdownText="Select Location" />
          </div>
        ) : (
          <div className="recruiterSearchBar">
            <RecruiterSearchBar dropdownText="Select Location" />
          </div>
        )}
      </div>
      <div className=" col-md-5 right no-padding" hidden={props.showOnlyLogo}>
        <div className="button-job-post">
          <button onClick={jobPost}>
            <span className="symbal">{"+"}</span>
            <span className="text">{"Post a new job"}</span>
          </button>
        </div>
        <div className="menu-icon" id="notification" onClick={toggleNtf}>
          <img
            src={Notification}
            // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/notification.svg"
            alt="notifications"
            className="img-responsive"
          />
        </div>
        {showNotif && (
          <div className="notification-menu ">
            <div className="col-xs-12 posts">
              <h4 className="empty-ntf">No new notifications</h4>
            </div>{" "}
            <div className="col-xs-12 bottom-section">
              <a to={"/notification"}>View All</a>
            </div>
          </div>
        )}
        <div className="profile menu-icon">
          {user && user?.pic != "" && (
            <img
              alt="profile image"
              onClick={toggleMenu}
              className="no-padding img-responsive profile-image"
              // src={user?.pic}
              src={Avatar}
            />
          )}
          <svg
            onClick={toggleMenu}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            transform={showMenu ? "scale(1 -1)" : "scale(1 1)"}
          >
            <path
              d="M19.4977 7.98903L12.0007 15.297L4.50372 7.98903C4.36978 7.85819 4.18997 7.78495 4.00272 7.78495C3.81548 7.78495 3.63567 7.85819 3.50172 7.98903C3.43687 8.05257 3.38534 8.12842 3.35017 8.21213C3.31499 8.29584 3.29687 8.38573 3.29688 8.47653C3.29687 8.56733 3.31499 8.65721 3.35017 8.74092C3.38534 8.82463 3.43687 8.90048 3.50172 8.96403L11.4772 16.74C11.6173 16.8765 11.8051 16.953 12.0007 16.953C12.1963 16.953 12.3842 16.8765 12.5242 16.74L20.4997 8.96553C20.565 8.90193 20.617 8.8259 20.6524 8.74191C20.6878 8.65792 20.7061 8.56769 20.7061 8.47653C20.7061 8.38537 20.6878 8.29513 20.6524 8.21114C20.617 8.12715 20.565 8.05112 20.4997 7.98753C20.3658 7.85669 20.186 7.78345 19.9987 7.78345C19.8115 7.78345 19.6317 7.85669 19.4977 7.98753V7.98903Z"
              fill="#001B4B"
              stroke="#001B4B"
            />
          </svg>
          {showMenu && <Menu />}
        </div>
      </div>
    </div>
  );
}
