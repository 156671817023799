import { createSlice } from '@reduxjs/toolkit';
import { createChimeMeetingService, getMyChimeMeetingListService, joinChimeMeetingService, leaveChimeMeetingService } from '../services';

const initialState = {
    join: null,
    leave: null,
    loading: false,
    error: null,
    createMeeting: null,
    myList: null
}

export const chimeMeetingSlice = createSlice({
    name: 'chimeMeeting',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder
            // Create Meeting
            .addCase(createChimeMeetingService.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createChimeMeetingService.fulfilled, (state, action) => {
                state.loading = false;
                state.createMeeting = action.payload;
            })
            .addCase(createChimeMeetingService.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // My List Get
            .addCase(getMyChimeMeetingListService.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getMyChimeMeetingListService.fulfilled, (state, action) => {
                state.loading = false;
                state.myList = action.payload;
            })
            .addCase(getMyChimeMeetingListService.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Join Meeting
            .addCase(joinChimeMeetingService.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(joinChimeMeetingService.fulfilled, (state, action) => {
                state.loading = false;
                state.join = action.payload;
            })
            .addCase(joinChimeMeetingService.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Leave Meeting
            .addCase(leaveChimeMeetingService.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(leaveChimeMeetingService.fulfilled, (state, action) => {
                state.loading = false;
                state.leave = action.payload;
            })
            .addCase(leaveChimeMeetingService.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export const { joinChime } = chimeMeetingSlice.actions;
export default chimeMeetingSlice.reducer;
