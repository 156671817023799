import React, { useState, useEffect } from "react";
import JobDescHeader from "./JobDescHeader";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import Sprouts from "../sprouts.png";
import NotFound from "../PageNotFound";
import Header from "../JobPostHeader";
const core = require("../../../../src/lib/core");

const JobDesc = () => {
  const location = useLocation();

  // const [formJobdetails, setFormJobdetails] = useState({});
  const [jobDetails, setJobDetails] = useState(null);
  const [criteria, setCriteria] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [description, setDescription] = useState("");
  const [education, setEducation] = useState("");
  const [orglogo, setOrglogo] = useState(null);

  const [orgDetails, setOrgeDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchJob = async () => {
    setLoading(true);
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.ORGANIZATION_API.GET_JOBS + location.pathname.split("/").at(-1),
        1,
        {},
      );
      if (json.data) {
        setLoading(false);
        // console.log("Fetched Job Details:", json); // Log the fetched data
        setOrgeDetails(json?.data?.organization);
        setOrglogo(json?.data?.organization?.logo);
        setJobDetails(json.data); // Access the data property

        const criteriaArray = json.data.analytics.skills.map(
          (skillCategory) => skillCategory.criteria,
        );
        setCriteria(criteriaArray);

        const departmentName =
          json.data.departments.length > 0 ? json.data.departments[0].name : "";
        setDepartmentName(departmentName);

        setDescription(parseHtml(json.data.description || ""));

        setEducation(parseHtmledu(json.data.education || ""));
      }
    } catch (error) {
      setLoading(false);
    }
  };
  //  "/job_details/",

  useEffect(() => {
    fetchJob();
  }, [location]);

  return (
    <>
      {loading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <ScaleLoader height={14} color="blue" loading={true} />
        </div>
      ) : (
        <>
          {orgDetails.careers_page_integration ? (
            <div className="text-justify h-screen w-screen flex flex-col overflow-auto gap-12">
              <Header orgDetails={orgDetails} />
              <div className="px-4 sm:px-[5%]">
                <JobDescHeader
                  jobDetails={jobDetails}
                  criteria={criteria}
                  departmentName={departmentName}
                  description={description}
                  education={education}
                  buttonBgColor={orgDetails?.theme_color ?? "#1369E9"}
                />
              </div>

              <footer className="bg-gray-200 pt-5  pb-4">
                <div className="container mx-auto flex items-center justify-center">
                  <p className="text-gray-600 mr-1">Hire with</p>
                  <img
                    src={Sprouts}
                    alt="Sprouts Logo"
                    className="w-24 h-auto ml-4"
                  />
                </div>
                <div className="text-center mt-2">
                  <p className="text-sm">
                    Read our{" "}
                    <a
                      href="https://www.sproutsai.com/privacy"
                      className="text-blue-500 hover:underline"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </footer>
            </div>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default JobDesc;

function parseHtml(html) {
  const parsedHtml = parse(html.split("&lt;").join("<"));
  return parsedHtml;
}

function parseHtmledu(html) {
  const parsedHtml = parse(html.split("&lt;").join("<"));
  const textContent = parsedHtml.props.children || ""; // Get the text content within the parsed HTML
  return textContent.trim() ? parsedHtml : null; // Return parsed HTML if there's meaningful text, otherwise null
}
