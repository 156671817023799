import React, { useState } from 'react'
import Modal from '../../components/modal/Modal'
import InputBox from '../../layouts/InputBox';
import TextAreaBox from '../../layouts/TextAreaBox';

function EditTemplate({ modalDismiss,formData, onSave }) {
    const [editedData, setEditedData] = useState(  {
        "_id": "657ba00bd88edede05039f97",
        "JobTitle": "Software Engineer",
        "Overview": "At [Company]], we are seeking a talented Software Engineer to join our innovative team. As a Software Engineer, you will play a crucial role in designing, developing, and implementing software solutions that drive our technological initiatives.",
        "Responsibilities": [
          "Develop high-quality software design and architecture.",
          "Collaborate with cross-functional teams to define, design, and ship new features.",
          "Build efficient, reusable, and reliable code.",
          "Conduct thorough testing of code to ensure smooth functionality.",
          "Troubleshoot, debug, and upgrade existing software.",
          "Participate in code reviews and knowledge sharing sessions."
        ],
        "Requirements": {
          "Education": "Bachelor’s degree in Computer Science or related field.",
          "Experience": "Minimum of 3 years of proven work experience in software engineering.",
          "Skills": [
            "Proficiency in programming languages such as Java, Python, or C++.",
            "Strong understanding of data structures, algorithms, and system design.",
            "Experience with software development methodologies like Agile or Scrum.",
            "Familiarity with databases (SQL, NoSQL) and web technologies.",
            "Excellent problem-solving and analytical skills."
          ]
        },
        "KeyCompetencies": [
          "Adaptability in a fast-paced, dynamic environment.",
          "Strong communication and collaboration skills.",
          "Ability to work independently and as part of a team.",
          "Innovative mindset with a passion for technology and learning."
        ],
        "CompanyCulture": {
          "Culture": "At [Company Name], we foster a culture of creativity, innovation, and inclusivity. Our team values collaboration, continuous learning, and a supportive work environment that encourages personal and professional growth."
        }
      },);

    const handleChange = (e) => {
      const { name, value } = e.target;
  
      // Handle nested fields (e.g., Requirements.Education)
      const updatedData = { ...editedData };
      const nameParts = name.split('.');
      if (nameParts.length === 2) {
        updatedData[nameParts[0]][nameParts[1]] = value;
      } else {
        updatedData[name] = value;
      }
  
      setEditedData(updatedData);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSave(editedData);
      // You can add logic here to send the edited data to an API or perform other actions
    };
    return (
        <Modal modalDismiss={modalDismiss} heading={"Edit template"}>
            <form onSubmit={handleSubmit}>
                {Object?.keys(editedData)?.map((key) => {
                    if (typeof editedData[key] === 'object') {
                        // For nested objects (e.g., Requirements, CompanyCulture)
                        return (
                            <div className='mr-[20px]' key={key} >
                                <h3 >{key}</h3>
                                {Object?.keys(editedData[key])?.map((nestedKey) => (
                                    <div key={`${key}-${nestedKey}`} className='items-center mt-[20px]'>
                                        <label className='mr-[20px]'>{nestedKey}</label>
                                        <TextAreaBox 
                                         name={`${key}.${nestedKey}`}
                                         value={editedData[key][nestedKey]}
                                         onChange={handleChange}
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    } else if (Array.isArray(editedData[key])) {
                        // For arrays (e.g., Responsibilities, KeyCompetencies)
                        return (
                            <div key={key}>
                                <h3>{key}</h3>
                                {editedData[key]?.map((item, index) => (
                                    <div key={`${key}-${index}`}>
                                        <InputBox
                                             type={"text"}
                                             name={`${key}.${index}`}
                                             value={item}
                                             onChange={(e) => handleChange(e, index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    } else {
                        // For regular fields (e.g., JobTitle, Overview)
                        return (
                            <div key={key}>
                                <label>{key}</label>
                                <InputBox
                                 type={"text"}
                                 name={key}
                                 value={editedData[key]}
                                 onChange={handleChange}
                                />
                            </div>
                        );
                    }
                })}
            </form>
        </Modal>
    )
}

export default EditTemplate