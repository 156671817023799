import React, { useEffect, useState } from 'react'
import InputBox from '../../layouts/InputBox';
import SelectBox from '../../layouts/SelectBox';
import TextAreaBox from '../../layouts/TextAreaBox';
import CustomButton from '../../layouts/Button';
import EditTemplate from './EditTemplate';
const core = require("../../lib/core")

function TemplateManager() {

    const metadata =
    {
        "fields": [
            {
                "type": "input",
                "label": "First Name",
                "name": "first_name",
                "placeholder": "Enter your first name"
            },
            {
                "type": "input",
                "label": "Last Name",
                "name": "last_name",
                "placeholder": "Enter your last name"
            },
            {
                "type": "textarea",
                "label": "Message",
                "name": "message",
                "placeholder": "Enter your message"
            },
            {
                "type": "select",
                "label": "Gender",
                "name": "gender",
                "options": [
                    { "label": "Male", "value": "male" },
                    { "label": "Female", "value": "female" },
                    { "label": "Other", "value": "other" }
                ]
            }
        ]
    }
    const renderField = (field) => {
        const { type, label, name, placeholder, options } = field;

        switch (type) {
            case 'input':
                return (
                    <div key={name}>
                        <label className='text-[#252E4B] text-[14px] font-normal mt-[28px] mb-[5px] not-italic'>{label}</label>
                        <InputBox />
                    </div>
                );
            case 'textarea':
                return (
                    <div key={name}>
                        <label className='text-[#252E4B] text-[14px] font-normal mt-[28px] mb-[5px] not-italic'>{label}</label>
                        <TextAreaBox />
                    </div>
                );
            case 'select':
                return (
                    <div key={name}>
                        <label className='text-[#252E4B] text-[14px] font-normal mt-[28px] mb-[5px] not-italic'>{label}</label>
                        <SelectBox />
                    </div>
                );
            default:
                return null;
        }
    };


    const [templateList, setTemplateList] = useState([])

    //   const [selectTemp, setSelsectTemp] = useState("")

    async function fetch_job_tempaltes() {
        try {
            let api_resp = await core.API(
                core.API_METHODS.GET,
                core.JOB_API.GET_JD_TEMPLATES,
                1, {}
            );
            if (api_resp?.data) {
                setTemplateList(api_resp?.data)
            }

        }
        catch (error) {

        }
    }

    useEffect(() => {
        fetch_job_tempaltes()
    }, [])

    const [showModal, setShowModal] = useState(false)

    const modalClose = () => {
        setShowModal(false)
    }

    return (

        <div>
            {showModal && (
                <EditTemplate
                    modalDismiss={modalClose}
                // onSave={}
                />
            )}
            
            <h1 className='text-[#252E4B] font-medium text-[18px] py-[8px] not-italic leading-[22px] '>Templates</h1>
            <div className="overflow-x-auto h-[calc(100vh-200px)] overflow-y-scroll">
                <table className="min-w-full">
                    <thead className='sticky top-0 z-50'>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                ID
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Job title
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {templateList?.map((item) => (
                            <tr key={item._id}>
                                <td className="px-6 py-4 whitespace-no-wrap">{item?._id}</td>
                                <td className="px-6 py-4 whitespace-no-wrap">
                                    {item?.JobTitle}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap">
                                    <CustomButton text={"View"} onClick={e=>setShowModal(true)} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TemplateManager