import { useEffect, useState } from "react";
import $ from "jquery";
import JobAnalytics from "../../recruiter/job/description/JobAnalytics";
import PdfView from "../../all/PdfView";
import EditSkillAnalytics from "../../../temp/EditAnalytics";
const core = require("../../../lib/core");


export default function ResumeAnalytics({editSkills,profile,skills,setSkills}) {
  const [analyticsData, setAnalyticsData] = useState();
  const [columnChartWidth, setColumnChartWidth] = useState(400);
  const [columnChartData, setColumnChartData] = useState([]);
  const [barChartWidth, setBarChartWidth] = useState(280);
  const [skillDivWidth, setSkillDivWidth] = useState(400);
  const [donutChartWidth, setDonutChartWidth] = useState(100);
  const [gnattChartWidth, setGnattChartWidth] = useState(450);
  // const [profile, setProfile] = useState([])
  // const [skills, setSkills] = useState([]);
  const timelineColors = [
    "#26E45B",
    "#4CD4FF",
    "#1C2D87",
    "#741EFF",
    "#C51EFF",
    "#FF1E6F",
    "#ECC703",
    "#EC7303",
    "#FF2E00",
  ];



  const timelineData = [
    {
      name: "company",
      values: [
        { label: "C1", start: 2012, end: 2016 },
        { label: "C2", start: 2015, end: 2017 },
        { label: "C4", start: 2018, end: 2020 },
      ],
    },
    {
      name: "roles",
      values: [
        { label: "R1", start: 2014, end: 2016 },
        { label: "R2", start: 2016, end: 2018 },
        { label: "R3", start: 2019, end: 2020 },
      ],
    },
    {
      name: "industry",
      values: [
        { label: "I1", start: 2014, end: 2017 },
        { label: "I2", start: 2016, end: 2018 },
        { label: "I3", start: 2018, end: 2020 },
      ],
    },
  ];
  useEffect(() => {
    getAnalyticsData();
    getChartSizes();
    // showKeySkills();
    getProfile()
  }, []);
  $(window).on("load resize", getChartSizes);



async function getProfile() {
    try {
      let response = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_PROFILE_DETAILS,
        1,
        {}
      );
      console.log(response.data);
      // setProfile(response?.data);
      // setSkills(response?.data?.skills)
    } catch (err) {
      console.log(err);
    }
  }





  function getChartSizes() {
    try {
      var w = $("#profile-analytics .section-data.skills").width();
      setColumnChartWidth(w);
      setGnattChartWidth(
        $("#profile-analytics .experience .section-data").width()
      );
    } catch (e) { }
  }
  async function getAnalyticsData() {
    let response = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_RESUME_ANALYTICS,
      1
    );
    setAnalyticsData(response?.data);
    console.log(response?.data);
  }

  function showKeySkills(id = skills[0]?.label) {
    var data = skills.filter((d) => d.label == id)[0];
    setColumnChartData(data);
  }






  const [reload, setReload] = useState(0);
  // const [editSkills, setEditSkills] = useState(false);

  function editSkillValue(slabel, kslabel, value) {
    var skill_arr = skills;
    setReload(reload + 1);
    // console.log(slabel, kslabel, value);
    skill_arr.map((skill, i) => {
      if (skill.label == slabel) {
        skills[i].keySkills.map((ks, it) => {
          if (ks.label == kslabel || ks.label.name == kslabel) {
            ks.value = value;
          }
        });
      }
    });
    setSkills(skill_arr);
    // getChartSizes();
    showKeySkills(slabel, skill_arr);
  }


  function resetSkills() {
    window.location.reload();
  }

  const [view, setView] = useState("Both");
  // function editEnabled() {
  //   setEditSkills(true);
  // }

  async function update_profile() {
    let response = await core.API(
      core.API_METHODS.PUT,
      core.CANDIDATE_API.PUT_UPDATE_PROFILE,
      1,
      {
        skills:skills
      }
    );
    console.log(profile);
    getProfile();
    // setEditSkills(false);

  }

  return (
    <div
      className="analytics-dashboard col-xs-12 no-padding"
      id="profile-analytics"
    >
      {/* {!editSkills ? (
        <button
          className="edit-button"
          data-tooltip="Edit"
          onClick={() => {
            editEnabled();
          }}
        >
          <EditButton />
        </button>
      ) : (
        <>
          <button
            className="done-button edit-button"
            data-tooltip="Done"
            onClick={() => {
              update_profile();
            }}
          >
            <svg
              x="0px"
              y="0px"
              width="24px"
              height="24px"
              viewBox="0 0 122.881 89.842"
            >
              <path
                d="M1.232,55.541c-1.533-1.388-1.652-3.756-0.265-5.289c1.388-1.534,3.756-1.652,5.29-0.265l34.053,30.878l76.099-79.699 c1.429-1.501,3.804-1.561,5.305-0.132c1.502,1.428,1.561,3.803,0.133,5.305L43.223,88.683l-0.005-0.005 c-1.396,1.468-3.716,1.563-5.227,0.196L1.232,55.541L1.232,55.541z"
                fill="#001B4B"
                stroke="#001B4B"
                strokeWidth="4"
                strokeLinecap="round"
              />
            </svg>
          </button>
          <button
            className="revert-button edit-button"
            data-tooltip="Revert"
            onClick={() => {
              // setSkills(job?.analytics?.skills);
              setEditSkills(false);
              getProfile()
              // reload.func(reload.var + 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="4 4 24 24"
              fill="none"
            >
              <path
                d="M24 24L8 8M24 8L8 24"
                stroke="#001B4B"
                strokeWidth="2"
                strokeLinecap="round"
              ></path>
            </svg>
          </button>
        </>
      )} */}
      <div className="analytics">
        <div className="col-xs-12 col-md-12 no-padding" >
          {editSkills ? (
            <>
              {/* {view != "Details" && ( */}
                <EditSkillAnalytics
                  classTxt={
                    view == "Both"
                      ? "analytics-dashboard col-md-12 col-xs-12 no-padding"
                      : "analytics-dashboard col-xs-12 no-padding"
                  }
                  analytics={skills}
                  skills={skills}
                  setSkills={setSkills}
                />
              {/* )} */}
            </>
          ) : (
            <>
              {/* {view != "Details" && ( */}
                <JobAnalytics
                  job={profile}
                  classTxt={
                    view == "Both"
                      ? "analytics-dashboard col-md-12 col-xs-12 no-padding"
                      : "analytics-dashboard col-xs-12 no-padding"
                  }
                />
              {/* )} */}
            </>
          )}
        </div>
        <div className="col-xs-12 col-md-12 no-padding ">
          <div className="col-xs-12 data-section experience">
            <div className="col-xs-12 section-header no-padding">
              <h4 className="col-xs-9 no-padding">Experience</h4>
              {/* <span
                className="col-xs-3 no-padding edit"
                data-tooltip="Edit"
              >
                <EditButton />
              </span> */}
            </div>
            <div className="col-xs-12 no-padding section-data">

              {/* <NewExpChart
                profile={profile}
              /> */}
            </div>
          </div>
          {/* <div className="col-xs-12 data-section other">
            <div className="col-xs-12 section-header no-padding">
              <h4 className="col-xs-9 no-padding">Others</h4>
              <span
                className="col-xs-3 no-padding edit"
                data-tooltip="Edit"
              >
                <EditButton />
              </span>
            </div>
            {analyticsData?.education != [] && (
              <div className="section-data col-md-4 col-xs-12 no-padding education">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M1.33203 11.6L15.3467 6L29.3614 11.6L15.3467 17.2L1.33203 11.6Z"
                      stroke="#0B53DD"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.3618 11.6733V17.822M7.70312 14.55V22.8447C7.70312 22.8447 10.9098 26 15.3471 26C19.7851 26 22.9918 22.8447 22.9918 22.8447V14.55"
                      stroke="#0B53DD"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                {analyticsData?.education?.map((i) => {
                  <span className="title">{i}</span>;
                })}
              </div>
            )}
            {analyticsData?.jobType && (
              <div className="section-data col-md-4 col-xs-12 no-padding">
                <span className="icon"></span>
                <span className="tag">
                  {Array.isArray(analyticsData.jobType)
                    ? analyticsData.jobType.join(", ")
                    : analyticsData.jobType}
                </span>
              </div>
            )}
            {analyticsData?.salary && (
              <div className="section-data col-md-4 col-xs-12 no-padding">
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M26.868 6.66667V6.46667H26.668H6.66797C5.82138 6.46667 5.13464 7.15501 5.13464 8V24C5.13464 24.845 5.82138 25.5333 6.66797 25.5333H26.6693H26.8693V25.3333V21.3333V21.1333H26.6693H18.668C17.3078 21.1333 16.2013 20.0269 16.2013 18.6667V13.3333C16.2013 11.9731 17.3078 10.8667 18.668 10.8667H26.668H26.868V10.6667V6.66667ZM26.868 13.3333V13.1333H26.668H18.668H18.468V13.3333V18.6667V18.8667H18.668H26.668H26.868V18.6667V13.3333ZM6.66797 4.2H26.668C28.0282 4.2 29.1346 5.30646 29.1346 6.66667V25.3333C29.1346 26.6935 28.0282 27.8 26.668 27.8H6.66797C4.57309 27.8 2.86797 26.0949 2.86797 24V8C2.86797 5.90512 4.57309 4.2 6.66797 4.2Z"
                      fill="#0B53DD"
                      stroke="#E1EDFF"
                      strokeWidth="0.4"
                    />
                  </svg>
                </span>
                <span className="title">{analyticsData.salary}</span>
              </div>
            )}
            {analyticsData?.location && (
              <div className="section-data col-md-4 col-xs-12 no-padding">
                <span className="icon"></span>
                <span className="tag">
                  {Array.isArray(analyticsData?.location)
                    ? analyticsData?.location.join(", ")
                    : analyticsData?.location}
                </span>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className="col-xs-12 no-padding resume">
        {/* <PdfView
          pdf={
            "https://portal-storage-19fd1431184217-dev.s3.us-east-2.amazonaws.com/public/rohitkumarjangir79%40gmail_resume.pdf"
          }
        /> */}
      </div>
    </div>
  );
}
