import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Header from "../../../components/all/header/Header";
import Menu from "../../../components/all/header/Menu";
import SideBar from "../../../components/all/SideBar";
import $ from "jquery";
import axios from "axios";
import { useRef } from "react";
import InterviewChart from "../../../components/analytics/InterviewLineChart";
import { toast } from "react-toastify";
import Logo from "../../../components/assets/Logo";
import { PropagateLoader, ScaleLoader } from "react-spinners";
import { async } from "q";
import Avatar from "../../../components/assets/candidate/Avatar.png";

const core = require("../../../lib/core");

export default function Bot() {
  const location = useLocation();
  const nav = useNavigate();
  const messagesEndRef = useRef(null);
  // Meeting status
  const [buttonTxt, setButtonTxt] = useState("Start Meeting");
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [assistStarted, setAssistStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [botID, setBotID] = useState("");
  const [meetingData, setMeetingData] = useState({});
  const [transcription, setTranscription] = useState([]);
  const [quNo, setQuNo] = useState(0);
  const [question, setQuestion] = useState();
  const [graphData, setGraphData] = useState([]);
  const [chartWidth, setChartWidth] = useState(400);
  const [chartHeight, setChartHeight] = useState(400);

  const [currentCriteria, setCurrentCriteria] = useState("Select criteria");
  const [criteriaMenuShow, setCriteriaMenuShow] = useState(false);
  const [criteriaMenu, setCriteriaMenu] = useState(["ML", "DE", "DS"]);
  const [startAssistTime, setStartAssistTime] = useState(0);
  const [endAssistTime, setEndAssistTime] = useState(false);

  useEffect(() => {
    scrollToBottom();
    getIntDetails();
    getChartSizes();
  }, [question]);

  window.onbeforeunload = function (e) {
    console.log(e);
    var dialogText = "Dialog text here";
    e.returnValue = dialogText;
    return dialogText;
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  $(window).on("load resize", getChartSizes);
  function getChartSizes() {
    try {
      var w = $("#botpage .question-section .graph").width();
      var h = $("#botpage .question-section .graph").height();
      setChartWidth(w - 50);
      setChartHeight(h - 70);
    } catch (e) {}
  }

  const getIntDetails = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_INTERVIEW_DETAILS +
        location.pathname.split("/").at(-1),
      1,
    );
    if (json?.data) {
      console.log("Meeting data", json.data);
      setMeetingData(json?.data);
      setCriteriaMenu(json.data.interviewCriterias);
      setCurrentCriteria(json.data.interviewCriterias[0]);
    } else {
      toast.error("Page not found");
      // nav("/");
    }
  };

  console.log(
    "Melink",
    location.search.split("link=").at(-1).split("&").at(-3),
  );

  console.log("Meeting link 3", location.search);

  async function enterBot() {
    const token = "Token aa71aa7bf0dbc53aa62c212206f7df3f95b21962";
    await fetch(core.BOT_API.POST_JOIN_MEETING, {
      method: "POST",
      headers: { Authorization: token, "Content-Type": "application/json" },
      body: JSON.stringify({
        meeting_url: location.search.split("link=").at(-1).split("&").at(-3),
        token: "aa71aa7bf0dbc53aa62c212206f7df3f95b21962",
        interviewId: location.pathname.split("/").at(-1),
      }),
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          setBotID(res.data.data.id);
          getLiveTranscription(res.data.data.id, 30000);
          console.log("Bot Enterred", res.data);
        }),
    );
  }

  function buttonClick() {
    if (!meetingStarted) {
      setQuNo(0);
      enterBot();
      toast("SproutsAI Notetaker has joined the meeting");
      setMeetingStarted(true);
      setButtonTxt("Start Assist");
    } else if (!assistStarted) {
      setStartAssistTime(new Date().toISOString());
      sendQuTime();
      // getLiveQuestions();
      setButtonTxt("End Assist");
      setAssistStarted(true);
    } else {
      // getLiveQuestions();
      leaveMeeting();
      if (location.state) {
        nav(-1);
      } else {
        nav("/applicants");
      }
    }
  }

  const [questionrender, setQuestionrender] = useState("");
  const [loading, setLoading] = useState(false);

  const sendQuTime = async () => {
    try {
      setLoading(true);
      const flag = quNo === 5 ? true : false;
      const json = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_QUESTION_TIME + botID,
        1,
        {
          recruiterQuestionTime: new Date().toISOString(),
          jobId: location.search.split("link=").at(-1).split("&").at(-1),
          interviewid: location.pathname.split("/").at(-1),
          notwarmup: quNo == 0 ? true : false,
        },
      );

      if (json.data) {
        setQuestionrender(json?.data?.nextQusn?.question);
        setLoading(false);
        setQuNo((prev) => prev + 1);
      }
      if (flag) {
        setEndAssistTime(true);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  function getLiveTranscription(id, delay) {
    setTimeout(async function () {
      //your code to be executed after 12 second
      var p = document.createElement("p");
      await axios
        .get(core.BOT_API.GET_LIVE_TRANSCRIPTION + String(id), {
          body: JSON.stringify({ bot_id: id }),
        })
        .then((response) => {
          if (response?.data) {
            // console.log(response?.data)
            setTranscription(response?.data?.data);
            for (let i = 0; i < transcription?.length; i++) {
              console.log(transcription?.includes(transcription[i]));
            }
          }
        });
      getLiveTranscription(id, 30000);
    }, delay);
  }

  async function getLiveQuestions() {
    setIsLoading(true);
    await core
      .API(core.API_METHODS.POST, core.BOT_API.GET_LIVE_MEETING_DATA, 1, {
        botId: botID,
        criteria: currentCriteria,
        recruiter: meetingData.recruiter.name,
        candidate: meetingData.candidate.name,
      })
      .then((response) => {
        if (response?.data) {
          setGraphData(response?.data?.graphData);
          setQuestion(response?.data?.questions.at(-1).question);
          setCurrentCriteria(response?.data?.questions.at(-1).label);
        }
        setIsLoading(false);
        console.log("Q data", response?.data);
        // console.log("Graph data", response?.data?.graphData.splice(-1));
      });
  }
  async function SkipQuestions() {
    setIsLoading(true);
    await core
      .API(core.API_METHODS.POST, core.BOT_API.SKIP_QUESTION, 1, {
        criteria: currentCriteria,
        interview: location.pathname.split("/").at(-1),
      })
      .then((response) => {
        if (response?.data) {
          // setGraphData(response?.data?.graphData);
          setQuestion(response?.data?.question);
          setCurrentCriteria(response?.data?.label);
        }
        setIsLoading(false);
        console.log("Q data", response?.data);
        // console.log("Graph data", response?.data?.graphData.splice(-1));
      });
  }

  async function leaveMeeting() {
    await core
      .API(core.API_METHODS.POST, core.BOT_API.POST_LEAVE_MEETING + botID, 1)
      .then((json) => {
        console.log(json);
        toast("Bot has left the meeting");
      });
    await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.POST_END_INTERVIEW + botID,
      1,
    );
  }

  return (
    <div
      className="w-screen h-screen overflow-hidden flex flex-col gap-y-4 bg-white p-4 pb-6"
      id="botpage"
    >
      {/* Header */}
      <div className="w-full h-12 flex items-center gap-x-12" id="header">
        <Logo />
        <button className="btn-primary btn-md ml-4" onClick={buttonClick}>
          {buttonTxt}
        </button>
      </div>
      {/* Main section */}
      <main className="grow w-full flex gap-5 flex-col">
        {/* <span className="criteria">
          <h3 onClick={() => setCriteriaMenuShow(!criteriaMenuShow)}>
            {currentCriteria}
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            style={{ width: "5px", height: "15px" }}
            viewBox="0 0 29.96 122.88"
            onClick={() => setCriteriaMenuShow(!criteriaMenuShow)}
          >
            <path
              className="cls-1"
              style={{ fill: "#363636" }}
              d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
            />
          </svg>
        </span> */}
        {criteriaMenuShow && (
          <div className="criteria-menu">
            {criteriaMenu.map((crit, i) => (
              <div
                className="menu-item"
                key={i}
                onClick={() => {
                  setCurrentCriteria(crit);
                  setCriteriaMenuShow(!criteriaMenuShow);
                }}
              >
                {crit}
              </div>
            ))}
          </div>
        )}
        <div className="w-full h-1/2 flex gap-5">
          {/* <div className="graph col-xs-5 bg-white rounded-xl p-5 shadow-PipelineCardsShadow border border-gray-200">
            <h1 className="w-full text-lg font-bold m-0">Evaluation</h1>
            <InterviewChart
              ele_id="job-skills"
              data={graphData}
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              color="#dd5e0b"
              axisOnly={!question && !assistStarted}
              labelAttr={"label"}
            />
          </div> */}
          <div className="w-full bg-white rounded-xl p-5 shadow-PipelineCardsShadow border border-gray-200 flex flex-col relative">
            <h1 className="w-full text-lg font-bold m-0">Questions</h1>

            {loading ? (
              <div className="flex justify-center w-full">
                <ScaleLoader height={14} color="#1563E9" loading={true} />
              </div>
            ) : (
              <div className="w-full pt-4">
                {assistStarted && meetingData?.questions && (
                  <p
                    className="text-base text-gray-800 font-normal"
                    style={{ paddingBottom: 30 }}
                  >
                    <b>{"Q" + quNo}</b>
                    {"  :  " + questionrender}
                  </p>
                )}
              </div>
            )}

            {assistStarted && (
              <div className="w-full flex gap-2 items-center justify-between absolute z-10 bottom-0 left-0 p-4 bg-gray-300 border-t border-gray-500 rounded-b-xl">
                <span className="shrink">
                  Is the question answered? If yes, then click on the next
                  question button
                </span>
                <span className="flex gap-2">
                  <button
                    disabled={loading || quNo == 5}
                    // disabled={
                    //   endAssistTime || quNo == meetingData?.questions.length
                    // }
                    className="btn-sec btn-md whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                    onClick={() => {
                      if (quNo < 5) sendQuTime();
                    }}
                  >
                    {isLoading ? (
                      <ScaleLoader color="#0b53dd" size={10} />
                    ) : quNo == 5 ? (
                      "Finished"
                    ) : (
                      "Next question"
                    )}
                  </button>
                  {/* <button
                    disabled={quNo == meetingData?.questions.length - 1}
                    className="btn-sec btn-md disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                    onClick={() => {
                      // SkipQuestions();
                      if (quNo < meetingData?.questions.length - 1) {
                        setQuNo((prev) => prev + 1);
                      }
                    }}
                  >
                    Skip
                  </button> */}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="w-full h-[calc(50vh-88px)] flex justify-between gap-5">
          {/* Attendees */}
          <div className="w-1/4 flex flex-col gap-y-4 bg-white rounded-xl p-5 shadow-PipelineCardsShadow border border-gray-200">
            <h1 className="w-full text-lg font-bold m-0">Attendees</h1>
            <div className="w-full flex gap-4 items-center justify-start">
              <img
                className="w-10 h-10 object-cover rounded-full"
                src={
                  "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png"
                }
                // src={Avatar}
              />
              <h4 className="text-base font-normal text-gray-800">
                {meetingData?.recruiter?.name}
                <span className="text-xs font-semibold text-gray-600 ml-2 uppercase">
                  [You]
                </span>
              </h4>
            </div>
            <div className="w-full flex gap-4 items-center justify-start">
              <img
                className="w-10 h-10 object-cover rounded-full"
                // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/Screenshot+2023-09-01+at+5.45.17+PM.png"
                src={
                  meetingData?.candidate?.pic ??
                  "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png"
                }
              />
              <h4 className="text-base font-normal text-gray-800">
                {meetingData?.candidate?.name}
                <span className="text-xs font-semibold text-gray-600 ml-2 uppercase">
                  [Candidate]
                </span>
              </h4>
            </div>
          </div>
          {/* Transcription */}
          <div className="w-3/4 h-full max-h-full bg-white rounded-xl p-5 pt-0 shadow-PipelineCardsShadow border border-gray-200 overflow-y-scroll scrollbar-candidate relative ">
            <h1 className="w-full text-lg font-bold m-0 sticky bg-white pt-5 pb-3 top-0">
              Live transcription
            </h1>
            {transcription?.map((data, i) => (
              <div
                key={i}
                className="text-base text-gray-800 mt-2 mb-4 flex gap-1 flex-wrap"
              >
                <span className="font-semibold">{data.speaker}</span>
                <span className="text-gray-600 font-bold">
                  [{data.start}] :{" "}
                </span>
                <span className="ml-2">{data.sentence}</span>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
      </main>
    </div>
  );
}
