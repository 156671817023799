import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");

export const fetchJobListByCompany = createAsyncThunk(
  "jobList/fetchJobListByCompany",
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ALL_JOBS_BY_COMPANY +
          payload?.companyName +
          (payload?.query ?? ""),
        1
      );
      return list;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  }
);
