import $ from "jquery";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "../assets/candidate/Hamburger.svg"
const core = require("../../lib/core");

export default function SideBar({ active }) {
  const [show, setShow] = useState(true);
  const { user } = useContext(core.UserContext);

  const sidebarIcons = {
    dashboard: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M5.33203 6.66671C5.33203 6.31309 5.47251 5.97395 5.72256 5.7239C5.9726 5.47385 6.31174 5.33337 6.66536 5.33337H11.9987C12.3523 5.33337 12.6915 5.47385 12.9415 5.7239C13.1916 5.97395 13.332 6.31309 13.332 6.66671V13.3334C13.332 13.687 13.1916 14.0261 12.9415 14.2762C12.6915 14.5262 12.3523 14.6667 11.9987 14.6667H6.66536C6.31174 14.6667 5.9726 14.5262 5.72256 14.2762C5.47251 14.0261 5.33203 13.687 5.33203 13.3334V6.66671ZM18.6654 6.66671C18.6654 6.31309 18.8058 5.97395 19.0559 5.7239C19.3059 5.47385 19.6451 5.33337 19.9987 5.33337H25.332C25.6857 5.33337 26.0248 5.47385 26.2748 5.7239C26.5249 5.97395 26.6654 6.31309 26.6654 6.66671V9.33337C26.6654 9.687 26.5249 10.0261 26.2748 10.2762C26.0248 10.5262 25.6857 10.6667 25.332 10.6667H19.9987C19.6451 10.6667 19.3059 10.5262 19.0559 10.2762C18.8058 10.0261 18.6654 9.687 18.6654 9.33337V6.66671ZM5.33203 21.3334C5.33203 20.9798 5.47251 20.6406 5.72256 20.3906C5.9726 20.1405 6.31174 20 6.66536 20H11.9987C12.3523 20 12.6915 20.1405 12.9415 20.3906C13.1916 20.6406 13.332 20.9798 13.332 21.3334V25.3334C13.332 25.687 13.1916 26.0261 12.9415 26.2762C12.6915 26.5262 12.3523 26.6667 11.9987 26.6667H6.66536C6.31174 26.6667 5.9726 26.5262 5.72256 26.2762C5.47251 26.0261 5.33203 25.687 5.33203 25.3334V21.3334ZM18.6654 17.3334C18.6654 16.9798 18.8058 16.6406 19.0559 16.3906C19.3059 16.1405 19.6451 16 19.9987 16H25.332C25.6857 16 26.0248 16.1405 26.2748 16.3906C26.5249 16.6406 26.6654 16.9798 26.6654 17.3334V25.3334C26.6654 25.687 26.5249 26.0261 26.2748 26.2762C26.0248 26.5262 25.6857 26.6667 25.332 26.6667H19.9987C19.6451 26.6667 19.3059 26.5262 19.0559 26.2762C18.8058 26.0261 18.6654 25.687 18.6654 25.3334V17.3334Z"
          stroke="#363636"
          strokeWidth="2"
        />
        <defs>
          <linearGradient
            id="gradientShades"
            x1="6.9549"
            y1="7.77272"
            x2="28.6367"
            y2="34.7727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#76A5FF" />
            <stop offset="1" stopColor="#0F61FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    suitcase: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <path
          d="M29.6333 10.8999H6.76667C4.96254 10.8999 3.5 12.3624 3.5 14.1666V27.2332C3.5 29.0374 4.96254 30.4999 6.76667 30.4999H29.6333C31.4375 30.4999 32.9 29.0374 32.9 27.2332V14.1666C32.9 12.3624 31.4375 10.8999 29.6333 10.8999Z"
          stroke="#363636"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9341 18.1667L15.2787 18.3398C16.186 18.7935 17.1864 19.0297 18.2008 19.0297C19.2151 19.0297 20.2156 18.7935 21.1228 18.3398L21.4674 18.1667M13.3008 8.36667C13.3008 7.5003 13.6449 6.66941 14.2576 6.05679C14.8702 5.44417 15.7011 5.10001 16.5674 5.10001H19.8341C20.7005 5.10001 21.5314 5.44417 22.144 6.05679C22.7566 6.66941 23.1008 7.5003 23.1008 8.36667V10H13.3008V8.36667Z"
          stroke="#363636"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="gradientShades"
            x1="6.9549"
            y1="7.77272"
            x2="28.6367"
            y2="34.7727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#76A5FF" />
            <stop offset="1" stopColor="#0F61FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    person: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3346 12.0001C21.3346 13.4146 20.7727 14.7711 19.7725 15.7713C18.7723 16.7715 17.4158 17.3334 16.0013 17.3334C14.5868 17.3334 13.2303 16.7715 12.2301 15.7713C11.2299 14.7711 10.668 13.4146 10.668 12.0001C10.668 10.5856 11.2299 9.22904 12.2301 8.22885C13.2303 7.22865 14.5868 6.66675 16.0013 6.66675C17.4158 6.66675 18.7723 7.22865 19.7725 8.22885C20.7727 9.22904 21.3346 10.5856 21.3346 12.0001ZM18.668 12.0001C18.668 12.7073 18.387 13.3856 17.8869 13.8857C17.3868 14.3858 16.7085 14.6667 16.0013 14.6667C15.2941 14.6667 14.6158 14.3858 14.1157 13.8857C13.6156 13.3856 13.3346 12.7073 13.3346 12.0001C13.3346 11.2928 13.6156 10.6146 14.1157 10.1145C14.6158 9.61437 15.2941 9.33341 16.0013 9.33341C16.7085 9.33341 17.3868 9.61437 17.8869 10.1145C18.387 10.6146 18.668 11.2928 18.668 12.0001Z"
          fill="#363636"
          stroke="#F4F8FF"
          strokeWidth="0.8"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0007 1.33337C7.90065 1.33337 1.33398 7.90004 1.33398 16C1.33398 24.1 7.90065 30.6667 16.0007 30.6667C24.1007 30.6667 30.6673 24.1 30.6673 16C30.6673 7.90004 24.1007 1.33337 16.0007 1.33337ZM4.00065 16C4.00065 18.7867 4.95132 21.352 6.54465 23.3894C7.66363 21.9199 9.10718 20.729 10.7626 19.9098C12.4179 19.0905 14.2403 18.6651 16.0873 18.6667C17.9104 18.665 19.7099 19.0794 21.3486 19.8784C22.9872 20.6774 24.4219 21.8399 25.5433 23.2774C26.6986 21.7622 27.4764 19.9937 27.8125 18.1182C28.1486 16.2427 28.0332 14.3141 27.476 12.492C26.9187 10.67 25.9356 9.00677 24.608 7.64009C23.2803 6.27341 21.6463 5.24252 19.8412 4.63272C18.036 4.02292 16.1116 3.85174 14.2271 4.13334C12.3427 4.41494 10.5524 5.14123 9.00434 6.25211C7.45631 7.363 6.19507 8.82653 5.32496 10.5216C4.45485 12.2167 4.00089 14.0947 4.00065 16ZM16.0007 28C13.2459 28.0042 10.5743 27.0565 8.43798 25.3174C9.29789 24.0864 10.4424 23.0813 11.7742 22.3876C13.106 21.694 14.5857 21.3323 16.0873 21.3334C17.5702 21.3322 19.032 21.6848 20.3512 22.3619C21.6705 23.0391 22.8091 24.0212 23.6727 25.2267C21.5197 27.0223 18.8041 28.004 16.0007 28Z"
          fill="#363636"
          stroke="white"
          strokeWidth="0.8"
        />
        <defs>
          <linearGradient
            id="gradientShades"
            x1="6.9549"
            y1="7.77272"
            x2="28.6367"
            y2="34.7727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#76A5FF" />
            <stop offset="1" stopColor="#0F61FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    applied: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M11.332 20H20.6654M11.332 24H15.9987M25.332 2.66666H6.66536C6.31174 2.66666 5.9726 2.80713 5.72256 3.05718C5.47251 3.30723 5.33203 3.64637 5.33203 3.99999V28C5.33203 28.3536 5.47251 28.6927 5.72256 28.9428C5.9726 29.1928 6.31174 29.3333 6.66536 29.3333H25.332C25.6857 29.3333 26.0248 29.1928 26.2748 28.9428C26.5249 28.6927 26.6654 28.3536 26.6654 28V3.99999C26.6654 3.64637 26.5249 3.30723 26.2748 3.05718C26.0248 2.80713 25.6857 2.66666 25.332 2.66666V2.66666Z"
          stroke="#363636"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 8.66666L14.6667 14L12 11.3333"
          stroke="#363636"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="gradientShades"
            x1="6.9549"
            y1="7.77272"
            x2="28.6367"
            y2="34.7727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#76A5FF" />
            <stop offset="1" stopColor="#0F61FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    saved: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M20.2389 8.44443L15.1031 13.4815L12.5352 10.9629"
          stroke="#363636"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.60156 3.39999V27.192C5.60156 28.0821 6.67688 28.5286 7.30737 27.9004L15.2958 19.9413C15.686 19.5525 16.3171 19.5525 16.7074 19.9413L24.6958 27.9004C25.3262 28.5286 26.4016 28.0821 26.4016 27.192V3.39999C26.4016 2.84771 25.9538 2.39999 25.4016 2.39999H6.60156C6.04928 2.39999 5.60156 2.84771 5.60156 3.39999Z"
          stroke="#363636"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="gradientShades"
            x1="6.9549"
            y1="7.77272"
            x2="28.6367"
            y2="34.7727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#76A5FF" />
            <stop offset="1" stopColor="#0F61FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    message: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M15.9993 16C16.7357 16 17.3327 15.4031 17.3327 14.6667C17.3327 13.9303 16.7357 13.3334 15.9993 13.3334C15.263 13.3334 14.666 13.9303 14.666 14.6667C14.666 15.4031 15.263 16 15.9993 16Z"
          fill="#363636"
        />
        <path
          d="M21.3333 16C22.0697 16 22.6667 15.4031 22.6667 14.6667C22.6667 13.9303 22.0697 13.3334 21.3333 13.3334C20.597 13.3334 20 13.9303 20 14.6667C20 15.4031 20.597 16 21.3333 16Z"
          fill="#363636"
        />
        <path
          d="M10.6673 16C11.4037 16 12.0007 15.4031 12.0007 14.6667C12.0007 13.9303 11.4037 13.3334 10.6673 13.3334C9.93094 13.3334 9.33398 13.9303 9.33398 14.6667C9.33398 15.4031 9.93094 16 10.6673 16Z"
          fill="#363636"
        />
        <path
          d="M25.3327 4H6.66602C5.60515 4 4.58773 4.42143 3.83759 5.17157C3.08744 5.92172 2.66602 6.93913 2.66602 8V28C2.66642 28.2363 2.7296 28.4682 2.84908 28.672C2.96857 28.8758 3.14006 29.0442 3.34602 29.16C3.54529 29.2729 3.7703 29.3326 3.99935 29.3333C4.23866 29.3333 4.47354 29.2688 4.67935 29.1467L10.666 25.52C10.8873 25.3883 11.142 25.3234 11.3994 25.3333H25.3327C26.3935 25.3333 27.411 24.9119 28.1611 24.1618C28.9113 23.4116 29.3327 22.3942 29.3327 21.3333V8C29.3327 6.93913 28.9113 5.92172 28.1611 5.17157C27.411 4.42143 26.3935 4 25.3327 4ZM26.666 21.3333C26.666 21.687 26.5255 22.0261 26.2755 22.2761C26.0254 22.5262 25.6863 22.6667 25.3327 22.6667H11.3994C10.6711 22.6661 9.95654 22.8643 9.33268 23.24L5.33268 25.64V8C5.33268 7.64638 5.47316 7.30724 5.72321 7.05719C5.97326 6.80714 6.31239 6.66667 6.66602 6.66667H25.3327C25.6863 6.66667 26.0254 6.80714 26.2755 7.05719C26.5255 7.30724 26.666 7.64638 26.666 8V21.3333Z"
          fill="#363636"
          stroke="white"
          strokeWidth="0.4"
        />
        <defs>
          <linearGradient
            id="gradientShades"
            x1="6.9549"
            y1="7.77272"
            x2="28.6367"
            y2="34.7727"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#76A5FF" />
            <stop offset="1" stopColor="#0F61FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
  };
  const recruiter_sidebar_items = [
    {
      navLink: "/",
      id: "dashboard",
      name: "Dashboard",
      svgIcon: sidebarIcons.dashboard,
    },
    {
      id: "active",
      name: "Jobs",
      svgIcon: sidebarIcons.suitcase,
      submenu: [
        {
          navLink: "/job/active",
          id: "active",
          name: "Active jobs",
        },
        {
          navLink: "/job/draft",
          id: "draft",
          name: "Draft jobs",
        },
        {
          navLink: "/job/archived",
          id: "archived",
          name: "Archived jobs",
        },
      ],
    },
    {
      navLink: "/browse-candidates",
      id: "applicants",
      name: "Candidates browser",
      svgIcon: sidebarIcons.person,
    },
    // {
    //   navLink: "/chats",
    //   name: "Messages",
    //   svgIcon: sidebarIcons.message,
    // },
    {
      navLink: "/company-profile",
      id: "company",
      name: "Company",
      svgIcon: sidebarIcons.applied,
    },
  ];

  useEffect(() => {
    // setShow(true);
    toggleSidebar();
  }, [show]);
  function toggleSidebar() {
    // setShow(!show)
    if ($(".page #sidebar .sidebar-menu").length > 0) {
      $(".page").css("padding-left", "100px");
    } else {
      $(".page").css("padding-left", "20px");
    }
  }

  return (
    <div id="sidebar">
      <span
        className="toggle"
        onClick={() => {
          setShow(!show);
          toggleSidebar();
        }}
      >
        <img
          // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/menu_hamburger.svg"
          src={Hamburger}
          className={"img-responsive " + show ? "menu-cross" : "menu-hamburger"}
          alt="sidebar-toggle"
        />
      </span>
      {show && (
        <ul className="sidebar-menu">
          {/* </a> */}
          {user?.account == 0 ? (
            <>
              <li
                className="sidebar-item col-xs-12 no-padding"
                onClick={() => {
                  toggleSidebar();
                }}
              >
                <Link
                  className={
                    active == "dashboard"
                      ? "menu-toggle active no-padding"
                      : "menu-toggle no-padding"
                  }
                  to="/"
                >
                  {sidebarIcons.suitcase}
                  <span className="no-padding">Jobs</span>
                </Link>
              </li>
              <li
                className="sidebar-item col-xs-12 candidate-item no-padding"
                onClick={() => {
                  toggleSidebar();
                }}
              >
                <Link
                  className={
                    active == "applied"
                      ? "menu-toggle active no-padding"
                      : "menu-toggle no-padding"
                  }
                  to="/applied"
                >
                  {sidebarIcons.saved}
                  <span> Applied Jobs</span>
                </Link>
              </li>
              <li
                className="sidebar-item candidate-item col-xs-12 no-padding"
                onClick={() => {
                  toggleSidebar();
                }}
              >
                <Link
                  className={
                    active == "saved"
                      ? "menu-toggle active no-padding"
                      : "menu-toggle no-padding"
                  }
                  to="/saved"
                >
                  {sidebarIcons.saved}
                  <span> Saved Jobs</span>
                </Link>
              </li>

              {/* <li
                className="sidebar-item col-xs-12 no-padding"
                onClick={() => {
                  toggleSidebar();
                }}
              >
                <Link className={active == 'chat' ? "menu-toggle active no-padding": "menu-toggle no-padding"} to="/chat">
                 {sidebarIcons.message}
                  <span> Message </span>
                </Link>
              </li> */}
              <li
                className="sidebar-item col-xs-12 no-padding"
                onClick={() => {
                  toggleSidebar();
                }}
              >
                <Link className={active == 'chat' ? "menu-toggle active no-padding": "menu-toggle no-padding"} to="/assessment-dashboard">
                 {sidebarIcons.person}
                  <span> Assessment </span>
                </Link>
              </li>
            </>
          ) : (
            recruiter_sidebar_items.map((item, ix) => (
              <li
                key={ix}
                id={item.id}
                className="sidebar-item col-xs-12 no-padding"
                onClick={toggleSidebar}
              >
                {item.navLink ? (
                  <Link
                    className={
                      active == item.id
                        ? "menu-toggle active no-padding"
                        : "menu-toggle no-padding"
                    }
                    to={item.navLink}
                  >
                    {item.svgIcon}
                    <span className="no-padding">{item.name}</span>
                  </Link>
                ) : (
                  <div
                    className={
                      active == item.id
                        ? "menu-toggle active no-padding"
                        : "menu-toggle no-padding"
                    }
                    onClick={() => {
                      $("#sidebar #" + item.id + " .submenu").toggleClass(
                        "hide"
                      );
                    }}
                  >
                    {item.svgIcon}
                    <span className="no-padding">{item.name}</span>
                    <ul className="submenu hide">
                      {item.submenu.map((sbItem, i) => (
                        <li key={i} id={sbItem.id} className="col-xs-12 no-padding">
                          <Link className="submenu-toggle col-xs-12" to={sbItem.navLink}>
                            {sbItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}
