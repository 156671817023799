import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const core = require("../../lib/core");

function Role() {
  const loc = useLocation();
  const roleId = loc.pathname.split("/").pop();
  const [resources, setResources] = useState([]);

  useEffect(() => {
    getResources(roleId);
  }, [roleId]);

  const getResources = async (id) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        `${core.USER_API.GET_ALL_RESOURCES}${id}`,
        1,
      );
      if (json.data) {
        setResources(json.data);
      } else {
        // Handle error if needed
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  const updateResources = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.GET_ALL_RESOURCES,
        1,
        {
          roleId: roleId,
          resourceIds: Object.values(resources).flatMap((section) =>
            section.filter((item) => item.checked).map((item) => item.id),
          ),
        },
      );
      if (response.data) {
        toast.success("Role updated successfully");
      } else {
        // Handle error if needed
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (section, id) => {
    setResources((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item,
      ),
    }));
  };

  return (
    <div className="h-full">
      <>
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-[#252E4B] font-medium text-[18px] py-[8px] not-italic leading-[22px]">
              Role
            </h1>
            <span className="text-[#252E4B] font-normal text-[14px]  not-italic">
              Add permissions/resources for this role
            </span>
          </div>
          <button
            onClick={updateResources}
            className="solid-button-blue px-[12px]  h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center"
          >
            <span className="text-[#FFF] text-[16px] not-italic font-normal">
              Save
            </span>
          </button>
        </div>
        <div className="mt-[40px] h-[calc(100%-100px)] overflow-scroll">
          <table className="min-w-full">
            <thead className="sticky top-0 z-50 bg-[#F2F4F8] text-[#252E4B]  text-[12px]">
              <tr>
                <th className="px-6 py-3 text-left  font-medium w-[50%]   tracking-wider">
                  {"key"}
                </th>
                <th className="px-6 py-3 text-left  font-medium   tracking-wider">
                  Read
                </th>
                <th className="px-6 py-3 text-left  font-medium   tracking-wider">
                  Write
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object?.entries(resources)?.map(([key, values]) => (
                <tr key={key}>
                  <td className="px-6 py-4 whitespace-no-wrap">{key}</td>
                  {values?.filter((item) => item.action === "read")?.length >
                  0 ? (
                    <>
                      {values
                        ?.filter((items) => items.action === "read")
                        ?.map((item) => (
                          <td
                            key={item.id}
                            className="px-6 py-4 whitespace-no-wrap"
                          >
                            <input
                              type="checkbox"
                              className="w-[18px] h-[18px] border-[1px] border-[#C9D5E2] rounded-[4px] bg-[#FFF] cursor-pointer"
                              checked={item?.checked}
                              onChange={(e) =>
                                handleCheckboxChange(key, item?.id)
                              }
                            />
                          </td>
                        ))}
                    </>
                  ) : (
                    <td className="px-6 py-4 whitespace-no-wrap">•</td>
                  )}

                  {values?.filter((item) => item.action !== "read")?.length >
                  0 ? (
                    <>
                      {values
                        ?.filter((items) => items?.action !== "read")
                        ?.map((item) => (
                          <td
                            key={item.id}
                            className="px-6 py-4 whitespace-no-wrap"
                          >
                            <input
                              type="checkbox"
                              className="w-[18px] h-[18px] border-[1px] border-[#C9D5E2] rounded-[4px] bg-[#FFF] cursor-pointer"
                              checked={item.checked}
                              onChange={() =>
                                handleCheckboxChange(key, item?.id)
                              }
                            />
                          </td>
                        ))}
                    </>
                  ) : (
                    <td className="px-6 py-4 whitespace-no-wrap">•</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
}

export default Role;
