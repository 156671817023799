import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { TbCloudDownload } from "react-icons/tb";

function ResumeModal({ resume_link, modalDismiss, setContentReload }) {
  const docs = [{ uri: resume_link }];

  return (
    <Modal
      heading={
        <a
          href={resume_link}
          rel="noreferrer"
          target="_blank"
          className="text-lg font-bold"
        >
          <TbCloudDownload color="gray" size={30} />
        </a>
      }
      noBtns={true}
      className={"md:max-w-[60%] h-[80vh]"}
      modalDismiss={modalDismiss}
    >
      <DocViewer
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
        theme={{
          primary: "#f9f9f9",
          secondary: "#ffffff",
          tertiary: "#f9f9f9",
          textPrimary: "#000",
          textSecondary: "#000",
          textTertiary: "#00000099",
          disableThemeScrollbar: false,
        }}
        documents={docs}
        pluginRenderers={DocViewerRenderers}
      />
    </Modal>
  );
}

export default ResumeModal;
