import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Jobs(props) {
    const [job, setJob] = useState([])
    useEffect(() => {
        
        setJob(props?.props?.jobs)
    }, [props])
   
    return (
        <>
            {job?.length == 0 ? (
                <>
                    <div>
                        <h4>No job posted</h4>
                    </div>
                </>
            ) : (
                <div className="col-xs-12 no-padding" id='company-job-list' >
                    {job?.map((item) => (
                        <Link to={`/job/${item?.id}&tab=description`} key={item?.id}>
                            <div className='col-xs-12 no-padding job-list' key={item?.id}>
                                <div className="col-xs-4 no-padding data-item">
                                    <h4 className="col-xs-12 no-padding position">
                                        {item.position}
                                    </h4>
                                    <h6 className="col-xs-12 no-padding company">
                                        {props?.props?.company?.name}
                                    </h6>
                                </div>
                                <div className="col-xs-2 no-padding posted-on images data-item">
                                    {item?.candidates.length == 0 && (
                                        <h5>Not any</h5>
                                    )}
                                    {item?.candidates[0]?.pic && (
                                        <img
                                            className=""
                                            src={item?.candidates[0]?.pic}
                                            alt=""
                                        />
                                    )}
                                    {item?.candidates[1]?.pic && (
                                        <img
                                            className="imageCount"
                                            src={item?.candidates[1]?.pic}
                                            alt=""
                                        />
                                    )}
                                    {item?.candidates[2]?.pic && (
                                        <img
                                            className="imageCount"
                                            src={item?.candidates[2]?.pic}
                                            alt=""
                                        />
                                    )}
                                    {item?.candidates[3]?.pic && (
                                        <span className="imageCount">{"+"}{item?.candidates.length - 3}</span>
                                    )}
                                </div>
                                {/* <h5 className="col-xs-2 no-padding posted-on position-no data-item">{"3 positions"}</h5> */}
                                <div className="col-xs-2 no-padding posted-on data-item">
                                    <label className=" ">
                                        {/* <span>{"Part time "}</span> */}
                                    </label>
                                </div>
                                <h5 className="col-xs-2 no-padding posted-on date data-item">
                                    {new Date(item.createdAt).toString().split(" ")[2]}{" "}
                                    {new Date(item.createdAt).toString().split(" ")[1]}
                                    {","} {new Date(item.createdAt).toString().split(" ")[3]}
                                </h5>
                            </div>
                        </Link>
                    ))}
                </div>

            )}
        </>
    )
}
