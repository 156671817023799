function SvgIcon6({ className }) {
  return (
    <div className={`grow-0 shrink-0 basis-auto box-border w-8 h-8 flex ${className}`}>
      <svg fill="none" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg" x="0" y="0">
        <defs>
          <clipPath id="clip1_4135_83287" xmlns="http://www.w3.org/2000/svg">
            <rect height="14.7273" width="16.2" fill="white" transform="translate(8.400000000000006, 9.137)" />
          </clipPath>
        </defs>
        <g id="button_2" xmlns="http://www.w3.org/2000/svg">
          <rect height="32" width="32" fill="white" rx="8" x="0.5" y="0.5" />
          <rect height="31" width="31" rx="7.5" stroke="#E1E1EE" x="1" y="1" />
          <g id="Huge-icon/social/outline/gmail" clipPath="url(#clip1_4135_83287)">
            <rect height="13.2545" id="Rectangle 1084" width="14.7273" fill="#5F6989" rx="2" stroke="white" x="9.137" y="10.010999999999996" />
            <path id="Vector 3014" d="M9.137,12.957l4.865,3.892c1.46,1.169 3.536,1.169 4.997,0l4.865,-3.892" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SvgIcon6;
